import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { constant, isNil } from 'lodash';
import { DiscoverModalContainerContext } from '../../discover-modal';

export const PortalContext = createContext<{
  getPortalContainer: () => HTMLElement | undefined;
}>({ getPortalContainer: constant(undefined) });

export const PortalProvider = ({ children }) => {
  const dashletContainer = useContext(DiscoverModalContainerContext);
  const portalRef = useRef<HTMLElement>(
    !isNil(dashletContainer) ? dashletContainer : document.body,
  );

  useEffect(() => {
    const rootEl = dashletContainer ? dashletContainer : document.body;
    const portalEl = document.createElement('div');
    portalEl.setAttribute('id', 'modal-container');
    rootEl.appendChild(portalEl);
    portalRef.current = portalEl;

    return () => {
      rootEl.removeChild(portalRef.current);
      portalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPortalContainer = useCallback(() => portalRef?.current, [portalRef]);

  return (
    <PortalContext.Provider value={{ getPortalContainer }}>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortalRef = () => useContext(PortalContext);
