import { IAnnotation, IDataset } from './interfaces';
import _, { isObject, isEmpty, isArray } from 'lodash';
import JSON5 from 'json5';
import {
  SUGARCRM_PRIMARY_DATASET_SYSTEM_NAME,
  DATASET_STATUS,
} from '../common';
import { messages } from '../i18n';
import React from 'react';
import { Spinner } from '../common/widgets/Status';
import DatasetError from './DatasetError';
import { IDatasetJson } from '../views/edit-dataset-settings';

export const DatasetStatusIcon = ({
  statusType,
}: {
  statusType: DATASET_STATUS;
}) => {
  switch (statusType) {
    case DATASET_STATUS.PREPARING:
      return <Spinner>{messages.datasets.preparingYourData}</Spinner>;
      break;
    case DATASET_STATUS.INGESTING:
      return <Spinner>{messages.datasets.refreshingYourData}</Spinner>;
      break;
    case DATASET_STATUS.ERROR:
      return <DatasetError shortDisplay={messages.editDatasetPanel.error} />;
    default:
      return <>{statusType}</>;
  }
};

export const hasSystemNameAnnotation = (
  annotations: IAnnotation[],
): boolean => {
  return _.some(annotations, {
    key: 'systemName',
    value: SUGARCRM_PRIMARY_DATASET_SYSTEM_NAME,
  });
};

export const getPrimarySugarCRMDataset = (
  datasets: IDataset[] = [],
): IDataset => {
  return _.find(datasets, _dataset => {
    const annotations = _.get(_dataset, 'annotations');
    return hasSystemNameAnnotation(annotations);
  });
};

export const parseAndCleanDatasetJSON: (
  json: string | IDatasetJson,
) => IDatasetJson = (json: string | IDatasetJson) => {
  let datasetJson: IDatasetJson = {};

  try {
    datasetJson = isEmpty(json)
      ? {}
      : isObject(json)
        ? json
        : JSON5.parse(json);
  } catch (e) {
    throw new Error(`Malformed DatasetJSON ${e}`);
  }

  if (!isArray(datasetJson.joinedTables)) {
    datasetJson.joinedTables = [];
  }

  return datasetJson;
};
