import { IExpression, IExpressionSegment, IFilter } from '../../../datasets';
import {
  isDynamicFieldValue,
  NULL_TOKEN,
  reverseSlugLookup,
} from '../../../common';
import { messages } from '../../../i18n';
import _ from 'lodash';
import { decodeExpressionTree } from '../filter.utils';
import { NOW, Operator } from '../Filter';
import {
  convertYearOperandLabel,
  FilterOperators,
  timestampOperators,
} from '../FilterOperators';
import moment from '../../../common/Moment';
import { Viz } from '../../../discovery/VizUtil';

const getLeafCondition = (
  expression: IExpression | IExpressionSegment,
  direction: string,
): IExpressionSegment => {
  if (_.isNil(_.get(expression, direction, undefined))) {
    return expression as IExpressionSegment;
  } else {
    return getLeafCondition(
      _.get(expression as IExpression, direction),
      direction,
    );
  }
};

const joinLimit = (items, verbose = false, maxDisplay = 3) => {
  if (_.isArray(items) && items.length > 1) {
    const total = items.length;
    if (!verbose) {
      const extra = total - maxDisplay;
      const displayItems = items.slice(0, maxDisplay).map(i => `'${i}'`);
      if (extra > 0) {
        displayItems.push(`+${extra}`);
      }
      return displayItems.join(', ');
    } else {
      return items.map(i => `'${i}'`).join(', ');
    }
  } else if (items.length === 1) {
    return _.head(items);
  } else {
    return items;
  }
};

const MapRelativePeriodToFiscal = {
  CALENDAR_WEEKS: 'FISCAL_WEEKS',
  CALENDAR_QUARTERS: 'FISCAL_QUARTERS',
  CALENDAR_YEARS: 'FISCAL_YEARS',
};

const getMessage = msg => {
  return _.get(messages, msg, msg);
};

const relativeDateIsAnchored = exp => {
  return exp.left.operands.length > 3;
};

const MapDisplayPeriodsToFiscal = {
  YEAR: 'FISCAL_YEAR',
  QUARTER: 'FISCAL_QUARTER',
};

const DisplayRelativePeriods = {
  MINUTES: 'activeFilterPanel.minute',
  HOURS: 'activeFilterPanel.hour',
  DAYS: 'activeFilterPanel.day',
  WEEKS: 'activeFilterPanel.week',
  CALENDAR_WEEKS: 'activeFilterPanel.calendarWeek',
  FISCAL_WEEKS: 'activeFilterPanel.fiscalWeek',
  MONTHS: 'activeFilterPanel.month',
  CALENDAR_MONTHS: 'activeFilterPanel.calendarMonth',
  CALENDAR_QUARTERS: 'activeFilterPanel.calendarQuarter',
  FISCAL_QUARTERS: 'activeFilterPanel.fiscalQuarter',
  YEARS: 'activeFilterPanel.year',
  CALENDAR_YEARS: 'activeFilterPanel.calendarYear',
  FISCAL_YEARS: 'activeFilterPanel.fiscalYear',
};

const DisplayPeriods = {
  YEAR: 'activeFilterPanel.yearToDate',
  FISCAL_YEAR: 'activeFilterPanel.fiscalYearToDate',
  QUARTER: 'activeFilterPanel.quarterToDate',
  FISCAL_QUARTER: 'activeFilterPanel.fiscalQuarterToDate',
  MONTH: 'activeFilterPanel.monthToDate',
  DAY: 'activeFilterPanel.dayToDate',
  HOUR: 'activeFilterPanel.hourToDate',
  MINUTE: 'activeFilterPanel.minuteToDate',
};

const getDateSetConditionOperandLabel = (
  condition: IExpressionSegment,
  dateFormat: string,
) => {
  switch (condition.operator) {
    case timestampOperators.inWeek.key: {
      const weekOperandLabel = _.toString(_.head(condition.operands));

      const yearOperandLabel = convertYearOperandLabel(
        _.nth(condition.operands, 1),
      );

      return messages.formatString(
        messages.filters.dateTermInYearRange,
        weekOperandLabel,
        yearOperandLabel,
      );
    }
    case timestampOperators.inMonth.key: {
      const monthOperandLabel: string = moment()
        .month(_.parseInt(_.head(condition.operands)) - 1)
        .format('MMMM');

      const yearOperandLabel = convertYearOperandLabel(
        _.nth(condition.operands, 1),
      );

      return messages.formatString(
        messages.filters.dateTermInYearRange,
        monthOperandLabel,
        yearOperandLabel,
      );
    }
    case timestampOperators.inQuarter.key: {
      const quarterOperandLabel = `${
        messages.timeAttributes.Quarter
      } ${_.toString(_.head(condition.operands))}`;

      const yearOperandLabel = convertYearOperandLabel(
        _.nth(condition.operands, 1),
      );

      return messages.formatString(
        messages.filters.dateTermInYearRange,
        quarterOperandLabel,
        yearOperandLabel,
      );
    }
    case timestampOperators.weekInQuarter.key: {
      const quarterOperandLabel = `${
        messages.timeAttributes.Quarter
      } ${_.toString(_.head(condition.operands))}`;

      const weekOperandLabel = `${messages.timeAttributes.Week} ${_.toString(
        _.nth(condition.operands, 1),
      )}`;

      const yearOperandLabel = convertYearOperandLabel(
        _.nth(condition.operands, 2),
      );

      return messages.formatString(
        messages.filters.dateTermInYearRange,
        `${quarterOperandLabel} - ${weekOperandLabel}`,
        yearOperandLabel,
      );
    }
    case timestampOperators.inYear.key: {
      const yearOperandLabel = convertYearOperandLabel(
        _.nth(condition.operands, 0),
      );
      return yearOperandLabel;
    }
    default: {
      // default to date
      return _.head(condition.operands) === NOW
        ? messages.activeFilterPanel.now
        : moment(_.head(condition.operands)).format(dateFormat);
    }
  }
};

const buildConditionFormat = (filter: IFilter, verbose = false) => {
  const exp = filter.expression;
  //left is either a leaf node or a recursively constructed string
  let left = '';
  if (Viz.isJoiningOperator(exp.left?.operator)) {
    left = `${buildConditionFormat({
      ...filter,
      expression: exp.left as IExpression,
    })}`;
  } else {
    const leftCondition = getLeafCondition(filter.expression, 'left');

    const leftOperator = getOperatorObj(filter, leftCondition.operator);
    left = _.isNil(exp.left)
      ? ''
      : `${getMessage(leftOperator?.shortDisplayText)} ${
          leftOperator?.requiresOperand && !leftOperator?.hideOperand
            ? joinLimit((exp.left as IExpressionSegment).operands, verbose)
            : ``
        }`;
    if (leftOperator.key === 'dynamicField') {
      left = getMessage(
        `filters.current${reverseSlugLookup(_.head(leftCondition.operands))}`,
      );
    }
  }
  const rightCondition = getLeafCondition(filter.expression, 'right');
  const rightOperator = getOperatorObj(filter, rightCondition.operator);
  const operator = _.isNil(exp.operator) ? '' : `${exp.operator.toLowerCase()}`;
  const right = _.isNil(exp.right)
    ? ''
    : `${getMessage(rightOperator?.shortDisplayText)} ${
        rightOperator.requiresOperand
          ? joinLimit(exp.right.operands, verbose)
          : ``
      }`;
  return `${left} ${operator} ${right}`;
};

export const getOperatorObj = (filter: IFilter, operator: string): Operator => {
  if (operator === 'currentUser') {
    operator = 'dynamicField';
  }
  return FilterOperators.forFilterType(filter.type, filter.subType)[operator];
};

export const formatString =
  ({ dateFormat, dateTimeFormat }) =>
  (filter: IFilter, useFiscalCalendar = false, verbose = false): string => {
    const exp = filter.expression;
    switch (filter.type) {
      case 'BOOLEAN':
        return (exp.left as IExpressionSegment)?.operands?.length > 0
          ? (exp.left as IExpressionSegment)?.operands[0]
          : '';
      case 'STRING':
        switch (filter.subType) {
          case 'SELECT_ITEMS': {
            // Add appropriate commas, spacing, and conjunction
            const operands = (exp.left as IExpressionSegment).operands
              .map(o =>
                isDynamicFieldValue(o)
                  ? messages.filters[`is${reverseSlugLookup(o)}`]
                  : o,
              )
              .map(o => (o === NULL_TOKEN ? 'NULL' : o));
            if (operands.length === 1) {
              return `${operands[0]}`;
            } else if (operands.length === 2) {
              operands[operands.length - 1] = `or ${
                operands[operands.length - 1]
              }`;
              return `${operands.join(' ')}`;
            } else if (operands.length === 3) {
              operands[operands.length - 1] = `or ${
                operands[operands.length - 1]
              }`;
              return `${operands.join(', ')}`;
            } else {
              // Only show up to three selected items
              return messages.formatString(
                messages.activeFilterPanel.nSelected,
                operands.length,
              );
            }
          }
          case 'SET_CONDITION': {
            return buildConditionFormat(filter, verbose);
          }
          case 'TOP_BOTTOM':
            if (
              exp.left.operator === 'topNPercent' ||
              exp.left.operator === 'bottomNPercent'
            ) {
              // Append an '%' to percentage operands
              return `${getMessage(
                getOperatorObj(filter, exp.left.operator).shortDisplayText,
              )} ${(exp.left as IExpressionSegment).operands.join()}%`;
            } else {
              return `${getMessage(
                getOperatorObj(filter, exp.left.operator).shortDisplayText,
              )} ${(exp.left as IExpressionSegment).operands.join()}`;
            }
        }
        break;
      case 'NUMERIC': {
        const left =
          _.isNil(exp.left) ||
          _.isNil(getOperatorObj(filter, exp.left.operator))
            ? ''
            : `${getMessage(
                getOperatorObj(filter, exp.left.operator).shortDisplayText,
              )} ${
                getOperatorObj(filter, exp.left.operator).requiresOperand
                  ? (exp.left as IExpressionSegment).operands.join()
                  : ``
              }`;
        const operator = _.isNil(exp.operator)
          ? ''
          : `${exp.operator.toLowerCase()}`;
        const right =
          _.isNil(exp.right) ||
          _.isNil(getOperatorObj(filter, exp.right.operator))
            ? ''
            : `${getMessage(
                getOperatorObj(filter, exp.right.operator).shortDisplayText,
              )} ${exp.right.operator ? exp.right.operands.join() : ``}`;
        return _.join(_.compact([left, operator, right]), ' ');
      }
      case 'DATE':
        switch (filter.subType) {
          case 'SET_CONDITION': {
            // Format string operands in single quotes
            const operator = _.isNil(exp.operator)
              ? ''
              : `${exp.operator.toLowerCase()}`;
            const conditions = decodeExpressionTree(filter.expression);

            const conditionLabelSubStrings = _.map(
              conditions,
              (_condition, _idx) => {
                const isFirst = _idx === 0;
                const prefix = isFirst ? '' : ` ${operator} `;
                const conditionOperator = getOperatorObj(
                  filter,
                  _condition.operator,
                );

                const filterFormat = conditionOperator.disableTime
                  ? dateFormat
                  : dateTimeFormat;
                const conditionLabel = `${getMessage(
                  useFiscalCalendar &&
                    !_.isNil(conditionOperator.shortFiscalDisplayText)
                    ? conditionOperator.shortFiscalDisplayText
                    : conditionOperator.shortDisplayText,
                )} ${
                  conditionOperator.requiresOperand
                    ? getDateSetConditionOperandLabel(_condition, filterFormat)
                    : ``
                }`;

                return `${prefix}${conditionLabel}`;
              },
            );

            return joinLimit(conditionLabelSubStrings, verbose, 2);
          }
          case 'RELATIVE_DATES': {
            const anchored = relativeDateIsAnchored(exp) ? '*' : '';
            const timePeriod =
              useFiscalCalendar &&
              _.includes(
                _.keys(MapRelativePeriodToFiscal),
                (exp.left as IExpressionSegment).operands[1],
              )
                ? MapRelativePeriodToFiscal[
                    (exp.left as IExpressionSegment).operands[1]
                  ]
                : (exp.left as IExpressionSegment).operands[1];

            if (exp.left.operator === 'this') {
              // Don't show the date value
              return `${getMessage(
                getOperatorObj(filter, exp.left.operator)?.shortDisplayText,
              )} ${getMessage(DisplayRelativePeriods[timePeriod])}${anchored}`;
            } else if (
              _.toInteger((exp.left as IExpressionSegment).operands[0]) > 1
            ) {
              // Append an 's' to relative period if the date value is greater than one
              return `${getMessage(
                getOperatorObj(filter, exp.left.operator)?.shortDisplayText,
              )} ${(exp.left as IExpressionSegment).operands[0]} ${getMessage(
                `${DisplayRelativePeriods[timePeriod]}Plural`,
              )}${anchored}`;
            } else {
              return `${getMessage(
                getOperatorObj(filter, exp.left.operator)?.shortDisplayText,
              )} ${(exp.left as IExpressionSegment).operands[0]} ${getMessage(
                DisplayRelativePeriods[timePeriod],
              )}${anchored}`;
            }
          }
          case 'PERIOD_TO_DATE': {
            const timePeriod =
              useFiscalCalendar &&
              _.includes(
                _.keys(MapDisplayPeriodsToFiscal),
                (exp.left as IExpressionSegment).operands[0],
              )
                ? MapDisplayPeriodsToFiscal[
                    (exp.left as IExpressionSegment).operands[0]
                  ]
                : (exp.left as IExpressionSegment).operands[0];
            return `${getMessage(DisplayPeriods[timePeriod])}`;
          }
        }
        break;
    }
  };
