import _ from 'lodash';
import { BodyCell } from './ui/components';
import { GhostInput } from './ui/ghost-input.component';
import classnames from 'classnames';
import { SortDirection } from 'react-virtualized';
import { DATA_TYPE_FORMAT, Types } from '../../common/Constants';
import { DataTypeSelector } from '../../components/data-type-selector';
import { Tooltip } from '../../components/ui/tooltip';
import { FieldTypeIcon } from '../../icons';

import { i18nUtils, messages } from '../../i18n';
import { AggregationDropdown } from '../../components/aggregation-dropdown';
import { FilterToolBarIcon } from './filter-toolbar-icon';
import { ActionToolbar } from './action-toolbar';

export const TableSpec = {
  defaultSortBy: 'name',
  searchKeys: ['name', 'description'],
  columns: [
    {
      key: 'name',
      label: 'editDatasetPanel.fieldNameLabel',
      width: 200,
      percentWidth: 17,
      maxWidth: 2400,
      minWidth: 200,
      sortable: true,
      flex: 1,
      hidden: false,
      cellRenderer: ({ rowData, onChange, rowIndex, columnIndex }) => (
        <BodyCell
          key={`${rowIndex}-${columnIndex}-${rowData.index}`}
          className={classnames({ dim: rowData.hidden })}
        >
          <GhostInput
            value={rowData.name}
            title={messages.editDatasetPanel.editFieldNameLabel}
            onChange={value => onChange(rowData, { name: value })}
          />
        </BodyCell>
      ),
    },
    {
      key: 'sourceName',
      label: 'editDatasetPanel.sourceNameLabel',
      width: 250,
      percentWidth: 24,
      maxWidth: 400,
      minWidth: 200,
      sortable: false,
      flex: 0,
      hidden: false,
      dynamicAnnotationDisplay: true,
      annotationKey: 'SOURCE_NAME',
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => {
        const annotation = _.find(rowData.annotations, {
          key: 'SOURCE_NAME',
        });
        let cellValue;
        if (annotation) {
          cellValue = annotation.value;
        }
        return (
          <BodyCell
            key={`${rowIndex}-${columnIndex}-${rowData.index}`}
            className={classnames({ dim: rowData.hidden })}
          >
            <Tooltip
              id={`${rowIndex}-${columnIndex}-${rowData.index}`}
              placement={'bottom'}
              title={cellValue}
              enterDelay={1000}
              classes={{ tooltip: 'datasetPreview' }}
              arrow
            >
              <div>{cellValue}</div>
            </Tooltip>
          </BodyCell>
        );
      },
    },
    {
      key: 'displayLabel',
      label: 'editDatasetPanel.displayLabel',
      width: 160,
      percentWidth: 20,
      maxWidth: 400,
      minWidth: 160,
      sortable: false,
      flex: 0,
      hidden: false,
      dynamicAnnotationDisplay: true,
      annotationKey: 'DISPLAY_LABEL',
      cellRenderer: ({ rowData, rowIndex, columnIndex }) => {
        const annotation = _.find(rowData.annotations, {
          key: 'DISPLAY_LABEL',
        });
        let cellValue;
        if (annotation) {
          cellValue = annotation.value;
        }
        return (
          <BodyCell
            key={`${rowIndex}-${columnIndex}-${rowData.index}`}
            className={classnames({ dim: rowData.hidden })}
          >
            <Tooltip
              id={`${rowIndex}-${columnIndex}-${rowData.index}`}
              placement={'bottom'}
              title={cellValue}
              enterDelay={1000}
              arrow
            >
              <div>{cellValue}</div>
            </Tooltip>
          </BodyCell>
        );
      },
    },
    {
      key: 'description',
      label: 'editDatasetPanel.descriptionLabel',
      width: 296,
      percentWidth: 18,
      maxWidth: 400,
      sortable: true,
      flex: 0,
      hidden: true,
      cellRenderer: ({ rowData, onChange, rowIndex, columnIndex }) => (
        <BodyCell
          key={`${rowIndex}-${columnIndex}-${rowData.index}`}
          className={classnames({ dim: rowData.hidden })}
        >
          <GhostInput
            value={rowData.description}
            title={messages.editDatasetPanel.editFieldDescriptionLabel}
            onChange={value => onChange(rowData, { description: value })}
          />
        </BodyCell>
      ),
    },
    {
      key: 'formatType',
      label: 'editDatasetPanel.formatLabel',
      width: 170,
      percentWidth: 9,
      maxWidth: 184,
      minWidth: 170,
      sortable: true,
      flex: 0,
      hidden: false,
      cellRenderer: ({
        rowData,
        onChange,
        rowIndex,
        columnIndex,
        currencySymbol,
      }) => {
        const formatters = DATA_TYPE_FORMAT.getFormattersByDataType(
          rowData.attributeType,
        );
        const formatType = rowData.formatType
          ? rowData.formatType
          : formatters[0]?.formatType;
        return (
          <BodyCell
            key={`${rowIndex}-${columnIndex}-${rowData.index}`}
            className={classnames({ dim: rowData.hidden })}
          >
            <DataTypeSelector
              id={`att_type_${_.kebabCase(rowData.name)}`}
              key={`att_type_${rowData.name}`}
              className='dataset-preview-table__data-type-dropdown'
              showDataTypeIcon
              dataType={formatters[0]?.dataType}
              formatType={formatType}
              isCalc={!_.isEmpty(rowData.calculation)}
              currencySymbol={currencySymbol}
              onSelect={formatter =>
                onChange(rowData, {
                  formatType: formatter.formatType,
                  attributeType: formatter.dataType,
                })
              }
              disablePortal={false}
              headerSx={{ height: '30px', width: '100%' }}
              popperSx={{
                zIndex: 1050,
              }}
            />
          </BodyCell>
        );
      },
      customSort: list => {
        return _.sortBy(list, [
          o => _.toLower(o.formatType) || _.toLower(o.attributeType),
        ]);
      },
    },
    {
      key: 'defaultAggregation',
      label: 'editDatasetPanel.aggregationLabel',
      width: 160,
      percentWidth: 9,
      maxWidth: 164,
      minWidth: 160,
      sortable: true,
      flex: 0,
      hidden: false,
      cellRenderer: ({
        rowData,
        onChange,
        aggregateInfo,
        rowIndex,
        columnIndex,
      }) => {
        const aggregates = _.filter(aggregateInfo, info =>
          _.includes(info.supportedTypes, rowData.attributeType.toUpperCase()),
        ).map(info => info.name);

        if (_.isEmpty(aggregates) || rowData.defaultAggregation === 'NONE') {
          return null;
        }

        return (
          <BodyCell
            key={`${rowIndex}-${columnIndex}-${rowData.index}`}
            className={classnames({ dim: rowData.hidden })}
          >
            <AggregationDropdown
              dataType={rowData.attributeType}
              defaultValue={{
                value: rowData.defaultAggregation,
                label: i18nUtils.getAggregationDisplayText(
                  rowData.defaultAggregation,
                ),
              }}
              onSelect={agg =>
                onChange(rowData, { defaultAggregation: agg?.name })
              }
              headerSx={{ height: '30px', width: '100%' }}
              popperSx={{
                zIndex: 1050,
              }}
            />
          </BodyCell>
        );
      },
    },
    {
      key: 'percentNull',
      label: 'editDatasetPanel.percentNullLabel',
      width: 80,
      percentWidth: 9,
      maxWidth: 80,
      minWidth: 80,
      sortable: true,
      flex: 0,
      hidden: true,
      cellRenderer: ({
        rowData,
        getAttributeProfile,
        rowIndex,
        columnIndex,
      }) => {
        const attributeProfile = getAttributeProfile(rowData.originalName);
        const percentNull = attributeProfile.find(
          p => p.name === 'Null Percent',
        );
        const value = _.get(percentNull, 'value', null);
        return (
          <BodyCell key={`${rowIndex}-${columnIndex}-${rowData.index}`}>
            {_.isNil(value) ? '-' : `${_.round(value)}%`}
          </BodyCell>
        );
      },
      customSort: (list, getAttributeProfile) => {
        return _.sortBy(list, [
          o => {
            const attributeProfile = getAttributeProfile(o.originalName);
            const percentNull = attributeProfile.find(
              p => p.name === 'Null Percent',
            );
            const value = _.get(percentNull, 'value', null);
            return _.isNil(value) ? -1 : parseFloat(value);
          },
        ]);
      },
    },
    {
      key: 'percentUnique',
      label: 'editDatasetPanel.percentUniqueLabel',
      width: 80,
      percentWidth: 5,
      maxWidth: 80,
      minWidth: 80,
      sortable: true,
      flex: 0,
      hidden: true,
      cellRenderer: ({
        rowData,
        getAttributeProfile,
        rowIndex,
        columnIndex,
      }) => {
        const attributeProfile = getAttributeProfile(rowData.originalName);
        const percentUnique = attributeProfile.find(
          p => p.name === 'Unique Percent',
        );
        const value = _.get(percentUnique, 'value', null);
        return (
          <BodyCell key={`${rowIndex}-${columnIndex}-${rowData.index}`}>
            {_.isNil(value) ? '-' : `${_.round(value)}%`}
          </BodyCell>
        );
      },
      customSort: (list, getAttributeProfile) => {
        return _.sortBy(list, [
          o => {
            const attributeProfile = getAttributeProfile(o.originalName);
            const percentUnique = attributeProfile.find(
              p => p.name === 'Unique Percent',
            );
            const value = _.get(percentUnique, 'value', null);
            return _.isNil(value) ? -1 : parseFloat(value);
          },
        ]);
      },
    },
    {
      key: 'distribution',
      label: 'editDatasetPanel.distributionLabel',
      width: 160,
      percentWidth: 9,
      maxWidth: 160,
      sortable: false,
      flex: 0,
      hidden: true,
      cellRenderer: () => <BodyCell>-</BodyCell>,
    },
    {
      key: 'actions',
      label: '',
      percentWidth: 10,
      maxWidth: 200,
      minWidth: 160,
      sortable: false,
      flex: 0,
      hidden: false,
      cellRenderer: ({
        rowData,
        onChange,
        removeCalc,
        selectedAttribute,
        onPreviewIconClick,
        onEditFieldIconClick,
        isPreviewPanelOpen,
        isEditFieldSettingsPanelOpen,
        rowIndex,
        columnIndex,
      }) => {
        return (
          <BodyCell
            key={`${rowIndex}-${columnIndex}-${rowData.index}`}
            className='actions-cell'
          >
            <ActionToolbar
              rowData={rowData}
              showFields={() => onChange(rowData, { hidden: false })}
              hideFields={() => onChange(rowData, { hidden: true })}
              removeCalc={removeCalc}
              onPreviewIconClick={onPreviewIconClick}
              selectedAttribute={selectedAttribute}
              isPreviewPanelOpen={isPreviewPanelOpen}
              onEditFieldIconClick={onEditFieldIconClick}
              isEditFieldSettingsPanelOpen={isEditFieldSettingsPanelOpen}
            />
          </BodyCell>
        );
      },
    },
  ],
  filters: [
    {
      id: 'string',
      tooltip: 'editDatasetPanel.stringFieldsTooltip',
      icon: <FilterToolBarIcon fieldType={Types.STRING} />,
      default: _.stubFalse,
      hidden: false,
      test: o => _.toUpper(o.attributeType) === Types.STRING,
    },
    {
      id: 'number',
      tooltip: 'editDatasetPanel.numberFieldsTooltip',
      icon: <FieldTypeIcon fieldType={Types.NUMBER} />,
      default: _.stubFalse,
      hidden: false,
      test: o => _.toUpper(o.attributeType) === Types.NUMBER,
    },
    {
      id: 'calculation',
      tooltip: 'editDatasetPanel.calculatedFieldsTooltip',
      icon: <FieldTypeIcon fieldType={Types.NUMBER} isCalculation={true} />,
      default: _.stubFalse,
      hidden: false,
      test: o => !_.isEmpty(o.calculation),
    },
    {
      id: 'timestamp',
      tooltip: 'editDatasetPanel.timestampFieldsTooltip',
      icon: <FilterToolBarIcon fieldType={Types.TIMESTAMP} />,
      default: _.stubFalse,
      hidden: false,
      test: o => _.toUpper(o.attributeType) === Types.TIMESTAMP,
    },
    {
      id: 'boolean',
      tooltip: 'editDatasetPanel.booleanFieldsTooltip',
      icon: <FieldTypeIcon fieldType={Types.BOOLEAN} />,
      default: _.stubFalse,
      hidden: false,
      test: o => _.toUpper(o.attributeType) === Types.BOOLEAN,
    },
    {
      id: 'show',
      tooltip: 'editDatasetPanel.showHiddenFieldsTooltip',
      // @NOTE: Font-based icons don't fare well with static prop. Recommended to move 'filters' into DatasetPreviewComponent
      icon: [],
      hidden: true,
      default: _.stubFalse,
    },
    {
      id: 'hide',
      tooltip: 'editDatasetPanel.hideHiddenFieldsTooltip',
      icon: [],
      default: curr => !_.some(curr, { id: 'show' }),
      hidden: true,
      test: o => !o.hidden,
    },
  ],
  sort: ({
    data,
    sortBy,
    sortDirection,
    search,
    activeFilters,
    getAttributeProfile,
  }) => {
    let sortedList = [...data];
    /* Filter  */
    const defaultFilters = _.filter(TableSpec.filters, f =>
      f.default(activeFilters),
    );
    if (defaultFilters.length > 0) {
      sortedList = _.filter(sortedList, item =>
        TableSpec.filter(defaultFilters, item),
      );
    }
    const applyFilters = _.filter(activeFilters, f => _.isFunction(f.test));
    if (applyFilters.length > 0) {
      sortedList = _.filter(sortedList, item =>
        TableSpec.filter(applyFilters, item),
      );
    }
    /* Search */
    if (!_.isEmpty(search)) {
      search = _.toLower(search);
      sortedList = sortedList.filter(o => {
        const annotations = _.get(o, 'annotations', []);
        const annotationsMap = annotations.reduce((objMap, annotation) => {
          _.set(objMap, annotation.key, annotation.value);
          return objMap;
        }, {});
        return (
          _.includes(_.toLower(o.name), search) ||
          _.includes(_.toLower(o.description), search) ||
          _.includes(_.toLower(annotationsMap.SOURCE_NAME), search) ||
          _.includes(_.toLower(annotationsMap.DISPLAY_LABEL), search)
        );
      });
    }
    /* Sort */
    const columnSpec = _.find(TableSpec.columns, c => _.isEqual(c.key, sortBy));
    if (_.isFunction(columnSpec.customSort)) {
      sortedList = columnSpec.customSort(sortedList, getAttributeProfile);
    } else {
      sortedList = _.sortBy(sortedList, [o => _.toLower(o[sortBy])]);
    }
    sortedList = _.isEqual(sortDirection, SortDirection.DESC)
      ? _.reverse(sortedList)
      : sortedList;
    return sortedList;
  },
  filter: (filters, item) => {
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].test(item)) {
        return true;
      }
    }
    return false;
  },
};
