import { Navigate, NavigateProps } from 'react-router-dom';
import { IVizRedirect } from './viz-redirect.interfaces';
import { ROUTER_DIRS } from '../../common';

export const VizRedirect = (props: IVizRedirect) => {
  const toProp: NavigateProps['to'] = {
    pathname: ROUTER_DIRS.OPEN_VIZ(props.vizId),
  };

  return <Navigate to={toProp} state={props?.state} />;
};
