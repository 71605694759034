import { IAttribute } from '../../../datasets';
import _, { isEmpty } from 'lodash';

const SPACE = ' ';
const aggregationStringMap: Record<string, string> = {
  Average: 'AVG:',
  Count: 'COUNT:',
  'Count (Distinct)': 'CNT DIS:',
  Maximum: 'MAX:',
  Minimum: 'MIN:',
  Sum: 'SUM:',
  None: '',
};

export const AggregateFieldLabelMapper = {
  getAggregationLabel(axisField: IAttribute, aggName: string): string {
    if (axisField.attributeType === 'TIME_CALC') {
      return axisField.name;
    }

    const prefix = !isEmpty(aggregationStringMap[aggName])
      ? `${aggregationStringMap[aggName]}${SPACE}`
      : '';
    return `${prefix}${axisField.name}`;
  },
  getBaseFieldName(fieldLabel: string): string {
    const possiblePrefixes = _(aggregationStringMap)
      .values()
      .filter(p => !isEmpty(p))
      .map(p => `${p}${SPACE}`)
      .value();

    const matchedPrefix = _.find(possiblePrefixes, prefixWithSpace =>
      fieldLabel.startsWith(prefixWithSpace),
    );

    return matchedPrefix ? fieldLabel.slice(matchedPrefix.length) : fieldLabel;
  },
};
