import gql from 'graphql-tag';

export const userFragment = gql`
  fragment UserFragment on currentUser {
    id
    email
    emailNotifications
    givenName
    middleName
    surname
    fullName
    title
    phoneNumber
    profileImage
    slackNotifications
    smsNotifications
    i18nPrefs {
      dateFormat
      timeFormat
      numGroupSeparator
      decimal
    }
  }
`;

export const searchOptionsVizFragmentName = 'searchOptionsVizFragment';
export const searchOptionsVizFragment = gql`
  fragment ${searchOptionsVizFragmentName} on DatasetVisualization {
    tags
    chartType
    datasetId
  }
`;

export const searchOptionsDatasetFragmentName = 'searchOptionsDatasetFragment';
export const searchOptionsDatasetFragment = gql`
  fragment ${searchOptionsDatasetFragmentName} on Dataset {
    id
    name
  }
`;

export const updateVisualizationFragmentName = 'updateVisualizationFragment';
export const updateVisualizationFragment = gql`
  fragment ${updateVisualizationFragmentName} on DatasetVisualization {
    id
    name
    options {
      key
      value
    }
    commitMessage
    revisions {
      version
      commitMessage
      updatedByName
      updatedOn
    }
    isPrivate
    updatedOn
    tags
  }
`;

export const ADD_VISUALIZATION_QUERY_NAME = 'addVisualization';

export const allOptionsFragmentName = 'allOptionsFragment';
export const allOptionsFragment = gql`
  fragment ${allOptionsFragmentName} on DatasetVisualization {
    id
    name
    datasetId
    options {
      key
      value
    }
  }
`;

export const datasetFragmentName = 'datasetFragment';
export const datasetFragment = gql`
  fragment ${datasetFragmentName} on Dataset {
    id
    name
    creator
    description
    editMode
    isDeletable
    attributes {
      index
      name
      description
      attributeType
      defaultAggregation
      hidden
      aggregateMeasure
      formatType
      ordinalAttribute
      annotations {
        key
        value
      }
      calculation
    }
    annotations {
      key
      value
    }
  }
`;

export const visualizationFragmentName = 'visualizationFragment';
export const visualizationFragment = gql`
  fragment ${visualizationFragmentName} on DatasetVisualization {
    id
    name
    chartType
    isPrivate
    datasetId
    creatorName
    creator
    createdOn
    updatedByName
    updatedOn
    canDelete
    canUpdate
    commitMessage
    revisions {
      version
      commitMessage
      updatedByName
      updatedOn
    }
    layout {
      id
      fields
    }
    options {
      key
      value
    }
    tags

    dataset {
      ...${datasetFragmentName}
    }
  }
  ${datasetFragment}
`;
