import { ComponentClass as ComponentType, useEffect } from 'react';
import { Viz } from '../../discovery/VizUtil';
import { isDashletMode } from '../../auth';
import { find, forEach, isArray, isEmpty, last, map } from 'lodash';
import {
  SOURCE_NAME,
  SUGAR_ENUM_SUFFIX,
} from '../utilities/sugar-filter-converter/sugar-filter-converter';
import { ISugarApp } from '../../../../../mfe/src/mfe.interfaces';
import { useDispatch } from 'react-redux';
import { LineChartUtils } from '../../discovery/charts/LineChartUtils/LineChartUtils';
import Discover from '../redux/actions/DiscoverActions';
import {
  useOpenVizLayoutSelector,
  useOpenVizSelector,
  useVizCustomColorsSelector,
} from '../redux/selectors/viz-selector.hook';
import { useSugarCoreDropdownColorsQuery } from './sugar-core-dropdown-colors/sugar-core-dropdown-colors.gql';
import {
  DropdownValueStyles,
  ColorScheme,
  AppDropdownStyles,
  SugarCoreDropdownStyle,
} from './sugar-core-dropdown-colors/sugar-core-dropdown-colors.interface';

declare const App: ISugarApp;

// adds Sugar Dropdown Field colors to ColorManager
export function withSugarCoreDropdownColors(Component: ComponentType) {
  function ComponentWithSugarReportColors(props) {
    const dispatch = useDispatch();

    const viz = useOpenVizSelector({ discoveryId: props?.vizId });
    const customColors = useVizCustomColorsSelector({
      discoveryId: props?.vizId,
    });

    const layout = useOpenVizLayoutSelector({ discoveryId: props?.vizId });

    const fieldsInPlay = Viz.getAllFieldsInPlay(layout);

    const colorLookup: ColorScheme = {};
    const { sugarCoreDropdownColors } = useSugarCoreDropdownColorsQuery();

    useEffect(() => {
      const fieldEnumSourceNames = map(
        fieldsInPlay,
        ({ annotations = [] }) =>
          find(annotations, { key: SOURCE_NAME })?.value,
      )
        .filter(Boolean)
        .filter(sourceName => sourceName.endsWith(SUGAR_ENUM_SUFFIX));

      if (isEmpty(fieldEnumSourceNames)) {
        return;
      }

      forEach(fieldEnumSourceNames, sourceName => {
        const delimiter = '_';
        const [module, ...remaining] = sourceName.split(delimiter);
        const withoutModulePrefix = remaining.join(delimiter);
        const withoutEnumSuffix = withoutModulePrefix.replace(
          new RegExp(`${'_value'}$`),
          '',
        );

        let dropdownStyle: SugarCoreDropdownStyle = {};

        if (isDashletMode()) {
          const { options: dropdownName = '' } =
            App.metadata.getField({
              module,
              name: withoutEnumSuffix,
            }) ?? {};
          dropdownStyle = App.metadata.getDropdownStyle(dropdownName);
        } else {
          const dropdownStylesName = `${withoutEnumSuffix}_dom_style`;

          const appDropdownStyles: AppDropdownStyles = find(
            sugarCoreDropdownColors,
            {
              dropdownStylesName,
            },
          );

          if (
            appDropdownStyles &&
            isArray(appDropdownStyles?.dropdownValueStyles)
          ) {
            dropdownStyle = appDropdownStyles.dropdownValueStyles.reduce(
              (acc: SugarCoreDropdownStyle, item: DropdownValueStyles) => {
                if (item?.valueStyle) {
                  acc[item.valueName] = item.valueStyle;
                }
                return acc;
              },
              {},
            );
          }
        }

        for (const key in dropdownStyle) {
          let colorKey = key;
          if (viz?.chartType === 'line') {
            colorKey = LineChartUtils.getColorKey({
              lines: [key],
              valueName: last(layout.VALUES)?.name,
            });
          }

          colorLookup[colorKey] = dropdownStyle[key]?.backgroundColor;
        }
      });

      if (!isEmpty(colorLookup)) {
        dispatch(
          Discover.updateCustomColors(props?.vizId, {
            ...colorLookup,
            ...customColors,
          }),
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sugarCoreDropdownColors]);

    return <Component {...props} />;
  }

  return ComponentWithSugarReportColors;
}
