import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../common/emotion';
import { Tooltip } from '../../components/ui/tooltip';
import { noop } from 'lodash';
import { Menu } from '@mui/material';

const sidebarCollapsedWidth = '3.5rem';
const sidebarTransition = css`
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-property: left;
`;

export const SidebarNavOverlay = styled.div(() => {
  const {
    colors: { SidebarNavOverlay },
  } = useDiscoverTheme();

  return css`
    z-index: 1050;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${SidebarNavOverlay}80;

    &:not(.expanded) {
      display: none;
    }
  `;
});

export const SidebarNavWrapper = styled.div(() => {
  const {
    colors: {
      SidebarNavIcon,
      SidebarNavBackground,
      SidebarNavNotch,
      SidebarNavIconHover,
      BorderColor,
      SidebarNavNotchDisabled,
      SidebarNavIconDisabled,
      SidebarNavText,
      SidebarNavItemBackgroundHover,
      SidebarNavItemTextHover,
      SidebarNavSugar,
      SidebarNavSugarHover,
    },
  } = useDiscoverTheme();

  return css`
    .sidebar-nav {
      width: 15rem;
      height: 100%;
      position: fixed;
      display: flex;
      flex-direction: column;
      background-color: ${SidebarNavBackground};
      z-index: 1050;
      ${sidebarTransition};

      // When fully collapsed
      &:not(.expanded):not(.expanding):not(.collapsing) {
        left: -11.5rem;

        .sidebar-nav-item {
          left: 11.5rem;
          width: ${sidebarCollapsedWidth};
        }

        .sidebar-nav-item-kebab {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          left: ${sidebarCollapsedWidth};
        }

        .expanded {
          display: none;
        }
      }

      // When fully expanded
      &.expanded:not(.collapsing):not(.expanding) {
        left: 0;

        .sidebar-nav-item {
          left: 0;
        }

        .sidebar-nav-item-kebab {
          right: 0;
          position: absolute;

          .sicon {
            color: ${SidebarNavText};
          }

          &:hover,
          &.active {
            background-color: ${SidebarNavNotch};
            .sicon {
              color: ${SidebarNavIconHover} !important;
            }
          }
        }

        .sidebar-nav-sub-menu {
          left: 0;
          background-color: transparent;
        }

        .collapsed {
          display: none;
        }

        .sidebar-nav-item {
          &:hover:not(.disabled):not(.sidebar-nav-item-toggle) {
            background-color: ${SidebarNavItemBackgroundHover};
            color: ${SidebarNavItemTextHover};
            font-weight: 600;
          }
        }
      }

      // While expanding
      &.expanding {
        left: 0;

        .sidebar-nav-item {
          left: 0;
        }

        .collapsed {
          display: none;
        }
      }

      // While collapsing
      &.collapsing {
        left: -11.5rem;

        .sidebar-nav-item {
          left: 11.5rem;
        }

        .collapsed {
          display: none;
        }
      }
    }

    .animated-background {
      transform: matrix(1, 0, 0, 1, -28, 0);
      transition:
        transform 0.3s linear 0.1s,
        width 0.3s linear 0.1s,
        border-radius 0.3s linear 0.1s,
        height 0.3s linear 0.1s;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 10;
      width: 2rem;
      height: 2rem;
      background-color: ${SidebarNavNotch};
    }

    .sidebar-nav-item-group {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-grow: 0;
      border-top: 1px solid ${BorderColor};

      &:first-child {
        border: none;
      }

      &:nth-child(3) {
        flex-grow: 1;
      }
    }

    .sidebar-nav-item {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: ${sidebarCollapsedWidth};
      min-height: ${sidebarCollapsedWidth};
      ${sidebarTransition};

      .sicon:not(.sicon-kebab-lg):not(.sicon-close-lg) {
        width: ${sidebarCollapsedWidth};
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition-duration: 0.3s;
        z-index: 20;
      }

      &:hover,
      &.selected {
        .sicon:not(.sicon-close-lg) {
          color: ${SidebarNavIconHover};
        }
      }
    }

    .sidebar-nav-item-sugar {
      .sidebar-nav-item-btn {
        color: ${SidebarNavSugar};
      }
      &:hover .sidebar-nav-item-btn {
        color: ${SidebarNavSugarHover};
      }
    }

    .sidebar-nav-item-btn {
      background-color: initial;
      color: ${SidebarNavText};
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border: 0;
      padding: 0;
      text-decoration: none;
      background-color: initial;

      .sicon {
        color: ${SidebarNavIcon};
      }

      &.disabled {
        cursor: not-allowed;

        .animated-background,
        .sidebar-nav-item-tab {
          background-color: ${SidebarNavNotchDisabled};
        }

        color: ${SidebarNavIconDisabled};
        .sicon {
          color: ${SidebarNavIconDisabled} !important;
        }
      }

      &.active {
        .sicon {
          color: ${SidebarNavIconHover};
        }
        .animated-background {
          transform: matrix(1, 0, 0, 1, 12, 0);
        }
      }
    }

    .sidebar-nav-item-btn-toggle {
      width: ${sidebarCollapsedWidth};

      &.expanded {
        justify-content: center;
      }

      &:hover {
        .sicon {
          color: ${SidebarNavIcon} !important;
        }
      }
    }

    .sidebar-nav-item-tab {
      height: ${sidebarCollapsedWidth};
      width: 0;
      left: ${sidebarCollapsedWidth};
      position: fixed;
      background-color: ${SidebarNavNotch};
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .sidebar-nav {
      // Animations specific to collapsed mode
      &:not(.expanded):not(.expanding):not(.collapsing) {
        .sidebar-nav-item:not(.disabled) {
          &:hover,
          &.selected {
            .animated-background {
              height: 100%;
              width: 100%;
              transform: translateX(0);
              border-radius: 0;
            }

            .sidebar-nav-item-btn {
              &:not(.active) {
                .animated-background {
                  transition:
                    height 0.1s linear,
                    border-radius 0.1s linear,
                    width 0.2s linear 0.1s,
                    transform 0.2s linear 0.1s;
                }
              }

              &.active {
                .animated-background {
                  transition:
                    height 0.3s linear,
                    border-radius 0.3s linear,
                    width 0.3s linear,
                    transform 0.3s linear;
                }
              }
            }

            .sidebar-nav-item-kebab,
            .sidebar-nav-item-tab {
              width: 1.125rem;
              transition: width 0.1s linear 0.3s;
            }
          }

          &:not(:hover):not(:focus-within):not(.selected) {
            .sidebar-nav-item-kebab,
            .sidebar-nav-item-tab {
              transition: width 0.1s linear;
            }
            .sidebar-nav-item-btn {
              &:not(.active) {
                .animated-background {
                  transition:
                    transform 0.2s linear 0.1s,
                    width 0.2s linear 0.1s,
                    border-radius 0.1s linear 0.3s,
                    height 0.1s linear 0.3s;
                }
              }

              &.active {
                .animated-background {
                  transition:
                    transform 0.3s linear 0.1s,
                    width 0.3s linear 0.1s,
                    border-radius 0.3s linear 0.1s,
                    height 0.3s linear 0.1s;
                }
              }
            }
          }
        }
      }

      // Animations specific to expanded mode
      &.expanded {
        .sidebar-nav-item:not(.disabled) {
          &:hover,
          &:focus-within,
          &.selected {
            .sidebar-nav-item-btn {
              // When the nav item is hovered, focused, selected, slide the color block over the icon over 400ms. If the color
              // block is marked as expanded-full-fill, fill the whole nav item over 400ms
              .animated-background {
                transform: translateX(0.75rem);
                transition: transform 0.4s linear;

                &.expanded-full-fill {
                  height: 100%;
                  width: 100%;
                  transform: translateX(0);
                  border-radius: 0;
                  transition:
                    height 0.1s linear,
                    border-radius 0.1s linear,
                    width 0.3s linear 0.1s,
                    transform 0.3s linear 0.1s;
                }
              }
            }

            // Wait for 300ms of the color block animation, then expand the kebab over 100ms so the color block and kebab
            // finish at the same time
            .sidebar-nav-item-kebab,
            .sidebar-nav-item-tab {
              width: 1.125rem;
              transition: width 0.1s linear 0.3s;
            }
          }

          &:not(:hover):not(:focus-within):not(.selected) {
            .sidebar-nav-item-btn {
              // When the nav item comes off hover/focus/selected, slide the color block back to the notch state over 400ms
              .animated-background {
                transition: transform 0.4s linear;

                &.expanded-full-fill {
                  transition:
                    transform 0.3s linear,
                    width 0.3s linear,
                    border-radius 0.1s linear 0.3s,
                    height 0.1s linear 0.3s;
                }
              }

              // At the same time the color block animation starts, shrink the kebab width to 0 over 100ms
              .sidebar-nav-item-kebab,
              .sidebar-nav-item-tab {
                transition: width 0.1s linear;
              }
            }
          }
        }
      }
    }
  `;
});

export const TooltipInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;

  &:not(.tooltip-btn-toggle) {
    left: 1.125rem;
    > .sicon {
      left: -1.125rem;
    }
  }
`;

export const NavTooltip = ({
  title,
  children,
  className = '',
  onClick = noop,
}) => (
  <Tooltip placement='right' arrow title={title}>
    <TooltipInner onClick={onClick} data-testid={title} className={className}>
      {children}
    </TooltipInner>
  </Tooltip>
);

export const SidebarNavItemOption = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  height: 100%;
  span {
    margin-left: 5px;
  }
  cursor: pointer;
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 1.25rem;
  width: 7.75rem;
  height: 100%;
  position: relative;
  z-index: 10;
  transition-duration: 0.3s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  svg {
    user-select: none;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 500px;
  }
`;

export const StyledMenu = styled(Menu)`
  &.no-border .MuiPaper-root {
    border: none;
    border-radius: 8px;
    background-color: transparent;

    & > .MuiMenu-list {
      background-color: transparent;
    }
  }
`;
