import { css } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import _, { noop } from 'lodash';
import { useAutofocus } from '../../common/utilities/autofocus.hook';
import {
  SearchIcon as SearchIconBase,
  SearchIconLg as SearchIconLgBase,
  CloseIcon,
} from '../../icons';
import { useDiscoverTheme } from '../../common/emotion/theme/discover-emotion-theme-provider.component';
import { ModernLgSearchRadius } from '../../common/emotion';

const Border = ({ theme: { colors: { BorderColor } = {} } = {} }) => css`
  border: 1px solid ${BorderColor};
  border-radius: ${ModernLgSearchRadius};
`;

const SearchIconCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 1em;
  z-index: 10;
  right: 0;
`;

const SearchIconStyled = styled(SearchIconBase)`
  ${SearchIconCss}
`;

const SearchIconLgStyled = styled(SearchIconLgBase)`
  ${SearchIconCss}
`;

const ClearAllIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  margin-right: 1em;
  z-index: 10;
  cursor: pointer;
`;

const textInputStyles = ({ isMobile, fontSize, height, theme }) => css`
  ${Border({ theme })}
  font-size: ${fontSize ? fontSize : isMobile ? `16px` : `14px`};
  height: ${_.isNumber(height) ? `${height}px` : height || '40px'};
  width: 100%;
  font-weight: 400;
  padding: 0 10px 0 10px;
  background-color: ${theme?.darkMode
    ? theme?.colors?.MediumBlue
    : theme?.colors?.ContentBackground};
  color: ${theme?.colors?.ContentText};
  border-color: ${theme?.colors?.TextInputBorderColor};
  &:focus {
    border-color: ${!theme?.darkMode && theme?.colors?.ValLineStroke};
  }
`;

const Root = styled.div(
  ({ width, theme: { colors: { Gray80 } = {} } = {} }) => css`
    display: inline-block;
    position: relative;
    width: ${width || '60%'};

    @media (max-width: 1300px) {
      width: 50%;
    }
    @media (max-width: 1100px) {
      width: 480px;
    }

    input:focus {
      box-shadow: ${Gray80}4D 0px 0px 8px;
    }
  `,
);

const TextInputExtended = ({
  shouldFocus,
  inputRef: passthroughRef,
  isMobile,
  fontSize,
  height,
  ...props
}) => {
  const ref = useAutofocus({ shouldFocus, passthroughRef });
  const theme = useDiscoverTheme();
  return (
    <input
      ref={ref}
      {...props}
      css={css`
        ${textInputStyles({ isMobile, fontSize, height, theme })}
        padding-left: ${ModernLgSearchRadius};
        padding-right: 0px;
        appearance: none;
        &:focus {
          box-shadow: ${theme.darkMode &&
          `${theme.colors.Gray80}4D 0px 0px 8px`};
        }
        &::placeholder {
          color: ${theme?.colors?.PlaceholderText};
          font-weight: 300;
        }
      `}
    />
  );
};

const ClearAllArea = styled.span`
  display: flex;
  height: 100%;
  width: 24px;
  position: absolute;
  right: 0;
  justify-content: center;
  align-items: center;
`;

export class TextSearchField extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value || '', em: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }
  componentDidUpdate(prevProps) {
    // Added this to allow externally updating this search field so that we can
    // clear it out when closing a slicer. I put it behind a flag to avoid
    // breaking anything else in the app that uses this
    if (this.props.allowUpdate && this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }
  focus() {
    this.setState({ em: true });
    if (this.input && document.activeElement !== this.input) {
      this.input.focus();
    }
  }
  blur() {
    this.setState({ em: false });
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value);
  }

  handleClear() {
    this.setState({ value: '' });
    this.props.onChange('');
    this.props.onClear();
  }

  render() {
    const {
      onClear,
      em,
      onChange,
      value,
      width,
      style,
      inputRef,
      autoFocus,
      large,
      ...otherProps
    } = this.props;

    const SearchIcon = large ? SearchIconLgStyled : SearchIconStyled;

    return (
      <Root width={width} style={style}>
        {this.state.value !== '' ? (
          <ClearAllArea title='Clear search'>
            <ClearAllIcon
              hover
              size={12}
              key='clear'
              onClick={this.handleClear}
            />
          </ClearAllArea>
        ) : (
          <SearchIcon key='search' />
        )}
        <TextInputExtended
          autoFocus={autoFocus}
          inputRef={input => {
            this.input = input;
            if (inputRef) {
              inputRef(input);
            }
          }}
          key='input'
          value={this.state.value}
          onChange={v => this.handleChange(v)}
          {...otherProps}
          onFocus={() => this.focus()}
          onBlur={() => this.blur()}
        />
      </Root>
    );
  }
}

TextSearchField.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

TextSearchField.defaultProps = {
  onChange: noop,
  onClear: noop,
};

const GhostInput = styled.input`
  border: none;
  background: none;
  width: 100%;
`;
export const ClickToCopy = ({ text }) => {
  const copy = e => {
    e.target.select();
    document.execCommand('copy');
    e.stopPropagation();
  };
  return (
    <GhostInput type='text' title='Click to copy' onClick={copy} value={text} />
  );
};

export const MobileListHeader = styled.div(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    background-color: ${ContentBackground};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 8px;
  `,
);
