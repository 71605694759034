import { useMemo } from 'react';
import { useViewportDimensions } from '../../../../common/utilities/dimensions.hook';
import {
  SCROLL_CONTAINER_MAX_HEIGHT,
  SCROLL_ROW_HEIGHT,
  useSlicerTheme,
} from '.';
import { min } from 'lodash';

interface ISlicerDimensionsProps {
  optionsLength: number;
  slicerErrorVisible: boolean;
  isDashletTag?: boolean;
}

interface ISlicerDimensionsResult {
  height: number;
  width: number;
}

export const useSlicerDimensions = ({
  optionsLength,
  slicerErrorVisible,
  isDashletTag,
}: ISlicerDimensionsProps): ISlicerDimensionsResult => {
  const { isMobile, width: _width, listHeight } = useSlicerTheme();
  const { height: windowHeight } = useViewportDimensions();
  const calcHeight = optionsLength * SCROLL_ROW_HEIGHT;
  let height =
    isMobile && !isDashletTag
      ? windowHeight
      : min([SCROLL_CONTAINER_MAX_HEIGHT, calcHeight, listHeight]);
  if (isMobile && slicerErrorVisible) {
    height = height - 40;
  }

  const isScrolling = calcHeight > SCROLL_CONTAINER_MAX_HEIGHT;
  const width = useMemo(
    () => (isScrolling ? _width - 12 : _width),
    [_width, isScrolling],
  );

  return {
    height,
    width,
  };
};
