import { FC, MouseEvent as ReactMouseEvent, RefObject } from 'react';
import { noop } from 'lodash';
import { ISugarIconProps, KebabIcon } from '../../icons';
import { KabobIconContainerStyled } from './icon-dropdown.styles';
import {
  MuiMenuProps,
  useDropdownMenu,
} from '../../discovery/sidebar-navigation/sidebar-navigation.context';

interface IconDropdownProps {
  iconSize?: number;
  IconComponent?: FC<Omit<ISugarIconProps, 'icon'> & { isOpen?: boolean }>;
}

export const IconDropdown = ({
  id = 'kabob-dropdown',
  iconSize = 16,
  IconComponent = KebabIcon,
  children,
  onOpen = noop,
  onClose = noop,
  ...remainingProps
}: IconDropdownProps &
  MuiMenuProps & {
    onOpen?: () => void;
    onClose?: () => void;
  }) => {
  const { MenuComponent, isOpen, anchorRef, doToggle } = useDropdownMenu({
    onOpen,
    onClose,
  });

  return (
    <>
      <KabobIconContainerStyled
        ref={anchorRef as RefObject<HTMLDivElement>}
        className={`kabob-dropdown ${isOpen ? 'active' : 'inactive'}`}
        aria-describedby={id}
        onClick={(e: ReactMouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          doToggle(e?.currentTarget);
        }}
        role={'listbox'}
      >
        <IconComponent isOpen={isOpen} hover size={iconSize} />
      </KabobIconContainerStyled>
      <MenuComponent id={id} {...remainingProps}>
        {children}
      </MenuComponent>
    </>
  );
};
