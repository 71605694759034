import styled from '@emotion/styled';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal/modal-portal.component';
import { ModalBody as ModalBodyOrig } from '../../common/widgets/dialogs/modal-portal/modal-portal.styles';

export const StyledModalPortal = styled(ModalPortal)`
  .confirm-logout-dialog {
    width: 430px;
  }
`;

export const ModalBody = styled(ModalBodyOrig)`
  padding: 0.4rem 1rem 1rem;
  max-height: unset;
`;

export const UnsavedList = styled.ul`
  margin: 0.6rem 0 0;
  padding-left: 1rem;
`;
