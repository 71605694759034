import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import {
  IDiscoverEmotionTheme,
  useDiscoverTheme,
} from './discover-emotion-theme-provider.component';
import { DefaultFontName } from '../../../components/ui/fonts';
import { SugarPrimaryPalette } from './colors.styles';
import { Appearance } from '../../../account/interfaces';

export const useDiscoverMaterialTheme = () => {
  const discoverTheme = useDiscoverTheme();
  const {
    colors: {
      ContentBackground,
      ContentText,
      MediumBorder,
      LightFontWeight,
      MediumFontWeight,
      RegularFontWeight,
      StrongFontWeight,
      DropdownBackgroundHover,
      SugarGrayPalette,
    } = {},
  } = discoverTheme || ({} as IDiscoverEmotionTheme);
  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: discoverTheme.darkMode ? Appearance.Dark : Appearance.Light,
          primary: {
            light: SugarPrimaryPalette.LightBlue,
            main: SugarPrimaryPalette.MediumBlue,
            dark: SugarPrimaryPalette.DarkBlue,
          },
          secondary: {
            main: SugarGrayPalette?.White,
          },
        },
        typography: {
          allVariants: {
            color: ContentText,
          },
          fontFamily: DefaultFontName,
          fontSize: 14,
          fontWeightBold: StrongFontWeight,
          fontWeightLight: LightFontWeight,
          fontWeightMedium: MediumFontWeight,
          fontWeightRegular: RegularFontWeight,
        },
        components: {
          MuiMenu: {
            styleOverrides: {
              list: {
                backgroundColor: ContentBackground,
                borderColor: MediumBorder,
                borderWidth: 1,
                borderRadius: 2,
                padding: 0,
                boxShadow: 'none',
                fontSize: 14,
                fontWeight: 400,
              },
              paper: {
                backgroundColor: ContentBackground,
                borderColor: MediumBorder,
                borderWidth: 1,
                borderRadius: 2,
                borderStyle: 'solid',
                padding: 0,
                boxShadow: 'none',
                fontSize: 14,
                fontWeight: 400,
              },
            },
          },
          MuiMenuItem: {
            defaultProps: {
              disableRipple: true,
            },
            styleOverrides: {
              root: {
                fontSize: 12,
                borderBottom: `1px ${MediumBorder} solid`,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 15,
                height: 35,
                '&:hover': {
                  backgroundColor: DropdownBackgroundHover,
                },
                '&:last-of-type': {
                  borderBottom: 'none',
                },
              },
            },
          },
          MuiButton: {
            defaultProps: {
              disableRipple: true,
              disableElevation: true,
            },
            styleOverrides: {
              root: {
                '&.Mui-disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'visible',
                },
              },
            },
          },
          MuiTab: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiCheckbox: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiTabs: {
            defaultProps: {
              TabIndicatorProps: {
                style: {
                  transition: 'none',
                },
              },
            },
          },
        },
      }),
    [
      discoverTheme.darkMode,
      ContentText,
      StrongFontWeight,
      LightFontWeight,
      MediumFontWeight,
      RegularFontWeight,
      ContentBackground,
      MediumBorder,
      DropdownBackgroundHover,
      SugarGrayPalette?.White,
    ],
  );
  return useMemo(
    () => ({ ...discoverTheme, ...muiTheme }),
    [discoverTheme, muiTheme],
  );
};

export const MaterialThemeProvider = ({ children }) => {
  let materialTheme = { colors: {} } as IDiscoverEmotionTheme;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    materialTheme = useDiscoverMaterialTheme();
  } catch {
    materialTheme = { colors: {} } as IDiscoverEmotionTheme;
  }

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};
