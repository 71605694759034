import { forwardRef, useState, useCallback, useEffect, memo } from 'react';
import _, {
  map,
  noop,
  filter,
  toLower,
  isEmpty,
  isNil,
  slice,
  some,
  join,
  isFunction,
  isEqual,
  toString,
} from 'lodash';
import { messages } from '../../../i18n';
import {
  ClearIcon,
  SelectedCheck,
  useHeaderSx,
  useListSx,
  usePopperSx,
  useSearchableDropdownSx,
  useTitleStyles,
} from './searchable-dropdown.styles';
import {
  ISearchableDropdownProps,
  ISearchableDropdownOption,
} from './searchable-dropdown.interfaces';
import { SelectDropdown, SelectItem } from '../select-dropdown';
import InputBase from '@mui/material/InputBase';
import { SearchIcon } from '../../../icons';
import { createDefaultSelectedOptions } from './searchable-dropdown.utils';
import { usePreviousValue } from '../../../common/utilities/state-helpers.hook';

const Title = ({ errorMessage, placeholder, selectedOptions, handleClear }) => {
  const { titleContainerStyle, titleSpanStyle } = useTitleStyles();

  const hasClearableState = some(selectedOptions) || !isEmpty(errorMessage);

  if (!hasClearableState) {
    // strictly send text back and allow default styles
    return placeholder;
  }

  let titleText = errorMessage ?? placeholder;

  if (some(selectedOptions)) {
    titleText = join(map(selectedOptions, 'label'), ', ');
  }

  return (
    <div style={titleContainerStyle}>
      <span title={titleText} aria-label={titleText} style={titleSpanStyle}>
        {titleText}
      </span>
      {isFunction(handleClear) && (
        <ClearIcon className={'clear-action'} onClick={handleClear} size={12} />
      )}
    </div>
  );
};

const getValueLabels = arr =>
  map(arr, ({ value, label }) => ({
    value,
    label: `${label}`, // convert labels to strings
  }));

export const SearchableDropdown = memo<ISearchableDropdownProps>(
  forwardRef<HTMLDivElement, ISearchableDropdownProps>(
    (
      {
        children,
        placeholder = `${messages.search}...`,
        errorMessage: providedErrorMessage,
        options = [],
        defaultValue,
        onSelect = noop,
        multiSelect = false,
        headerSx: providedHeaderSx = {},
        popperSx: providedPopperSx = {},
        listSx: providedListSx = {},
        forceDisableSearch = false,
        ...remainingProps // @NOTE that these remaining props are defined in ISearchableDropdownProps
      },
      forwardingRef,
    ) => {
      const [searchText, setSearchText] = useState('');
      const [errorMessage, setErrorMessage] = useState(providedErrorMessage);

      const [selectableOptions, setSelectableOptions] = useState(
        createDefaultSelectedOptions({
          defaultValue,
          options: getValueLabels(options),
        }),
      );

      const disableSearch =
        forceDisableSearch || selectableOptions?.length <= 5;

      const prevDefaultValue = usePreviousValue({ value: defaultValue });

      const handleSelectedOptions = useCallback(
        (_options: ISearchableDropdownOption[]) => {
          setErrorMessage(null);
          onSelect(_options);
        },
        [onSelect],
      );

      // options may be populated late
      useEffect(() => {
        const providedOptions = getValueLabels(options);
        const currentOptions = getValueLabels(selectableOptions);

        if (
          !isEqual(providedOptions, currentOptions) ||
          (!prevDefaultValue && defaultValue)
        ) {
          setSelectableOptions(() =>
            createDefaultSelectedOptions({
              defaultValue,
              options: providedOptions,
            }),
          );
        }
      }, [
        prevDefaultValue,
        defaultValue,
        options,
        selectableOptions,
        handleSelectedOptions,
      ]);

      const handleClear = e => {
        e.preventDefault();
        e.stopPropagation();
        setSelectableOptions(() => []);
        handleSelectedOptions([]);
      };

      const handleOptionToggle = useCallback(
        (toggledOption: ISearchableDropdownOption) => {
          setSelectableOptions(prevOptions => {
            const newOptions = isNil(toggledOption?.value)
              ? []
              : map(prevOptions, prevOption => {
                  if (prevOption?.value === toggledOption?.value) {
                    return {
                      ...toggledOption,
                      isSelected: !toggledOption.isSelected,
                    };
                  } else if (!multiSelect && prevOption?.isSelected) {
                    return {
                      ...prevOption,
                      isSelected: false,
                    };
                  }

                  return prevOption;
                });

            const _selectedOptions = filter(newOptions, 'isSelected');

            if (multiSelect) {
              handleSelectedOptions(_selectedOptions);
            } else {
              handleSelectedOptions(slice(_selectedOptions, 0, 1));
            }

            return newOptions;
          });
        },
        [handleSelectedOptions, multiSelect],
      );

      const filteredOptions = filter(
        selectableOptions,
        option =>
          !isEmpty(option?.label) &&
          toLower(toString(option?.label))?.includes(toLower(searchText)),
      );

      const selectedOptions = filter(selectableOptions, 'isSelected');

      const headerSx = useHeaderSx({ providedHeaderSx, errorMessage });

      const popperSx = usePopperSx({ sx: providedPopperSx });

      const listSx = useListSx({
        disableSearch,
        providedListSx,
        disableScroll: filteredOptions?.length <= 5,
      });

      return (
        <SelectDropdown
          title={
            <Title
              errorMessage={errorMessage}
              placeholder={placeholder}
              selectedOptions={selectedOptions}
              handleClear={handleClear}
            />
          }
          headerSx={headerSx}
          popperSx={popperSx}
          listSx={listSx}
          ref={forwardingRef}
          disablePortal={false}
          {...remainingProps}
        >
          {!disableSearch && (
            <SearchInput
              onInput={e =>
                setSearchText(
                  () => (e?.target as HTMLInputElement)?.value ?? '',
                )
              }
              defaultValue={searchText}
              placeholder={placeholder}
            />
          )}
          {isEmpty(filteredOptions) ? (
            <SelectItem sx={{ fontStyle: 'italic' }}>
              {messages.searchDropdown.empty}
            </SelectItem>
          ) : (
            <Options
              options={filteredOptions}
              searchText={searchText}
              handleOptionToggle={handleOptionToggle}
            />
          )}
        </SelectDropdown>
      );
    },
  ),
);

const Options = ({
  options = [],
  searchText = '',
  handleOptionToggle = noop,
}) => {
  const { menuItemSx } = useSearchableDropdownSx();

  return map(options, (option, idx) => {
    const optionLabel = toString(option?.label);
    const spliceIndex = toLower(optionLabel).indexOf(toLower(searchText));

    // empty option may not have search term
    if (spliceIndex < 0) {
      return <span title={optionLabel}>{optionLabel}</span>;
    }

    const prefix = optionLabel.slice(0, spliceIndex);
    const underlined = optionLabel.slice(
      spliceIndex,
      spliceIndex + searchText?.length,
    );
    const suffix = optionLabel.slice(
      spliceIndex + searchText?.length,
      optionLabel?.length,
    );

    return (
      <SelectItem
        key={`searchable-option-${idx}`}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          handleOptionToggle(option);
        }}
      >
        {option.isSelected && <SelectedCheck />}
        <span style={menuItemSx} title={toString(option.label)}>
          {!isEmpty(prefix) && <span>{prefix}</span>}
          {!isEmpty(underlined) && (
            <span style={{ textDecoration: 'underline' }}>{underlined}</span>
          )}
          {!isEmpty(suffix) && <span>{suffix}</span>}
        </span>
      </SelectItem>
    );
  });
};

export const SearchInput = ({
  onInput = noop,
  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
  },
  defaultValue = '',
  placeholder = `${messages.search}...`,
  endAdornment = <SearchIcon size={12} />,
  menuItemSx: providedMenuItemSx = {},
  inputSx: providedInputSx = {},
}) => {
  const { searchMenuItemSx, searchInputSx } = useSearchableDropdownSx({
    searchMenuItemSx: providedMenuItemSx,
    searchInputSx: providedInputSx,
  });
  return (
    <SelectItem
      sx={searchMenuItemSx}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <InputBase
        sx={searchInputSx}
        onInput={onInput}
        onClick={onClick}
        autoFocus
        defaultValue={defaultValue}
        placeholder={placeholder}
        endAdornment={endAdornment}
        fullWidth
      />
    </SelectItem>
  );
};
