import { client } from '../../ApolloClient';
import { DatasetQueries, DiscoverQueries } from '../../graphql/';
import LoginActions from './LoginActions';
import Const from './ActionConst';
export const supportedFileTypes = ['.txt', '.csv', '.tab', '.tsv', '.zip'];

const Actions = {
  setDatasetAdded(datasetId) {
    return { type: Const.Dataset.SET_DATASET_ADDED, datasetId };
  },

  refreshSugarCRMDataset(id) {
    return () => {
      client
        .mutate({
          mutation: DatasetQueries.RefreshSugarCrmDatasetMutation,
          variables: { id },
        })
        .then(results => {
          console.log('refreshed sugarcrm datasource');
          return results.data.refreshSugarCrmDataset;
        })
        .catch(data => {
          console.log(`failed to refresh datasource: ${data}`);
        });
    };
  },

  loadDatasets() {
    return client.query({
      query: DatasetQueries.DatasetQuery,
      variables: {},
      fetchPolicy: 'network-only',
    });
  },

  setDatasetPage(page) {
    return dispatch => {
      if (page === 'datasetList') {
        Actions.loadDatasets().then(() =>
          dispatch({ type: Const.Dataset.SET_DATASET_PAGE, page }),
        );
      } else {
        dispatch({ type: Const.Dataset.SET_DATASET_PAGE, page });
      }
    };
  },

  getContentForDataset(datasetId) {
    return dispatch => {
      client
        .query({
          query: DiscoverQueries.VisualizationsByDataset,
          variables: { datasetId },
          fetchPolicy: 'network-only',
        })
        .then(data => {
          dispatch({
            type: Const.Dataset.GET_ASSOCIATED_CONTENT_FULFILLED,
            linkedContent: data?.data?.visualizations ?? [],
            linkedContentForDatasetId: datasetId,
          });
        })
        .catch(
          LoginActions.onFailure.bind(this, dispatch, (innerDispatch, data) => {
            console.log(
              `failed to get visualizations for dataset ${datasetId}: ${data}`,
            );
          }),
        );
    };
  },

  editDataset: (datasetId, editable) => {
    return {
      type: Const.Dataset.EDIT_DATASET,
      datasetId,
      editable,
    };
  },
};

export default Actions;
