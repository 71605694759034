import { useEffect, useMemo, useState } from 'react';
import { SugarIcon, useIconColor } from '../../sugar-icon';
import { ISugarIconProps } from '../../common';
import styled from '@emotion/styled';
import _ from 'lodash';
import { css, SerializedStyles } from '@emotion/react';

export interface IFeedbackContainerProps {
  disabled?: boolean;
  styles?: SerializedStyles;
}

export interface IFeedbackIcon
  extends ISugarIconProps,
    IFeedbackContainerProps {
  submitted?: boolean;
  isHovering?: boolean;
}

// used for native mouse event handling
const FeedbackContainer = styled.div<IFeedbackContainerProps>`
  display: inline-block;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => props.styles ?? css``}
`;

const getThumbsIcon =
  ({ solidIcon, outlineIcon, iconType: providedIconType }) =>
  (props: IFeedbackIcon) => {
    const { submitted: submittedProp, disabled = false, styles } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
      if (_.isBoolean(submittedProp) && submittedProp !== isSubmitted) {
        setIsSubmitted(submittedProp);
      }
    }, [submittedProp, isSubmitted, setIsSubmitted]);

    const iconType = useMemo(() => {
      return isSubmitted || isHovered ? providedIconType : 'medium';
    }, [isHovered, isSubmitted]);

    const hoverColorProp = useIconColor({ iconType: providedIconType });

    const iconProp = isSubmitted ? solidIcon : outlineIcon;
    const hoverProp = !isSubmitted;

    return (
      <FeedbackContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={disabled}
        styles={styles}
      >
        <SugarIcon
          icon={iconProp}
          hover={hoverProp}
          hoverColor={hoverColorProp}
          iconType={iconType}
          {...props}
        />
      </FeedbackContainer>
    );
  };

export const ThumbsUpIcon = getThumbsIcon({
  solidIcon: 'thumbs-up-lg',
  outlineIcon: 'thumbs-up-line-lg',
  iconType: 'success',
});

export const ThumbsDownIcon = getThumbsIcon({
  solidIcon: 'thumbs-down-lg',
  outlineIcon: 'thumbs-down-line-lg',
  iconType: 'danger',
});
