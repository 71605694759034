import { useStore } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import toUpper from 'lodash/toUpper';
import map from 'lodash/map';
import { ROUTER_DIRS } from '../../Constants';
import { isEmpty } from 'lodash';

export const SecureRoutes = ({
  allowedRoles = [],
  disallowedRoles = [],
  redirect = ROUTER_DIRS.ROOT,
}) => {
  // useSelector does not provide immediacy that this logic requires
  const store = useStore();
  const { login = {}, account = {} } = store.getState() ?? {};

  const { isLoggedIn = false } = login;
  const roles = get(account, 'currentUser.roles', []);

  const hasCreds = !isEmpty(disallowedRoles)
    ? intersection(map(roles, toUpper), map(disallowedRoles, toUpper))
        .length === 0
    : intersection(map(roles, toUpper), map(allowedRoles, toUpper)).length > 0;

  return isLoggedIn && hasCreds ? <Outlet /> : <Navigate to={redirect} />;
};
