import { isEqual, isFunction, uniq, uniqWith } from 'lodash';
import ColorManager from '../ColorManager';
import { useOpenVizSelector } from '../../redux/selectors/viz-selector.hook';
import { useDiscoverTheme } from '../../emotion/theme';
import ChartUtils from '../../../discovery/charts/ChartUtils';
import { LineChartUtils } from '../../../discovery/charts/LineChartUtils/LineChartUtils';

export const useLinearGradient = (vizId: string) => {
  const theme = useDiscoverTheme();
  const openViz = useOpenVizSelector({ discoveryId: vizId });
  const layout = openViz?.layout;

  const getStackGradientBaseColors = (data: any): string[] =>
    uniq<string>(
      data
        .map(series => {
          return series.VALUES.map(s => {
            const { stackName } = s;
            const getStackColor = ColorManager.getColor(vizId, stackName);
            const stackColor = ColorManager.getColor(vizId, stackName, theme);
            return isFunction(getStackColor)
              ? getStackColor(s, stackColor)
              : stackColor;
          });
        })
        .flat(),
    );

  const getBarsGradientBaseColors = data =>
    uniqWith(
      data.map(groupDatum => {
        const colorName = ChartUtils.getX(groupDatum, layout);
        const color = groupDatum.color
          ? groupDatum.color
          : ColorManager.getColor(vizId, colorName, theme);
        const yVal = ChartUtils.getY(groupDatum);
        const isNegative = yVal < 0;

        return { color, isNegative };
      }),
      isEqual,
    );

  const getLinesGradientBaseColors = (data: any): string[] =>
    uniq<string>(
      data.map(linePoints => {
        const colorKey = LineChartUtils.getColorKey(linePoints?.pathInfo);
        const color = ColorManager.getColor(vizId, colorKey, theme);
        return color;
      }),
    );

  return {
    getStackGradientBaseColors,
    getBarsGradientBaseColors,
    getLinesGradientBaseColors,
  };
};

export const LinearGradientHOC = HOCComponent => props => {
  const {
    getStackGradientBaseColors,
    getBarsGradientBaseColors,
    getLinesGradientBaseColors,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useLinearGradient(props.vizId);

  return (
    <HOCComponent
      {...props}
      getStackGradientBaseColors={getStackGradientBaseColors}
      getBarsGradientBaseColors={getBarsGradientBaseColors}
      getLinesGradientBaseColors={getLinesGradientBaseColors}
    />
  );
};
