import Const from '../actions/ActionConst';
import { SortDirection } from 'react-virtualized';

const mainDefault = {
  search: '',
  sorts: {
    sortBy: ['name'],
    sortDirection: {
      name: SortDirection.ASC,
    },
  },
};

const initState = {
  library: mainDefault,
  discover: mainDefault,
  datasets: mainDefault,
  targets: mainDefault,
  administration: {
    sorts: {
      sortBy: ['email'],
      sortDirection: {
        email: SortDirection.ASC,
      },
    },
  },
  default: mainDefault,
};

export default function (state = initState, action) {
  const { domain } = action;
  switch (action.type) {
    case Const.Main.SET_SORTING: {
      const { sortBy, sortDirection } = action;
      const newState = { ...state };
      const curr = newState[domain];
      newState[domain] = {
        ...curr,
        sorts: {
          sortBy,
          sortDirection,
        },
      };
      return newState;
    }
    case Const.Main.SET_SEARCH: {
      const { search } = action;
      const newState = { ...state };
      const curr = newState[domain];
      newState[domain] = { ...curr, search };
      return newState;
    }
    case Const.Main.SET_SEARCH_TAGS: {
      const { tags } = action;
      const newState = { ...state };
      const curr = newState[domain];
      newState[domain] = { ...curr, tags };
      return newState;
    }
    case Const.Main.SET_SEARCH_CHART_TYPES: {
      const { chartTypes } = action;
      const newState = { ...state };
      const curr = newState[domain];
      newState[domain] = { ...curr, chartTypes };
      return newState;
    }
    case Const.Main.SET_SEARCH_DATASETS: {
      const { searchDatasets } = action;
      const newState = { ...state };
      const curr = newState[domain];
      newState[domain] = { ...curr, searchDatasets };
      return newState;
    }
  }
  return state;
}
