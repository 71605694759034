import { useDispatch } from 'react-redux';
import { IDiscoverSubMenuProps } from '../discover-sub-menu/discover-sub-menu.interfaces';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { SubMenuComponent } from '../sub-menu';
import { messages } from '../../../i18n';
import { find, head, isEmpty, map, noop, sortBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { IDataset } from '../../../datasets';
import { DatasetQueries } from '../../../common/graphql';
import { IMenuItem } from '../sub-menu/sub-menu.interfaces';
import { useCallback, useContext } from 'react';
import { NavTooltip, SidebarNavItemOption } from '../sidebar-navigation.styles';
import { PlusLgIcon } from '../../../icons';
import classnames from 'classnames';
import { SideNavSubMenuContext } from '../sidebar-navigation.context';

export const useCachedDatasets = () => {
  const { data: { datasets = [] } = {} } = useQuery<{ datasets: IDataset[] }>(
    DatasetQueries.DatasetQuery,
    { fetchPolicy: 'cache-first' },
  );

  return sortBy(datasets ?? [], 'name');
};

export const useCreateReport = () => {
  const datasets = useCachedDatasets();
  const dispatch = useDispatch();

  const createNewViz = useCallback(
    (datasetId?: string) => {
      const dataset = find(datasets, { id: datasetId }) ?? head(datasets);

      if (isEmpty(dataset)) {
        return;
      }

      dispatch(
        Discover.newVisualization({
          dataset,
        }),
      );
    },
    [datasets, dispatch],
  );

  return { createNewViz, datasets };
};

export const QuickCreateSubMenu = ({
  onToggle = noop,
  hideSidebar,
}: IDiscoverSubMenuProps) => {
  const { createNewViz, datasets } = useCreateReport();
  const handleClick = useCallback(
    datasetId => {
      createNewViz(datasetId);
      hideSidebar();
    },
    [createNewViz, hideSidebar],
  );

  const disabled = isEmpty(datasets);

  const { doOpen, isOpen } = useContext(SideNavSubMenuContext);

  return (
    <div
      className={classnames('sidebar-nav-item', {
        selected: isOpen,
        disabled,
      })}
      onClick={() => {
        if (datasets.length === 1) {
          createNewViz();
        }

        doOpen();
      }}
    >
      <a className={'sidebar-nav-item-btn'}>
        <span className={'animated-background'} />
        <NavTooltip title={messages.nav.quickCreate} className={'collapsed'}>
          <PlusLgIcon hover />
          <span className='sidebar-nav-item-tab' />
        </NavTooltip>
        <SidebarNavItemOption className='expanded sidebar-nav-item-option'>
          <PlusLgIcon hover />
          <span>{messages.nav.quickCreate}</span>
        </SidebarNavItemOption>
      </a>

      {datasets.length > 1 && (
        <SubMenuComponent
          noBorder={true}
          onToggle={onToggle}
          labels={{
            title: messages.nav.quickCreate,
            more: messages.nav.moreDatasets,
            less: messages.nav.lessDatasets,
          }}
          menuItems={map<IDataset, IMenuItem>(datasets, ({ name, id }) => ({
            key: id,
            label: name,
            handleOnItemClick: () => handleClick(id),
          }))}
        />
      )}
    </div>
  );
};
