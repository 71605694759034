import { useRef, useEffect, ComponentClass } from 'react';
import { useResettableState } from '../../common/utilities/resettable-state.hook';
import { connect } from 'react-redux';
import _, { times } from 'lodash';
import {
  VizPanelContent,
  VizFilterPanel,
  VizLegendInPanel,
  PanelResizeHandle,
  RuntimeFilterPanel,
  VizPanelContainer,
} from './viz-panel.styles';
import { dispatchSetLegendPanelWidth } from './redux';
import { VIZ, VIZ_OPTION_IDS } from '../../common/Constants';
import { IBaseChartSpec, IDiscovery } from '../interfaces';
import {
  ActiveFilterPanel,
  LoaderWrapper,
} from '../../discovery/filter/active-filter-panel';
import { IfTrue } from '../../components/ui/conditional';
import { ChartSpecs } from '../ChartSpecs';
import { messages } from '../../i18n';
import { IProps } from './viz-panel.interfaces';
import { ErrorBoundaryComponentProps } from '../../components/error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { SkeletonListLoader } from '../../common/loaders/skeleton-list-loader';
import { drag, select } from 'd3';

export const UnconnectedVizPanel = (props: IProps) => {
  const {
    vizId,
    viz,
    isResizable = true,
    initialPanelWidth = VIZ.LEGEND_PANEL_WIDTH,
    useFiscalCalendar = false,
    minWidth = 80,
    maxWidth = 1000,
    loading,
    showLegend = true,
    showFilters = true,
    showRuntimeFilters = false,
    setLegendPanelWidth: setLegendPanelWidthExternal,
  } = props;

  const panelRef = useRef(null);
  const isResizingValRef = useRef(false);
  const resizeHandleRef = useRef(null);
  const startingWidth = useRef(initialPanelWidth);
  const currentWidth = useRef(initialPanelWidth);
  const startingX = useRef(0);

  const [legendPanelWidth, setLegendPanelWidth] =
    useResettableState(initialPanelWidth);

  const setResizing = (isResizingVal, event) => {
    isResizingValRef.current = isResizingVal;
    if (isResizingVal && event) {
      startingX.current = event.sourceEvent.clientX;
      startingWidth.current = panelRef.current.offsetWidth;
    }
  };

  // set listeners on mount
  useEffect(() => {
    const handleDrag = event => {
      if (isResizingValRef.current) {
        // find out where the mouse is now
        const resizeX = event.sourceEvent.clientX;

        const howMuchTheMouseMoved = startingX.current - resizeX;
        const newWidth = startingWidth.current + howMuchTheMouseMoved;

        // don't allow to resize beyond the minWidth and maxWidth
        if (newWidth >= minWidth && newWidth <= maxWidth) {
          currentWidth.current = newWidth;
          setLegendPanelWidth(newWidth);
        }
      }
    };

    const handleDragStart = event => {
      setResizing(true, event);
    };

    const handleDragStop = event => {
      if (isResizingValRef.current) {
        setResizing(false, event);
        setLegendPanelWidthExternal(currentWidth.current);
        // force a resize event when resizing the panel to give anything on the screen a chance to account for it
        setTimeout(() => window.dispatchEvent(new window.Event('resize')), 100);
      }
    };
    const resizeHandle = resizeHandleRef.current;
    if (isResizable) {
      select(resizeHandle).call(
        drag()
          .on('start', handleDragStart)
          .on('drag', handleDrag)
          .on('end', handleDragStop),
      );
    }
    return () => {
      //remove listeners on unmount
      select(resizeHandle).call(
        drag().on('start', null).on('drag', null).on('end', null),
      );
    };
  }, [
    isResizable,
    maxWidth,
    minWidth,
    setLegendPanelWidthExternal,
    setLegendPanelWidth,
  ]);

  const showContent = showLegend || showFilters || showRuntimeFilters;
  if (!showContent) {
    // do not show panel when all elements are hidden
    return null;
  }

  let numSections = 0;

  showLegend && numSections++;
  showFilters && numSections++;
  showRuntimeFilters && numSections++;

  return (
    <VizPanelContainer legendPanelWidth={legendPanelWidth}>
      <ErrorBoundary {...ErrorBoundaryComponentProps}>
        <VizPanelContent as={'div'} ref={panelRef} numSections={numSections}>
          <IfTrue value={loading}>
            {times(numSections, index => (
              <LoaderWrapper key={index}>
                <SkeletonListLoader />
              </LoaderWrapper>
            ))}
          </IfTrue>
          <IfTrue value={!loading}>
            {showLegend && (
              <VizLegendInPanel
                key={`vizLegend-${vizId}`}
                vizId={vizId}
                hasBelowPanel={numSections > 1}
              />
            )}
            {showFilters && (
              <VizFilterPanel
                aria-label={messages.formatting.filters}
                position={showLegend ? 2 : 1}
              >
                <ActiveFilterPanel
                  key={`vizActiveFilterPanel-${vizId}`}
                  discoveryId={vizId}
                  viz={viz}
                  useFiscalCalendar={useFiscalCalendar}
                />
              </VizFilterPanel>
            )}
            {showRuntimeFilters && (
              <RuntimeFilterPanel position={numSections} discoveryId={vizId} />
            )}
          </IfTrue>
          <PanelResizeHandle ref={resizeHandleRef} />
        </VizPanelContent>
      </ErrorBoundary>
    </VizPanelContainer>
  );
};

export const VizPanel = (connect as any)(
  (state, ownProps: IProps) => {
    const discovery = (state as any)?.discover?.openDiscoveries[ownProps.vizId]
      ?.present as IDiscovery;

    const { viz, vizLoading: loading } = discovery;

    const chartSpec = ChartSpecs[viz.chartType] as IBaseChartSpec;
    const legendDisabled = chartSpec.isToggleDisabled('showLegendPanel', viz);
    const showLegend =
      JSON.parse(
        _.get(viz, `options.${VIZ_OPTION_IDS.showLegendPanel}`, 'true'),
      ) && !legendDisabled;
    const filtersDisabled = chartSpec.isToggleDisabled('showFiltersPanel', viz);
    const showFilters =
      JSON.parse(
        _.get(viz, `options.${VIZ_OPTION_IDS.showFiltersPanel}`, 'true'),
      ) && !filtersDisabled;
    const showRuntimeFilters = JSON.parse(
      _.get(viz, `options.${VIZ_OPTION_IDS.showRuntimeFilters}`, 'false'),
    );

    return {
      isResizable: !_.isNil(ownProps.isMobile) ? ownProps.isMobile : true,
      loading,
      showLegend,
      showFilters,
      showRuntimeFilters,
    };
  },
  (dispatch, ownProps) => ({
    setLegendPanelWidth: dispatchSetLegendPanelWidth(dispatch, ownProps),
  }),
)(UnconnectedVizPanel) as ComponentClass<IProps>;
