import {
  memo,
  useState,
  Fragment,
  RefObject,
  MouseEvent as ReactMouseEvent,
} from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  SubMenu,
  IconWrapper,
  MenuListStyled,
  MenuItemStyled,
  SubMenuTitle,
  SidebarSubMenuBtn,
  ShowMoreBtn,
  ScrollItems,
} from './sub-menu.styles';
import { ISubMenuProps } from './sub-menu.interfaces';
import { CloseIcon, KebabIcon } from '../../../icons';
import { isFunction, noop } from 'lodash';
import { messages } from '../../../i18n';
import { useOutsideClick } from '../../../components/ClickOutsideListener';
import { useDropdownMenu } from '../sidebar-navigation.context';

export const NUMBER_OF_ITEMS_TO_SHOW = 5;

export const SubMenuComponent = memo(
  ({
    onToggle = noop,
    menuItems,
    labels = {
      more: messages.nav.moreReports,
      less: messages.nav.lessReports,
    },
    noBorder = false,
  }: ISubMenuProps) => {
    const { MenuComponent, doToggle, doClose, isOpen, anchorRef } =
      useDropdownMenu({
        onToggle,
        noBorder,
      });

    const [menuItemLimit, setMenuItemLimit] = useState(NUMBER_OF_ITEMS_TO_SHOW);
    const moreThenMinimum = menuItems.length > NUMBER_OF_ITEMS_TO_SHOW;

    const showMore = (e: ReactMouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setMenuItemLimit(prev => {
        if (prev === NUMBER_OF_ITEMS_TO_SHOW) {
          return menuItems.length;
        } else {
          return NUMBER_OF_ITEMS_TO_SHOW;
        }
      });
    };

    const menuListRef = useOutsideClick<HTMLUListElement>(doClose, isOpen);

    const renderContent = ({ icon, label }) => (
      <Fragment>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <span>{label}</span>
      </Fragment>
    );

    return (
      <SubMenu>
        <SidebarSubMenuBtn
          ref={anchorRef as RefObject<HTMLButtonElement>}
          className={classnames('sidebar-nav-item-kebab', {
            active: isOpen,
            inactive: !isOpen,
          })}
          onClick={(e: ReactMouseEvent<HTMLElement>) =>
            doToggle(e.currentTarget)
          }
        >
          <KebabIcon hover size={12} />
        </SidebarSubMenuBtn>
        <MenuComponent
          className={'sub-menu'}
          sx={{
            top: '-8px',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuListStyled
            open={isOpen}
            ref={menuListRef}
            className={`sidebar-nav-sub-menu`}
          >
            {labels?.title && (
              <SubMenuTitle>
                <span>{labels?.title}</span>
                <CloseIcon onClick={doClose} size={12} />
              </SubMenuTitle>
            )}
            <ScrollItems
              className={classnames({
                'not-rounded': moreThenMinimum,
              })}
            >
              {menuItems
                .slice(0, menuItemLimit)
                .map(({ key, label, icon, link, handleOnItemClick }) => (
                  <MenuItemStyled
                    key={key || label}
                    onClick={() => {
                      if (isFunction(handleOnItemClick)) {
                        handleOnItemClick();
                      }
                      doClose();
                    }}
                  >
                    {link ? (
                      <Link to={link}>{renderContent({ icon, label })}</Link>
                    ) : (
                      <div>{renderContent({ icon, label })}</div>
                    )}
                  </MenuItemStyled>
                ))}
            </ScrollItems>
            {moreThenMinimum && (
              <MenuItemStyled onClick={showMore}>
                <ShowMoreBtn>
                  <span>
                    {menuItemLimit === NUMBER_OF_ITEMS_TO_SHOW
                      ? `${labels?.more}`
                      : labels?.less}
                  </span>
                </ShowMoreBtn>
              </MenuItemStyled>
            )}
          </MenuListStyled>
        </MenuComponent>
      </SubMenu>
    );
  },
);
