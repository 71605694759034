import {
  head,
  isEqual,
  isString,
  last,
  reduce,
  split,
  toUpper,
  takeRight,
  nth,
  trim,
  toInteger,
} from 'lodash';
import {
  FilterOperators,
  IN_LIST,
  NOT_IN_LIST,
  relativeTimestampOperators,
  timestampOperators,
} from '../../../discovery/filter/FilterOperators';
import { FilterTypes } from '../../../discovery/filter/Filter';
import moment from '../../Moment';
import JSON5 from 'json5';
import { IDatasetJson } from '../../../views/edit-dataset-settings';

const StringFilters = FilterOperators.forFilterType(FilterTypes.STRING);
const DateFilters = FilterOperators.forFilterType(FilterTypes.DATE);

export const sugarFilterOperatorsMap = {
  is: StringFilters.eq.key,
  is_not: StringFilters.neq.key,
  empty: StringFilters.null.key,
  not_empty: StringFilters.notNull.key,
  equals: StringFilters.matchesPattern.key,
  not_equals_str: StringFilters.notMatchesPattern.key,
  contains: StringFilters.matchesPattern.key,
  does_not_contain: StringFilters.notMatchesPattern.key,
  starts_with: StringFilters.matchesPattern.key,
  ends_with: StringFilters.matchesPattern.key,
  one_of: IN_LIST.key,
  not_one_of: NOT_IN_LIST.key,
  on: DateFilters.on.key,
  before: timestampOperators.lt.key,
  after: timestampOperators.gt.key,
  tp_yesterday: relativeTimestampOperators.past.key,
  tp_today: relativeTimestampOperators.this.key,
  tp_tomorrow: relativeTimestampOperators.next.key,
  tp_this_month: relativeTimestampOperators.this.key,
  tp_this_quarter: relativeTimestampOperators.this.key,
  tp_this_year: relativeTimestampOperators.this.key,
  tp_last_n_days: relativeTimestampOperators.thisAndPast.key,
  tp_last_7_days: relativeTimestampOperators.thisAndPast.key,
  tp_last_30_days: relativeTimestampOperators.thisAndPast.key,
  tp_last_month: relativeTimestampOperators.past.key,
  tp_last_quarter: relativeTimestampOperators.past.key,
  tp_last_year: relativeTimestampOperators.past.key,
  tp_next_n_days: relativeTimestampOperators.thisAndNext.key,
  tp_next_7_days: relativeTimestampOperators.thisAndNext.key,
  tp_next_30_days: relativeTimestampOperators.thisAndNext.key,
  tp_next_month: relativeTimestampOperators.next.key,
  tp_next_quarter: relativeTimestampOperators.next.key,
  tp_next_year: relativeTimestampOperators.next.key,
  tp_previous_fiscal_quarter: relativeTimestampOperators.past.key,
  tp_previous_fiscal_year: relativeTimestampOperators.past.key,
  tp_next_fiscal_quarter: relativeTimestampOperators.next.key,
  tp_next_fiscal_year: relativeTimestampOperators.next.key,
  tp_current_fiscal_quarter: relativeTimestampOperators.this.key,
  tp_current_fiscal_year: relativeTimestampOperators.this.key,
};

export const timestampFilterOverrideMap = {
  ...sugarFilterOperatorsMap,
  empty: timestampOperators.null.key,
  not_empty: timestampOperators.notNull.key,
  equals: timestampOperators.eq.key,
  not_equals_str: timestampOperators.neq.key,
};

export const convertDayOperatorToOperands = (
  operator: string,
  operands?: string | string[],
): string[] => {
  const operandsToConvert = takeRight(split(operator, '_'), 3);

  const unit = toUpper(last(operandsToConvert));
  const numberOperand = nth(operandsToConvert, 1);
  const userInputNumber: string = isString(operands)
    ? operands
    : head(operands);
  const numDays = isEqual(numberOperand, 'n') ? userInputNumber : numberOperand;

  const numDaysNumber: number = toInteger(trim(numDays));
  const number: string =
    isNaN(numDaysNumber) || numDaysNumber <= 0 ? '0' : `${numDaysNumber - 1}`;

  return [number, unit, 'false'];
};

export const convertRelativeDatesOperatorToOperands = (
  operator: string,
): string[] => {
  const period = last(split(operator, '_'));
  const periodOperand = `CALENDAR_${toUpper(period)}S`;

  return ['1', periodOperand, 'false'];
};

export const convertDateOperands = (operands: string | string[]): string[] => {
  const dateString = isString(operands) ? operands : head(operands);
  return [moment.utc(dateString).format(moment.ISO8601)];
};

export const generateAliasModuleMap = (
  datasetJson: string,
): { [alias: string]: string } => {
  try {
    const parsed: IDatasetJson = isString(datasetJson)
      ? JSON5.parse(datasetJson)
      : datasetJson;
    const aliasMap = reduce(
      parsed.joinedTables,
      (acc, { alias, name }) => {
        acc[alias] = name;
        return acc;
      },
      {},
    );

    return aliasMap ?? {};
  } catch {
    return {};
  }
};
