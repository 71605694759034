/**
 *  This library contains the actions and state relating to the login experience.
 */
import _ from 'lodash';
import URLs from '../../Urls';
import Const from './ActionConst';
import { getAuthHeaders } from '../../../auth';
import { CommonActions } from './common.actions';
import { ROUTER_DIRS } from '../../Constants';

const Actions = {
  loginActions: [],
  login: CommonActions.login,
  isLoggedIn: CommonActions.isLoggedIn,
  async logout(dispatch) {
    const doLogout = _.once(result => {
      let idmLogoutUrl;
      try {
        ({ idmLogoutUrl } = _.isString(result) ? JSON.parse(result) : result);
      } catch {
        idmLogoutUrl = null;
      }

      if (idmLogoutUrl) {
        dispatch({
          type: Const.Login.LOGOUT,
          idmLogoutUrl,
          returnToUrl: window.location.href,
        });
        URLs.goTo(ROUTER_DIRS.LOGOUT);
      }
    });

    const headers = await getAuthHeaders({});
    return fetch('/api/auth/logout', {
      credentials: 'include',
      headers,
    }).then(async res => {
      let json;
      try {
        json = await res.json();
      } catch {
        json = {};
      }
      doLogout(json);
    });
  },
  onFailure(dispatch, callback, event) {
    if (event.status === 401 /* UNAUTHORIZED */) {
      URLs.goTo(ROUTER_DIRS.LOGIN);
    } else {
      callback.call(this, dispatch, event);
    }
  },
};

export default Actions;
