import _ from 'lodash';
import {
  useCallback,
  useContext,
  useMemo,
  useState,
  createContext,
} from 'react';
import { useVizOptionState } from '../../common/redux/actions/discover-actions.hook';
import { useDiscoverThemeColors } from '../../common/emotion';
import { IVizOptions } from '../interfaces';

export const DiscoveryOptionOverrideContext = createContext(null);

export const DiscoveryOptionOverrideContextProvider = ({
  children,
  defaultValue,
}: {
  children?;
  defaultValue?;
}) => {
  const [options, setOptions] = useState(defaultValue);
  const updateOptions = useCallback(newVals => {
    setOptions(opts => _.assign({}, opts, newVals));
  }, []);
  const value = useMemo(
    () => ({ options, updateOptions }),
    [options, updateOptions],
  );
  return (
    <DiscoveryOptionOverrideContext.Provider value={value}>
      {children}
    </DiscoveryOptionOverrideContext.Provider>
  );
};

export function useDiscoverOptionSelector<T>({
  vizId,
  option,
  skipParse = false,
  defaultValue,
}: {
  vizId?: string;
  option: keyof IVizOptions;
  skipParse?: boolean;
  defaultValue?: T;
}) {
  const fromContext = useContext(DiscoveryOptionOverrideContext);
  const [state, setState] = useVizOptionState({
    vizId,
    option,
    skipParse,
    defaultValue,
  });
  const updateValue = useCallback(
    newVal => {
      if (_.isFunction(fromContext?.updateOptions)) {
        fromContext?.updateOptions({ [option]: newVal });
      } else {
        setState(newVal);
      }
    },
    [fromContext, option, setState],
  );
  const _defaultValue = fromContext ? defaultValue : state;
  const optVal = _.get(fromContext, ['options', option], _defaultValue);
  const retVal = useMemo(
    () => ({ value: optVal, updateValue }),
    [optVal, updateValue],
  );
  return retVal as any;
}

export const withDiscoverThemeColors =
  Component =>
  ({ children, ...props }) => {
    const themeColors = useDiscoverThemeColors();
    return <Component themeColors={themeColors} {...props} />;
  };

export function withDiscoverOption<T>({
  vizId,
  option,
  skipParse = false,
  defaultValue,
}: {
  vizId?: string;
  option: keyof IVizOptions;
  skipParse?: boolean;
  defaultValue?: T;
}) {
  return Component =>
    ({ children, ...props }) => {
      const { value, setValue } = useDiscoverOptionSelector({
        vizId,
        defaultValue,
        skipParse,
        option,
      });
      props = {
        ...props,
        [option]: value,
        [`set${_.upperFirst(option)}`]: setValue,
      };
      return <Component {...props}>{children}</Component>;
    };
}

export const useDiscoverBooleanOptionSelector = ({
  vizId,
  option,
  defaultValue = false,
}: {
  vizId?;
  option;
  defaultValue?: boolean;
}) => {
  const { value: _value, updateValue } = useDiscoverOptionSelector({
    vizId,
    option,
    defaultValue,
  });
  const value = _value === 'true' || _value === true;
  const retVal = useMemo(() => ({ value, updateValue }), [updateValue, value]);
  return retVal;
};
