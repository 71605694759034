import { Component, RefObject, createRef } from 'react';
import Discover from '../../common/redux/actions/DiscoverActions';
import Dataset from '../../common/redux/actions/DatasetActions';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'react-recompose';
import FieldPill from '../FieldPill';
import { Shelf } from '../shelf';
import _, { forEach, groupBy, has, isEmpty, keys, noop, reduce } from 'lodash';
import CalcDialog from '../calc/CalcDialog';
import { Viz } from '../VizUtil';
import FieldConfiguration from '../../datasets/FieldConfiguration';
import URLs from '../../common/Urls';
import { ROUTER_DIRS, Types } from '../../common/Constants';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';
import { TextSearchField } from '../../components/ui/form';
import { NoMatches } from '../../components/ui';
import Util from '../../common/Util';
import { messages } from '../../i18n';
import { AddCircle } from '../../icons';
import {
  FieldPillGroupStyled,
  FieldPillStyled,
  HiddenFieldsHeader,
  VizFieldSearchContainer,
} from './viz-layout-panel.styles';
import {
  addFieldDispatch,
  DeleteCalcPrompt,
  enforceAggregationType,
} from './viz-layout-panel.utils';
import {
  IVizLayoutPanelProps,
  IVizLayoutPanelState,
} from './viz-layout-panel.interfaces';
import { IChartShelf } from '../interfaces';
import {
  FieldDropdown,
  FieldMenuActions,
} from '../../components/field-dropdown';
import {
  FieldMetadata,
  IAnyAttribute,
  ITimeCalcAttribute,
} from '../../datasets/interfaces/attribute.interface';
import { shortid } from '../../common/utilities/shortid-adapter';
import { useOpenVizDatasetSelector } from '../../common/redux/selectors/viz-selector.hook';
import { useDiscoverTheme } from '../../common/emotion';
import { isAdmin as isUserAdmin } from '../../common/redux/selectors/AccountSelectors';

class VizLayoutPanelUnconnected extends Component<
  IVizLayoutPanelProps,
  IVizLayoutPanelState
> {
  _scrollTimeout: any;
  dropdownCloseRef: RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      searchFieldsValue: '',
      searchFieldsActive: false,
      hideFields: {},
      visibleFields: [],
      invisibleFields: [],
      showDeleteCalcPrompt: false,
      calcFieldToDelete: null,
      expandTimeFields: [],
      isScrolling: false,
    };
    this._scrollTimeout = null;
    this.onExpandToggleHandler = this.onExpandToggleHandler.bind(this);
    this.dropdownCloseRef = createRef();
  }

  componentDidMount() {
    this.rebuildVisibleFields();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.expandTimeFields, this.props.expandTimeFields)) {
      this.setState({
        expandTimeFields: this.props.expandTimeFields,
      });
    }
    if (prevProps.fields !== this.props.fields) {
      this.rebuildVisibleFields();
    }
  }

  onContextMenuAction(
    key,
    field,
    shelf = null,
    extraOpts: {
      fromShelf?: IChartShelf;
    } = {},
  ) {
    if (this.props.chartSpec.shelves[key]) {
      // context key is a shelf Id
      const shelfId = key;
      const { fromShelf } = extraOpts;

      const from = Viz.findShelvesContainingField(
        this.props.viz.layout,
        field.name,
      );
      const fromWithoutSlicer = _.without(from, 'SLICER');
      const fieldOnlyOnSlicer = _.isEmpty(fromWithoutSlicer);
      const draggedFromSlicer = fromShelf?.id === 'SLICER';
      const draggedToSlicer = shelfId === 'SLICER';
      const movingInSameShelf =
        !_.isNil(fromShelf) && fromShelf?.id === shelf?.id;

      let moveToShelf = _.head(fromWithoutSlicer);

      if (
        (fieldOnlyOnSlicer ||
          (draggedFromSlicer && fieldOnlyOnSlicer) ||
          draggedToSlicer) &&
        !movingInSameShelf
      ) {
        // add it to the new shelf
        this.props.addField({
          discoveryId: this.props.discovery.id,
          chartType: this.props.viz.chartType,
          field,
          shelf: shelfId,
          layout: this.props.viz.layout,
        });
      } else {
        if (movingInSameShelf) {
          moveToShelf = shelf.id;
        }
        this.props.moveFieldToAnotherShelf(field, moveToShelf, shelfId);
      }
    } else if (key === FieldMenuActions.REMOVE) {
      this.removeField(field, shelf?.id);
    } else if (key === FieldMenuActions.MOVE_DOWN) {
      this.props.moveFieldDown(field, shelf, this.props.viz.layout[shelf.id]);
    } else if (key === FieldMenuActions.MOVE_UP) {
      this.props.moveFieldUp(field, shelf, this.props.viz.layout[shelf.id]);
    } else if (key === FieldMenuActions.FILTER) {
      if (!_.isNil(field.parentField)) {
        this.props.showFieldFilterDialog(field.parentField);
      } else {
        this.props.showFieldFilterDialog(field);
      }
    } else if (key === FieldMenuActions.FILTER_AGGREGATE) {
      this.props.showFieldFilterAggregateDialog(field);
    } else if (key === FieldMenuActions.EDIT_CALC) {
      this.props.setShowFieldCalcDialog(true, field);
    } else if (key === FieldMenuActions.DELETE_CALC) {
      this.setState({
        showDeleteCalcPrompt: true,
        calcFieldToDelete: field,
      });
    } else if (key === FieldMenuActions.SORT_ASCENDING) {
      this.props.sortField({
        direction: 'asc',
        field,
        shelf,
        shelfFields: this.props.viz.layout[shelf.id],
      });
    } else if (key === FieldMenuActions.SORT_DESCENDING) {
      this.props.sortField({
        direction: 'desc',
        field,
        shelf,
        shelfFields: this.props.viz.layout[shelf.id],
      });
    } else if (key === FieldMenuActions.REMOVE_SORT) {
      this.props.sortField({
        direction: 'remove_sort',
        field,
        shelf,
        shelfFields: this.props.viz.layout[shelf.id],
      });
    } else if (key === FieldMenuActions.DESIGNATE_RUNTIME) {
      this.props.designateRuntimeFilter(field);
    } else if (key === FieldMenuActions.INCREASE) {
      this.props.updateFieldInViz(field, {
        [FieldMetadata.IS_DECREASE]: false,
      });
    } else if (key === FieldMenuActions.DECREASE) {
      this.props.updateFieldInViz(field, {
        [FieldMetadata.IS_DECREASE]: true,
      });
    }
  }
  removeField(field, shelfId) {
    if (this.props?.viz?.layout[shelfId]) {
      this.props.removeField(field, shelfId, this.props.viz.layout);
    }
  }

  onFieldSearch(searchFieldsValue) {
    const hideFields = {};

    if (isEmpty(searchFieldsValue)) {
      this.setState({
        searchFieldsValue,
        hideFields,
      });
      return;
    }

    const inPlay = Viz.getAllFieldsInPlay(this.props.viz.layout);
    this.props.fields.forEach(f => {
      if (!_.isEmpty(f.children)) {
        const childrenToHide = f.children.filter(childField => {
          const isChildInPlay = _.some(inPlay, _.pick(childField, 'name'));
          return (
            !isChildInPlay &&
            !(childField as ITimeCalcAttribute).fieldListDisplayName
              .toLowerCase()
              .includes(searchFieldsValue.toLowerCase())
          );
        });
        if (
          !_.isEmpty(childrenToHide) &&
          !f.name.toLowerCase().includes(searchFieldsValue.toLowerCase())
        ) {
          forEach(
            [..._.map(childrenToHide, 'name'), f.name],
            name => (hideFields[name] = true),
          );
        }
      } else if (
        !f.name.toLowerCase().includes(searchFieldsValue.toLowerCase())
      ) {
        hideFields[f.name] = true;
      }
    });

    this.setState({
      searchFieldsValue,
      hideFields,
    });
  }

  onExpandToggleHandler(fieldName) {
    const { expandTimeFields } = this.state;
    this.setState({
      expandTimeFields: _.xor(expandTimeFields, [fieldName]),
    });
  }

  renderChildFields(field) {
    if (!_.isEmpty(field.children)) {
      // get the fields that are in play, we won't want to show those in the list
      const hierarchyFields = field.children;
      return hierarchyFields.map((timeField, idx) => {
        return (
          <FieldPill
            field={timeField}
            key={`${timeField.name}-${idx}`}
            renderFieldDropdown={isDragging => (
              <FieldDropdown
                discoveryId={this.props.discovery?.id}
                field={timeField}
                doMenuAction={key => this.onContextMenuAction(key, timeField)}
                isScrolling={this.state.isScrolling ?? false}
                isDragging={isDragging}
              />
            )}
          />
        );
      });
    }
  }

  rebuildVisibleFields() {
    if (!this.props.fields) {
      this.setState({ visibleFields: [] });
      return;
    }

    const fieldsByVisibility = groupBy(this.props.fields, (f: IAnyAttribute) =>
      f?.hidden ? 'hidden' : 'visible',
    );

    this.setState({
      invisibleFields: fieldsByVisibility.hidden,
      visibleFields: fieldsByVisibility.visible,
    });
  }

  renderFields(fields = []) {
    const { hideFields = {} } = this.state;

    return _(fields)
      .filter(f => !has(hideFields, f.name))
      .reduce((acc, f, idx) => {
        if (!_.isEmpty(f.children)) {
          const childFields = this.renderChildFields(f);
          // only render the group if there are children in it (example: not all children in play)
          if (!_.isEmpty(childFields)) {
            const fieldPillRoot = (
              <FieldPill
                field={f}
                iconDataType={'HIERARCHY'}
                key={`${f.name}-${idx}`}
                renderFieldDropdown={isDragging => (
                  <FieldDropdown
                    discoveryId={this.props.discovery?.id}
                    field={f}
                    doMenuAction={key => this.onContextMenuAction(key, f)}
                    isScrolling={this.state.isScrolling ?? false}
                    isDragging={isDragging}
                  />
                )}
              />
            );
            const forceExpanded = _.includes(
              this.state.expandTimeFields,
              f.name,
            );
            acc.push(
              <FieldPillGroupStyled
                root={fieldPillRoot}
                key={`field-pill-${f.name}-${idx}`}
                expanded={
                  forceExpanded || !_.isEmpty(this.state.searchFieldsValue)
                }
                onExpandToggle={() => this.onExpandToggleHandler(f.name)}
                isScrolling={this.state.isScrolling ?? false}
              >
                {childFields}
              </FieldPillGroupStyled>,
            );
          }
        } else {
          acc.push(
            <FieldPillStyled
              field={f}
              key={`${f.name}-${idx}`}
              renderFieldDropdown={isDragging => (
                <FieldDropdown
                  discoveryId={this.props.discovery?.id}
                  field={f}
                  doMenuAction={key => this.onContextMenuAction(key, f)}
                  isScrolling={this.state.isScrolling ?? false}
                  isDragging={isDragging}
                />
              )}
            />,
          );
        }
        return acc;
      }, []);
  }

  onDeleteCalcCancel() {
    this.setState({ showDeleteCalcPrompt: false });
  }

  onDeleteCalc() {
    this.props.deleteFieldCalc([this.state.calcFieldToDelete]);
    this.setState({ showDeleteCalcPrompt: false, calcFieldToDelete: null });
  }

  refresh() {
    if (_.isUndefined(this.props.viz.id)) {
      // this is a new, unsaved report. The viz.id won't be set but the discovery will have the generated id
      URLs.goTo(ROUTER_DIRS.OPEN_VIZ(this.props.discovery.id));
    } else {
      URLs.goTo(ROUTER_DIRS.OPEN_VIZ(this.props.viz.id));
    }
  }

  hideDropdowns() {
    this.dropdownCloseRef.current.click();
  }

  handleScroll() {
    const { isScrolling } = this.state;
    if (!_.isEmpty(this._scrollTimeout)) {
      clearTimeout(this._scrollTimeout);
    }

    if (isScrolling) {
      clearTimeout(this._scrollTimeout);
      this._scrollTimeout = setTimeout(() => {
        this._scrollTimeout = null;
        this.setState({
          isScrolling: false,
        });
      }, 300);
    }
    if (!isScrolling) {
      this.hideDropdowns();
      this.setState({
        isScrolling: true,
      });
    }
  }

  render() {
    const { dataset } = this.props.viz;
    const { visibleFields = [], invisibleFields = [] } = this.state;
    const inPlay = Viz.getAllFieldsInPlay(this.props.viz.layout);
    const availableFieldCount = reduce(
      visibleFields,
      (counter, field) => {
        if (_.isEmpty(field.children)) {
          counter++;
        } else {
          counter += field.children.length;
        }
        return counter;
      },
      0,
    );

    const visibleFieldsRendered = this.renderFields(visibleFields);
    const invisibleFieldsRendered = this.renderFields(invisibleFields);

    const shelves = Object.values(this.props.chartSpec.shelves).map(shelf => {
      return (
        <Shelf
          key={shelf.id}
          shelf={shelf}
          discoveryId={this.props.discovery.id}
          missingFields={this.props.viz.missingFields}
          onContextMenuAction={(key, f, fromShelf) =>
            this.onContextMenuAction(key, f, shelf, {
              fromShelf,
            })
          }
        />
      );
    });

    const showHiddenFields =
      this.props.isAdmin && !isEmpty(this.state.searchFieldsValue);

    return (
      <div className={'viz-layout'}>
        <div className={'layout-container'}>
          <div ref={this.dropdownCloseRef} className={'fields'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <label
                style={{
                  marginRight: '5px',
                }}
              >
                {messages.layoutPanel.fieldsLabel}
              </label>
              <AddCircle
                hover
                title={messages.layoutPanel.addCalcButtonTooltip}
                onClick={() => this.props.setShowFieldCalcDialog(true)}
              />
            </div>

            <VizFieldSearchContainer className='search-input'>
              <TextSearchField
                width='100%'
                value={this.state.searchFieldsValue}
                placeholder={messages.layoutPanel.searchPlaceholder}
                onChange={e => this.onFieldSearch(e)}
              />
              <hr />
            </VizFieldSearchContainer>

            <div
              className={'field-list'}
              role={'list'}
              onScroll={() => {
                this.handleScroll();
              }}
            >
              {keys(this.state.hideFields).length ===
                availableFieldCount - inPlay.length &&
                this.state.searchFieldsActive &&
                !isEmpty(this.state.searchFieldsValue) && (
                  <div className='no-search-results'>
                    {messages.layoutPanel.noMatches}
                  </div>
                )}
              {visibleFieldsRendered.length <= 0 && (
                <NoMatches>{messages.layoutPanel.noMatches}</NoMatches>
              )}
              {visibleFieldsRendered}
              {showHiddenFields && [
                <HiddenFieldsHeader key={'invisible-fields-header'}>
                  {messages.layoutPanel.hiddenFields}
                </HiddenFieldsHeader>,
                invisibleFieldsRendered?.length === 0 ? (
                  <NoMatches key={'no-matches'}>
                    {messages.layoutPanel.noMatches}
                  </NoMatches>
                ) : (
                  invisibleFieldsRendered
                ),
              ]}
            </div>
          </div>
          <div className={'shelves'}>{shelves}</div>
        </div>

        <div style={{ marginBottom: '5px' }} className={'dataset'}>
          <FieldSettingsLink
            discoveryId={this.props.viz?.id}
            onClick={() =>
              this.props.setShowDatasetEditDialog(
                this.props.viz.dataset.id,
                true,
              )
            }
          />
        </div>
        <CalcDialog
          saveCalc={this.props.saveVizCalc}
          allFields={Viz.getAllFieldsInPlay(this.props.viz.layout)}
          viz={this.props.viz}
          fields={this.props.fields}
          datasetId={dataset.id}
          currencySymbol={Viz.getCurrencySymbol(this.props.viz)}
        />
        <DeleteCalcPrompt
          show={this.state.showDeleteCalcPrompt}
          onCancel={() => this.onDeleteCalcCancel()}
          onConfirm={() => {
            this.onDeleteCalc();
          }}
          calcFieldToDelete={this.state.calcFieldToDelete}
          calcFields={this.props.calcFields}
        />
        {Util.isDatasetEditable(dataset) && (
          <FieldConfiguration
            discovery={this.props.discovery}
            onClose={() => {
              this.refresh();
            }}
          />
        )}
      </div>
    );
  }
}

const FieldSettingsLink = ({ discoveryId, onClick = noop }) => {
  const dataset = useOpenVizDatasetSelector({ discoveryId });

  const { colors: { LinkButtonTextColor } = {} } = useDiscoverTheme();

  if (!Util.isDatasetEditable(dataset)) {
    return null;
  }

  return (
    <span
      style={{
        color: LinkButtonTextColor,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {messages.formatString(messages.layoutPanel.configureFields, {
        name: dataset?.name,
      })}
    </span>
  );
};

const mapStateToProps = (state, ownProps) => {
  const discovery = VIZ_SELECTORS.getActive(state, ownProps);
  const isAdmin = isUserAdmin(state?.account ?? {}, ownProps);

  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, ownProps) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, ownProps) === 'true';
  const expandTimeFields = _.get(discovery, `expandTimeFields`, []);
  return {
    viz: VIZ_SELECTORS.getActiveViz(state, ownProps),
    chartSpec: VIZ_SELECTORS.getActiveVizChartSpec(state, ownProps),
    calcFields: VIZ_SELECTORS.getActiveVizCalcFields(state, ownProps),
    timeHierarchies: VIZ_SELECTORS.getActiveVizTimeHierarchies(state, ownProps),
    datasetAttributes: VIZ_SELECTORS.getActiveDatasetAttributes(
      state,
      ownProps,
    ),
    useFiscalCalendar,
    expandTimeFields,
    isAdmin,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addField: addFieldDispatch(dispatch),
    removeField: (field, shelf, layout) => {
      const priorPeriodRefs = Viz.fieldIsReferencedByPriorPeriodCalc(
        layout,
        field,
      );
      if (
        field.attributeType === Types.PRIOR_PERIOD_CALC ||
        !_.isEmpty(priorPeriodRefs)
      ) {
        // need to prompt the user first
        dispatch(
          Discover.showConfirmRemoveField(ownProps.discovery.id, field, shelf),
        );
      } else {
        dispatch(
          Discover.removeFieldFromVisualization(
            ownProps.discovery.id,
            field,
            shelf,
          ),
        );
      }
    },
    moveFieldToAnotherShelf: (field, fromShelf, toShelf) => {
      const movingField = enforceAggregationType({
        dispatch,
        discoveryId: ownProps.discovery.id,
        chartType: ownProps.discovery?.viz?.chartType,
        field,
        shelfId: toShelf,
      });

      dispatch(
        Discover.moveFieldToAnotherShelf(
          ownProps.discovery.id,
          movingField,
          fromShelf,
          toShelf,
        ),
      );
    },
    moveFieldUp: (field, shelf, shelfFields) => {
      const index = shelfFields.indexOf(field);
      const newShelfFields = _.slice(shelfFields, 0, index - 1)
        .concat([field, shelfFields[index - 1]])
        .concat(_.slice(shelfFields, index + 1));
      dispatch(
        Discover.reorderVizShelf(
          ownProps.discovery.id,
          shelf.id,
          newShelfFields,
        ),
      );
    },
    moveFieldDown: (field, shelf, shelfFields) => {
      const index = shelfFields.indexOf(field);
      const newShelfFields = _.slice(shelfFields, 0, index)
        .concat([shelfFields[index + 1], field])
        .concat(_.slice(shelfFields, Math.min(shelfFields.length, index + 2)));
      dispatch(
        Discover.reorderVizShelf(
          ownProps.discovery.id,
          shelf.id,
          newShelfFields,
        ),
      );
    },
    sortField: ({ field, shelf, shelfFields, direction }) => {
      dispatch(
        Discover.sortVizData({
          field,
          shelf,
          shelfFields,
          direction,
          discoveryId: ownProps.discovery.id,
        }),
      );
    },
    designateRuntimeFilter: field => {
      dispatch(
        Discover.designateRuntimeFilter({
          discoveryId: ownProps.discovery.id,
          field,
        }),
      );
    },
    updateFieldInViz: (field, fieldMeta = {}) => {
      const txId = shortid.generate();

      dispatch(
        Discover.updateFieldInViz(
          ownProps.discovery.id,
          field,
          fieldMeta,
          field.name,
          txId,
        ),
      );
    },
    showFieldFilterDialog: field => {
      dispatch(Discover.showFieldFilterDialog(field));
    },
    showFieldFilterAggregateDialog: field => {
      dispatch(Discover.showFieldFilterAggregateDialog(field));
    },
    setShowFieldCalcDialog: (show, field) => {
      dispatch(Discover.setShowFieldCalcDialog(show, field));
    },
    deleteFieldCalc: fields => {
      dispatch(Discover.deleteCalcFields(ownProps.discovery.id, fields));
    },
    setShowDatasetEditDialog: (datasetId, editable) => {
      dispatch(Dataset.editDataset(datasetId, editable));
    },
    showTimeHierarchy: (timeHierarchyFields, field) => {
      dispatch(
        Discover.showTimeHierarchy(
          ownProps.discovery.id,
          field,
          timeHierarchyFields,
        ),
      );
    },
    saveVizCalc: (
      name,
      description,
      formula,
      previousField,
      dataType = Types.NUMBER,
      aggregation = 'Sum',
      aggregateMeasure = false,
      formatType = '',
      customFormatProps,
    ) => {
      const attributeType =
        dataType === Types.STRING
          ? Types.STRING_CALC
          : dataType === Types.TIMESTAMP
            ? Types.TIMESTAMP
            : dataType === Types.BOOLEAN
              ? Types.BOOLEAN
              : Types.CALC;

      // field is of type IAnyAttribute
      const field = {
        name,
        description,
        formula,
        attributeType,
        calcType: dataType,
        defaultAggregation: aggregateMeasure ? 'NONE' : aggregation,
        aggregateMeasure,
        formatType,
        customFormatProps,
      };
      const previousName = _.isNil(previousField) ? null : previousField.name;
      const txId = shortid.generate();
      dispatch(
        Discover.saveCalcField(
          ownProps.discovery.id,
          field,
          previousName,
          txId,
        ),
      );
      dispatch(
        Discover.updateFieldInViz(
          ownProps.discovery.id,
          field,
          undefined,
          previousName,
          txId,
        ),
      );
      dispatch(Discover.setShowFieldCalcDialog(false));
    },
  };
};

export const VizLayoutPanel = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['calcFields'], ({ calcFields }) => ({
    calcFields: _.reject(calcFields, 'parentField'),
  })),
  withPropsOnChange(
    ['calcFields', 'timeHierarchies', 'datasetAttributes'],
    Viz.hydrateTimeHierarchyFields,
  ),
)(VizLayoutPanelUnconnected);
