import React, { createContext, useContext, useState } from 'react';
import { noop } from 'lodash';

const FeatureFlagContext = createContext<{
  featureFlags: { [key: string]: boolean };
  updateFlag: (key: string, val: boolean) => void;
}>({
  featureFlags: {},
  updateFlag: noop,
});

const getInitialFeatureFlags = () => {
  return {};
};

export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(getInitialFeatureFlags());

  const updateFlag = (flag, value) => {
    setFeatureFlags(prev => ({
      ...prev,
      [flag]: value,
    }));
  };
  return (
    <FeatureFlagContext.Provider value={{ featureFlags, updateFlag }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagProvider = () => useContext(FeatureFlagContext);
