import { Component } from 'react';
import { connect } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import CorvanaUrlWrapper from './common/hoc/CorvanaUrlWrapper';
import { compose } from 'react-recompose';
import Main from './common/redux/actions/MainActions';
import { isStandalone } from './common/Util';
import Urls from './common/Urls';
import { MainComponent } from './MainApp';
import { MaterialThemeProvider } from './common/emotion/theme/material-theme-provider.component';
import { ToastProvider } from './components/toast/toast-launcher';

function configureRouter() {
  return isStandalone() ? HashRouter : BrowserRouter;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieTimerStarted: false,
    };
  }

  onResize = (event = {}) => {
    const newWidth = event.currentTarget
      ? event.currentTarget.innerWidth
      : window.innerWidth;
    this.props.setIsMobile(newWidth);
  };

  onSystemDarkModeChange = e => {
    return this.props.setSystemDarkMode(e.matches);
  };

  componentDidMount() {
    this.match = window.matchMedia('(prefers-color-scheme: dark)');
    window.addEventListener('resize', this.onResize);
    this.match.addEventListener('change', this.onSystemDarkModeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.match.removeEventListener('change', this.onSystemDarkModeChange);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isMobile) {
      this.onResize();
    }
    if (isStandalone() && !prevProps.pathname && this.props.pathname) {
      Urls.goTo(this.props.pathname);
    }
  }

  render() {
    const Router = configureRouter();
    return (
      <DndProvider backend={HTML5Backend}>
        <MaterialThemeProvider>
          <ToastProvider>
            <Router>
              <CorvanaUrlWrapper>
                <div id='mainDiv'>
                  <MainComponent />
                </div>
              </CorvanaUrlWrapper>
            </Router>
          </ToastProvider>
        </MaterialThemeProvider>
      </DndProvider>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.main.isMobile,
  pathname: state.storage.pathname,
});

const mapDispatchToProps = dispatch => ({
  setIsMobile: viewportWidth => {
    dispatch(Main.setIsMobile(viewportWidth));
  },
  setSystemDarkMode: isSystemDarkModeEnabled => {
    dispatch(Main.setSystemDarkMode(isSystemDarkModeEnabled));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
