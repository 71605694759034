import Const from '../actions/ActionConst';

const initState = {
  pathname: null,
  acceptCookies: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case Const.Main.SET_URL_LOCATION: {
      const { pathname } = action;
      if (pathname === state.pathname) {
        return state;
      }
      return {
        ...state,
        pathname,
      };
    }
    case Const.Main.SET_ACCEPT_COOKIES: {
      // Saving the acceptance of Cookies
      const { acceptsCookieUse } = action;
      if (acceptsCookieUse === state.acceptsCookieUse) {
        return state;
      }
      return {
        ...state,
        acceptsCookieUse,
      };
    }
  }
  return state;
}
