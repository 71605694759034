import styled from '@emotion/styled';
import { FormInput } from '../../../components/ui/form-input';
import { SearchableDropdown } from '../../../ui/dropdowns/searchable-dropdown';

export const StyledSearchableDropdown = styled(SearchableDropdown)`
  min-width: 10rem;
`;

export const StyledFormControl = props => (
  <FormInput
    {...props}
    sx={{
      height: '30px',
      maxHeight: '28px',
    }}
  />
);

export const FormGroup = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 0;

  input[type='checkbox'],
  label,
  .form-control {
    margin: 0 0 0 0;
  }

  & > * {
    vertical-align: middle;
    flex-shrink: 1;
    max-width: 15rem;
    max-height: 28px;
    text-wrap: nowrap;
  }
`;
