import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { grey } from '@mui/material/colors';
import { CSSProperties } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDiscoverTheme } from '../../../common/emotion';
import {
  MAX_SLICER_WIDTH,
  MIN_SLICER_WIDTH,
  ISlicerWidgetThemeProp,
  useSlicerTheme,
  MOBILE_WIDTH_OFFSET,
  SELECT_ALL_ITEM_HEIGHT,
  SLICER_DROPDOWN_HEIGHT,
} from './common';
import { SelectItem } from '../../../ui/dropdowns/select-dropdown';
import { forwardRef, HTMLProps } from 'react';

export const noBottomBorder = () => ({
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
});

export const SlicerCheckbox: typeof Checkbox = withStyles(
  ({ colors: { PrimaryColor } = {} as any }: any = {}) => ({
    root: {
      color: grey[400],
      '&$checked': {
        color: PrimaryColor,
      },
      '& input[type=checkbox]': {
        marginTop: -4,
        marginLeft: -4,
        width: 16,
        height: 16,
      },
      padding: 0,
      marginRight: 5,
      marginLeft: 10,
      width: 8,
      height: 8,
    },
    checked: {},
  }),
)(props => <Checkbox size='small' color='primary' {...props} />) as any;

export const SlicerCheckboxLabel: typeof FormControlLabel = withStyles(
  ({ colors: { baseFontColor, LightFontWeight } = {} as any }: any) => ({
    root: {
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 0,
      marginLeft: 0,
      borderBottomWidth: 0,
    },
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: baseFontColor,
      fontWeight: LightFontWeight,
      marginLeft: 0,
      marginRight: 0,
    },
  }),
)((props: any) => <FormControlLabel {...props} />) as any;

export const useStyles = ({ isOpen = false }) => {
  const {
    darkMode,
    isDashletMode,
    isMobile,
    colors: { ContentBackground, Gray50, Gray70, Gray80 },
  } = useDiscoverTheme();

  const isResponsiveView = isDashletMode || isMobile;

  const headerSx: CSSProperties = {
    height: `${SLICER_DROPDOWN_HEIGHT}px`,
    color: darkMode ? Gray70 : Gray50,
    backgroundColor: ContentBackground,
    marginRight: isResponsiveView ? 0 : '10px',
  };

  const listSx: CSSProperties = {
    backgroundColor: ContentBackground,
    maxHeight: isResponsiveView ? 'auto' : '20rem',
  };

  const dropdownWrapperStyle: CSSProperties = {
    boxShadow: isOpen && !isResponsiveView ? `${Gray80}4D 0px 2px 8px` : 'none',
  };

  const searchMenuItemSx: CSSProperties = {
    padding: '8px 10px',
    height: 'auto',
    '&:hover,&:focus': { backgroundColor: 'inherit' },
  };

  if (isResponsiveView) {
    headerSx.width = '100%';
    headerSx.minWidth = '100%';

    searchMenuItemSx.width = '100%';
    searchMenuItemSx.minWidth = '100%';
  }

  return {
    headerSx,
    listSx,
    dropdownWrapperStyle,
    searchMenuItemSx,
  };
};

export const SlicerWidgetContainer = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & { width?: number }
>(({ children, className }, ref) => {
  const { isDashletMode, isMobile } = useSlicerTheme();

  const mobileWidth = isMobile || isDashletMode ? '100%' : `auto`;

  const getStyles = () => css`
    width: ${mobileWidth};
  `;

  return (
    <div ref={ref} css={getStyles()}>
      <div className={`combo-dropdown ${className || ''}`}>{children}</div>
    </div>
  );
});

export const SlicerTitle = styled.span(({ theme }: any) =>
  css({
    marginLeft: theme?.isMobile || theme?.isDashletMode ? 15 : 10,
    fontWeight: theme?.colors?.MediumFontWeight,
    fontSize: 14,
    marginRight: '1rem',
    minWidth: '34%',
    maxWidth: theme?.isMobile || theme?.isDashletMode ? 'inherit' : '66%',
  }),
);

export const SlicerFooterMenuItem = styled(SelectItem)(
  () => css`
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;

    :hover,
    :focus {
      background-color: inherit;
    }

    a {
      cursor: default;

      :hover,
      :focus {
        text-decoration: none !important;
      }
    }
  `,
);

export const MobileBackButton = styled.button(() => {
  const {
    colors: { Gray70 },
  } = useDiscoverTheme();

  return css`
    background: none;
    border: none;
    padding: 5px 5px 5px 10px;
    display: flex;

    .sicon {
      color: ${Gray70};
    }
  `;
});

export const SlicerDropdownToggle = styled.div(
  ({
    theme: {
      isOpen,
      viewWidth,
      isMobile,
      isDashletMode,
      slicerDropdownToggleHeight,
      slicerToggleFontFamily,
      colors: { MediumFontWeight, Strokes } = {} as any,
    } = {} as any,
  }: ISlicerWidgetThemeProp) => {
    const isResponsiveView = isMobile || isDashletMode;
    const borderRadius = isResponsiveView && !isOpen ? 5 : 3;
    const baseStyles: CSSProperties = {
      pointerEvents: 'all',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      borderColor: isResponsiveView
        ? 'transparent !important'
        : `${Strokes} !important`,
      boxShadow: 'none !important',
      height: slicerDropdownToggleHeight,
      fontFamily: slicerToggleFontFamily,
      borderRadius,
      display: 'flex',
      placeContent: 'stretch flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      '.title': {
        flex: '1 1 0%',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: MediumFontWeight,
        fontFamily: slicerToggleFontFamily,
        fontSize: 12,
      },
    };

    const sizeStyles = isDashletMode
      ? {
          width: `100%`,
        }
      : isMobile
        ? {
            width: `calc(${viewWidth} - ${MOBILE_WIDTH_OFFSET}px)`,
            marginLeft: 10,
            marginBottom: 5,
          }
        : {
            maxWidth: `min(100%, ${MAX_SLICER_WIDTH}px)`,
            minWidth: MIN_SLICER_WIDTH,
            flex: 'none',
          };
    return css({
      ...baseStyles,
      ...sizeStyles,
    } as any) as any;
  },
);

export const SelectAllItem = styled(SelectItem)(() => {
  const {
    colors: { DimText, DropdownOptionHoverBackground },
  } = useDiscoverTheme();

  return css`
    padding: 0 10px;
    height: ${SELECT_ALL_ITEM_HEIGHT}px;

    &:hover {
      background-color: transparent;
    }

    &:hover label {
      background-color: ${DropdownOptionHoverBackground};
    }

    label {
      width: 100%;
      margin-bottom: 0;
      padding: 3px 0px 7px 0px;
      border: 1px solid ${DimText};
      border-bottom-width: 0;
    }
  `;
});
