import { memo, FunctionComponent, ComponentType } from 'react';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import { ChartSpecs } from '../ChartSpecs';
import Discover from '../../common/redux/actions/DiscoverActions';
import { get, isNil } from 'lodash';

import { getVizQuerySorts } from '../../common/redux/selectors/viz-selectors';
import { sortLegendData } from '../LegendUtils';
import { ColorSettings } from './color-settings';
import { ConditionalFormattingSettings } from './conditional-formatting-settings';
import { ComboChartSettings } from './combo-chart-settings';
import { AddMonitorButton } from './add-monitor-button';
import { VIZ_OPTION_IDS } from '../../common';

interface IVizSettingsPanelProps {
  chartSpec?: any;
  chartType?: string;
  legendData?: any;
  vizId: string;
  customColors?: any;
  alignYAxesAtZero?: boolean;
  setAlignYAxesAtZero?: (boolean) => void;
  advancedMode?: boolean;
}

const VizSettingsPanel = memo((props: IVizSettingsPanelProps) => {
  const {
    chartSpec,
    chartType,
    legendData,
    vizId,
    customColors,
    alignYAxesAtZero,
    setAlignYAxesAtZero,
    advancedMode,
  } = props as any;

  return (
    <div className={'viz-settings-panel'}>
      <ConditionalFormattingSettings
        chartSpec={chartSpec}
        chartType={chartType}
        vizId={vizId}
      />
      <ColorSettings
        chartType={chartType}
        legendData={legendData}
        vizId={vizId}
        customColors={customColors}
      />
      <ComboChartSettings
        chartSpec={chartSpec}
        alignYAxesAtZero={alignYAxesAtZero}
        setAlignYAxesAtZero={setAlignYAxesAtZero}
      />
      <AddMonitorButton advancedMode={advancedMode} />
    </div>
  );
});

const mapDispatchToProps = (dispatch, ownProps: any) => {
  return {
    setAlignYAxesAtZero: checked => {
      dispatch(
        Discover.setSettingForViz({
          id: ownProps.vizId,
          setting: VIZ_OPTION_IDS.alignYAxesAtZero,
          value: checked,
        }),
      );
    },
  };
};

export default compose(
  pure,
  connect((state: any, ownProps: any) => {
    const rawDiscovery = state.discover.openDiscoveries[ownProps.vizId];
    const open = rawDiscovery.present;
    const legendDataList = open.legendData || [];
    let legendData = legendDataList.reduce((acc, item) => {
      if (!isNil(item.label)) {
        acc.push(item);
      }
      return acc;
    }, []);
    // TODO: [DSC-3532] Legend data should already be sorted by the time it
    // gets to this component
    const querySorts = getVizQuerySorts(open.viz);
    legendData = sortLegendData(querySorts, legendData);
    return {
      advancedMode: state.main.advanced && !isNil(ownProps.vizId),
      legendData,
      chartType: open.viz.chartType,
      chartSpec: ChartSpecs[open.viz.chartType],
      alignYAxesAtZero: JSON.parse(
        get(open, `viz.options.${VIZ_OPTION_IDS.alignYAxesAtZero}`, 'true'),
      ),
      customColors: JSON.parse(
        get(open, `viz.options.${VIZ_OPTION_IDS.customColors}`, '{}'),
      ),
    };
  }, mapDispatchToProps),
)(
  VizSettingsPanel as FunctionComponent<IVizSettingsPanelProps>,
) as ComponentType<IVizSettingsPanelProps>;
