import _ from 'lodash';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useSlicerOptionsQuery } from '../graphql/slicer-options.gql';
import { ISlicerReducerType, SelectStatus } from '../interfaces';
import { useDispatchSetSelectionForSlicer } from '../redux';
import { defaultTimestampSlicerOptions } from '../util';
import { useTimestampSlicer } from './common/timestamp-slicer.hook';

export enum SlicerActions {
  UpdateSearchInput,
  Update,
  ToggleOption,
  SetOnlyOption,
  SelectNone,
  SelectAll,
  SetTimeFrame,
}

export const slicerReducer = (state, action) => {
  const { mappedOptions } = state;
  switch (action.type) {
    case SlicerActions.UpdateSearchInput: {
      return {
        ...state,
        searchInput: action.searchInput,
      };
    }
    case SlicerActions.Update: {
      return {
        ...state,
        searchInput: action.searchInput,
        mappedOptions: action.mappedOptions,
        savedOptions: action.initialOptions,
      };
    }
    case SlicerActions.ToggleOption:
      return {
        ...state,
        mappedOptions: mappedOptions.map(({ option, isSelected }) => ({
          option,
          isSelected: option === action.option ? !isSelected : isSelected,
        })),
      };
    case SlicerActions.SetOnlyOption:
      return {
        ...state,
        mappedOptions: mappedOptions.map(({ option }) => ({
          option,
          isSelected: option === action.option,
        })),
      };
    case SlicerActions.SelectNone:
      return {
        ...state,
        mappedOptions: mappedOptions.map(el => {
          if (
            _.isEmpty(state.searchInput) ||
            _.includes(_.lowerCase(el.option), _.lowerCase(state.searchInput))
          ) {
            return {
              option: el.option,
              isSelected: false,
            };
          }
          return el;
        }),
      };
    case SlicerActions.SelectAll:
      return {
        ...state,
        mappedOptions: mappedOptions.map(el => {
          if (
            _.isEmpty(state.searchInput) ||
            _.includes(_.lowerCase(el.option), _.lowerCase(state.searchInput))
          ) {
            return {
              option: el.option,
              isSelected: true,
            };
          }
          return el;
        }),
      };
    case SlicerActions.SetTimeFrame:
      return {
        ...state,
        mappedOptions: action.mappedOptions.map(option => ({
          option,
          isSelected: true,
        })),
      };
  }
};

export const useSlicerReducer: (
  name: string,
  vizId?: string,
) => ISlicerReducerType = (name: string, vizId: string) => {
  const dispatchSetSelection = useDispatchSetSelectionForSlicer(vizId);

  const { isTimestampSlicer, isTimestampSlicerNotInSlicerSelection } =
    useTimestampSlicer({
      name,
      vizId,
    });

  const {
    options: fetchedOptions,
    loading,
    isPaged,
  } = useSlicerOptionsQuery({
    name,
    vizId,
  });
  const initialState = useMemo(() => {
    let initialOptions = fetchedOptions;
    const initialEmpty = !_.some(fetchedOptions, 'isSelected');
    if (initialEmpty) {
      initialOptions = _.map(fetchedOptions, ({ option }) => ({
        option,
        isSelected: true,
      }));
    }
    return { initialOptions, mappedOptions: initialOptions };
  }, [fetchedOptions]);
  const { initialOptions } = initialState;
  const [{ mappedOptions, savedOptions, searchInput }, dispatch] = useReducer(
    slicerReducer,
    initialState,
  );

  const { options, allSelected, noneSelected, hasChanged, selectStatus } =
    useMemo(() => {
      const options = _.filter(
        mappedOptions,
        ({ option }) =>
          _.isEmpty(searchInput) ||
          _.includes(_.lowerCase(option), _.lowerCase(searchInput)),
      );

      const allSelected = _.every(mappedOptions, 'isSelected');
      const noneSelected = !_.some(mappedOptions, 'isSelected');
      const someSelected = _.every(options, 'isSelected');
      const someDeSelected = !_.some(options, 'isSelected');
      const hasChanged = !_.isEqual(initialOptions, mappedOptions);
      const selectStatus = allSelected
        ? SelectStatus.ALL
        : noneSelected
          ? SelectStatus.NONE
          : someSelected
            ? SelectStatus.SOME_SELECTED
            : someDeSelected
              ? SelectStatus.SOME_DESELECTED
              : SelectStatus.SOME;

      return { allSelected, noneSelected, hasChanged, selectStatus, options };
    }, [mappedOptions, searchInput, initialOptions]);

  const reset = useCallback(() => {
    dispatch({ type: SlicerActions.Update, ...initialState, searchInput: '' });
  }, [initialState]);

  useEffect(() => {
    if (!_.isEqual(initialOptions, savedOptions)) {
      reset();
    }
  }, [initialOptions, savedOptions, reset]);

  useEffect(() => {
    if (isTimestampSlicer && isTimestampSlicerNotInSlicerSelection) {
      dispatchSetSelection({
        name,
        options: defaultTimestampSlicerOptions,
      });
    }
  }, [
    dispatchSetSelection,
    isTimestampSlicer,
    isTimestampSlicerNotInSlicerSelection,
    name,
  ]);

  const updateSearchInput = useCallback(
    (searchInput: string) => {
      dispatch({ type: SlicerActions.UpdateSearchInput, searchInput });
    },
    [dispatch],
  );

  const toggleOption = useCallback(
    (option: string) => {
      dispatch({ type: SlicerActions.ToggleOption, option });
    },
    [dispatch],
  );

  const selectOnly = useCallback(
    (option: string) => {
      dispatch({ type: SlicerActions.SetOnlyOption, option });
    },
    [dispatch],
  );

  const clear = useCallback(() => {
    dispatch({ type: SlicerActions.SelectNone });
  }, [dispatch]);

  const selectAll = useCallback(() => {
    dispatch({ type: SlicerActions.SelectAll });
  }, [dispatch]);

  const selectTimeFrame = useCallback(
    (options: string[]) => {
      dispatch({
        type: SlicerActions.SetTimeFrame,
        mappedOptions: options,
      });
    },
    [dispatch],
  );

  const save = useCallback(() => {
    dispatchSetSelection({
      name,
      options:
        selectStatus === SelectStatus.ALL && !isTimestampSlicer
          ? []
          : mappedOptions,
    });
    reset();
  }, [
    selectStatus,
    dispatchSetSelection,
    name,
    isTimestampSlicer,
    mappedOptions,
    reset,
  ]);

  return {
    save,
    searchInput,
    hasChanged,
    selectStatus,
    allSelected,
    noneSelected,
    options,
    loading,
    reset,
    isPaged,
    updateSearchInput,
    toggleOption,
    selectOnly,
    clear,
    selectAll,
    selectTimeFrame,
  };
};
