import Const from '../actions/ActionConst';
import { composeResetReducer } from 'redux-reset-store';
import { getFrontendUrl } from '../../Constants';
import { URLs } from '../../Urls';
import reject from 'lodash/reject';
import find from 'lodash/find';
import isMatch from 'lodash/isMatch';
import { omitBy, isUndefined, startsWith } from 'lodash';
import { getConfig } from '../../global-discover-config';
import { Appearance } from '../../../account/interfaces';
import { shortid } from '../../utilities/shortid-adapter';

const APPEARANCE_KEY = '__APPEARANCE__';
const { TENANT_ID, TENANT_IDM_ID } = getConfig();

const getFromRoute = () => {
  let {
    tenant_id: tenantId,
    tenant_idm_id: tenantIdmId,
    appearance: userApperanceOverride,
  } = URLs.getQueryParams();
  let windowTenantId = TENANT_ID;
  if (startsWith(windowTenantId, 'dummy-')) {
    windowTenantId = '';
  }
  tenantId = tenantId || windowTenantId;
  tenantIdmId = tenantIdmId || TENANT_IDM_ID;
  const isSystemDarkModeEnabled = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;
  const userApperanceSetting =
    localStorage.getItem(APPEARANCE_KEY) || Appearance.System;
  return omitBy(
    {
      appUrl: getFrontendUrl(),
      userApperanceOverride,
      isSystemDarkModeEnabled,
      userApperanceSetting,
      tenantId,
      tenantIdmId,
    },
    isUndefined,
  );
};

export const MOBILE_MAX_WIDTH = 808;

/**
 *  This is the Reducers module contains the state and reducers of
 * the corvana application.  See React Redux for more info on Reducers.
 */
const initialState = {
  // Global App State
  advanced: false,
  activeTab: 'activityFeed', // activityFeed, library, datasets, discover, monitor, present
  activeSubmenuTab: { activityFeed: 'feed', discover: 'browse' }, // activity:  {feed, manageNotifications}  discover: {browse, open}
  activityFeedAvailable: false,
  showGlassPane: false,
  glassPaneContent: null,
  controlDown: false,
  metaDown: false,
  showReAuthenticate: false,
  toasts: [],
  mobileMaxWidth: MOBILE_MAX_WIDTH,

  ...getFromRoute(),
};

export default composeResetReducer((state = initialState, action) => {
  switch (action.type) {
    case Const.Account.SET_CURRENT_USER: {
      const { currentUser: user } = action;
      const { appearance } = user || {};
      localStorage.setItem(APPEARANCE_KEY, appearance || Appearance.System);
      return { ...state, userApperanceSetting: appearance };
    }
    case Const.Main.SET_SYSTEM_DARK_MODE:
      return {
        ...state,
        isSystemDarkModeEnabled: action.isSystemDarkModeEnabled,
      };
    case Const.Main.SET_USER_APPEARANCE_OVERRIDE:
      return { ...state, userApperanceOverride: action.userApperanceOverride };

    case Const.Main.UPDATE_FROM_ROUTE:
      return { ...state, ...getFromRoute() };

    case Const.Main.SET_MOBILE_MAX_WIDTH:
      return { ...state, mobileMaxWidth: action.mobileMaxWidth };

    case Const.Main.INTERNAL_SERVER_ERROR:
      return { ...state, hasInternalServerError: true };

    case Const.Login.LOGIN:
      return { ...state, activeTab: 'activityFeed' };

    case Const.Main.SET_ACTIVITY_FEED_STATUS:
      return { ...state, activityFeedAvailable: action.activityFeedAvailable };

    case Const.Main.TOGGLE_ADVANCED:
      return { ...state, advanced: !state.advanced };

    case Const.Main.ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
        datasetPage: 'dataset',
        uploadStatus: 'dropzone',
      };

    case Const.Main.ACTIVE_SUBMENU_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
        activeSubmenuTab: {
          ...state.activeSubmenuTab,
          [action.activeTab]: action.activeSubmenuTab,
        },
      };

    // switch to "browse" if there are no open discoveries
    case Const.Discover.ADD_OPEN_DISCOVERY:
      return {
        ...state,
        activeTab: 'discover',
        activeSubmenuTab: { ...state.activeSubmenuTab, discover: 'open' },
      };

    // switch to "browse" if there are no open discoveries
    case Const.Discover.CLOSE_OPEN_DISCOVERY:
      if (Object.keys(action.openDiscoveries).length === 0) {
        return {
          ...state,
          activeTab: 'discover',
          activeSubmenuTab: { ...state.activeSubmenuTab, discover: 'browse' },
        };
      } else {
        return state;
      }
    case Const.Main.SHOW_GLASS_PANE:
      return {
        ...state,
        showGlassPane: action.visible,
        glassPaneContent: action.content,
      };

    case Const.Main.SET_CONTROL_DOWN: {
      return {
        ...state,
        controlDown: action.down,
      };
    }

    case Const.Main.SET_META_DOWN: {
      return {
        ...state,
        metaDown: action.down,
      };
    }

    case Const.Main.SHOW_ERROR_DETAIL: {
      return {
        ...state,
        showErrorDetailDialog: action.showErrorDetailDialog,
        errorTitle: action.errorTitle,
        errorDetail: action.errorDetail,
      };
    }

    case Const.Main.SHOW_APP_OUT_OF_DATE: {
      return {
        ...state,
        showAppOutOfDate: true,
      };
    }

    case Const.Main.SHOW_RE_AUTHENTICATE: {
      return {
        ...state,
        showReAuthenticate: true,
      };
    }

    case Const.Main.CLEAR_RE_AUTH: {
      return {
        ...state,
        showReAuthenticate: false,
      };
    }

    case Const.Main.SHOULD_FORCE_MOBILE: {
      const { shouldForceMobile } = action;
      const { isMobile, isViewMobile } = state;
      return {
        ...state,
        shouldForceMobile,
        isForcedMobile: shouldForceMobile && !isViewMobile,
        isMobile: isMobile || shouldForceMobile,
      };
    }

    case Const.Main.SET_IS_MOBILE: {
      const { shouldForceMobile, mobileMaxWidth = MOBILE_MAX_WIDTH } = state;
      const isViewMobile = action.viewportWidth <= mobileMaxWidth;
      const isMobile = isViewMobile || shouldForceMobile;
      const isForcedMobile = shouldForceMobile && !isViewMobile;
      if (isMatch(state, { isMobile, isViewMobile, isForcedMobile })) {
        return state;
      }
      return {
        ...state,
        isMobile,
        isViewMobile,
        isForcedMobile,
      };
    }

    case Const.Main.SHOW_TOAST: {
      const { content } = action;
      const isOpen = find(state.toasts, content);
      if (isOpen) {
        return state;
      }
      return {
        ...state,
        toasts: [...state.toasts, { ...content, key: shortid.generate() }],
      };
    }

    case Const.Main.CLOSE_TOAST: {
      const { searchObj } = action;
      if (!searchObj) {
        return {
          ...state,
          toasts: state.toasts.slice(1),
        };
      }
      return {
        ...state,
        toasts: reject(state.toasts, searchObj),
      };
    }

    /*
     * Updates the `aggregateInfo` in the state.
     *
     * @typedef {Object} AggregateInfo
     * @property {string} name - The name of the aggregate function.
     * @property {Array<'STRING' | 'BOOLEAN' | 'NUMBER' | 'TIMESTAMP'>} supportedTypes - The data types supported by this aggregate function.
     * @property {'AVERAGE' | 'COUNT' | 'COUNT_DISTINCT' | 'MAXIMUM' | 'SUM'} functionName - The name of the aggregation function.
     * @property {'AVG' | 'CNT' | 'CTD' | 'MAX' | 'SUM'} abbreviation - The abbreviation of the aggregation function.
     *
     * @param {Object} state - The current state of the reducer.
     * @param {Object} action - The action object dispatched to the reducer.
     * @param {Array<AggregateInfo>} action.list - The list of aggregate functions.
     * @returns {Object} The updated state with the new `aggregateInfo`.
     */
    case Const.Main.SET_AGGREGATE_INFO: {
      return {
        ...state,
        aggregateInfo: action.list,
      };
    }

    default:
      return state;
  }
}, initialState);
