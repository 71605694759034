import { IHistoryItemProps } from './history-item.interface';
import {
  CommitDetails,
  CommitMessage,
  HistoryItemWrapper,
  AuthorDetail,
  DotSeparator,
  BulletItem,
  CommitDetailsWrapper,
  Bullet,
  UnsavedCommitMessage,
  TopConnector,
  BottomConnector,
  FetchLoader,
  UpdateDetails,
  CurrentVersionDetail,
} from './history-item.styles';
import { messages } from '../../i18n';
import { isEmpty, noop } from 'lodash';
import classnames from 'classnames';

export const HistoryItem = ({
  commitMessage,
  commitDate,
  commitAuthor,
  commitVersion,
  selected,
  fetchingViz,
  isCurrentVersion,
  isFirstVersion,
  onClick,
  disabled = false,
}: IHistoryItemProps) => {
  const unsavedCurrentVersion = isCurrentVersion && isEmpty(commitMessage);
  const fullMessage =
    isCurrentVersion && !isEmpty(commitMessage)
      ? commitMessage
      : isCurrentVersion
        ? messages.reportDetail.currentVersion
        : commitMessage;

  return (
    <HistoryItemWrapper
      onClick={disabled ? noop : () => onClick(commitVersion)}
      className={classnames({
        selected,
        disabled,
      })}
      title={fullMessage}
    >
      <BulletItem>
        {!selected && (
          <>
            {!isCurrentVersion && <TopConnector />}
            {!isFirstVersion && <BottomConnector />}
          </>
        )}
        {fetchingViz ? <FetchLoader /> : <Bullet />}
      </BulletItem>
      <CommitDetailsWrapper>
        <CommitDetails>
          {unsavedCurrentVersion ? (
            <UnsavedCommitMessage title={fullMessage}>
              {fullMessage}
            </UnsavedCommitMessage>
          ) : (
            <CommitMessage>{fullMessage}</CommitMessage>
          )}
        </CommitDetails>
        <UpdateDetails>
          {isCurrentVersion && !isEmpty(commitMessage) && (
            <CurrentVersionDetail>
              {messages.reportDetail.currentVersion}
            </CurrentVersionDetail>
          )}
          <CommitDetails>
            <AuthorDetail>{commitAuthor}</AuthorDetail>
            <DotSeparator />
            <AuthorDetail>{commitDate}</AuthorDetail>
          </CommitDetails>
        </UpdateDetails>
      </CommitDetailsWrapper>
    </HistoryItemWrapper>
  );
};
