import { ISugarIconProps, SugarIconType } from '../../common';
import _ from 'lodash';
import { SugarIcon } from '../../sugar-icon';

export const ToastIcon = (props: Omit<ISugarIconProps, 'icon'>) => {
  const { iconType = 'medium', iconSize = 'md' } = props;

  const iconTypeMap = {
    success: 'check-circle',
    warning: 'warning-circle',
    danger: 'warning-lg',
    info: 'info-lg',
    error: 'error-lg',
  };

  const icon = (_.get(iconTypeMap, _.toLower(iconType)) ??
    'check-circle') as SugarIconType;

  return <SugarIcon iconType={iconType} icon={icon} iconSize={iconSize} />;
};
