import { css } from '@emotion/react';
import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDiscoverTheme } from '../../common/emotion';
import {
  ISlicerOption,
  noBottomBorder,
  SlicerButtonFooter,
  SlicerOptions,
  SlicerThemeProvider,
  SlicerTooltip,
  SelectAllItem,
  SlicerSelectionColumn,
  isStatusValid,
  isIndeterminateStatus,
  SlicerFooterMenuItem,
  SCROLL_CONTAINER_MAX_HEIGHT,
} from '../slicer';
import { SlugSpan } from '../slicer/slicer-widget/item-count-slug/item-count-slug.styles';
import _ from 'lodash';
import { messages } from '../../i18n';
import { SugarIcon } from '../../icons';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import { FilterWidgetContext } from './filter-widget.context';
import { SelectDropdown } from '../../ui/dropdowns/select-dropdown';
import { useResizeObserver } from '../../common/utilities/resize-observer';
import { SearchInput } from '../../ui/dropdowns/searchable-dropdown';
import { useStyles } from './filter-widget.styles';

export interface IFilterWidgetProps {
  placeholder?: string;
  onlyEnabled?: boolean;
  disableAdd?: boolean;
  disableSelectAll?: boolean;
  onSave: (tags: string[]) => void;
  isDashletTag?: boolean;
  widgetType?: IWidgetType;
  headerSx?: CSSProperties;
  listSx?: CSSProperties;
  popperSx?: CSSProperties;
}

export type IWidgetType = 'tags' | 'chartTypes' | 'datasets';

const MIN_DROPDOWN_WIDTH = 80;
const TAG_MENU_TOGGLE_HEIGHT = 40;
const TAG_MENU_TOGGLE_HEIGHT_DASHLET = 30;
const TAG_DROPDOWN_ID = 'tag-dropdown';

const createOptionsList = (
  allTags: string[],
  selectedTags?: string[],
): ISlicerOption[] => {
  const isSelected = (tag: string): boolean =>
    !_.isEmpty(selectedTags) ? _.includes(selectedTags, tag) : false;

  return _.orderBy(
    _.reduce(
      allTags,
      (acc, tag) => {
        return [...acc, { option: tag, isSelected: isSelected(tag) }];
      },
      [],
    ),
    ['isSelected', 'option'],
    ['desc', 'asc'],
  );
};

const encodeLabels = tagOptions =>
  _.map(_.filter(tagOptions, { isSelected: true }), 'option').join(', ');

const FilterTitle = ({
  widgetType,
  isOpen,
  selectedTagsLabel,
  placeholder,
  ...remaininProps
}: {
  widgetType: string;
  isOpen: boolean;
  selectedTagsLabel: string;
  placeholder: string;
}) => {
  const {
    isDashletMode,
    colors: { BorderColor },
  } = useDiscoverTheme();
  return (
    <div
      id={`tag-toggle-${widgetType}`}
      css={css({
        width: '100%',
        height: `${
          isDashletMode
            ? TAG_MENU_TOGGLE_HEIGHT_DASHLET
            : TAG_MENU_TOGGLE_HEIGHT
        }px !important`,
        boxShadow: 'none !important',
        borderColor: `${BorderColor} !important`,
        display: 'flex !important',
        placeContent: 'stretch flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        ...(isOpen
          ? {
              ...noBottomBorder(),
            }
          : {}),
        '&:hover': {
          ...(isOpen ? noBottomBorder() : {}),
        },
      })}
      {...remaininProps}
    >
      <SlugSpan
        css={css({
          width: '100%',
          textAlign: 'left',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        })}
        className={'tag-slug'}
      >
        {selectedTagsLabel || placeholder}
      </SlugSpan>
    </div>
  );
};

export const FilterWidget = ({
  placeholder = messages.tags.emptyTags,
  disableAdd = false,
  disableSelectAll = true,
  onSave,
  isDashletTag = false,
  widgetType = 'tags',
  headerSx: providedHeaderSx = {},
  listSx: providedListSx = {},
  popperSx = {},
}: IFilterWidgetProps) => {
  const {
    isDashletMode,
    isMobile,
    colors: { MediumBlue },
  } = useDiscoverTheme();

  const isResponsive = isDashletMode || isMobile;

  const { loading, data, selectedTypes, labelDisplayMap } =
    useContext(FilterWidgetContext);
  const getDisplayValues = useCallback(
    (values: string[]) =>
      !labelDisplayMap || _.isEmpty(labelDisplayMap[widgetType])
        ? values
        : _.map(values, v => _.get(labelDisplayMap[widgetType], v)),
    [labelDisplayMap, widgetType],
  );
  const getFilterValues = useCallback(
    (labels: string[]) =>
      !labelDisplayMap || _.isEmpty(labelDisplayMap[widgetType])
        ? labels
        : _.map(labels, v => _.get(_.invert(labelDisplayMap[widgetType]), v)),
    [labelDisplayMap, widgetType],
  );
  const selectedOptions = getDisplayValues(selectedTypes[widgetType]);
  const [dropdownWidth, setDropdownWidth] = useState(MIN_DROPDOWN_WIDTH);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('');
  const [selectAllToggleActive, setSelectAllToggleActive] = useState(false);

  const ref = useRef(null);
  const footerRef = useRef();
  const { height: footerHeight = 0 } =
    useResizeObserver(footerRef?.current) ?? {};

  const selectAllLabel = selectAllToggleActive
    ? messages.deselectAll
    : messages.selectAll;

  const allTags = useMemo(
    () =>
      _.uniq([
        ...getDisplayValues(_.flatMap(data?.visualizations, widgetType)),
        ...selectedOptions,
      ]),
    [data?.visualizations, getDisplayValues, selectedOptions, widgetType],
  );

  const [allTagOptions, setAllTagOptions] = useState<ISlicerOption[]>(
    createOptionsList(allTags, selectedOptions),
  );

  const hasAllTagsChanged = useHasValueChanged({ value: allTags });
  const hasSelectedTagsChanged = useHasValueChanged({ value: selectedOptions });

  useEffect(() => {
    if (!loading && (hasAllTagsChanged || hasSelectedTagsChanged)) {
      setAllTagOptions(createOptionsList(allTags, selectedOptions));
    }
  }, [
    loading,
    allTags,
    selectedOptions,
    hasAllTagsChanged,
    hasSelectedTagsChanged,
  ]);

  const [selectedTagsLabel, originalTagsLabel] = useMemo(
    () => [
      encodeLabels(allTagOptions),
      encodeLabels(createOptionsList(allTags, selectedOptions)),
    ],
    [allTagOptions, selectedOptions, allTags],
  );
  const hasSelectedTagsLabelChanged = selectedTagsLabel !== originalTagsLabel;

  const filteredTagOptions = _.filter(allTagOptions, t =>
    _.includes(_.toLower(t.option), _.toLower(currentFilter)),
  );

  const toggleOption = useCallback(
    (option: string) => {
      const updatedOptions = allTagOptions.map(opt =>
        _.isEqual(opt.option, option)
          ? ({ option, isSelected: !opt.isSelected } as ISlicerOption)
          : opt,
      );
      setAllTagOptions(updatedOptions);
    },
    [allTagOptions],
  );

  const addOptionToTags = useCallback(() => {
    const newOption: ISlicerOption = {
      option: currentFilter,
      isSelected: true,
    };
    setCurrentFilter('');
    setAllTagOptions([newOption, ...allTagOptions]);
  }, [allTagOptions, currentFilter]);

  const tagRef = useCallback(node => {
    if (!_.isNil(node)) {
      const { width } = node.getBoundingClientRect();
      setDropdownWidth(Math.max(width, MIN_DROPDOWN_WIDTH) - 2);
    }
  }, []);

  const toggleAll = useCallback(() => {
    const isActive = !selectAllToggleActive;
    setSelectAllToggleActive(isActive);
    const updatedOptions = allTagOptions.map(opt => ({
      option: opt.option,
      isSelected: isActive,
    }));
    setAllTagOptions(updatedOptions);
  }, [selectAllToggleActive, allTagOptions]);

  const onCancel = useCallback(() => {
    const resetOptions = createOptionsList(
      _.map(allTagOptions, 'option'),
      selectedOptions,
    );
    setCurrentFilter('');
    setAllTagOptions(resetOptions);
    setIsOpen(false);
    setSelectAllToggleActive(false);
  }, [allTagOptions, selectedOptions]);

  const selectStatus = useMemo(
    () =>
      _.every(allTagOptions, 'isSelected')
        ? 'all'
        : !_.some(allTagOptions, 'isSelected')
          ? 'none'
          : 'some',
    [allTagOptions],
  );

  const { dropdownWrapperStyle, searchMenuItemSx, headerSx, listSx } =
    useStyles({
      isOpen,
      headerSx: providedHeaderSx,
      listSx: providedListSx,
      hasSelection: selectedOptions?.length > 0,
    });

  return (
    <div
      id={`tag-wrapper-${widgetType}`}
      ref={tagRef}
      className={'combo-dropdown'}
      css={css({
        width: '100%',
      })}
    >
      <SlicerThemeProvider
        isOpen={isOpen}
        width={dropdownWidth}
        listHeight={SCROLL_CONTAINER_MAX_HEIGHT - footerHeight}
        toggleHeight={
          isResponsive ? TAG_MENU_TOGGLE_HEIGHT_DASHLET : TAG_MENU_TOGGLE_HEIGHT
        }
      >
        <SlicerTooltip
          options={_.filter(allTagOptions, 'isSelected')}
          loading={loading}
        >
          <span id={`tag-tooltip-${widgetType}`}>
            <SelectDropdown
              id={`${TAG_DROPDOWN_ID}-${widgetType}`}
              closeAfterSelect={false}
              ref={ref}
              className={'tag-dropdown'}
              css={css({
                width: '100%',
              })}
              onToggle={setIsOpen}
              onDismiss={onCancel}
              headerSx={headerSx}
              listSx={listSx}
              popperSx={popperSx}
              style={dropdownWrapperStyle as any}
              title={
                <FilterTitle
                  widgetType={widgetType}
                  isOpen={isOpen}
                  selectedTagsLabel={selectedTagsLabel}
                  placeholder={placeholder}
                />
              }
            >
              <SearchInput
                onInput={e =>
                  setCurrentFilter(
                    () => (e?.target as HTMLInputElement)?.value ?? '',
                  )
                }
                menuItemSx={searchMenuItemSx}
                defaultValue={currentFilter}
                endAdornment={
                  disableAdd
                    ? undefined
                    : !_.isEmpty(currentFilter) && (
                        <span
                          title={messages.tags.addTag}
                          css={css({ display: 'flex' })}
                        >
                          <SugarIcon
                            css={css({
                              display: 'flex',
                              alignItems: 'center',
                            })}
                            icon={'plus'}
                            hoverColor={MediumBlue}
                            onClick={addOptionToTags}
                          />
                        </span>
                      )
                }
              />
              {!disableSelectAll && (
                <SelectAllItem role='presentation'>
                  <SlicerSelectionColumn
                    option={selectAllLabel}
                    selected={isStatusValid(selectStatus)}
                    labelRef={null}
                    onSelect={toggleAll}
                    width={'100%'}
                    name={messages.selectAll}
                    indeterminate={isIndeterminateStatus(selectStatus)}
                  />
                </SelectAllItem>
              )}
              <SlicerOptions
                slicerErrorVisible={false}
                options={filteredTagOptions}
                name={'tags'}
                toggleOption={toggleOption}
                selectOnly={_.noop}
                isDashletTag={isDashletTag}
              />
              <SlicerFooterMenuItem ref={footerRef}>
                <SlicerButtonFooter
                  name={'bar'}
                  onCancel={onCancel}
                  onSave={() => {
                    const selectedOptions: string[] = getFilterValues(
                      _.map(_.filter(allTagOptions, 'isSelected'), 'option'),
                    );
                    onSave(selectedOptions);
                    setCurrentFilter('');
                    setIsOpen(false);
                  }}
                  saveDisabled={!hasSelectedTagsLabelChanged}
                />
              </SlicerFooterMenuItem>
            </SelectDropdown>
          </span>
        </SlicerTooltip>
      </SlicerThemeProvider>
    </div>
  );
};
