import { branch, compose, renderNothing } from 'react-recompose';
import { connect } from 'react-redux';
import _, { head } from 'lodash';
import { PromptDialog } from '../common/widgets/dialogs/prompt-dialog';
import Discover from '../common/redux/actions/DiscoverActions';
import { Viz } from './VizUtil';
import { HelpBlock } from '../views/VizSaveDialog/ui';
import { messages } from '../i18n';
import { shortid } from '../common/utilities/shortid-adapter';

const AddFieldToVizWarningDialog = props => {
  const doAdd = () => {
    props.addField(
      props.discoveryId,
      props.field,
      props.shelf,
      props.breakingFields,
      props.layout,
      props.insertionPosition,
    );
    props.hideConfirmAddField();
  };
  const cancel = () => {
    props.hideConfirmAddField();
  };

  let detail;
  if (props.breakingFields?.length > 1) {
    detail = (
      <HelpBlock>
        <p className='message'>
          {messages.formatString(
            messages.addFieldToVizDialog.invalidateOtherFields,
            <strong>{messages.addFieldToVizDialog.warning}</strong>,
          )}
          <br />
          {messages.formatString(
            messages.addFieldToVizDialog.continueWillAddRemoveList,
            <strong>{messages.continue}</strong>,
            <strong>{props.field?.name}</strong>,
          )}
          <ul>
            {props.breakingFields.map(f => (
              <li key={f.name}>
                <strong>{f.name}</strong>
              </li>
            ))}
          </ul>
        </p>
      </HelpBlock>
    );
  } else {
    detail = (
      <HelpBlock>
        <p className='message'>
          {messages.formatString(
            messages.addFieldToVizDialog.actionWillInvalidate,
            <strong>{messages.addFieldToVizDialog.warning}</strong>,
            <strong>{head(props.breakingFields)?.name}</strong>,
          )}
          <br />
          {messages.formatString(
            messages.addFieldToVizDialog.continueWillAddRemoveItem,
            <strong>{messages.continue}</strong>,
            <strong>{props.field?.name}</strong>,
            <strong>{head(props.breakingFields)?.name}</strong>,
          )}
        </p>
      </HelpBlock>
    );
  }

  return (
    <PromptDialog
      show={props.show}
      detail={detail}
      yesText={messages.continue}
      doYes={() => {
        doAdd();
      }}
      noText={messages.cancel}
      doNo={() => {
        cancel();
      }}
    />
  );
};

export default compose(
  connect(
    state => {
      const {
        field,
        discoveryId,
        shelf,
        breakingFields = [],
        insertionPosition,
      } = state.discover.toBeAdded ? state.discover.toBeAdded : {};
      const open = discoveryId
        ? state.discover.openDiscoveries[discoveryId].present
        : null;
      return {
        show: state.discover.showConfirmAddField,
        layout: open ? open.viz.layout : null,
        field,
        discoveryId,
        shelf,
        breakingFields,
        insertionPosition,
        chartType: open ? open.viz.chartType : null,
      };
    },
    dispatch => {
      return {
        addField: (id, field, shelf, breakingFields, layout, index) => {
          const txId = shortid.generate();
          dispatch(
            Discover.addFieldToVisualization(id, field, shelf, index, txId),
          );
          if (!_.isEmpty(breakingFields)) {
            breakingFields.forEach(ref => {
              const fieldShelf = Viz.findShelfContainingField(layout, ref.name);
              dispatch(
                Discover.removeFieldFromVisualization(
                  id,
                  ref,
                  fieldShelf,
                  txId,
                ),
              );
            });
            dispatch(Discover.deleteCalcFields(id, breakingFields, txId));
          }
        },
        hideConfirmAddField: () => {
          dispatch(Discover.hideConfirmAddField());
        },
      };
    },
  ),
  branch(
    props =>
      !props.show ||
      !props.field ||
      !props.discoveryId ||
      _.isEmpty(props.breakingFields),
    renderNothing,
  ),
)(AddFieldToVizWarningDialog);
