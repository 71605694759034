import { DASHLET_MODE } from './dashlet.const';
import { useSelector } from 'react-redux';
import { getConfig, updateConfig } from '../common/global-discover-config';

export const setDashletModeOverride = dashletMode =>
  updateConfig({
    dashletMode,
  });

export const getDashletMode: () => DASHLET_MODE = () => {
  const { dashletMode: _dashletMode } = getConfig();
  if (_dashletMode) {
    return _dashletMode;
  }

  return;
};

export const isDashletMode: () => boolean = () => !!getDashletMode();

export const useIsDashletMode = () =>
  useSelector(
    ({ dashlet: dashletState = {} }: any = {}) => dashletState?.isDashletMode,
  );

export const useIsResponsive = () =>
  useSelector(
    ({ main: { isMobile = false }, dashlet: { isDashletMode = false } }: any) =>
      isMobile || isDashletMode,
  );
