import { Hierarchy, Viz } from '../../VizUtil';
import { ILayout, IMeasure, IQueryCalc, IViz } from '../../interfaces';
import { IAnyAttribute, IDataset } from '../../../datasets';
import {
  ChartSpecIds,
  FieldTypes,
  NULL_DISPLAY,
  ShelfName,
} from '../../../common/Constants';
import {
  flatten,
  head,
  includes,
  map,
  find,
  get,
  some,
  flatMap,
  concat,
  filter,
  keys,
  pickBy,
  isEmpty,
  pick,
} from 'lodash';
import { generateMeasuresFromMetrics } from '../../BaseChartSpec';
import { ShelfWithQueryCalcs } from './waterfall.interface';
import { messages } from '../../../i18n';
import { getChartSpecs } from '../../VizUtil.common';
import intersection from 'lodash/intersection';

export const makeUniqueFieldName = (
  existingFieldNames: string[],
  prefix = 'tmp',
) => {
  let i = 0;
  let fieldName = `${prefix}`;
  while (includes(existingFieldNames, fieldName)) {
    fieldName = `${prefix} ${i}`;
    i++;
  }
  return fieldName;
};

export const getExistingFieldNames = (layout: ILayout, dataset: IDataset) => {
  const fieldsInShelves = Viz.getAllFieldsInPlay(layout);
  const datasetAttributes = dataset.attributes;
  return map(fieldsInShelves.concat(datasetAttributes), 'name');
};

export const getDefaultSnapshotFieldName = (dataset: IDataset) => {
  const SNAPSHOT_ANNOTATION = find(dataset.annotations, {
    key: 'SNAPSHOT_DATE_FIELDNAME',
  });
  return get(SNAPSHOT_ANNOTATION, 'value', 'Snapshot Date');
};

export const shelvesInPlay = (viz: IViz) => {
  const nonEmptyShelfKeys = keys(pickBy(viz.layout, value => !isEmpty(value)));

  return intersection(
    [ChartSpecIds.ENTRANCE, ChartSpecIds.CHANGE, ChartSpecIds.EXIT],
    nonEmptyShelfKeys,
  );
};

export const getFirstMetricShelf = (layout: ILayout = {}): IAnyAttribute[] => {
  return layout[ChartSpecIds.VALUES];
};

export const getFirstMetric = (layout: ILayout = {}) => {
  const fields = getFirstMetricShelf(layout);
  return head(fields ?? []);
};

export const getFormattedShelfName = (chartType, shelfId) => {
  const chartSpec = getChartSpecs()[chartType];
  return get(messages, chartSpec.shelves[shelfId].name);
};

export const getMetricCalcByFieldName = (viz, calcName): IQueryCalc => {
  const calcShelves = getShelvesWithQueryCalcs(viz);

  const { shelfId } = find(calcShelves, ({ calcs }) =>
    some(calcs, { attributeName: calcName }),
  );

  const metricCalcs = waterfallExtraCalcsWithShelfId(viz.layout, viz.dataset);

  return pick(find(metricCalcs, { shelfId }), [
    'attributeName',
    'expression',
  ]) as IQueryCalc;
};

export const getShelvesWithQueryCalcs = (viz: IViz): ShelfWithQueryCalcs[] => {
  const vizCalcs = Viz.getCalcsFromViz(viz);

  return map(shelvesInPlay(viz), (shelfId: ShelfName) => {
    const calcs = viz.layout[shelfId];
    return {
      shelfId,
      calcs: map(calcs, calc => {
        const isVizCalc = some(vizCalcs, { name: calc.name });
        return {
          attributeName: calc.name as FieldTypes,
          expression: isVizCalc && (calc.formula || calc.calculation),
        };
      }),
    };
  });
};

export const getCaseQueryCalcs = (viz: IViz): IQueryCalc[] => {
  const { layout, dataset } = viz;

  const existingFieldNames = map(
    Viz.getAllFieldsInPlay(layout).concat(dataset.attributes),
    'name',
  );

  const chartSpec = getChartSpecs()[viz.chartType];

  const calcShelves = getShelvesWithQueryCalcs(viz);

  const caseQueryCalcs = flatMap(
    calcShelves,
    (readableShelf: ShelfWithQueryCalcs) => {
      const innerFormula = map(
        readableShelf.calcs,
        ({ attributeName }) => `[${attributeName}], "${attributeName}"`,
      ).join(', ');

      const caseDefinition = `CASE(${innerFormula}, "${NULL_DISPLAY}")`;
      const tmpName = makeUniqueFieldName(
        existingFieldNames,
        get(messages, chartSpec.shelves[readableShelf.shelfId].name),
      );

      return concat(
        map(
          filter(readableShelf.calcs, 'expression'),
          ({ attributeName, expression }) => ({
            attributeName,
            expression,
          }),
        ),
        {
          attributeName: tmpName,
          expression: caseDefinition,
        },
      );
    },
  );

  return caseQueryCalcs;
};

export const getSnapshotDateFieldCalc = (
  layout: ILayout,
  dataset: IDataset,
): IQueryCalc => {
  const expression = `date_part("${
    Hierarchy.TIME_ATTRIBUTES.DATE.calcFunction
  }", [${getDefaultSnapshotFieldName(dataset)}])`;

  const existingFieldNames = map(
    Viz.getAllFieldsInPlay(layout).concat(dataset.attributes),
    'name',
  );

  const attributeName = makeUniqueFieldName(
    existingFieldNames,
    'Date (Snapshot Date)',
  );

  return {
    attributeName,
    expression,
  };
};

export const waterfallExtraCalcs = (
  layout: ILayout,
  dataset: IDataset,
): IQueryCalc[] =>
  map(waterfallExtraCalcsWithShelfId(layout, dataset), _calc =>
    pick(_calc, ['attributeName', 'expression']),
  );

export const waterfallExtraCalcsWithShelfId = (
  layout: ILayout,
  dataset: IDataset,
): (IQueryCalc & { shelfId: ShelfName })[] => {
  const existingFieldNames = map(
    Viz.getAllFieldsInPlay(layout).concat(dataset.attributes),
    'name',
  );

  const firstMetric = head(getFirstMetricShelf(layout));

  const entranceCalcName = makeUniqueFieldName(
    existingFieldNames,
    messages.formatString(
      messages.chartSpecs.waterfall.entranceFieldName,
      firstMetric.name,
    ),
  );
  const changesCalcName = makeUniqueFieldName(
    existingFieldNames.concat([entranceCalcName]),
    messages.formatString(
      messages.chartSpecs.waterfall.changeFieldName,
      firstMetric.name,
    ),
  );
  const exitCalcName = makeUniqueFieldName(
    existingFieldNames.concat([entranceCalcName, changesCalcName]),
    messages.formatString(
      messages.chartSpecs.waterfall.exitFieldName,
      firstMetric.name,
    ),
  );

  const entranceCalcExpression = `FIRST([${firstMetric.name}])`;
  const changesCalcExpression = `[${firstMetric.name}] - FIRST([${firstMetric.name}])`;
  const exitCalcExpression = `[${firstMetric.name}]`;

  return map(
    [
      [entranceCalcName, entranceCalcExpression, ChartSpecIds.ENTRANCE],
      [changesCalcName, changesCalcExpression, ChartSpecIds.CHANGE],
      [exitCalcName, exitCalcExpression, ChartSpecIds.EXIT],
    ],
    ([attributeName, expression, shelfId]) =>
      ({
        attributeName,
        expression,
        shelfId,
      }) as IQueryCalc & { shelfId: ShelfName },
  );
};

export const waterfallQueryMeasures = (viz: IViz) => {
  const vizMeasures = generateMeasuresFromMetrics(viz);

  const booleanQueryCalcs = waterfallExtraCalcs(viz.layout, viz.dataset) ?? [];
  const extraMeasures: IMeasure[] = map(
    booleanQueryCalcs,
    ({ attributeName }) => ({
      attributeName,
      aggregation: 'Sum',
      resultSetFunction: 'NONE',
    }),
  );

  return flatten([extraMeasures, vizMeasures]);
};
