import { withProps, compose } from 'react-recompose';
import Utils from './PivotTableUtils';
import PivotCell from './PivotCell';
import _, { map } from 'lodash';
import {
  DATA_FORMATTER,
  EMPTY_CELL_TOKEN,
  NULL_DISPLAY,
  PIVOT_TABLE,
} from '../../../common/Constants';
import { Viz } from '../../VizUtil';
import { color } from 'd3';
import { TOTALS_FLAG } from './QueryPivotUtils';
import { withDiscoverContainerContext } from '../../../common/utilities/container.hoc';
import { useOpenVizSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { useAccount } from '../../../common/utilities/account';
import { messages } from '../../../i18n';
import Util from '../../../common/Util';

const BottomRightCellRenderer = ({
  columnCount,
  rowCount,
  columnIndex,
  key,
  rowIndex,
  style,
  conditionalFormatting,
  rowSubtotalsOn = false,
  theme,
  bodyData,
  formatters,
  showColGrandTotals,
  showRowGrandTotals,
  valuesAndTargets,
}) => {
  const classes = [
    Utils.getPivotCellClasses(columnIndex, rowIndex, columnCount, rowCount),
  ];

  const { currentUser: { i18nPrefs = {} } = {} } = useAccount();

  const viz = useOpenVizSelector();

  // Check to see if it's a scroll placeholder
  if (rowIndex === bodyData.length) {
    return (
      <PivotCell
        {...{
          value: { value: '' },
          key,
          style,
          classes: classes.join(' '),
          sorting: {},
          formatter: null,
          recordCount: null,
          rowIndex,
          columnIndex,
        }}
      />
    );
  }

  const updatedStyle = { ...style };
  let formatter = DATA_FORMATTER.NUMBER;
  let customFormatter = {};
  let value = { value: NULL_DISPLAY };
  const values = bodyData[rowIndex].columnData;

  // keys of bodyData[rowIndex] have the Column Field names and
  const isRowSubtotal = bodyData[rowIndex].type === 'rowSubtotal';
  let isNextRowASubtotal = false;
  if (!isRowSubtotal && rowSubtotalsOn && rowIndex < bodyData.length - 1) {
    isNextRowASubtotal = bodyData[rowIndex + 1].type === 'rowSubtotal';
  }

  if (Utils.isPivotLayout({ viz }) && Utils.numValues({ viz }) > 0) {
    formatter = formatters.body[columnIndex];
    customFormatter = formatters.custom[columnIndex];
    value =
      values[values.length - columnCount + columnIndex - /* Summary Col */ 1];
  } else if (!Utils.isPivotLayout({ viz })) {
    formatter = formatters.columnHeaders[columnIndex];
    value = values[columnIndex];
  }
  if (_.isNaN(value)) {
    value = { value: NULL_DISPLAY };
  }

  const isGrandTotal =
    columnIndex >= columnCount - Utils.numValues({ viz }) && showColGrandTotals;

  // Get conditional formatting
  const metric = valuesAndTargets[columnIndex % valuesAndTargets.length];
  if (!_.isNil(metric) && !isGrandTotal) {
    const format = _.find(conditionalFormatting, _.pick(metric, 'name'));
    if (!_.isNil(format)) {
      const { median, d3Scales } = format;
      // Apply conditional formatting color scale style
      if (!isRowSubtotal && !_.isNil(d3Scales) && _.isNumber(value?.value)) {
        const backgroundColor =
          value?.value <= median
            ? d3Scales.lowerScale(value?.value)
            : d3Scales.upperScale(value?.value);
        updatedStyle.background = backgroundColor;
        updatedStyle.color = Viz.getContrastColor(
          color(backgroundColor),
          theme,
        );
      }
    }
  }

  if (Utils.hasRows({ viz })) {
    classes.push('rows-present');
    if (Utils.hasValues({ viz }) && showRowGrandTotals) {
      classes.push('total-present');
    }
  }
  if (Utils.hasColumns({ viz })) {
    classes.push('cols-present');
  }
  if (isRowSubtotal) {
    classes.push('row-subtotal');
  }
  if (isNextRowASubtotal) {
    classes.push('next-row-is-subtotal');
  }
  if (isGrandTotal) {
    classes.push('col-grand-total');
  }

  return (
    <PivotCell
      {...{
        value,
        key,
        style: updatedStyle,
        classes: classes.join(' '),
        sorting: {},
        formatter,
        recordCount: null,
        customFormatter,
        i18nPrefs,
        rowIndex,
        columnIndex,
      }}
    />
  );
};

const VAL = 0;
const REPEAT = 1;
export default compose(
  withDiscoverContainerContext,
  withProps(props => {
    const bottomLeftCellRendererFunc = ({
      dataRow,
      idx,
      columnCount,
      rowCount,
      columnIndex,
      key,
      rowIndex,
      style,
      prevRowSubtotalsOn = false,
    }) => {
      const classes = [
        Utils.getPivotCellClasses(
          idx > 0 ? -1 : columnIndex,
          rowIndex,
          columnCount,
          rowCount,
        ),
      ];
      if (Utils.hasValues(props) && props.showRowGrandTotals) {
        classes.push('total-present');
      }
      if (Utils.hasColumns(props)) {
        classes.push('cols-present');
      }

      let recordCount;
      const formatter = props.formatters.rowHeaders[idx];
      // value or empty if a scrolling placeholder
      let value = '';
      if (rowIndex < dataRow.length) {
        let val = dataRow[rowIndex][VAL]?.value;
        if (val === TOTALS_FLAG) {
          classes.push('row-subtotal');
          // also look to see if any previous column in the same row was also a total
          if (rowIndex > 0) {
            const precedingColValue = dataRow[rowIndex - 1][VAL];
            if (precedingColValue === TOTALS_FLAG || !prevRowSubtotalsOn) {
              // flag the text to display an empty cell
              val = EMPTY_CELL_TOKEN;
            }
          }
        }

        value = val === TOTALS_FLAG ? messages.pivot.subTotal : val;
      }
      if (idx === columnCount - 1) {
        classes.push('last-row-header-col');
      }
      if (props.showRecordCounts && value === messages.pivot.subTotal) {
        // need to translate the collapsed rowIndex to the raw rowIndex
        const rawRowIndex = dataRow
          .slice(0, rowIndex)
          .reduce((ridx, header) => {
            return ridx + header[REPEAT];
          }, 0);

        recordCount = props.bodyData[rawRowIndex].count;
        if (!_.isNil(recordCount)) {
          classes.push('with-record-count');
        }
      }
      return (
        <PivotCell
          {...{
            value,
            key,
            style,
            classes: classes.join(' '),
            sorting: {},
            formatter,
            recordCount,
            i18nPrefs: props.i18nPrefs,
            rowIndex,
            columnIndex,
          }}
        />
      );
    };
    const topRightColumnsCellRendererFunc = ({
      row,
      idx,
      columnIndex,
      key,
      rowIndex,
      style,
    }) => {
      // value or empty if a scrolling placeholder
      let value = columnIndex < row.length ? row[columnIndex][VAL]?.value : '';

      // Special case for Column Grand Totals, last column will be named __ALL__
      let mergedGrandTotalCell = false;
      if (
        props.showColGrandTotals &&
        map(row[columnIndex], 'value').includes(TOTALS_FLAG)
      ) {
        // Only show grand total on last column
        if (idx === Utils.numColumns(props) - 1) {
          value = messages.pivot.grandTotal;
        } else {
          mergedGrandTotalCell = true;
          value = '';
        }
      }

      const formatter = props.formatters.columnHeaders[idx];

      return (
        <PivotCell
          {...{
            value,
            key,
            style,
            classes:
              Utils.getPivotCellClasses(columnIndex, rowIndex, row.length) +
              (Utils.hasRows(props) ? ' rows-present' : '') +
              (mergedGrandTotalCell ? ' merged-grand-total' : '') +
              (columnIndex === row.length - 1 && props.showColGrandTotals
                ? ' col-grand-total'
                : ''),
            sorting: {},
            formatter,
            recordCount: null,
            i18nPrefs: props.i18nPrefs,
            rowIndex,
            columnIndex,
          }}
        />
      );
    };

    const topRightValuesCellRendererFunc = ({
      columnIndex,
      key,
      rowIndex,
      style,
      columnCount,
    }) => {
      const metricPos = columnIndex % props.valuesAndTargets.length;

      // value or empty if a scrolling placeholder
      const metric = props.valuesAndTargets[metricPos];
      const value = Utils.hasValues(props) ? metric.name : '';

      if (columnCount && columnIndex < columnCount) {
        const displayValue = Utils.getPivotCellDisplayValue(metric);
        const parents = Utils.getParentColumns(
          columnIndex,
          props.colHeaderData,
          props.viz,
        );

        const pathToMe = [value].concat(parents);
        const sorting = props.sorting.ROWS.find(x =>
          _.isEqual(x.path, pathToMe),
        );
        const highlight = _.isEqual(
          Utils.getHighlight(props, 'ROWS'),
          pathToMe,
        );

        return (
          <PivotCell
            {...{
              value: { value: displayValue },
              key,
              style,
              classes:
                Utils.getPivotCellClasses(columnIndex, rowIndex, columnCount) +
                (Utils.hasColumns(props) ? ' cols-present' : '') +
                (Utils.hasRows(props) ? ' rows-present' : '') +
                (columnIndex === columnCount - Utils.numValues(props) &&
                props.showColGrandTotals
                  ? ' col-grand-total'
                  : ''),
              sorting: {
                ...sorting,
                highlight,
                sortFunc: dir => props.sort('ROWS', value, dir, parents),
                sortType: 'ROWS',
              },
              formatter: undefined,
              recordCount: null,
              i18nPrefs: props.i18nPrefs,
              rowIndex,
              columnIndex,
            }}
          />
        );
      } else {
        // Just a scrolling placeholder cell
        return (
          <PivotCell
            {...{
              value: { value: '' },
              key,
              style,
              classes:
                Utils.getPivotCellClasses(columnIndex, rowIndex) +
                (Utils.hasColumns(props) ? ' cols-present' : '') +
                (Utils.hasRows(props) ? ' rows-present' : ''),
              sorting: {},
              formatter: undefined,
              recordCount: null,
              i18nPrefs: props.i18nPrefs,
              rowIndex,
              columnIndex,
            }}
          />
        );
      }
    };
    return {
      topLeftCellRenderer: ({ columnIndex, key, rowIndex, style }) => {
        const field = props.viz.layout.ROWS[columnIndex];
        const value = field.name;
        const displayValue = Utils.getPivotCellDisplayValue(field);

        const pathToMe = [value];
        let sorting = props.sorting.ROWS.find(x => _.isEqual(x.path, pathToMe));

        // If not the last in the hierarchy, default sorting to asc
        if (!sorting && columnIndex < props.viz.layout.ROWS.length - 1) {
          sorting = { path: pathToMe, direction: 'asc' };
        }
        const highlight = _.isEqual(
          Utils.getHighlight(props, 'ROWS'),
          pathToMe,
        );

        return (
          <PivotCell
            {...{
              value: { value: displayValue },
              key,
              style,
              classes: Utils.getPivotCellClasses(
                columnIndex,
                rowIndex,
                Utils.numRows(props),
              ),
              sorting: {
                ...sorting,
                highlight,
                sortFunc: dir => props.sort('ROWS', value, dir),
                sortType: 'ROWS',
              },
              formatter: undefined,
              recordCount: null,
              i18nPrefs: props.i18nPrefs,
              rowIndex,
              columnIndex,
            }}
          />
        );
      },
      topRightCellRenderer: ({ columnIndex, key, rowIndex, style }) => {
        const field = props.viz.layout.COLUMNS[rowIndex];
        const value = field.name;
        const displayValue = Utils.getPivotCellDisplayValue(field);

        const pathToMe = [value];
        let sorting = props.sorting.COLUMNS.find(x =>
          _.isEqual(x.path, pathToMe),
        );
        sorting = sorting || { path: pathToMe, direction: 'asc' };
        const highlight = _.isEqual(
          Utils.getHighlight(props, 'COLUMNS'),
          pathToMe,
        );

        return (
          <PivotCell
            {...{
              value: { value: displayValue },
              key,
              style,
              classes:
                Utils.getPivotCellClasses(columnIndex, rowIndex, 1, 1) +
                (Utils.hasRows(props) ? ' rows-present' : ''),
              sorting: {
                ...sorting,
                highlight,
                sortFunc: dir => props.sort('COLUMNS', value, dir),
                sortType: 'COLUMNS',
              },
              formatter: undefined,
              recordCount: null,
              i18nPrefs: props.i18nPrefs,
              rowIndex,
              columnIndex,
            }}
          />
        );
      },
      topRightColumnsCellRenderer: (row, idx) => args => {
        return topRightColumnsCellRendererFunc({ row, idx, ...args });
      },
      topRightTabularCellRenderer: ({ columnIndex, key, rowIndex, style }) => {
        const field = props.viz.layout.COLUMNS[columnIndex];
        const value = field.name;
        const displayValue = Utils.getPivotCellDisplayValue(field);

        const pathToMe = [value];
        let sorting = props.sorting.ROWS.find(x => _.isEqual(x.path, pathToMe));
        sorting = sorting || { path: pathToMe, direction: 'asc' };
        const highlight = _.isEqual(
          Utils.getHighlight(props, 'ROWS'),
          pathToMe,
        );

        return (
          <PivotCell
            {...{
              value: { value: displayValue },
              key,
              style,
              classes: Utils.getPivotCellClasses(columnIndex, rowIndex),
              sorting: {
                ...sorting,
                highlight,
                sortFunc: dir => props.sort('ROWS', value, dir),
                sortType: 'ROWS',
              },
              formatter: undefined,
              recordCount: null,
              i18nPrefs: props.i18nPrefs,
              rowIndex,
              columnIndex,
            }}
          />
        );
      },

      bottomLeftCellRenderer:
        (idx, columnCount, rowCount, dataRow, prevRowSubtotalsOn) => args => {
          return bottomLeftCellRendererFunc({
            idx,
            columnCount,
            rowCount,
            dataRow,
            prevRowSubtotalsOn,
            ...args,
          });
        },
      bottomRightCellRenderer:
        (
          columnCount,
          rowCount,
          conditionalFormatting,
          rowSubtotalsOn,
          colorTheme,
        ) =>
        args => {
          const {
            bodyData,
            formatters,
            showColGrandTotals,
            showRowGrandTotals,
            valuesAndTargets,
          } = props;

          return (
            <BottomRightCellRenderer
              columnCount={columnCount}
              rowCount={rowCount}
              conditionalFormatting={conditionalFormatting}
              rowSubtotalsOn={rowSubtotalsOn}
              theme={colorTheme}
              bodyData={bodyData}
              formatters={formatters}
              showColGrandTotals={showColGrandTotals}
              showRowGrandTotals={showRowGrandTotals}
              valuesAndTargets={valuesAndTargets}
              {...args}
            />
          );
        },
      topRightValuesCellRenderer: columnCount => args => {
        return topRightValuesCellRendererFunc({ columnCount, ...args });
      },

      /**
       * Given an array of labels return a Promise that when resolved results in an array of widths.
       *
       * Renders to a hidden div
       * @param stringLengths
       * @returns {Promise<any>}
       */
      measureCells: cellValues => {
        return new Promise(resolve => {
          resolve(
            cellValues.map((val, _idx) => {
              const formattedValue = DATA_FORMATTER.STRING.format(
                val?.value ?? val,
                props?.i18nPrefs,
              );
              const textWidth = Util.calcTextWidth(formattedValue);
              const padding = 45;
              return Math.min(textWidth + padding, PIVOT_TABLE.MAX_CELL_WIDTH);
            }),
          );
        });
      },
    };
  }),
);
