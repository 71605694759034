import _, {
  filter,
  includes,
  isArray,
  isEmpty,
  isNumber,
  isString,
  map,
  toString,
} from 'lodash';
import { ISearchableDropdownOption } from './searchable-dropdown.interfaces';

export const createDefaultSelectedOptions = ({
  defaultValue,
  options = [],
}: {
  defaultValue?: ISearchableDropdownOption | ISearchableDropdownOption[];
  options: ISearchableDropdownOption[];
}) => {
  if (isEmpty(defaultValue) || isEmpty(options)) {
    return options;
  }

  const defaultSelectedOptions = filter(
    isArray(defaultValue)
      ? (defaultValue ?? {})
      : ([defaultValue] as ISearchableDropdownOption[]),
    'value',
  );

  const selectedValues = map(defaultSelectedOptions, 'value');

  const _selectedOptions = map(options, ({ value, label }) => {
    if (includes(selectedValues, value)) {
      return {
        value,
        label,
        isSelected: true,
      } as ISearchableDropdownOption;
    }

    return {
      value,
      label,
      isSelected: false,
    } as ISearchableDropdownOption;
  });

  return _selectedOptions;
};

export const makeDropdownOption: (
  value: any,
  label?: string | number,
) => ISearchableDropdownOption = (value, label) => ({
  value,
  label: isString(label) || isNumber(label) ? label : toString(value),
});
