import { useQuery } from '@apollo/client';
import { VizQueries } from '../../graphql';
import { useSelector } from 'react-redux';
import { SugarCoreDropdownColors } from './sugar-core-dropdown-colors.interface';
import { isDashletMode } from '../../../auth';

export const useSugarCoreDropdownColorsQuery = (): any => {
  const { tenantId } = useSelector((state: { main: any }) => state?.main);
  const { data } = useQuery<SugarCoreDropdownColors>(
    VizQueries.SugarCoreDropdownColors,
    {
      skip: isDashletMode(),
      variables: { tenantId },
    },
  );
  return { sugarCoreDropdownColors: data?.getTenantStyles?.appDropdownsStyles };
};
