import { useEffect } from 'react';
import { compose } from 'react-recompose';
import { useWithRouterProps } from '../../common/utilities/with-router';
import URLs from '../Urls';
import MainActions from '../redux/actions/MainActions';
import { usePreviousValue } from '../utilities/state-helpers.hook';
import { includes, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { ROUTER_DIRS } from '../Constants';

// Captures History and last location
export default compose()(({ children }) => {
  const dispatch = useDispatch();
  const { location, history } = useWithRouterProps();

  const lastLocation = usePreviousValue({
    value: location?.pathname,
    defaultValue: location?.pathname,
  });

  useEffect(() => {
    if (!isEqual(lastLocation, location?.pathname)) {
      // last location should be in a provider, but keeping legacy mechanism here for now
      URLs.setHistoryAndLastLocation(history, {
        pathname: lastLocation,
      });
    }

    if (!includes([ROUTER_DIRS.LOGIN, ROUTER_DIRS.ROOT], location.pathname)) {
      dispatch(MainActions.setUrlLocation(location.pathname));
    }
  }, [dispatch, history, location.pathname, lastLocation]);

  return children;
});
