import { Button } from './Button.styles';
import { Popover } from './Popover.styles';
import { Dialog } from './Dialog.styles';
import { DateField } from './DateField.styles';
import { RangeCalendar } from './RangeCalendar.styles';
import { Calendar } from './Calendar.styles';
import { Form } from './Form.styles';
import { Switch } from './Switch.styles';
import { TextField } from './TextField.styles';
import { Modal } from './Modal.styles';

export const commonStyles = `
${Button}
${Popover}
${Dialog}
${DateField}
${RangeCalendar}
${Calendar}
${Form}
${Switch}
${TextField}
${Modal}
`;

export const DatePicker = `
${commonStyles}

.react-aria-DatePicker {
  color: var(--text-color);

  .react-aria-Group {
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .react-aria-Button {
    background: var(--button-background);
    color: var(--highlight-background);
    border: 2px solid var(--field-background);
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;
    margin-left: -1.5rem;
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    font-size: 0.857rem;
    box-sizing: content-box;

    &[data-pressed] {
      box-shadow: none;
      background: var(--highlight-background);
    }

    &[data-focus-visible] {
      outline: 2px solid var(--focus-ring-color);
      outline-offset: 2px;
    }

    &:hover {
      background: var(--highlight-background);
      color: var(--highlight-foreground);
    }
  }
}

.react-aria-Popover[data-trigger=DatePicker] {
  max-width: unset;
}

.react-aria-DatePicker {
  .react-aria-FieldError {
    font-size: 12px;
    color: var(--invalid-color);
  }

  [slot=description] {
    font-size: 12px;
  }
}
`;
