import {
  ForwardRefExoticComponent,
  ButtonHTMLAttributes,
  RefAttributes,
  PropsWithoutRef,
  HTMLAttributes,
  useMemo,
  forwardRef,
} from 'react';
import { Button as MuiButton } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isFunction, omit } from 'lodash';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { darken, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

// @NOTE: the colors are statically imported here because
// buttons do not have separate dark mode styling
import {
  RegularFontWeight,
  SemiboldFontWeight,
  MediumFontWeight,
} from '../../common/emotion/theme/colors.styles';
import { useDiscoverThemeColors } from '../../common/emotion/theme';

export interface IButtonProps
  extends Partial<HTMLAttributes<HTMLInputElement>> {
  // these props are now based on MUI, not legacy Bootstrap
  bsStyle?;
  title?;
  buttonId?;
}

export const borderRadius = '8px';

const buttonColorMixin = ({
  color,
  hoverColor,
  borderColor = color,
  borderHoverColor = hoverColor,
}) => css`
  background-color: ${color};
  border: 1px solid ${borderColor};
  &:hover {
    background-color: ${hoverColor};
    border: 1px solid ${borderHoverColor};
  }
`;

const themeButtonMixin = ({
  bgColor,
  bgHoverColor,
  borderColor,
  borderHoverColor,
  textColor,
  disabledColor = bgColor,
}) => css`
  border: 1px solid ${borderColor};
  background-color: ${bgColor};
  color: ${textColor} !important;
  &:hover {
    border: 1px solid ${borderHoverColor};
    background-color: ${bgHoverColor};
  }
  &[disabled] {
    background-color: ${disabledColor};
    border-color: ${disabledColor};
    pointer-events: all;
    cursor: not-allowed;
    opacity: 1;
  }
`;

export const ButtonDefault = styled(MuiButton)(
  ({
    theme: {
      colors: {
        DefaultButtonFillColor,
        DefaultButtonFillColorHover,
        DefaultButtonBorderColor,
        DefaultButtonBorderColorHover,
        DefaultButtonTextColor,
      } = {} as any,
    } = {} as any,
  }) => css`
    ${themeButtonMixin({
      bgColor: DefaultButtonFillColor,
      bgHoverColor: DefaultButtonFillColorHover,
      borderColor: DefaultButtonBorderColor,
      borderHoverColor: DefaultButtonBorderColorHover,
      textColor: DefaultButtonTextColor,
    })};
    border-radius: ${borderRadius};
    text-align: center;
    white-space: nowrap;
    text-transform: none;
    padding: 8px 16px;
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.42857143;
  `,
);

export const ButtonPrimary = styled(ButtonDefault)(
  ({
    theme: {
      colors: {
        SugarPrimaryPalette: { DarkBlue } = {} as any,
        ButtonColorPrimaryHover,
        TooltipTextColor,
        DisabledPrimaryButtonColor,
      } = {} as any,
    } = {} as any,
  }) => css`
    ${themeButtonMixin({
      bgColor: DarkBlue,
      bgHoverColor: ButtonColorPrimaryHover,
      borderColor: DarkBlue,
      borderHoverColor: ButtonColorPrimaryHover,
      textColor: TooltipTextColor,
      disabledColor: DisabledPrimaryButtonColor,
    })};
    font-weight: 400;
  `,
);

export const ButtonSuccess = styled(ButtonDefault)(
  ({
    theme: {
      colors: {
        SuccessButtonColor,
        SuccessButtonBorderColor,
        SuccessButtonColorHover,
        SuccessButtonBorderColorHover,
      } = {} as any,
    } = {} as any,
  }) => css`
    ${buttonColorMixin({
      color: SuccessButtonColor,
      borderColor: SuccessButtonBorderColor,
      hoverColor: SuccessButtonColorHover,
      borderHoverColor: SuccessButtonBorderColorHover,
    })};
    font-size: 14px;
    font-weight: 400;
  `,
);

export const ButtonInfo = styled(ButtonDefault)(
  ({
    theme: {
      colors: {
        InfoButtonColor,
        InfoButtonBorderColor,
        InfoButtonColorHover,
        InfoButtonBorderColorHover,
      } = {} as any,
    } = {} as any,
  }) => css`
    ${buttonColorMixin({
      color: InfoButtonColor,
      borderColor: InfoButtonBorderColor,
      hoverColor: InfoButtonColorHover,
      borderHoverColor: InfoButtonBorderColorHover,
    })};
    font-size: 14px;
    font-weight: 400;
  `,
);

export const ButtonWarning = styled(ButtonDefault)(
  ({
    theme: {
      colors: {
        WarningButtonColor,
        WarningButtonBorderColor,
        WarningButtonColorHover,
        WarningButtonBorderColorHover,
      } = {} as any,
    } = {} as any,
  }) => css`
    ${buttonColorMixin({
      color: WarningButtonColor,
      borderColor: WarningButtonBorderColor,
      hoverColor: WarningButtonColorHover,
      borderHoverColor: WarningButtonBorderColorHover,
    })};
    font-size: 14px;
    font-weight: 400;
  `,
);

export const ButtonDanger = styled(ButtonDefault)(
  ({
    theme: {
      colors: {
        DangerButtonColor,
        DangerButtonBorderColor,
        DangerButtonColorHover,
        DangerButtonBorderColorHover,
      } = {} as any,
    } = {},
  }: any) => css`
    ${buttonColorMixin({
      color: DangerButtonColor,
      borderColor: DangerButtonBorderColor,
      hoverColor: DangerButtonColorHover,
      borderHoverColor: DangerButtonBorderColorHover,
    })};
    font-size: 14px;
    font-weight: 400;
  `,
);

export const ButtonLink = styled(ButtonDefault)(
  ({
    theme: {
      colors: { LinkButtonTextColor, LinkButtonTextColorHover } = {} as any,
    } = {} as any,
  }) => css`
    ${buttonColorMixin({ color: 'transparent', hoverColor: 'transparent' })};
    font-size: 14px;
    font-weight: 400;
    color: ${LinkButtonTextColor};
    &:hover {
      text-decoration: underline;
      color: ${LinkButtonTextColorHover};
    }
  `,
);

export const Button = (
  props: Omit<ButtonTypeMap<IButtonProps>['props'], 'color'>,
) => {
  const { bsStyle } = props;
  const passthroughProps = omit(props, 'bsStyle');
  const ButtonComp = useMemo(() => {
    switch (bsStyle) {
      case 'primary':
        return ButtonPrimary;
      case 'success':
        return ButtonSuccess;
      case 'info':
        return ButtonInfo;
      case 'warning':
        return ButtonWarning;
      case 'danger':
        return ButtonDanger;
      case 'link':
        return ButtonLink;
      default:
        return ButtonDefault;
    }
  }, [bsStyle]);
  return (
    <ButtonComp {...passthroughProps} id={props.buttonId} disableRipple>
      {props.children}
    </ButtonComp>
  );
};

const CommonButtonSxStyles = {
  borderRadius,
  fontSize: '14px',
  textTransform: 'unset',
  fontWeight: SemiboldFontWeight,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
};

// improves accessibility. some forms are overriding the 'enter' event
const handleOnEnter =
  callback =>
  (...args) => {
    const event = args[0];

    if (event?.key === 'Enter' && isFunction(callback)) {
      event.stopPropagation();
      event.preventDefault();
      callback(...args);
    }
  };

export const PrimaryButton: ForwardRefExoticComponent<
  PropsWithoutRef<
    ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
  > &
    RefAttributes<HTMLButtonElement>
> = forwardRef<
  HTMLButtonElement,
  ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const {
    PrimaryButtonColor,
    PrimaryButtonTextColor,
    PrimaryButtonColorHover,
    PrimaryButtonColorActive,
    PrimaryButtonColorDisabled,
    PrimaryButtonColorTextDisabled,
  } = useDiscoverThemeColors();

  return (
    <MuiButton
      variant={'contained'}
      color={'primary'}
      ref={ref}
      sx={
        {
          ...CommonButtonSxStyles,
          height: '32px',
          maxHeight: '32px',
          padding: '5.5px 7px',
          color: PrimaryButtonTextColor,
          backgroundColor: PrimaryButtonColor,
          '&:hover': {
            backgroundColor: PrimaryButtonColorHover,
          },
          '&:active': {
            backgroundColor: PrimaryButtonColorActive,
          },
          '&:focus-visible': {
            backgroundColor: PrimaryButtonColorActive,
          },
          '&:disabled': {
            backgroundColor: PrimaryButtonColorDisabled,
            color: PrimaryButtonColorTextDisabled,
            opacity: 0.3,
          },
          ...(props?.sx ?? {}),
        } as SxProps<Theme>
      }
      onKeyDown={handleOnEnter(props?.onClick)}
      {...omit(props, ['sx'])}
    >
      {props?.children}
    </MuiButton>
  );
});
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton: ForwardRefExoticComponent<
  PropsWithoutRef<
    ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
  > &
    RefAttributes<HTMLButtonElement>
> = forwardRef<
  HTMLButtonElement,
  ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const {
    SecondaryButtonTextColor,
    SecondaryButtonColor,
    SecondaryButtonBorderColor,
    SecondaryButtonColorHover,
    SecondaryButtonColorDisabled,
    SecondaryButtonTextColorHover,
    SecondaryButtonBorderColorHover,
    SecondaryButtonTextColorDisabled,
    SecondaryButtonBorderColorDisabled,
    LinkButtonTextColorHover,
  } = useDiscoverThemeColors();

  return (
    <MuiButton
      variant={'contained'}
      color={'secondary'}
      ref={ref}
      sx={
        {
          ...CommonButtonSxStyles,
          fontWeight: MediumFontWeight,
          height: '30px',
          maxHeight: '30px',
          padding: '4.5px 7px',
          color: SecondaryButtonTextColor,
          backgroundColor: SecondaryButtonColor,
          borderColor: SecondaryButtonBorderColor,
          borderWidth: '1px',
          borderStyle: 'solid',
          '&:hover, &:active': {
            color: SecondaryButtonTextColorHover,
            backgroundColor: SecondaryButtonColorHover,
            borderColor: SecondaryButtonBorderColorHover,
          },
          '&:focus-visible': {
            borderColor: LinkButtonTextColorHover,
            borderWidth: '3px',
            borderStyle: 'solid',
          },
          '&:disabled': {
            color: SecondaryButtonTextColorDisabled,
            backgroundColor: SecondaryButtonColorDisabled,
            borderColor: SecondaryButtonBorderColorDisabled,
          },
          ...(props?.sx ?? {}),
        } as SxProps<Theme>
      }
      onKeyDown={handleOnEnter(props?.onClick)}
      {...omit(props, 'sx')}
    >
      {props?.children}
    </MuiButton>
  );
});
SecondaryButton.displayName = 'SecondaryButton';

// named by the UI component in Mango
export const InvisibleButton: ForwardRefExoticComponent<
  PropsWithoutRef<
    ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
  > &
    RefAttributes<HTMLButtonElement>
> = forwardRef<
  HTMLButtonElement,
  ButtonTypeMap['props'] & ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const {
    LinkButtonTextColor: LinkButtonTextColorUnchanging,
    LinkButtonTextColorHover,
    LinkButtonTextColorActive,
    LinkButtonTextColorDisabled,
  } = useDiscoverThemeColors();

  return (
    <MuiButton
      variant={'text'}
      color={'secondary'}
      ref={ref}
      sx={
        {
          ...CommonButtonSxStyles,
          height: '32px',
          maxHeight: '32px',
          padding: '4.5px 7px',
          color: LinkButtonTextColorUnchanging,
          fontWeight: RegularFontWeight,
          '&:hover': {
            color: LinkButtonTextColorHover,
            backgroundColor: 'unset',
          },
          '&:active': {
            color: LinkButtonTextColorActive,
          },
          '&:focus-visible': {
            borderColor: darken(LinkButtonTextColorUnchanging, 0.1),
            borderWidth: '3px',
            borderStyle: 'solid',
          },
          '&:disabled': {
            color: LinkButtonTextColorDisabled,
          },
          ...(props?.sx ?? {}),
        } as SxProps<Theme>
      }
      onKeyDown={handleOnEnter(props?.onClick)}
      {...omit(props, ['sx'])}
    >
      {props?.children}
    </MuiButton>
  );
});
InvisibleButton.displayName = 'InvisibleButton';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const SmallModalButtonContainer = styled(ButtonContainer)`
  margin-bottom: 0.8rem;
  align-self: flex-end;
  margin-right: 1rem;
`;
