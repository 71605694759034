import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SidebarNavigation } from '../../discovery/sidebar-navigation';
import { Header } from '../../discovery/header';
import {
  AppBackground,
  MainContentWrapper,
} from '../../ui/app-background/app-background.styles';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { ROUTER_DIRS } from '../../common';
import { HeaderBar } from '../../discovery/header/header.styles';
import _, { some } from 'lodash';
import URLs from '../../common/Urls';
import { ReportLinkRedirect } from '../../common/hoc/ReportLinkRedirect';
import NewRelic from '../../common/NewRelic';
import { DiscoverTabs } from '../../discovery/discover-tabs';
import Library from '../../views/Library';
import Util from '../../common/Util';
import MobileLibrary from '../../views/MobileLibrary';
import { CenteredSpinner } from '../../common/widgets/Status';

const WithAppBackground = ({ children }) => {
  const isResponsive = useSelector(
    ({ main: { isMobile = false }, dashlet: { isDashletMode = false } }: any) =>
      isMobile || isDashletMode,
  );

  if (isResponsive) {
    return <>{children}</>;
  }

  return <AppBackground>{children}</AppBackground>;
};

export const WithNav = ({ children = [] }) => {
  const location = useLocation();
  const [loadPins, setLoadPins] = useState(false);

  useEffect(() => {
    const shouldLoadPins = some(
      [ROUTER_DIRS.ROOT, ROUTER_DIRS.LIBRARY],
      pattern => !!matchPath(pattern, location.pathname),
    );
    setLoadPins(shouldLoadPins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isMobile = false } = useSelector(({ main }: any) => main);

  return (
    <WithAppBackground>
      {!isMobile && <SidebarNavigation key='mainNav' />}
      <Header loadPinnedDiscoveries={loadPins} />
      <MainContentWrapper>{children}</MainContentWrapper>
    </WithAppBackground>
  );
};

export const Shell = ({ children = [] } = {}) => {
  const { appUrl = '' } = useSelector(({ main }: any) => main);

  const logoImgSrc = Util.assetUrl({
    appUrl,
    path: 'discover_logo_modern.svg',
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id='mainHeader'>
        <HeaderBar>
          <img
            src={logoImgSrc}
            className='logo'
            style={{ marginLeft: '24px' }}
          />
        </HeaderBar>
      </div>
      <div
        className={'mainBackground'}
        style={{ width: '100%', height: '100%' }}
      >
        {_.isEmpty(children) ? <CenteredSpinner /> : children}
      </div>
    </div>
  );
};

export const RedirectReportLink = routeProps => {
  const { drillContextId } = URLs.getQueryParams();
  return <ReportLinkRedirect {...{ ...routeProps, drillContextId }} />;
};

const useIsAuthenticatedView = () => {
  const { login: { isLoggedIn } = {}, dashlet: { isDashletMode } = {} } =
    useSelector(({ dashlet, login }: any) => ({ dashlet, login }));

  return isLoggedIn || isDashletMode;
};

export const AuthenticatedView = () => {
  const isAuthenticated = useIsAuthenticatedView();

  return isAuthenticated ? <Outlet /> : <Shell />;
};

const useTrackedByDomain = domain => {
  useEffect(() => {
    if (domain) {
      NewRelic.addPageAction('main-page-load', {
        domain,
      } as unknown as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const TrackedDiscoverView = () => {
  useTrackedByDomain('discover');

  return (
    <div id='discover-content-panel'>
      <DiscoverTabs />
    </div>
  );
};

export const TrackedLibraryView = () => {
  const { isMobile = false } = useSelector(({ main }: any) => main);

  useTrackedByDomain('library');

  return isMobile ? <MobileLibrary /> : <Library />;
};
