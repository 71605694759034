import _ from 'lodash';
import {
  IPosition,
  IRegisterLabelArguments,
  IBBox,
  ILabelWithCollidingIndexes,
} from './label-manager-provider.interface';
import uuid from 'uuid';
import { select } from 'd3';

export const labelContainerType = 'g';
export const labelContainerClass = 'label';
export const labelElementSelector = `${labelContainerType}.${labelContainerClass}`;

export const Placement = {
  NoChange: (datum: ILabelDataWithIds): IPosition => {
    return {
      x: datum?.anchor?.x,
      y: datum?.anchor?.y,
    };
  },
  Top: (datum: ILabelDataWithIds): IPosition => {
    const { height = 0, width = 0 } = datum?.size;
    return {
      x: datum?.anchor?.x - width / 2, // center label horizontally
      y: datum?.anchor?.y - height - 9,
    };
  },
  Bottom: (datum: ILabelDataWithIds): IPosition => {
    const { width = 0 } = datum?.size;
    return {
      x: datum?.anchor?.x - width / 2, // center label horizontally
      y: datum?.anchor?.y + 9,
    };
  },
  Right: (datum: ILabelDataWithIds): IPosition => {
    const { height = 0 } = datum?.size;
    return {
      x: datum?.anchor?.x + 10,
      y: datum?.anchor?.y - height / 2, // center label vertically
    };
  },
};

const collidesWithLabel = (thisBBox: IBBox, otherBBox: IBBox) => {
  const rightBBox = otherBBox.x + otherBBox.width;
  const bottomBBox = otherBBox.y + otherBBox.height;

  const paddedBottomSelf = thisBBox.y + thisBBox.height;

  const isLeftWithinExistingLabelRange =
    thisBBox.x >= otherBBox.x && thisBBox.x < rightBBox;
  const isRightWithinExistingLabelRange =
    thisBBox.x + thisBBox.width >= otherBBox.x &&
    thisBBox.x + thisBBox.width < rightBBox;
  const isTopWithinExistingLabelRange =
    thisBBox.y >= otherBBox.y && thisBBox.y < bottomBBox;
  const isBottomWithinExistingLabelRange =
    paddedBottomSelf >= otherBBox.y && paddedBottomSelf < bottomBBox;
  const isWiderAndIsOver =
    thisBBox.width >= rightBBox - otherBBox.x &&
    thisBBox.x <= otherBBox.x &&
    thisBBox.x + thisBBox.width > rightBBox;
  const isTallerAndIsOver =
    thisBBox.height >= bottomBBox - otherBBox.y &&
    thisBBox.y <= otherBBox.y &&
    paddedBottomSelf > bottomBBox;

  const isColliding =
    (isWiderAndIsOver ||
      isLeftWithinExistingLabelRange ||
      isRightWithinExistingLabelRange) &&
    (isTallerAndIsOver ||
      isTopWithinExistingLabelRange ||
      isBottomWithinExistingLabelRange);

  return isColliding;
};

export const getCollidingLabels = (
  thisDatumIdx,
  labelData: ILabelDataWithIds[],
  currentPosition: IPosition,
) => {
  const datum = labelData[thisDatumIdx];

  const { width: selfWidth = 14, height: selfHeight = 14 } = datum?.size;
  const hasMultiplePlots = _.uniqBy(labelData, 'isSecondaryPlot')?.length > 1;

  const collidingLabels = [];
  _.forEach(labelData, (otherDatum, otherIdx) => {
    if (thisDatumIdx === otherIdx) {
      return true;
    }
    const { width: widthOfExisting = 14, height: heightOfExisting = 14 } =
      otherDatum?.size;

    const isCollidingWithLabel = collidesWithLabel(
      {
        x: currentPosition?.x,
        y: currentPosition?.y,
        width: selfWidth,
        height: selfHeight,
      },
      {
        x: otherDatum?.anchor?.x,
        y: otherDatum?.anchor?.y,
        width: widthOfExisting,
        height: heightOfExisting,
      },
    );

    // simulate secondary (line) plot points as labels, since secondary is placed over primary
    const anchorRadius = 12;
    const isCollidingWithSecondaryPoint =
      datum?.isSecondaryPlot || !hasMultiplePlots || !otherDatum.isSecondaryPlot
        ? false
        : collidesWithLabel(
            {
              x: currentPosition?.x,
              y: currentPosition?.y,
              width: selfWidth,
              height: selfHeight,
            },
            {
              x: otherDatum?.anchor?.x - anchorRadius / 2,
              y: otherDatum?.anchor?.y - anchorRadius / 2,
              width: anchorRadius,
              height: anchorRadius,
            },
          );

    if (
      isCollidingWithSecondaryPoint &&
      !_.includes(collidingLabels, thisDatumIdx)
    ) {
      collidingLabels.push(thisDatumIdx);
    } else if (
      isCollidingWithLabel &&
      !_.includes(collidingLabels, otherIdx) &&
      !_.includes(collidingLabels, thisDatumIdx)
    ) {
      collidingLabels.push(otherIdx);
    }
  });

  return collidingLabels;
};

export interface ILabelDataWithIds extends IRegisterLabelArguments {
  controlId?: string;
  size?: {
    width: number;
    height: number;
  };
  collidingLabelIdx?: number;
}

export const getUniqueLabelDataId = datum =>
  `${datum?.value}.${datum?.anchor?.x}.${datum?.anchor?.y}.${
    datum?.isSecondaryPlot ? 'sec' : 'prime'
  }.${datum?.focused ? 'focused' : 'unfocused'}`;

export const useManagedLabels = (
  labelData: IRegisterLabelArguments[] = [],
  svg: d3.Selection<Element, unknown, null, undefined>,
) => {
  if (_.isNil(svg)) {
    return [];
  }

  // measure container is a non-presentational layer meant for measuring elements in an SVG before display
  const measureContainer = svg
    .select('.measure-container')
    .insert('g')
    .attr('opacity', '0')
    .attr('aria-hidden', 'true');
  const measureContainerNode = measureContainer.node() as SVGGElement;
  if (_.isNil(measureContainerNode)) {
    return [];
  }

  const labelDataWithIds: ILabelDataWithIds[] = _.map(labelData, datum => ({
    ...datum,
    controlId: `id-${_.head(uuid().split('-'))}`,
  }));

  // draw all labels on measure pane
  const measureData = measureContainer
    .selectAll<Element, IRegisterLabelArguments>('g')
    .data<ILabelDataWithIds>(labelDataWithIds)
    .enter();
  measureData
    .insert('g')
    .attr('id', (d: ILabelDataWithIds) => d?.controlId)
    .attr('class', 'measure-label')
    .attr('transform', (d: ILabelDataWithIds) => {
      // set default positions. renderLabel can also transform the element
      const { x: posX, y: posY } = d?.anchor;
      return `translate(${posX},${posY})`;
    })
    .each((d: ILabelDataWithIds, idx, selectionNodes) => {
      const d3BaseElement = select(selectionNodes[idx]);
      if (_.isFunction(d?.renderLabel)) {
        d?.renderLabel(d, d3BaseElement);
      }
    });

  measureData.exit().remove();

  // batch read from DOM, because of force reflow issues. read https://gist.github.com/paulirish/5d52fb081b3570c81e3a
  _.forEach(labelDataWithIds, (datum, idx) => {
    const element: SVGGraphicsElement = measureContainerNode.querySelector(
      `#${datum?.controlId}`,
    );

    const { width = 0, height = 0 } = element?.getBBox(
      undefined,
      // we need to send isSecondaryPlot for testing purpose
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      datum?.isSecondaryPlot,
    );
    labelDataWithIds[idx] = {
      ...datum,
      size: { width, height },
    };
  });

  // remove anything present
  measureContainer.selectAll('.measure-label').remove();
  measureContainer.remove();

  const renderingContainerHeight = _.get(
    _.find(labelDataWithIds, 'renderingContainerHeight'),
    'renderingContainerHeight',
    0,
  );

  // read/save all bBox sizes and find collisions
  // move labels via saved sizes and remove collisions
  const labelsAndCollisions: ILabelWithCollidingIndexes[] = _.map(
    labelDataWithIds,
    (datum, datumIdx) => {
      const { x, y, collidingIndexes } = getLabelPosition(
        datumIdx,
        labelDataWithIds,
      );

      // hide if over the x axis
      const {
        size: { height: selfHeight = 14, width: selfWidth = 16 },
        anchor: { y: yPos, x: xPos },
      } = datum;
      const hide =
        renderingContainerHeight !== 0 &&
        (yPos + selfHeight > renderingContainerHeight + 5 || yPos - 10 < 0);

      const isOverYAxis = xPos - selfWidth / 2 < 0;

      return {
        label: {
          ...(_.omit<ILabelDataWithIds>(datum, [
            'controlId',
            'size',
          ]) as IRegisterLabelArguments),
          anchor: { x, y },
        },
        hide: hide || !datum?.focused || isOverYAxis,
        collidingIndexes,
        labelIdx: datumIdx,
      };
    },
  );

  const visibleLabels = _.filter(labelsAndCollisions, { hide: false });

  const removingLabelIndexes = [];
  const keepLabelIndexes = [];

  _.forEach(visibleLabels, ({ label, collidingIndexes, labelIdx }) => {
    if (_.isEmpty(collidingIndexes)) {
      return;
    }

    if (_.includes(removingLabelIndexes, labelIdx)) {
      return;
    }

    if (
      label.isSecondaryPlot &&
      _.reject(collidingIndexes, collidingIdx =>
        _.includes(removingLabelIndexes, collidingIdx),
      )?.length > 0 // secondary labels can move after being evaluated by primary plot
    ) {
      removingLabelIndexes.push(labelIdx);
    } else {
      _.forEach(collidingIndexes, collidingIdx => {
        if (
          !_.includes(removingLabelIndexes, collidingIdx) &&
          !_.includes(keepLabelIndexes, collidingIdx)
        ) {
          removingLabelIndexes.push(collidingIdx);
          keepLabelIndexes.push(labelIdx);
        }
      });
    }
  });

  const withoutRemainingCollisions = _.reject(visibleLabels, ({ labelIdx }) =>
    _.includes(
      removingLabelIndexes.sort((a, b) => (a > b ? 1 : -1)), // sorted to help debugging
      labelIdx,
    ),
  );

  return _.map(withoutRemainingCollisions, 'label');
};

// recursively finds the position for the label
export const getLabelPosition = (
  datumIdx,
  labelData: ILabelDataWithIds[],
  proposedAnchors = [Placement.Top, Placement.Bottom, Placement.Right],
): IPosition & { collidingIndexes?: number[] } => {
  const datum = labelData[datumIdx];

  if (_.isEmpty(proposedAnchors)) {
    return Placement.NoChange(datum);
  }

  const currentPosition = _.head(proposedAnchors)(datum);

  const collidingIndexes = getCollidingLabels(
    datumIdx,
    labelData,
    datum?.isSecondaryPlot ? currentPosition : datum?.anchor,
  );

  if (!datum?.isSecondaryPlot) {
    return {
      ...datum?.anchor,
      collidingIndexes,
    };
  }

  if (proposedAnchors?.length != 1 && !_.isEmpty(collidingIndexes)) {
    return getLabelPosition(datumIdx, labelData, _.tail(proposedAnchors));
  }

  return {
    ...currentPosition,
    collidingIndexes,
  };
};

export const labelManagerPrimaryPlotClass = 'primary-plot-pane';
export const labelManagerSecondaryPlotClass = 'secondary-plot-pane';

export const removeLabelsFromPane = providedRenderingPane =>
  select(providedRenderingPane)
    .selectAll<Element, IRegisterLabelArguments>(labelElementSelector)
    .remove();

export const renderProvidedLabels = (
  providedRenderingPane,
  managedLabels,
  contentTextColor,
  i18nPrefs,
) => {
  const selectedLabels = select(providedRenderingPane)
    .selectAll<Element, IRegisterLabelArguments>(labelElementSelector)
    .data<IRegisterLabelArguments>(
      managedLabels,
      d => `${d?.anchor?.x},${d?.anchor?.y}`, // key to repaint appropriately
    );

  const enteredLabels = selectedLabels
    .enter()
    .append(labelContainerType)
    .attr('role', 'note') // role:note used for testing
    .attr(
      'class',
      d =>
        `${labelContainerClass}${
          d?.isSecondaryPlot ? ' secondary' : ' primary'
        }`,
    )
    .attr('transform', (d: IRegisterLabelArguments) => {
      // set default positions. renderLabel can also transform the element
      const { x: posX, y: posY } = d?.anchor;
      return `translate(${posX},${posY})`;
    });

  enteredLabels.each((d: IRegisterLabelArguments, idx, selectionNodes) => {
    const d3BaseElement = select(selectionNodes[idx]);
    if (_.isFunction(d?.renderLabel)) {
      d?.renderLabel(d, d3BaseElement);
    } else {
      d3BaseElement
        .append('text')
        .attr('fill', contentTextColor)
        .text(
          _.isNil(d?.formatter)
            ? d?.value
            : d?.formatter?.formatSmall(d?.value, i18nPrefs),
        );
    }
  });

  selectedLabels.exit().remove();
};
