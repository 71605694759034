import PivotSvgIcon from '../../../../images/sdd/viz/visualization-selection-pivot-table.svg';
import BaseChartSpec, {
  Validations,
  generateMeasuresFromMetrics,
  ENABLE_REPORT_LINK,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import {
  ChartSpecIds,
  Types,
  VIZ_OPTION_IDS,
  VIZ_QUERY_TOTALS_FLAG,
} from '../../../common/Constants';
import _, { get, find } from 'lodash';
import { Viz } from '../../VizUtil';
import { getAllLinksEnabledInReport } from '../../../components/PivotDrillLinks/utils';
import PivotDataFunc from './PivotDataFunc';
import { VizLayoutMapping } from '../../VizLayoutMapping';
import { requiresOrdinal } from '../ChartUtils';
import { messages } from '../../../i18n';

class PivotChartSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'pivot',
      name: 'pivot.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-pivot-table.svg',
      icon: <PivotSvgIcon />,
      listIcon: <PivotSvgIcon style={{ width: 24, height: 24 }} />,
      legendShape: LegendShapes.SQUARE,
      shelves: {
        [ChartSpecIds.ROWS]: {
          id: ChartSpecIds.ROWS,
          name: 'pivot.rowsShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.COLUMNS]: {
          id: ChartSpecIds.COLUMNS,
          name: 'pivot.columnsShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.VALUES]: {
          id: ChartSpecIds.VALUES,
          name: 'pivot.valuesShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.MEASURE,
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },
      isToggleDisabled: (toggle, viz) => {
        switch (toggle) {
          case 'showLegendPanel': {
            // Enabled if metric is in play and selected for conditional formatting
            const conditionalFormatting = JSON.parse(
              _.get(
                viz,
                `options.${VIZ_OPTION_IDS.conditionalFormatting}`,
                '[]',
              ),
            );
            const intersection = _.intersection(
              viz.layout.VALUES.map(m => m.name),
              conditionalFormatting.map(m => m.name),
            );
            return intersection.length === 0;
          }
          case 'showLegendTitles':
            return true;
          default:
            return false;
        }
      },
      customFormatToggles: [
        ENABLE_REPORT_LINK,
        {
          name: 'rowSubtotals',
          displayLabel: 'pivot.rowSubtotalsToggle',
          type: 'subtotals',
          shelf: 'ROWS',
          isAvailable: _.stubTrue,
          default: true,
          getDefaultOptions: viz => {
            return {
              fields:
                viz.layout.ROWS.length > 0 ? [viz.layout.ROWS[0].name] : 'ALL',
            };
          },
          isDisabled: _.stubFalse,
        },
        {
          name: 'colSubtotals',
          displayLabel: 'pivot.colsSubtotalsToggle',
          type: 'subtotals',
          isAvailable: _.stubFalse,
          default: false,
        },
        {
          name: 'rowGrandTotals',
          displayLabel: 'pivot.rowsGrandTotalsToggle',
          type: 'grandtotals',
          isAvailable: _.stubTrue,
          default: true,
        },
        {
          name: 'colGrandTotals',
          displayLabel: 'pivot.colsGrandTotalsToggle',
          type: 'grandtotals',
          isAvailable: _.stubTrue,
          default: false,
        },
        {
          name: 'recordCounts',
          displayLabel: 'pivot.recordCountsToggle',
          type: 'counts',
          isAvailable: _.stubTrue,
          default: true,
        },
      ],
      supportsConditionalFormatting: true,
      supportsLayoutPanelSort: false,
      requiredFields: false,
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.OneOf([
        this.shelves.VALUES,
        this.shelves.COLUMNS,
        this.shelves.ROWS,
      ]),
      Validations.TypeSafe,
    ];
  }

  getFieldsAndOrdinals(fieldsInPlay, viz) {
    let allAttributes = [...fieldsInPlay];
    const ordinalCols = allAttributes
      .filter(a => requiresOrdinal(a))
      .map(a => {
        return {
          attributeType: Types.CALC,
          defaultAggregation: 'None',
          formula: 'date_part("year", [Date])',
          name: `${a.name} :: ordinal`,
          parentField: a,
        };
      });

    const nonTimeOrdinals = allAttributes
      .filter(a => !_.isEmpty(a.ordinalAttribute))
      .map(attrWithOrdinal => {
        const attr = viz.dataset.attributes.find(
          a => a.name === attrWithOrdinal.ordinalAttribute,
        );
        if (!attr) {
          console.error(
            `Column (${attrWithOrdinal.name}) references a non-existent ordinal column: ${attrWithOrdinal.ordinalAttribute}`,
          );
          return null;
        }
        return attr;
      })
      .filter(a => a);

    allAttributes = allAttributes.concat(ordinalCols).concat(nonTimeOrdinals);

    // Swap Linked Attributes
    const links = getAllLinksEnabledInReport(
      viz,
      Viz.getAllFieldsInPlay(viz.layout),
    );
    allAttributes = allAttributes.map(attr => {
      if (links[attr.name]) {
        return {
          attributeType: Types.CALC,
          defaultAggregation: 'None',
          formula: links[attr.name].formula,
          name: `${attr.name} :: link`,
          parentField: attr,
        };
      } else {
        return attr;
      }
    });

    const attributeNames = allAttributes.map(g => g.name);
    return attributeNames;
  }

  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }

    const allAttributes =
      viz?.layout?.COLUMNS?.concat(viz.layout.ROWS ?? []) ?? [];
    // Check to see if any are date_part calculations. If so add ordinal selections for these to facilitate sorting
    const attributeNames = this.getFieldsAndOrdinals(allAttributes, viz);

    const measures = generateMeasuresFromMetrics(viz);
    return {
      attributeNames: _.uniq(attributeNames),
      measures,
    };
  }

  mapSubtotalsToQuery(viz) {
    const colFields = _.get(viz, 'layout.COLUMNS', []);
    const rowFields = _.get(viz, 'layout.ROWS', []);
    const metricFields = _.get(viz, 'layout.VALUES', []);
    const subtotals = [];

    const allAttributes = [...colFields, ...rowFields];
    const allAttributeNames = this.getFieldsAndOrdinals(allAttributes, viz);
    const includeRowSubtotals = Viz.isCustomFormatToggleOn('rowSubtotals', viz);
    const includeRowGrandTotals = Viz.isCustomFormatToggleOn(
      'rowGrandTotals',
      viz,
    );
    const includeColGrandTotals = Viz.isCustomFormatToggleOn(
      'colGrandTotals',
      viz,
    );

    // grand totals
    if (!_.isEmpty(colFields) || !_.isEmpty(rowFields)) {
      if (includeRowGrandTotals) {
        let rowGrandTotals;
        if (_.isEmpty(colFields)) {
          // if we don't have anything in columns, then we need a total aggregation of all the row values
          rowGrandTotals = { attributeNames: [] };
        } else if (_.isEmpty(rowFields)) {
          // this would be the horizontal total
          rowGrandTotals = { attributeNames: [] };
        } else {
          // we have both columns and rows. need to ask for all column fields in the subtotal
          const attributeNames = this.getFieldsAndOrdinals(colFields, viz);

          // make sure they are in the same order as the attributes requested by the query. _.intersection will handle that for us
          rowGrandTotals = {
            attributeNames: _.intersection(allAttributeNames, attributeNames),
          };
        }
        if (!_.isEmpty(metricFields)) {
          subtotals.push(rowGrandTotals);
        }
      }
      if (includeColGrandTotals) {
        let colGrandTotals;
        if (_.isEmpty(rowFields)) {
          // if we don't have anything in columns, then we need a total aggregation of all the row values
          colGrandTotals = { attributeNames: [] };
        } else if (_.isEmpty(colFields)) {
          // this would be the horizontal total
          colGrandTotals = { attributeNames: [] };
        } else {
          // we have both columns and rows. need to ask for all column fields in the subtotal
          const attributeNames = this.getFieldsAndOrdinals(rowFields, viz);

          // make sure they are in the same order as the attributes requested by the query. _.intersection will handle that for us
          colGrandTotals = {
            attributeNames: _.intersection(allAttributeNames, attributeNames),
          };
        }
        if (!_.isEmpty(colGrandTotals)) {
          subtotals.push(colGrandTotals);
        }
      }
    }

    if (!_.isEmpty(colFields) && includeColGrandTotals) {
      // Add Final grand total, button-right of table
      subtotals.push({ attributeNames: [] });
    }

    // row subtotals
    if (includeRowSubtotals && !_.isEmpty(rowFields) && rowFields.length > 1) {
      const colAttributeNames = this.getFieldsAndOrdinals(colFields, viz);

      // get selection of fields for row subtotals
      const options = Viz.getCustomFormatToggleOptions('rowSubtotals', viz);
      let fields = _.get(options, 'fields', []);

      // filter fields to those in the ROWS shelf
      if (_.isArray(fields)) {
        fields = fields.filter(f => {
          const match = rowFields.find(rowField => rowField.name === f);
          return !_.isNil(match);
        });
      }

      const selectedFields = _.isEmpty(fields) ? rowFields[0].name : fields;

      // need a subtotal for each combination of rows (except the last one) and columns
      for (let i = 0; i < rowFields.length - 1; i++) {
        // skip fields that are not selected
        if (
          selectedFields !== 'ALL' &&
          !_.includes(selectedFields, rowFields[i].name)
        ) {
          continue;
        }
        const rowSubtotalFields = rowFields.slice(0, i + 1);
        const rowAttributeNames = this.getFieldsAndOrdinals(
          rowSubtotalFields,
          viz,
        );
        const attributeNames = _.intersection(allAttributeNames, [
          ...colAttributeNames,
          ...rowAttributeNames,
        ]);
        if (!_.isEmpty(metricFields)) {
          const rowSubtotal = { attributeNames };
          subtotals.push(rowSubtotal);
        }
        if (includeColGrandTotals) {
          // Add a Col grand total, has the rows, but not columns
          subtotals.push({ attributeNames: rowAttributeNames });
        }
      }
    }

    if (!_.isEmpty(subtotals)) {
      return {
        subtotals,
      };
    }
  }

  getDataForExcelExport(origViz, queryResults, i18nPrefs = {}) {
    const viz = { ...origViz };
    if (viz.chartType !== 'pivot') {
      viz.layout = VizLayoutMapping(viz.layout, viz.chartType, 'pivot');
    }

    const layout = {};
    Object.entries(viz.layout).forEach(([key, val]) => {
      layout[key] = val.map(a => a.name);
    });
    const customFormatToggles = Viz.getCustomFormatTogglesFromViz(viz);
    const props = _.mapValues(
      _.mapKeys(
        _.keyBy(customFormatToggles, 'key'),
        (val, key) => `show${key.charAt(0).toUpperCase() + key.slice(1)}`,
      ),
      'on',
    );
    const data = PivotDataFunc({ ...props, queryResults, viz, i18nPrefs });

    if (_.isEmpty(data)) {
      return;
    }

    const { rowGrandTotals, rowHeaderData, dataColumnCount } = data;
    const grandTotalRow = _.head(rowGrandTotals)?.columnData ?? [];
    const { showRowGrandTotals } = props;
    const shouldAddGrandTotals =
      showRowGrandTotals &&
      _.isEqual(grandTotalRow.length, rowHeaderData.length);

    const colsData = _.reduce(
      rowHeaderData,
      (result, column, idx) => {
        const formattedCols = _.map(column, excelData => {
          const first = _.head(excelData);
          const last = _.last(excelData);
          const value = _.isNil(first?.value) ? first : first?.value;
          if (_.isEqual(value, VIZ_QUERY_TOTALS_FLAG)) {
            return ['', last];
          }
          return [value, last];
        });
        if (shouldAddGrandTotals) {
          const totalValue = _.isNil(grandTotalRow[idx]?.value)
            ? grandTotalRow[idx]
            : grandTotalRow[idx]?.value;
          const formattedVal = _.isEqual(totalValue, VIZ_QUERY_TOTALS_FLAG)
            ? _.isEqual(idx, 0)
              ? messages.formatting.grandTotals
              : ''
            : totalValue;
          result.push([...formattedCols, [formattedVal, 1]]);
        } else {
          result.push([...formattedCols]);
        }
        return result;
      },
      [],
    );

    const formatters = _.mapValues(data.formatters, f =>
      _.map(f, 'formatType').filter(Boolean),
    );

    const headers = data.colHeaderData.map(el => {
      return el.map(item => {
        const first = _.head(item);
        const last = _.last(item);
        const value = _.isNil(first?.value) ? first : first?.value;
        return [value, last];
      });
    });

    const datasetAnnotations = get(viz, 'dataset.annotations', []);
    const currencySymbol = find(datasetAnnotations, {
      key: 'DEFAULT_CURRENCY_SYMBOL',
    })?.value;

    return {
      cols: colsData,
      dataColumnCount,
      headers,
      layout,
      formatters,
      name: viz.name,
      description: _.get(viz, 'options.description', ''),
      currencySymbol,
    };
  }
}

export default PivotChartSpec;
