import React, { ComponentClass, memo } from 'react';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import moment from '../../common/Moment';
import {
  get,
  includes,
  isEmpty,
  isObject,
  groupBy,
  forEach,
  toPairs,
  head,
  tail,
  cloneDeep,
  isNil,
  isArray,
  toNumber,
  find,
  isEqual,
  max,
  map,
  filter,
  pick,
  pickBy,
  some,
  reject,
  flatMap,
  concat,
  keys,
  mapValues,
  slice,
  values,
} from 'lodash';
import { VizRedirect, IDrillContext } from '../../discovery/viz-redirect';
import ChartUtils from '../../discovery/charts/ChartUtils';
import DiscoverActions from '../redux/actions/DiscoverActions';
import { CorbotoLoading } from '../loaders';
import InListFilter from '../../discovery/filter/exports/InListFilter';
import EqualsFilter from '../../discovery/filter/exports/EqualsFilter';
import NullFilter from '../../discovery/filter/exports/NullFilter';
import { ReportDashlet } from '../../sugar-dashlet/report-dashlet/report-dashlet.component';
import DateFilter from '../../discovery/filter/exports/DateFilter';
import {
  addDashletSuffix,
  AllowedDashletDrillLinkDynamicFields,
  DynamicCalcValues,
  getFrontendUrl,
  NULL_DISPLAY,
  ROUTER_DIRS,
  stripDashletSuffix,
  Types,
  VIZ_OPTION_IDS,
} from '../Constants';
import { Moment } from 'moment';
import {
  IFilter,
  IPreFilterOperand,
  IAppliedFilters,
  IFiscalCalendarInfo,
  ITimeCalcAttribute,
  IExpressionSegment,
  IExpression,
} from '../../datasets/interfaces';
import { IDehydratedViz } from '../../discovery';
import { Hierarchy } from '../../discovery/VizUtil';
import { InTermFilter } from '../../discovery/filter/exports/InTermFilter';
import {
  Condition,
  LogicalOperators,
  StringFilterSubTypes,
  TimestampFilterSubTypes,
} from '../../discovery/filter/Filter';
import {
  decodeExpressionTree,
  insertIntoFilterExpression,
} from '../../discovery/filter/filter.utils';
import {
  IN_LIST,
  timestampOperators,
} from '../../discovery/filter/FilterOperators';
import { withDiscoverRouter } from '../utilities/with-router';
import URLs from '../Urls';
import Discover from '../redux/actions/DiscoverActions';
import { VizQueries } from '../graphql';
import { convertSugarFilter } from '../utilities/sugar-filter-converter/sugar-filter-converter';

const TIME_ORDER = [
  'YEAR',
  'QTR',
  'MONTH',
  'WEEK_IN_QTR',
  'WEEK',
  'DAY',
  'HOUR',
  'MINUTE',
  'SECOND',
  'EXACT_DATE',
  'DATE',
];

const DATE_FILTER_MAPPING = {
  YEAR: 'CALENDAR_YEARS',
  QTR: 'CALENDAR_QUARTERS',
  MONTH: 'CALENDAR_MONTHS',
  WEEK: 'CALENDAR_WEEKS',
  DAY: 'DAYS',
  EXACT_DATE: 'DAYS',
  DATE: 'DAYS',
  HOUR: 'NOT_SUPPORTED',
  MINUTE: 'NOT_SUPPORTED',
  SECOND: 'NOT_SUPPORTED',
};

const getDeepestGranularity = (g1, g2) => {
  const g1index = TIME_ORDER.indexOf(g1);
  const g2index = TIME_ORDER.indexOf(g2);
  return g2index === -1
    ? g1
    : g1index === -1
      ? g2
      : g1index > g2index
        ? g1
        : g2;
};

const replaceExpressionDynamicValue = (
  expression: IExpression | IExpressionSegment,
  expFilter,
  dashletDrillLinkDynamicValues,
) => {
  const branch = cloneDeep(expression);
  let { type, subType } = expFilter;
  if (!isNil((branch as IExpression)?.left)) {
    const {
      branch: branchLeft,
      type: typeLeft,
      subType: subTypeLeft,
    } = replaceExpressionDynamicValue(
      (branch as IExpression)?.left,
      expFilter,
      dashletDrillLinkDynamicValues,
    );
    (branch as IExpression).left = branchLeft;
    type = typeLeft;
    subType = subTypeLeft;
  } else if (
    includes(
      AllowedDashletDrillLinkDynamicFields,
      head((branch as IExpressionSegment)?.operands),
    )
  ) {
    const dynamicValues = dashletDrillLinkDynamicValues[
      head((branch as IExpressionSegment)?.operands as string[])
    ] as string;

    (branch as IExpressionSegment).operands = isArray(dynamicValues)
      ? dynamicValues
      : [dynamicValues];
    (branch as IExpressionSegment).operator = IN_LIST.key;
    type = Types.STRING;
    subType = StringFilterSubTypes.SELECT_ITEMS;
  }

  return {
    branch,
    type,
    subType,
  };
};

const replaceDynamicValue = (_filter, dashletDrillLinkDynamicValues) => {
  const expFilter = cloneDeep(_filter);
  const { expression } = expFilter;
  const { left: leftExpression, right: rightExpression } = expression;
  const {
    branch: left,
    type,
    subType,
  } = replaceExpressionDynamicValue(
    leftExpression,
    expFilter,
    dashletDrillLinkDynamicValues,
  );
  const { branch: right } = replaceExpressionDynamicValue(
    rightExpression,
    expFilter,
    dashletDrillLinkDynamicValues,
  );

  return {
    ..._filter,
    type,
    subType,
    expression: {
      ..._filter?.expression,
      left,
      right,
    },
  };
};

//
// Take the various date attributes (Year, Qtr, Month, etc) and combine them into a single filter.
//
const translateDateFilter = (
  dateFilter: any,
  attribute: string,
  filters: IAppliedFilters,
  fiscalCalendarInfo?: IFiscalCalendarInfo,
): IFilter => {
  const { useFiscalCalendar, fiscalCalendarYearType, fiscalCalendarStartDate } =
    fiscalCalendarInfo ?? ({} as any);

  const monthOffset =
    useFiscalCalendar && fiscalCalendarStartDate
      ? moment(fiscalCalendarStartDate).month()
      : 0;

  // build up date string
  let year = moment().format('YYYY');
  let month = moment().format('MM');
  let day = '01';

  if (!isNil(filters[attribute])) {
    // Extract Anchor from filters if exists to get year and month values
    try {
      if (
        !isNil(
          (filters[attribute].expression.left as IExpressionSegment).operands,
        ) &&
        (filters[attribute].expression.left as IExpressionSegment).operands
          .length >= 4
      ) {
        const anchor = moment(
          (filters[attribute].expression.left as IExpressionSegment)
            .operands[3],
        );
        year = anchor.format('YYYY');
        month = anchor.format('MM');
      }
    } catch {
      console.log('issue attempting to extract anchor from previous filter');
    }
  }

  if (!isNil(dateFilter.YEAR)) {
    year = dateFilter.YEAR;
    month = '01';
  }

  // determine the month based on month, week and qtr values
  // determine the day based on day and week values
  let dateFilterKey = dateFilter.key;

  if (!isNil(dateFilter.DATE)) {
    const m = moment(dateFilter.DATE, 'MM/DD/YYYY');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
  } else if (!isNil(dateFilter.EXACT_DATE)) {
    const m = moment(dateFilter.EXACT_DATE, 'MM/DD/YYYY HH:mm A');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
  } else if (!isNil(dateFilter.WEEK)) {
    // use ISO week - starts on Monday
    const m = moment(`${year}-${dateFilter.WEEK}`, 'YYYY-[Week] WW');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
    dateFilterKey = 'WEEK';
  } else if (!isNil(dateFilter.QTR) && !isNil(dateFilter.WEEK_IN_QTR)) {
    // Get the start of the quarter,
    // Then Add N-1 Weeks based on week in qtr
    const firstQtrWeek = moment(
      `${year}-${dateFilter.QTR}`,
      'YYYY-[Q]Q',
    ).isoWeekday(1);
    let weekInQtr = 1;
    try {
      weekInQtr = toNumber(dateFilter.WEEK_IN_QTR);
    } catch (e) {
      console.log(`error: ${e}`);
    }
    const qtrWeek = firstQtrWeek.add(weekInQtr - 1, 'week');
    year = qtrWeek.format('YYYY');
    month = qtrWeek.format('MM');
    day = qtrWeek.format('DD');
    dateFilterKey = 'WEEK';
  } else if (!isNil(dateFilter.MONTH)) {
    month = moment(dateFilter.MONTH, 'MMM').format('MM');
    if (!isNil(dateFilter.DAY)) {
      day = moment(dateFilter.DAY, 'DD').format('DD');
    }
  } else if (!isNil(dateFilter.QTR)) {
    month = moment(`${year}-${dateFilter.QTR}`, 'YYYY-[Q]Q').format('MM');
    dateFilterKey = 'QTR';
  } else if (!isNil(dateFilter.DAY)) {
    day = moment(dateFilter.DAY, 'DD').format('DD');
  }

  if (dateFilterKey === 'WEEK_IN_QTR') {
    dateFilterKey = 'QTR';
  }

  if (
    dateFilterKey === 'HOUR' ||
    dateFilterKey === 'MINUTE' ||
    dateFilterKey === 'SECOND'
  ) {
    dateFilterKey = 'DAY';
  }

  const useFiscalQuarters = useFiscalCalendar && dateFilterKey === 'QTR';
  const useFiscalMonths = useFiscalCalendar && dateFilterKey === 'MONTH';
  const useFiscalWeeks = useFiscalCalendar && dateFilterKey === 'WEEK';

  const dateString: Moment = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
  if (useFiscalQuarters || useFiscalWeeks) {
    if (fiscalCalendarYearType === 'end') {
      dateString.subtract(1, 'year');
    }
    dateString.add(monthOffset, 'months');
  }
  if (useFiscalMonths) {
    if (
      fiscalCalendarYearType === 'start' &&
      dateString.month() < monthOffset
    ) {
      dateString.subtract(1, 'year');
    } else if (
      fiscalCalendarYearType === 'end' &&
      dateString.month() >= monthOffset
    ) {
      dateString.subtract(1, 'year');
    }
  }
  return DateFilter(dateFilter.parentField, [
    '1',
    DATE_FILTER_MAPPING[dateFilterKey],
    'false',
    dateString.toISOString(true),
  ]);
};

const addQuarterInfoToFilter = (
  weekInQuarterFilter,
  allPrefilterOperands,
): any[] => {
  const quarterFilter = find(allPrefilterOperands, [
    'attribute.timeAttribute.key',
    'QTR',
  ]);
  if (isNil(quarterFilter)) {
    return [weekInQuarterFilter];
  }

  return [
    {
      ...weekInQuarterFilter,
      value: [...weekInQuarterFilter.value, head(quarterFilter.value)],
    },
  ];
};

const isWeekInQuarterFilter = (expFilter): boolean => {
  return isEqual('WEEK_IN_QTR', get(expFilter, 'attribute.timeAttribute.key'));
};

const getHighestGranularityFilters = (preFilterOperands): any[] => {
  const highestLevel = max(
    map(preFilterOperands, 'attribute.timeAttribute.order'),
  );

  const highestFilter = filter(preFilterOperands, [
    'attribute.timeAttribute.order',
    highestLevel,
  ]);

  const _filter = head(highestFilter);
  return isWeekInQuarterFilter(_filter)
    ? addQuarterInfoToFilter(_filter, preFilterOperands)
    : highestFilter;
};

const timeCalcFiltersUnderYear = (
  fiscalCalendarInfo: IFiscalCalendarInfo,
  preFilterOperands,
  yearValue?: string | number,
): IFilter[] => {
  // create immutable operands
  let preFiltersWithConvertedMonths = cloneDeep(preFilterOperands);

  // should we convert to fiscal week offset?
  if (fiscalCalendarInfo?.useFiscalCalendar) {
    preFiltersWithConvertedMonths =
      ChartUtils.offsetMonthOrdinalForFiscalCalendar(
        preFiltersWithConvertedMonths,
        fiscalCalendarInfo,
      );
  }

  const _filters = [];
  forEach(
    preFiltersWithConvertedMonths,
    ({
      attribute: _nonYearAttribute,
      value: _nonYearValues,
    }: IPreFilterOperand) => {
      const operands = cloneDeep(
        isArray(_nonYearValues) ? _nonYearValues : [_nonYearValues],
      );

      const isWeekInQuarter = isWeekInQuarterFilter({
        attribute: _nonYearAttribute,
      });
      // Quarter and Month operands have only two operands - last operand is year value
      if ((isWeekInQuarter || operands.length == 1) && !isNil(yearValue)) {
        operands.push(yearValue);
      }

      _filters.push(InTermFilter(_nonYearAttribute, operands));
    },
  );

  return _filters;
};

export const UnconnectedReportLinkRedirect = ({
  drillContext: drillContextProp,
  drillContextId,
  drillContextUpdating,
  linkedContent,
  updateDrillContext,
  isDashletMode,
  vizQueryLoading,
  linkStrategy,
  applyVizState,
  openVisualization,
  location,
}: {
  drillContext: IDrillContext;
  drillContextId?: string;
  drillContextUpdating?: boolean;
  linkedContent?: IDehydratedViz;
  updateDrillContext?;
  isDashletMode?: boolean;
  vizQueryLoading?: boolean;
  linkStrategy?: string;
  applyVizState?;
  openVisualization?;
  location?;
}) => {
  let drillContext = drillContextProp;

  if (drillContextId && !drillContext && !drillContextUpdating) {
    const { instanceUrl = getFrontendUrl() } = URLs.getQueryParams();
    updateDrillContext(drillContextId, instanceUrl);
    return <></>;
  }

  if (vizQueryLoading || drillContextUpdating) {
    return <CorbotoLoading />;
  } else if (!(linkedContent || isDashletMode)) {
    return <Navigate to={ROUTER_DIRS.ROOT} />;
  }

  drillContext = drillContext || location?.state?.drillContext;
  const { fiscalCalendarInfo } = drillContext ?? {};
  const { useFiscalCalendar } = fiscalCalendarInfo ?? {};

  drillContext = drillContext ?? ({} as IDrillContext);

  const {
    slicers,
    slicerSelections,
    filters = {},
    dynamicValues,
    runtimeFilters = {},
    dashboardFilters = {},
    isFromDrillEvent,
  } = drillContext;
  const dateFilters = {};
  let inTermAttributes = [];
  const filterFields = [];

  const additionalFilters =
    convertSugarFilter(
      get(dashboardFilters, stripDashletSuffix(linkedContent?.id), {}),
      values(runtimeFilters),
    ) ?? {};

  // Override the filters with the runtime filters
  forEach(additionalFilters, (val, key) => {
    filters[key] = val;
  });

  const dashletDrillLinkDynamicValues = pick(
    dynamicValues,
    AllowedDashletDrillLinkDynamicFields,
  );

  let calcDynamicValues = {};

  if (!isEmpty(dashletDrillLinkDynamicValues)) {
    // dynamic values, as in calculated fields in the Forecast report, need to be replaced
    for (const _fieldName in filters) {
      filters[_fieldName] = replaceDynamicValue(
        filters[_fieldName],
        dashletDrillLinkDynamicValues,
      );
    }
    calcDynamicValues = pickBy(dashletDrillLinkDynamicValues, (value, key) =>
      includes(DynamicCalcValues, key),
    );
  }

  let preFilterOperands: IPreFilterOperand[] = drillContext.attributes ?? [];

  const attributeTypeGroups = groupBy(
    drillContext.attributes ?? [],
    _attributeAndValue => {
      return _attributeAndValue?.attribute?.attributeType;
    },
  );

  /**
   * Some notes about attributes in drill linking:
   * - some attributes, like time calcs, are hierarchical in nature and require grouping to make the right filter
   */
  const { YEAR, QTR, MONTH, WEEK, WEEK_IN_QTR, DAY, EXACT_DATE, DATE } =
    Hierarchy.TIME_ATTRIBUTES;

  const COARSE_GRANULAR_TIME_TERMS = [YEAR, QTR, MONTH, WEEK_IN_QTR, WEEK];
  const FINE_GRANULAR_TIME_TERMS = [DAY, EXACT_DATE, DATE];

  const hasMoreGranularFieldsThanSupportedInTerm = some(
    attributeTypeGroups[Types.TIME_CALC],
    ({ attribute: _attr }) =>
      includes(
        map(FINE_GRANULAR_TIME_TERMS, 'key'),
        (_attr as ITimeCalcAttribute)?.timeAttribute?.key,
      ),
  );

  if (
    !isEmpty(attributeTypeGroups[Types.TIME_CALC]) &&
    !hasMoreGranularFieldsThanSupportedInTerm
  ) {
    // group by non-hierarchical attribute name
    const preFilterTimeCalcGroups: {
      [key: string]: IPreFilterOperand[];
    } = groupBy(
      attributeTypeGroups[Types.TIME_CALC],
      ({ attribute: _attr }: IPreFilterOperand) => _attr?.parentField?.name,
    );

    const accountedTimeCalcAttributes = {};

    // Time Calc will be merged later if they share the same parent attribute
    forEach(
      toPairs(preFilterTimeCalcGroups),
      ([_parentFieldName, preFilterOperandGroups]) => {
        // There is an assumption that fields cannot exist in more than one shelf.
        const inYearPreFilters: IPreFilterOperand[] = filter(
          preFilterOperandGroups,
          ({ attribute: _attr }: IPreFilterOperand) =>
            some([YEAR], {
              key: (_attr as ITimeCalcAttribute).timeAttribute.key,
            }),
        );
        const inYearAffectedPreFilters: IPreFilterOperand[] = filter(
          preFilterOperandGroups,
          ({ attribute: _attr }: IPreFilterOperand) =>
            some(reject(COARSE_GRANULAR_TIME_TERMS, { key: YEAR.key }), {
              key: (_attr as ITimeCalcAttribute).timeAttribute.key,
            }),
        );

        if (!isEmpty(inYearPreFilters)) {
          const { attribute: yearAttribute } = head(inYearPreFilters);
          const yearValues = flatMap(
            inYearPreFilters as IPreFilterOperand[],
            'value',
          ).filter(Boolean);

          // just make in year filter? why make filters with year operand
          if (!isEmpty(inYearAffectedPreFilters)) {
            // there are more granular date terms than year - use yearValues as operands for appropriate filters
            // this more or less creates a cross product of years and year-affected attributes
            forEach(yearValues, _yearValue => {
              inTermAttributes = concat(
                inTermAttributes,
                timeCalcFiltersUnderYear(
                  drillContext.fiscalCalendarInfo,
                  getHighestGranularityFilters(inYearAffectedPreFilters),
                  _yearValue,
                ) ?? [],
              );
            });
          } else {
            // no year-affected attributes, just make inYear filter
            forEach(yearValues, _yearValue => {
              inTermAttributes = concat(inTermAttributes, [
                InTermFilter(yearAttribute, _yearValue),
              ]);
            });
          }
        } else {
          // let the filters use default year values
          inTermAttributes = concat(
            inTermAttributes,
            timeCalcFiltersUnderYear(
              drillContext.fiscalCalendarInfo,
              inYearAffectedPreFilters,
            ) ?? [],
          );
        }

        forEach(
          concat(inYearPreFilters, inYearAffectedPreFilters),
          ({ attribute: _attr }) =>
            (accountedTimeCalcAttributes[_attr?.name] = _attr),
        );
      },
    );

    // remove entries that were already accounted for to create InTerm time calc filters
    forEach(keys(accountedTimeCalcAttributes), _accountedAttrName => {
      preFilterOperands = reject(
        preFilterOperands,
        ({ attribute: _attr }: IPreFilterOperand) => {
          return (
            _attr?.name === _accountedAttrName &&
            some(COARSE_GRANULAR_TIME_TERMS, {
              key: (_attr as ITimeCalcAttribute).timeAttribute?.key,
            })
          );
        },
      ) as IPreFilterOperand[];
    });
  }

  // merge string prefilter operands that share the same attribute (e.g. for InList filter)
  if (!isEmpty(attributeTypeGroups[Types.STRING])) {
    const stringPrefiltersByAttribute: {
      [key: string]: IPreFilterOperand[];
    } = groupBy(
      attributeTypeGroups[Types.STRING] as IPreFilterOperand[],
      'attribute.name',
    );

    // note mergedPrefilters values are not arrays/collections
    const mergedStringPrefilters: {
      [key: string]: IPreFilterOperand;
    } = mapValues(
      stringPrefiltersByAttribute,
      (_prefilters: IPreFilterOperand[]) => {
        const _attribute = head(_prefilters)?.attribute;
        const _value = flatMap(_prefilters, 'value');
        return {
          attribute: _attribute,
          value: _value,
        } as IPreFilterOperand;
      },
    );

    const _updateStringPrefilterOperands = flatMap(mergedStringPrefilters);
    // remove entries that were just merged
    forEach(keys(mergedStringPrefilters), _mergedAttrName => {
      preFilterOperands = reject(
        preFilterOperands,
        ({ attribute: _attr }: IPreFilterOperand) =>
          isEqual(_attr.name, _mergedAttrName),
      ) as IPreFilterOperand[];
    });

    // add new entries of the string prefilters that have merged operands
    preFilterOperands = concat(
      preFilterOperands,
      _updateStringPrefilterOperands,
    );
  }

  forEach(preFilterOperands, (d: IPreFilterOperand) => {
    switch (d?.attribute?.attributeType) {
      case Types.TIME_CALC: {
        const { DATE } = Hierarchy.TIME_ATTRIBUTES;

        let parentTimeCalcAttribute = d.attribute;
        if (!isEmpty(parentTimeCalcAttribute?.parentField)) {
          parentTimeCalcAttribute = parentTimeCalcAttribute?.parentField;
        }

        if (
          some([DATE], {
            key: (d.attribute as ITimeCalcAttribute).timeAttribute.key,
          })
        ) {
          let onDates = d.value;

          if (!isArray(d.value)) {
            onDates = [d.value];
          }

          // all date operands need to be converted to iso strings
          onDates = map(onDates, (_date: string) => {
            try {
              // On Date should truncate time
              return moment.utc(_date).format('MM/DD/YYYY');
            } catch {
              return _date;
            }
          });

          // construct filter with first date
          const onDatesFilter = DateFilter(
            parentTimeCalcAttribute,
            slice(onDates, 0, 1),
            timestampOperators.on.key,
          );
          onDatesFilter.subType = TimestampFilterSubTypes.SET_CONDITION;

          // add any additional dates into filter
          forEach(slice(onDates, 1), (_dateOperand: string) => {
            onDatesFilter.expression = insertIntoFilterExpression(
              onDatesFilter.expression,
              new Condition(timestampOperators.on.key, [_dateOperand]),
              LogicalOperators.OR,
            );
          });

          inTermAttributes.push(onDatesFilter);
        } else {
          // anchor-based code before the introduction of 'InTerm' operator
          let dateFilter: any = {};
          // if parent is already defined
          if (dateFilters[d.attribute?.parentField?.name]) {
            dateFilter = dateFilters[d.attribute?.parentField?.name];
          } else {
            dateFilters[d.attribute?.parentField?.name] = dateFilter;
            dateFilter.parentField = d.attribute?.parentField;
          }
          dateFilter[(d.attribute as ITimeCalcAttribute).timeAttribute.key] =
            d.value;
          dateFilter.key = getDeepestGranularity(
            (d.attribute as ITimeCalcAttribute).timeAttribute.key,
            dateFilter.key,
          );
          dateFilter.attribute = d.attribute;
          filterFields.push(d.attribute.parentField.name);
        }
        break;
      }
      case Types.STRING: {
        if (
          isEqual(d.value, [NULL_DISPLAY]) ||
          (d.value as any) === NULL_DISPLAY
        ) {
          filters[d.attribute.name] = NullFilter(d.attribute);
        } else {
          filters[d.attribute.name] = InListFilter(d.attribute, d.value);
        }
        filterFields.push(d.attribute.name);
        break;
      }
      default: {
        if (
          isEqual(d.value, [NULL_DISPLAY]) ||
          (d.value as any) === NULL_DISPLAY
        ) {
          filters[d.attribute.name] = NullFilter(d.attribute);
        } else {
          filters[d.attribute.name] = EqualsFilter(d.attribute, d.value);
        }
        filterFields.push(d.attribute.name);
        break;
      }
    }
  });

  const inTermAttributeGroups = groupBy(inTermAttributes, _filter => {
    let filterName = _filter.field;
    if (isObject(_filter.field)) {
      filterName = (_filter.field as ITimeCalcAttribute).name;
    }
    return filterName;
  });

  // merge filters that share a field with LogicalOperators.OR
  forEach(toPairs(inTermAttributeGroups), ([_filterName, unmergedFilters]) => {
    // all InTerm filters are Type DATE and subType SET_CONDITION
    const expFilter = head(unmergedFilters);
    forEach(tail(unmergedFilters), (_filter: IFilter) => {
      const _unmergedConditions = decodeExpressionTree(_filter.expression);
      forEach(_unmergedConditions, (_condition: IExpressionSegment) => {
        expFilter.expression = insertIntoFilterExpression(
          expFilter.expression,
          _condition,
          LogicalOperators.OR,
        );
      });
    });
    filters[_filterName] = expFilter;
  });

  for (const attribute in dateFilters) {
    // translate to filter
    filters[attribute] = translateDateFilter(
      dateFilters[attribute],
      attribute,
      filters,
      fiscalCalendarInfo,
    );
  }

  const updateVizState = {
    filters,
    removeFiltersOnFields: filterFields,
    calcs: drillContext.calcs,
    metrics: drillContext.metrics,
    toShelves: drillContext.toShelves,
    slicers,
    slicerSelections,
    dynamicValues: calcDynamicValues,
    useFiscalCalendar,
  };

  if (linkStrategy === 'clear') {
    // don't change the filters of the target report at all
    updateVizState.removeFiltersOnFields = ['__ALL__'];
  }

  if (isDashletMode) {
    const targetId = addDashletSuffix(linkedContent?.id);

    // applyVizState needs to happen before viz renders
    applyVizState(targetId, updateVizState);

    // render dashlet report instead of redirecting
    return <ReportDashlet vizId={targetId} isDrillLinking />;
  }

  const areRuntimeFiltersApplied = !isEmpty(runtimeFilters);
  openVisualization({
    ...linkedContent,
    canUpdate:
      linkedContent?.canUpdate &&
      !isFromDrillEvent &&
      !areRuntimeFiltersApplied,
  });

  if (includes(['update', 'clear'], linkStrategy)) {
    return <VizRedirect vizId={linkedContent?.id} state={updateVizState} />;
  }

  // don't change the filters of the target report at all
  return <VizRedirect vizId={linkedContent?.id} />;
};

const VizQuery = graphql<
  { drillContext: IDrillContext },
  { visualization: IDehydratedViz },
  { id: string },
  {
    visualizations: any[];
    vizQueryLoading: boolean;
  }
>(VizQueries.GetVisualization, {
  skip: ({ drillContext }: any) => !drillContext?.linkToReport?.id,
  options: ({ drillContext }: any) => ({
    variables: { id: drillContext.linkToReport.id },
  }),
  props: ({ data = {} }) => {
    const { visualization, loading: vizQueryLoading, error } = data;

    return {
      visualizations: error ? null : [visualization],
      linkedContent: visualization,
      vizQueryLoading,
    };
  },
});

const ReportLinkRedirect = compose(
  pure,
  withDiscoverRouter,
  connect(
    (state: any) => {
      const {
        discover: {
          openDiscoveries,
          displayDiscovery,
          drillContext,
          drillContextUpdating,
          linkStrategy,
        },
      } = state;
      const current = get(openDiscoveries, [displayDiscovery, 'present']);
      const vizLinkStrategy = get(
        current,
        `viz.options.${VIZ_OPTION_IDS.linkStrategy}`,
        'update',
      );

      return {
        linkStrategy: linkStrategy || vizLinkStrategy,
        drillContext,
        drillContextUpdating,
      };
    },
    (dispatch: any) => {
      return {
        updateDrillContext(drillContextId, instanceUrl) {
          dispatch(
            DiscoverActions.updateDrillContext(drillContextId, instanceUrl),
          );
        },
        openVisualization: (discovery: any) => {
          dispatch(DiscoverActions.openVisualization(discovery));
        },
        applyVizState: (vizId, vizState) => {
          dispatch(Discover.applyVizState({ ...vizState, id: vizId }));
        },
      };
    },
  ),
  VizQuery,
)(memo(UnconnectedReportLinkRedirect)) as ComponentClass<{
  isDashletMode?: boolean;
}>;

export {
  getDeepestGranularity,
  translateDateFilter,
  getHighestGranularityFilters,
  timeCalcFiltersUnderYear,
  ReportLinkRedirect,
};
