import { PromptDialog } from '../common/widgets/dialogs/prompt-dialog';
import { connect } from 'react-redux';
import { branch, compose, renderNothing, withProps } from 'react-recompose';
import _, { head } from 'lodash';
import Discover from '../common/redux/actions/DiscoverActions';
import { Viz } from './VizUtil';
import { Types } from '../common/Constants';
import styled from '@emotion/styled';
import { LightFontWeight } from '../components/ui/fonts';
import { IndicatorBubbleIcon } from '../components/Icons';
import { css } from '@emotion/react';
import { HelpBlock } from '../views/VizSaveDialog/ui';
import { messages } from '../i18n';
import { shortid } from '../common/utilities/shortid-adapter';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FieldListContainer = styled.div(
  ({
    theme: {
      colors: { ContentBackground, ContentTextColor },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 14px;
    font-weight: ${LightFontWeight};
    color: ${ContentTextColor};
    min-width: 400px;
    max-height: 400px;
    overflow-y: auto;
    background-color: ${ContentBackground};
    padding: 8px;
    margin: 16px 0 0 0;
    div {
      padding: 8px;
    }
    div > svg {
      margin-right: 8px;
      > circle {
        fill: ${ContentTextColor};
      }
    }
  `,
);

const RemoveFieldFromVizDialog = props => {
  const doDelete = () => {
    props.removeField(
      props.discoveryId,
      props.field,
      props.shelf,
      props.fieldRefs,
      props.layout,
    );
    props.hideConfirmRemoveField();
  };
  const cancel = () => {
    props.hideConfirmRemoveField();
  };
  let detail;
  let deleteButtonText = messages.ok;
  let cancelButtonText = messages.cancel;

  // 3 possible scenarios of why the user needs to confirm a field deletion
  //   1. User removes the Time field the calculated field is based on from the query
  //   2. User removes the Base field the calculated field is based on from the Values shelf
  //   3. User drags the Field out of the values shelf

  if (props.field?.attributeType === Types.PRIOR_PERIOD_CALC) {
    // 3. User drags the Field out of the values shelf
    detail = (
      <HelpBlock>
        <p className='message'>
          {messages.formatString(
            messages.removeFieldFromViz.removeFromReport,
            <strong>{props.field?.name}</strong>,
          )}
          <br />
        </p>
      </HelpBlock>
    );
    deleteButtonText = messages.yes;
    cancelButtonText = messages.no;
  } else {
    // go look for prior period fields in the layout that are referencing the field we are trying to delete
    const refs = props.fieldRefs ?? [];
    if (!_.isNil(refs) && refs.length === 1) {
      detail = (
        <HelpBlock>
          <p className='message'>
            {messages.formatString(
              messages.removeFieldFromViz.removingFieldFromReport,
              <strong>{props.field?.name}</strong>,
              <strong>{head(refs)?.name}</strong>,
            )}
            <br />
          </p>
        </HelpBlock>
      );
    } else if (!_.isNil(refs) && refs.length > 1) {
      detail = (
        <HelpBlock>
          <p className='message'>
            {messages.formatString(
              messages.removeFieldFromViz.removingFieldAlso,
              <strong>{props.field?.name}</strong>,
            )}
            <FlexWrapper>
              <FieldListContainer>
                {refs.map(f => {
                  return (
                    <div key={f.name}>
                      <IndicatorBubbleIcon />
                      <span>{f.name}</span>
                    </div>
                  );
                })}
              </FieldListContainer>
            </FlexWrapper>
          </p>
        </HelpBlock>
      );
    }
  }

  return (
    <PromptDialog
      show={props.show}
      detail={detail}
      yesText={deleteButtonText}
      doYes={() => {
        doDelete();
      }}
      noText={cancelButtonText}
      doNo={() => {
        cancel();
      }}
    />
  );
};

export default compose(
  connect(
    state => {
      const { field, discoveryId, shelf } = state.discover.toBeDeleted
        ? state.discover.toBeDeleted
        : {};
      const open = discoveryId
        ? state.discover.openDiscoveries[discoveryId].present
        : null;
      return {
        show: state.discover.showConfirmRemoveField,
        layout: open ? open.viz.layout : null,
        field,
        discoveryId,
        shelf,
        chartType: open ? open.viz.chartType : null,
      };
    },
    dispatch => {
      return {
        removeField: (id, field, shelf, refs, layout) => {
          const txId = shortid.generate();
          dispatch(
            Discover.removeFieldFromVisualization(id, field, shelf, txId),
          );
          if (field.attributeType === Types.PRIOR_PERIOD_CALC) {
            dispatch(Discover.deleteCalcFields(id, [field], txId));
          }
          if (!_.isEmpty(refs)) {
            refs.forEach(ref => {
              const fieldShelf = Viz.findShelfContainingField(layout, ref.name);
              dispatch(
                Discover.removeFieldFromVisualization(
                  id,
                  ref,
                  fieldShelf,
                  txId,
                ),
              );
              dispatch(Discover.deleteCalcFields(id, refs, txId));
            });
          }
        },
        hideConfirmRemoveField: () => {
          dispatch(Discover.hideConfirmRemoveField());
        },
      };
    },
  ),
  branch(
    props => !props.show || !props.field || !props.discoveryId,
    renderNothing,
  ),
  withProps(props => {
    return {
      fieldRefs: Viz.fieldIsReferencedByPriorPeriodCalc(
        props.layout,
        props.field,
      ),
    };
  }),
)(RemoveFieldFromVizDialog);
