import _ from 'lodash';
import { getClient } from '../../ApolloClient.common';
import Const from './ActionConst';
import copy from 'copy-to-clipboard';
import { GqlQuery } from '../../graphql';
import { CommonActions } from './common.actions';

const Actions = {
  setSystemDarkMode(isSystemDarkModeEnabled) {
    return dispatch =>
      dispatch({
        type: Const.Main.SET_SYSTEM_DARK_MODE,
        isSystemDarkModeEnabled,
      });
  },
  setUserAppearanceOverride(userApperanceOverride) {
    return dispatch =>
      dispatch({
        type: Const.Main.SET_USER_APPEARANCE_OVERRIDE,
        userApperanceOverride,
      });
  },
  setMobileMaxWidth(mobileMaxWidth) {
    return dispatch => {
      dispatch({
        type: Const.Main.SET_MOBILE_MAX_WIDTH,
        mobileMaxWidth,
      });
      dispatch(Actions.setIsMobile((window as any).viewportWidth));
    };
  },

  internalServerError: () => {
    return dispatch => {
      dispatch({
        type: Const.Main.INTERNAL_SERVER_ERROR,
      });
    };
  },

  toggleAdvanced() {
    return function (dispatch) {
      dispatch({ type: Const.Main.TOGGLE_ADVANCED });
    };
  },

  init: CommonActions.init,

  setActivityFeedStatus(isAvailable) {
    return {
      type: Const.Main.SET_ACTIVITY_FEED_STATUS,
      activityFeedAvailable: isAvailable,
    };
  },

  tabQueries: {} as { [key: string]: GqlQuery[] }, // Queries executed when tab is made visible
  setActiveTab(activeTab) {
    return dispatch => {
      if (!Const.Main.MENU_NAMES[activeTab]) {
        console.log(`invalid tab selected: ${activeTab}`);
        return;
      }
      dispatch({ type: Const.Main.ACTIVE_TAB, activeTab });
      // when selecting a tab, reissue associated queries
      const focusActions = this.tabQueries[activeTab] || [];
      setTimeout(() =>
        focusActions.forEach((query: GqlQuery) => {
          if (_.isObject(query)) {
            getClient().query({
              query,
              fetchPolicy: 'network-only',
            });
          }
        }),
      );
    };
  },
  refreshTabs() {
    return () => {
      const focusActions = _(this.tabQueries).values().flatten().value();
      setTimeout(() =>
        focusActions.forEach((query: GqlQuery) => {
          if (_.isObject(query)) {
            getClient().query({
              query,
              fetchPolicy: 'network-only',
            });
          }
        }),
      );
    };
  },
  setActiveSubmenuTab(activeTab, activeSubmenuTab) {
    return dispatch => {
      if (
        !Const.Main.MENU_NAMES[activeTab] ||
        !Const.Main.SUB_MENU_NAMES[activeTab]?.has(activeSubmenuTab)
      ) {
        console.log(
          `invalid submenu tab selected: ${activeTab}:${activeSubmenuTab}`,
        );
        return;
      }
      dispatch({
        type: Const.Main.ACTIVE_SUBMENU_TAB,
        activeTab,
        activeSubmenuTab,
      });
    };
  },
  showGlassPane(visible, content) {
    return dispatch => {
      dispatch({
        type: Const.Main.SHOW_GLASS_PANE,
        visible,
        content,
      });
    };
  },

  setControlDown(down) {
    return { type: Const.Main.SET_CONTROL_DOWN, down };
  },

  setMetaDown(down) {
    return { type: Const.Main.SET_META_DOWN, down };
  },

  showErrorDetail(show, title, errorDetail) {
    return {
      type: Const.Main.SHOW_ERROR_DETAIL,
      showErrorDetailDialog: show,
      errorTitle: title,
      errorDetail,
    };
  },

  showAppOutOfDate() {
    return dispatch => {
      dispatch({ type: Const.Main.SHOW_APP_OUT_OF_DATE });
    };
  },

  showReAuthenticate() {
    return dispatch => {
      dispatch({ type: Const.Main.SHOW_RE_AUTHENTICATE });
      const isOnLoginPage = /^\/?((login)|(initiallogin)|(logout))/i.test(
        window.location.pathname,
      );
      if (!isOnLoginPage) {
        // show logOUT dialog
        dispatch(Actions.returnToLogin());
      }
    };
  },

  returnToLogin: CommonActions.returnToLogin,
  unAuthedLogout: CommonActions.unAuthedLogout,
  forceMobile(shouldForceMobile) {
    return { type: Const.Main.SHOULD_FORCE_MOBILE, shouldForceMobile };
  },

  setIsMobile(viewportWidth) {
    return { type: Const.Main.SET_IS_MOBILE, viewportWidth };
  },

  setUrlLocation(pathname) {
    return { type: Const.Main.SET_URL_LOCATION, pathname };
  },

  copyToClipboard(text) {
    return () => {
      copy(text);
    };
  },

  showToast({ text, ...otherData }) {
    return dispatch => {
      const content = { text, ...otherData };
      dispatch({ type: Const.Main.SHOW_TOAST, content });
    };
  },
  closeToast(searchObj?) {
    return dispatch => {
      dispatch({ type: Const.Main.CLOSE_TOAST, searchObj });
    };
  },
  applicationInitialization: CommonActions.applicationInitialization,
  getAggregateInfo: CommonActions.getAggregateInfo,
  setSorting(domain, sortBy, sortDirection) {
    return { type: Const.Main.SET_SORTING, domain, sortBy, sortDirection };
  },
  setSearch(domain, search) {
    return { type: Const.Main.SET_SEARCH, domain, search };
  },
  setSearchTags(domain, tags) {
    return { type: Const.Main.SET_SEARCH_TAGS, domain, tags };
  },
  setSearchChartTypes(domain, chartTypes) {
    return {
      type: Const.Main.SET_SEARCH_CHART_TYPES,
      domain,
      chartTypes,
    };
  },
  setSearchDatasets(domain, searchDatasets) {
    return {
      type: Const.Main.SET_SEARCH_DATASETS,
      domain,
      searchDatasets,
    };
  },
  setAcceptCookies(acceptsCookieUse) {
    return { type: Const.Main.SET_ACCEPT_COOKIES, acceptsCookieUse };
  },
};

export default Actions;
