import { useMemo, FunctionComponent } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
} from 'react-router-dom';
import { shortid } from '../../utilities/shortid-adapter';
import { identity, noop } from 'lodash';
import { isFramelessMfeMode } from '../../Constants';

export type LegacyHistoryHook = {
  push(location: string, state?: any): void;
  location: Partial<Location>;
};

// since dashlets share the bundle with the full app, we should guard against usage
export const useLocationProtected = (): Partial<Location> => {
  try {
    return useLocation();
  } catch {
    return { pathname: window?.location?.pathname, key: shortid.generate() };
  }
};

// since dashlets share the bundle with the full app, we should guard against usage
export const useNavigateProtected = () => {
  try {
    return useNavigate();
  } catch {
    return noop;
  }
};

// providing legacy code react-router v5 useHistory API
export const useHistory = (): LegacyHistoryHook => {
  const navigate = useNavigateProtected();
  const location = useLocationProtected();

  return useMemo(
    () => ({
      push: (location, state) =>
        navigate(location, state ? { state } : undefined),
      location,
    }),
    [navigate, location],
  );
};

export const useWithRouterProps = () => {
  const history = useHistory();

  const navigate = useNavigateProtected();
  const params = useParams();

  return useMemo(
    () => ({
      location: history?.location,
      navigate,
      params,
      history,
    }),
    [history, navigate, params],
  );
};

export function withRouter(Component: FunctionComponent) {
  function ComponentWithRouterProp(props) {
    const { location, navigate, params, history } = useWithRouterProps();

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        history={history}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const withDiscoverRouter = isFramelessMfeMode() ? identity : withRouter;
