import UITable from '../../../components/UITable';
import { assignableRoles } from '../../../common/Util';
import {
  SelectDropdown,
  SelectItem,
} from '../../../ui/dropdowns/select-dropdown';
import { isEmpty, map, head } from 'lodash';
import { messages } from '../../../i18n';
import {
  AdministrationTableWrapper,
  ProfileImage,
} from './administration-table.styles';

export const AdministrationTable = ({ list, type, onRoleChange }) => {
  return (
    <AdministrationTableWrapper>
      <UITable data={list} domain='administration'>
        {({ Column, HeaderRendererWithSort, HorizontalCellInteractive }) => {
          const HorizontalCell = HorizontalCellInteractive;
          return [
            <Column
              label={messages.administration.tableNameColumn}
              key='fullName'
              headerRenderer={HeaderRendererWithSort}
              cellRenderer={({ rowData }) => {
                let profileImageUrl;
                try {
                  if (!isEmpty(rowData.profileImage)) {
                    // get the most recent version of the profile image
                    profileImageUrl = `/api/image/${rowData.email}/profile.jpg?${rowData.lastModifiedDate}`;
                  }
                } catch {
                  profileImageUrl = undefined;
                }

                return (
                  <HorizontalCell>
                    <ProfileImage src={profileImageUrl} />
                    {rowData.fullName}
                  </HorizontalCell>
                );
              }}
              dataKey='fullName'
              width={260}
            />,
            <Column
              label={messages.administration.tableEmailColumn}
              key='email'
              headerRenderer={HeaderRendererWithSort}
              cellRenderer={({ rowData }) => {
                return <HorizontalCell>{rowData.email}</HorizontalCell>;
              }}
              dataKey='email'
              width={300}
            />,
            <Column
              label={messages.administration.tableTitleColumn}
              key='title'
              headerRenderer={HeaderRendererWithSort}
              cellRenderer={({ rowData }) => {
                return <HorizontalCell>{rowData.title}</HorizontalCell>;
              }}
              dataKey='title'
              width={260}
            />,
            <Column
              label={messages.administration.tableRoleColumn}
              key='role'
              cellRenderer={({ rowData }) => {
                return (
                  <HorizontalCell style={{ containerType: 'normal' }}>
                    <SelectDropdown
                      title={
                        messages.nonTranslated.idmUserRoles[
                          head(rowData.roles as string[])
                        ]
                      }
                      headerSx={{ width: '100%' }}
                      disablePortal={false}
                      popperSx={{
                        zIndex: 1050,
                      }}
                    >
                      {map(assignableRoles, role => (
                        <SelectItem onClick={() => onRoleChange(rowData, role)}>
                          {messages.nonTranslated.idmUserRoles[role]}
                        </SelectItem>
                      ))}
                    </SelectDropdown>
                  </HorizontalCell>
                );
              }}
              dataKey='role'
              width={160}
              disableSort
            />,
            type === null,
            <Column
              label=''
              key='actions'
              dataKey='actions'
              width={160}
              disableSort
            />,
          ];
        }}
      </UITable>
    </AdministrationTableWrapper>
  );
};
