import { ModernBorderRadius } from '../../../emotion/mixins';
const cornerRadius = parseInt(ModernBorderRadius, 10);

export const topRoundedRect = (
  x,
  y,
  width,
  height,
  unadjustedRadius = cornerRadius,
) => {
  if (width === 0 || height === 0) {
    return '';
  }

  let radius = unadjustedRadius;

  if (radius > height) {
    radius = height;
  }

  // SVG path operations
  // https://www.w3.org/TR/SVG/paths.html#PathDataLinetoCommands
  // https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
  return `M${x + radius},${y}
    h${width - radius * 2}
    a${radius},${radius} 0 0 1 ${radius},${radius}
    v${height - radius}
    h${-width}
    v${-height + radius}
    a${radius},${radius} 0 0 1 ${radius},${-radius}z`;
};

export const bottomRoundedRect = (
  x,
  y,
  width,
  height,
  unadjustedRadius = cornerRadius,
) => {
  if (width === 0 || height === 0) {
    return '';
  }

  let radius = unadjustedRadius;

  if (radius > height) {
    radius = height;
  }

  // SVG path operations
  // https://www.w3.org/TR/SVG/paths.html#PathDataLinetoCommands
  // https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
  return `M${x},${y}
    h${width}
    v${height - radius}
    a${radius},${radius} 0 0 1 -${radius},${radius}
    h${-width + radius * 2}
    a${radius},${radius} 0 0 1 -${radius},-${radius}
    v${-height + radius}
    z`;
};

export const horizontalTopRoundedRect = (
  x,
  y,
  width,
  height,
  unadjustedRadius = cornerRadius,
) => {
  if (width === 0 || height === 0) {
    return '';
  }

  let radius = unadjustedRadius;

  if (radius > width) {
    radius = width;
  }

  // SVG path operations
  // https://www.w3.org/TR/SVG/paths.html#PathDataLinetoCommands
  // https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
  return `M${x},${y}
    h${width - radius}
    a${radius},${radius} 0 0 1 ${radius},${radius}
    v${height - radius - radius}
    a${radius},${radius} 0 0 1 -${radius},${radius}
    h-${width - radius}
    z`;
};

export const horizontalRoundedRect = (
  x,
  y,
  width,
  height,
  unadjustedRadius = cornerRadius,
) => {
  if (width === 0 || height === 0) {
    return '';
  }

  const topLeft = Math.min(unadjustedRadius, width / 2, height / 2);
  const topRight = Math.min(unadjustedRadius, width / 2, height / 2);
  const bottomRight = Math.min(unadjustedRadius, width / 2, height / 2);
  const bottomLeft = Math.min(unadjustedRadius, width / 2, height / 2);

  // SVG path operations
  // https://www.w3.org/TR/SVG/paths.html#PathDataLinetoCommands
  // https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
  return `M${x + topLeft},${y}
    h${width - topLeft - topRight}
    a${topRight},${topRight} 0 0 1 ${topRight},${topRight}
    v${height - topRight - bottomRight}
    a${bottomRight},${bottomRight} 0 0 1 -${bottomRight},${bottomRight}
    h-${width - bottomRight - bottomLeft}
    a${bottomLeft},${bottomLeft} 0 0 1 -${bottomLeft},-${bottomLeft}
    v-${height - bottomLeft - topLeft}
    a${topLeft},${topLeft} 0 0 1 ${topLeft},-${topLeft}
    z`;
};
