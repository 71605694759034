import _ from 'lodash';
import { memo } from 'react';
import {
  EMPTY_STRING_TOKEN,
  EMPTY_STRING_DISPLAY,
} from '../../../common/Constants';
import {
  ColorBoxContainer,
  ColorPickerToggle,
  ColorSeriesLabel,
  ColorSeriesRowRoot,
  DropdownCaret,
  Title,
} from './color-picker-row.styles';
import { Popover } from '@mui/material';
import { ColorBox } from '../common/color-picker-common.styles';
import { ColorPicker } from '../color-picker';
import { useColorPickerRowState } from './color-picker-row.hook';
import { NoFlex } from '../../../common/emotion';

export const ColorPickerRow = memo<{
  selectedColor: string;
  onSelect: (newColor: string) => void;
  label: string;
  disabled?: boolean;
}>(({ selectedColor, onSelect, label, disabled }) => {
  const { ref, color, setSelectedColor, handleClose, onClick, open, anchorEl } =
    useColorPickerRowState({ selectedColor, onSelect });
  const emptySafeLabel =
    label === EMPTY_STRING_TOKEN ? EMPTY_STRING_DISPLAY : label;
  const id = open ? `color-picker-${_.kebabCase(label)}` : null;

  return (
    <ColorSeriesRowRoot>
      <ColorSeriesLabel
        style={{ opacity: disabled ? 0.48 : 1 }}
        title={emptySafeLabel}
      >
        {emptySafeLabel}
      </ColorSeriesLabel>
      <div ref={ref}>
        <ColorPickerToggle
          disabled={disabled}
          disableRipple
          onClick={onClick}
          aria-describedby={id}
          variant='contained'
          color='primary'
        >
          <ColorBoxContainer>
            {color ? <ColorBox color={color} /> : <Title>None</Title>}
          </ColorBoxContainer>
          <NoFlex>
            <DropdownCaret />
          </NoFlex>
        </ColorPickerToggle>
      </div>
      <Popover
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <ColorPicker
          color={color}
          setColor={newColor => {
            setSelectedColor(newColor);
          }}
        />
      </Popover>
    </ColorSeriesRowRoot>
  );
});
