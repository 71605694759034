import { css } from '@emotion/react';
import {
  svgColorizePartial,
  font,
  svgColorize,
  hbox,
  vbox,
  flexFill,
  DetailTopRightPanelRadius,
} from './mixins';

export const widgetsStyles = ({
  theme: {
    darkMode,
    colors: {
      Clear,
      ButtonColorPrimary,
      ButtonColorPrimaryHover,
      DisabledPrimaryButtonColor,
      positiveColor,
      negativeColor,
      ChartLabelTextColor,
      ToolIconHoverColor,
      ActiveLinkText,
      LightFontWeight,
      TooltipColor,
      CorvanaLightText,
      CorvanaButtonIconColor,
      PanelBorder,
      PanelBackground,
      PanelShadowColor,
      RegularFontWeight,
      ToolIconColor,
      CorvanaMediumBorder,
      PanelIconHoverColor,
      PanelIconColor,
      PanelIconActiveColor,
      FilterSelectItemCheckbox,
      Strokes,
      ContentText,
      DropdownTextHover,
      DropdownBackgroundHover,
      DefaultButtonBorderColorActive,
      MediumFontWeight,
      GlyphIconColor,
      SensitivityHeaderText,
      ContentBackground,
      PrimaryButtonTextColor,
      LightBlue,
    },
  },
}) => {
  const CheckboxFontColor = SensitivityHeaderText;
  const LoadingButton = ButtonColorPrimary;
  const LoadingButtonHover = ButtonColorPrimaryHover;
  const LoadingButtonDisabled = DisabledPrimaryButtonColor;
  const LoadingButtonTextColor = PrimaryButtonTextColor;
  const closablePanelWidth = '320px';
  const closablePanelTransitionMs = '200ms';
  return css`
    .trend > img {
      height: 29px;
      width: 20px;
      vertical-align: middle;
    }
    .trend > .trend-label {
      margin-left: 5px;
      line-height: 29px;
    }
    .trend > .trend-label.trend-positive {
      color: ${positiveColor};
    }
    .trend > .trend-label.trend-negative {
      color: ${negativeColor};
    }
    .edit-text-popover {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
    .edit-text-popover svg {
      opacity: 0;
    }
    .edit-text-popover:hover {
      cursor: pointer;
      position: relative;
    }
    .edit-text-popover:hover svg {
      opacity: 1;
      cursor: pointer;
    }

    ${svgColorizePartial({
      fromColor: ChartLabelTextColor,
      toColor: ToolIconHoverColor,
    })('.edit-text-popover:hover svg:hover')}

    .edit-text-popover:hover .edit-text-popover-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: text;
    }
    .edit-text-popover-popup {
      max-width: none;
    }
    .edit-text-popover-popup > h3 {
      ${font({ size: '16px' })}
    }
    .edit-text-popover-popup input[type='text'] {
      ${font({ size: '14px' })}
      height: 30px;
      margin-right: 5px;
    }
    .edit-text-popover-popup
      form.form-inline
      > button.toolbar-btn.btn.toolbar-button {
      margin-right: 5px;
    }
    button.toolbar-btn.btn.toolbar-button[disabled] {
      cursor: default;
    }
    button.toolbar-btn.btn.toolbar-button {
      background-color: ${Clear};
      padding: 0px;
      line-height: 0px;
      vertical-align: top;
    }
    button.toolbar-btn.btn.toolbar-button:hover {
      background-color: ${Clear};
      padding: 0px;
      line-height: 0px;
      vertical-align: top;
    }

    ${svgColorize({ color: ActiveLinkText })(
      'button.toolbar-btn.btn.toolbar-button:hover:not([disabled])',
    )}

    .tooltip {
      ${font({ size: '16px', weight: LightFontWeight })}
    }
    .tooltip > .tooltip-inner {
      background-color: ${TooltipColor};
      opacity: 90;
    }
    .tooltip.top .tooltip-arrow {
      border-top-color: ${TooltipColor};
    }
    .tooltip.bottom .tooltip-arrow {
      border-bottom-color: ${TooltipColor};
    }
    .tooltip.right .tooltip-arrow {
      border-right-color: ${TooltipColor};
    }
    .tooltip.left .tooltip-arrow {
      border-left-color: ${TooltipColor};
    }
    .feedback {
      ${hbox()}
      justify-content: flex-end;
      color: ${CorvanaLightText};
      ${font({ weight: LightFontWeight })}
      vertical-align: top;
      text-align: right;
    }
    .feedback .feedback-label {
      margin-right: 16px;
      vertical-align: top;
      stroke: ${CorvanaButtonIconColor};
    }
    .feedback .fb {
      display: inline-block;
      background-repeat: no-repeat;
      width: 18px;
      height: 16px;
      margin-right: 8px;
    }
    .feedback .fbDisabled {
      display: inline-block;
      background-repeat: no-repeat;
      width: 18px;
      height: 16px;
      margin-right: 8px;
      cursor: not-allowed;
    }
    .feedback .fbEnabled {
      display: inline-block;
      background-repeat: no-repeat;
      width: 18px;
      height: 16px;
      margin-right: 8px;
      cursor: pointer;
    }

    .closable-panel-left > .panel-show > .panel.panel-default {
      position: relative;
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .closable-panel-left
      > .panel-show
      > .panel.panel-default
      .panel-heading::after {
      content: '';
      position: absolute;
      width: calc(100% - 16px);
      height: 1px;
      border-bottom: 1px solid ${PanelBorder};
      left: 8px;
      top: 45px;
    }
    .closable-panel-right > .panel-show > .panel.panel-default {
      position: relative;
      margin-bottom: 0px;
      border-radius: 0px;
    }
    .closable-panel-left > .panel-show > .panel {
      position: relative;
    }
    .closable-panel-left > .panel-show > .panel > .closable-panel-resize {
      position: absolute;
      right: -4px;
      height: 100%;
      width: 12px;
      z-index: 999;
      cursor: col-resize;
    }
    .closable-panel-right {
      height: 100%;
    }
    .closable-panel-left {
      margin-top: -50px;
      border-top-right-radius: ${DetailTopRightPanelRadius};
      box-shadow: 0 4px 6px -1px ${PanelShadowColor};
      z-index: 99;
    }
    .closable-panel-left > .panel-show,
    .closable-panel-right > .panel-show {
      height: 100%;
    }
    .closable-panel-left > .panel-show > .panel.panel-default,
    .closable-panel-right > .panel-show > .panel.panel-default {
      ${vbox()}
      width: ${closablePanelWidth};
      height: 100%;
      background-color: ${PanelBackground};
      border: ${darkMode && `1px solid ${LightBlue}`};
      border-bottom: none;
    }
    .closable-panel-left > .panel-show > .panel.panel-default .panel-heading,
    .closable-panel-right > .panel-show > .panel.panel-default .panel-heading {
      background-color: ${PanelBackground};
      border-bottom: none;
    }
    .closable-panel-left > .panel-show > .panel.panel-default .panel-title,
    .closable-panel-right > .panel-show > .panel.panel-default .panel-title {
      ${font({ size: '16px', color: ContentText, weight: RegularFontWeight })}
      text-align: center;
    }
    .closable-panel-left > .panel-show > .panel.panel-default .panel-body,
    .closable-panel-right > .panel-show > .panel.panel-default .panel-body {
      ${vbox()}
      flex: 1;
      width: 100%;
      padding: 0px;
      overflow: hidden;
    }
    .closable-panel-left
      > .panel-show
      > .panel.panel-default
      .panel-body
      .closable-panel-body,
    .closable-panel-right
      > .panel-show
      > .panel.panel-default
      .panel-body
      .closable-panel-body {
      ${vbox()}
      flex: 1;
      width: 100%;
    }
    .info-icon .icon-info-svg {
      fill: ${ToolIconColor};
    }
    .info-icon:hover .icon-info-svg {
      fill: ${ActiveLinkText};
    }
    .left-panel.exit {
      max-width: 0;
      transition: all ${closablePanelTransitionMs} ease-in;
    }
    .closable-panel-left.enter {
      min-width: ${closablePanelWidth};
      transform: translate(0%);
      transition: transform ${closablePanelTransitionMs} ease-in;
    }
    .closable-panel-left.exit {
      transform: translate(-100%);
      transition: transform ${closablePanelTransitionMs} ease-in;
      box-shadow: none;
    }
    .closable-panel-right-enter {
      width: ${closablePanelWidth};
      transform: translate(100%);
    }
    .closable-panel-right-enter.closable-panel-right-enter-active {
      width: ${closablePanelWidth};
      transform: translate(0);
      transition: transform ${closablePanelTransitionMs} ease-in;
    }
    .closable-panel-right-leave {
      width: ${closablePanelWidth};
      transform: translate(0);
    }
    .closable-panel-right-leave.closable-panel-right-leave-active {
      width: ${closablePanelWidth};
      transform: translate(100%);
      transition: transform ${closablePanelTransitionMs} ease-in;
    }
    .glass-pane {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      background-color: ${ContentBackground};
      opacity: 0.8;
      z-index: 2147483647;
      overflow: hidden;
    }

    ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: PanelIconHoverColor,
    })('.panel-icon:hover')}

    ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: PanelIconActiveColor,
    })(`
    .panel-icon.active,
    .panel-icon:active
  `)}
  
  ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: PanelIconHoverColor,
    })('.tool-icon:hover')}
  ${svgColorizePartial({
      fromColor: PanelIconActiveColor,
      toColor: PanelIconHoverColor,
    })('.tool-icon:hover')}
  
  ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: PanelIconActiveColor,
    })(`
    .tool-icon.active,
    .tool-icon:active
  `)}
  
  .custom-checkbox label {
      ${hbox()}
      padding: 0px;
      user-select: none;
    }
    .custom-checkbox .check-widget {
      display: inline-block;
      border: 1px solid ${FilterSelectItemCheckbox};
      border-radius: 2px;
      margin-top: 1px;
      height: 15px;
      width: 15px;
      min-width: 15px;
      background-color: ${ContentBackground};
    }
    .custom-checkbox .glyphicon {
      font-size: 12px;
    }
    .custom-checkbox .checkbox-label {
      margin-left: 8px;
      ${font({
        size: '14px',
        weight: LightFontWeight,
        color: CheckboxFontColor,
      })}
    }
    .custom-checkbox span {
      ${flexFill()}
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .custom-checkbox.disabled .check-widget {
      background-color: ${Strokes};
    }
    .custom-checkbox:not(.disabled) {
      cursor: pointer;
    }
    .custom-checkbox:not(.disabled) label {
      cursor: pointer;
    }
    .glyphicon {
      color: ${GlyphIconColor};
    }
    .dropdownStyle.aggregation-dropdown + ul,
    .dropdownStyle.aggregation-dropdown {
      min-width: 160px;
    }
    .corvana-calendar-picker {
      position: absolute;
      z-index: 10000;
      left: 0;
      top: 0;
      color: ${ContentText};
    }
    .portal-dropdown {
      position: absolute;
    }
    .portal-dropdown .dropdown-menu {
      left: -12px;
      top: 16px;
      margin-top: 2px;
    }
    .portal-dropdown .dropdown-menu > li.disabled > a,
    .portal-dropdown .dropdown-menu > li.disabled > a:hover {
      ${font({
        size: '12px',
        weight: LightFontWeight,
        color: CorvanaLightText,
      })}
    }

    ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: CorvanaLightText,
    })(
      `
      .portal-dropdown .dropdown-menu > li.disabled > a svg,
      .portal-dropdown .dropdown-menu > li.disabled > a:hover svg
    `,
    )}

    .portal-dropdown .dropdown-menu > li > a {
      font-size: 12px;
      padding: 4px 8px;
      min-width: 140px;
    }
    .portal-dropdown .dropdown-menu > li > a:active,
    .portal-dropdown .dropdown-menu > li > a:focus {
      outline: none;
    }

    ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: DropdownTextHover,
    })('.portal-dropdown .dropdown-menu > li > a:hover svg')}

    .portal-dropdown .dropdown-menu > li.selected {
      background-color: ${DropdownBackgroundHover};
      color: ${DropdownTextHover};
    }
    .status,
    .spinner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 16px;
    }
    .status .status-label,
    .spinner .status-label,
    .status .spinner-label,
    .spinner .spinner-label {
      ${font({ size: '12px', weight: RegularFontWeight, color: ContentText })}
      flex: 1;
      padding-left: 4px;
      white-space: normal;
    }
    .status .status-label a,
    .spinner .status-label a,
    .status .spinner-label a,
    .spinner .spinner-label a {
      color: ${ActiveLinkText};
    }
    .toggle-switch {
      ${font({ size: '12px', weight: LightFontWeight })}
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .toggle-switch .toggle {
      height: 20px;
    }
    .toggle-switch .group {
      position: relative;
      height: 17px;
      width: 32px;
      margin: 0 8px;
      cursor: pointer;
    }
    .toggle-switch .line {
      width: 32px;
      height: 2px;
      background-color: ${PanelIconColor};
      position: absolute;
      top: 8px;
    }
    .toggle-switch .circle {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: ${ContentBackground};
      position: absolute;
      transition: all 0.25s ease;
      top: 1px;
      left: 0px;
      border: 2px solid ${PanelIconColor};
    }
    .toggle-switch.checked .line {
      background-color: ${DefaultButtonBorderColorActive};
    }
    .toggle-switch.checked .circle {
      border: 2px solid ${DefaultButtonBorderColorActive};
      background-color: ${DefaultButtonBorderColorActive};
      transition: all 0.25s ease;
      left: 16px;
    }
    .phone-number input[type='tel'].react-phone-number-input__phone {
      font-weight: inherit;
      font-style: inherit;
      font-size: 16px;
      border: 1px solid ${CorvanaMediumBorder};
      color: ${ContentText};
      height: 40px;
      padding: 0 12px;
      width: 100%;
      /* use the same focus css as bootstrap .form-control:focus */
    }
    .profileImage {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }
    .loading-button {
      background-color: ${LoadingButton} !important;
      border: 1px solid ${LoadingButton} !important;
      height: 40px;
      padding: 8px 16px;
    }
    .loading-button:disabled,
    .loading-button[disabled]:hover {
      background-color: ${LoadingButtonDisabled} !important;
      border-color: ${LoadingButtonDisabled} !important;
      opacity: unset;
    }
    .loading-button:hover,
    .loading-button:active,
    .loading-button:focus {
      background-color: ${LoadingButtonHover} !important;
      border-color: ${LoadingButtonHover} !important;
    }
    .loading-button.is-loading,
    .loading-button.is-complete {
      pointer-events: none;
    }
    .loading-button .loading-button-content {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: ${LoadingButtonTextColor};
    }
    .loading-button .loading-button-content.is-loading > div:first-of-type {
      margin-right: 10px;
    }
    .loading-button .loading-button-content.is-complete > svg {
      margin-right: 10px;
    }
    .longPress {
      touch-action: none;
      user-select: none;
      -webkit-touch-callout: none;
      /* for iOS */
    }
    .password-content-text {
      margin: 16px 0;
      ${font({
        size: '16px',
        weight: MediumFontWeight,
        color: ContentBackground,
      })}
    }
    li.password-validation-indicator {
      ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
      list-style: none;
      align-content: center;
      display: flex;
      line-height: 24px;
      margin-bottom: 2px;
    }
    .password-validation ul {
      list-style: none;
      margin: 0 0 32px 8px;
      padding: 0;
    }
    .password-validation li {
      display: flex;
      align-items: center;
    }
    .password-validation li .sicon {
      margin-right: 8px;
    }
    .hideScroll {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .hideScroll::-webkit-scrollbar {
      display: none;
    }
    .hideScroll-y {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .hideScroll-y::-webkit-scrollbar:vertical {
      display: none;
      background-color: red;
    }
    .force-on-top {
      z-index: 2147483647;
    }
  `;
};
