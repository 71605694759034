import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  IDiscoverEmotionTheme,
  mainContentHeightProperty,
  vbox,
} from '../../common/emotion';

export const AppBackground = styled.div(
  ({
    theme: {
      isMobile,
      colors: { FromBackgroundGradientColor, ToBackgroundGradientColor },
    } = {} as IDiscoverEmotionTheme,
  }: any) => {
    return css`
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: ${isMobile ? 'column-reverse' : 'column'};
      background: ${`linear-gradient(45deg, ${FromBackgroundGradientColor}, ${ToBackgroundGradientColor});`};

      & > div {
        width: ${isMobile ? '100vw' : 'calc(100vw - 108px)'};
        margin-left: ${isMobile ? 0 : '24px'};
      }
    `;
  },
);

export const MainContentWrapper = styled.div(
  ({
    theme: {
      colors: { PanelBackground, MainContentBoxShadowColor },
      isMobile,
    } = {} as IDiscoverEmotionTheme,
    isMobile: _isMobile,
    isDashletMode: _isDashletMode,
  }: any) => css`
    background: ${PanelBackground};
    overflow-y: auto;
    ${vbox()};
    flex: 1;
    border-radius: ${isMobile ? 'none' : '24px 24px 0 0'};
    box-shadow: ${isMobile
      ? 'none'
      : `0 20px 20px 1px ${MainContentBoxShadowColor}`};

    ${mainContentHeightProperty({
      isMobile: _isMobile,
      isDashletMode: _isDashletMode,
    })}

    & > div {
      overflow: hidden;
      flex: 1;
      position: relative;
    }
  `,
);
