import component from './component';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { DatasetQueries } from '../../common/graphql';
import {
  isAdmin,
  isAppAdmin,
} from '../../common/redux/selectors/AccountSelectors';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

const AggQuery = graphql(DatasetQueries.AggregationInfo, {
  skip: ownProps => _.isEmpty(ownProps.datasetId),
  props: ({ data }) => {
    if (data?.aggregateInfo && !data.aggregateInfo.loading) {
      return {
        aggregateInfo: [...data.aggregateInfo],
      };
    } else {
      return {
        aggregateInfo: [],
      };
    }
  },
});

export default compose(
  AggQuery,
  connect(state => ({
    isAdmin: isAdmin(state.account),
    isAppAdmin: isAppAdmin(state.account),
    i18nPrefs: state?.account?.currentUser?.i18nPrefs,
  })),
)(component);
