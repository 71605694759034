'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { DatasetQueries } from '../../common/graphql';
import {
  DATA_TYPE_FORMAT,
  DATASET_EDITOR_PANELS,
} from '../../common/Constants';
import { Tooltip } from '../../components/ui/tooltip';
import {
  AutoSizer,
  Column,
  List,
  SortDirection,
  Table,
} from 'react-virtualized';
import { TextSearchField } from '../../components/ui/form';
import { CloseIcon } from '../../icons/icons/icons.component';
import { CorbotoLoading, TableSkeleton } from '../../common/loaders';
import * as UI from './ui';
import _, { includes } from 'lodash';
import classnames from 'classnames';
import { TableSpec } from './tableSpec';
import { messages } from '../../i18n';
import {
  DatasetPreviewTableColumn,
  ToolBar,
} from './dataset-preview-table.styles';
import { PrimaryButton } from '../../ui';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import {
  CheckboxStyles,
  FormControlLabelStyles,
  LinkUrlLabelStyles,
} from '../../discovery/viz-formatting';
import { SugarIcon } from '../../icons/sugar-icon/sugar-icon.component';
import { FilterToolbar } from './filter-toolbar';
import { PanelHeader } from './panel-header';
import { Switch } from '../../components/switch';

const LIMIT_INC = 100;
const LINK_URL = 'LINK_URL';

const defaultAnnotations = [
  { key: 'ENUM_ID_ATTRIB', disabled: false },
  { key: 'RELATED_SEGMENTS', disabled: true },
  { key: 'DATE_ATTRIBUTE', disabled: true },
  { key: 'COMPARISON_PERIOD_TYPE', value: 'CALENDAR_MONTHS', disabled: true },
  { key: 'COMPARISON_PERIOD_NUM', value: '1', disabled: true },
  { key: 'COMPARISON_PERIOD_AGG_TYPE', disabled: true },
  { key: 'SUBSTITUTE_MEASURE_ATTRIBUTE', disabled: true },
  { key: 'PARTITION_ATTRIBUTE', disabled: true },
  { key: 'INCREASES_ARE_GOOD', value: 'true', disabled: true },
  { key: 'NUM_ENTRIES', value: '[2:4]', disabled: true },
  { key: 'MOVER_PCT_THRESHOLD', value: '[-1:1]', disabled: true },
  { key: 'SCORE_IMPACT_PCT', value: '99', disabled: true },
  { key: 'SCORE_INCREASE_PCT', value: '1', disabled: true },
  { key: 'EXCLUDE_SEGMENTS', disabled: true },
  { key: 'SEGMENT_PERCENT_THRESHOLD', value: '2', disabled: true },
  { key: 'DISTRIBUTION_CEIL', value: '0.5', disabled: true },
  { key: 'DISTRIBUTION_THRESHOLD', value: '0.0', disabled: true },
  { key: 'DATE_ATTRIBUTE_OVERRIDE', disabled: true },
  { key: LINK_URL, value: '', disabled: true, hidden: true },
  { key: 'INSIGHT_CONTEXT', value: '', disabled: true }, // deprecated, but leaving here for backend continuity
  { key: 'IS_STAGE_FIELD', value: 'false', disabled: false },
  { key: 'STAGE_STATE', value: 'INACTIVE', disabled: false },
  { key: 'TO_STAGE_ATTRIB', value: '', disabled: true },
  { key: 'TO_STAGE_ORDINAL_ATTRIB', value: '', disabled: true },
  { key: 'TO_STAGE_ACTIVE_ATTRIB', value: '', disabled: true },
  { key: 'TO_STAGE_VALUE_ATTRIB', value: '', disabled: true },
  { key: 'DAYS_IN_STAGE_ATTRIB', value: '', disabled: true },
  { key: 'STAGE_TRANSITION_ATTRIB', value: '', disabled: true },
];

// for splitting the annotations list into another editor panel
const fieldEditingFormGroups = [
  {
    annotations: [
      // array so annotations can be grouped under one checkbox
      {
        key: 'INCREASES_ARE_GOOD',
        label: messages.editDatasetPanel.increasesAreGoodLabel,
        type: 'boolean',
      },
    ],
  },
  {
    annotations: [
      {
        key: 'ENUM_ID_ATTRIB',
        hidden: true,
      },
      {
        key: 'IS_STAGE_FIELD',
        label: messages.editDatasetPanel.enableStageConfigurationLabel,
        type: 'boolean',
      },
      {
        key: 'STAGE_STATE',
        label: messages.editDatasetPanel.stageStateLabel,
        hidden: true,
        readonly: true,
      },
      {
        key: 'DAYS_IN_STAGE_ATTRIB',
        label: messages.editDatasetPanel.daysInStageFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
      {
        key: 'STAGE_TRANSITION_ATTRIB',
        label: messages.editDatasetPanel.stageTransitionFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
      {
        key: 'TO_STAGE_ATTRIB',
        label: messages.editDatasetPanel.toStageFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
      {
        key: 'TO_STAGE_ORDINAL_ATTRIB',
        label: messages.editDatasetPanel.toStageOrdinalFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
      {
        key: 'TO_STAGE_ACTIVE_ATTRIB',
        label: messages.editDatasetPanel.toStageActiveFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
      {
        key: 'TO_STAGE_VALUE_ATTRIB',
        label: messages.editDatasetPanel.toStageValueFieldLabel,
        enabledBy: 'IS_STAGE_FIELD',
      },
    ],
  },
];

const DISABLED_KEY_SUFFIX = '-disabled';

const mapDisabledKeyToValue = annotation => {
  if (_.isNil(annotation)) {
    return;
  }

  const annoKeyObj = annotation.key.split(DISABLED_KEY_SUFFIX);
  if (annoKeyObj.length > 1) {
    // has DISABLED_KEY_SUFFIX
    return { ...annotation, key: annoKeyObj[0], disabled: true };
  }
  return annotation;
};

const mapDisabledValueToKey = annotation => {
  if (_.isNil(annotation)) {
    return;
  }

  if (annotation.disabled === true) {
    return {
      key: annotation.key + DISABLED_KEY_SUFFIX,
      value: annotation.value,
    };
  }
  return { key: annotation.key, value: annotation.value };
};

class component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      sortedList: [],
      sortBy: TableSpec.defaultSortBy,
      sortDirection: SortDirection.ASC,
      search: '',
      activeFilters: [],
      previouslySelectedAttributeIdx: null,
      originalSelectedAttributeRef: null,
      updatedAttributeData: null,
      previewListScrollToIndex: 0,
      limit: LIMIT_INC,
      datasetEditorPanel: null,
      configureInsightsFormData: null,
      editFieldSettingsFormData: null,
      annotationKeys: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onSelectAttributeForPreviewData =
      this.onSelectAttributeForPreviewData.bind(this);
    this.removeCalc = props.removeCalc.bind(this);
    this.getAttributeProfile = this.getAttributeProfile.bind(this);
    this.onSelectAttributeForConfigInsights =
      this.onSelectAttributeForConfigInsights.bind(this);
    this.onSelectAttributeForEditFieldSettings =
      this.onSelectAttributeForEditFieldSettings.bind(this);
    this.onSelectAttributeForFieldNameEdit =
      this.onSelectAttributeForFieldNameEdit.bind(this);
    this.cachingCellRenderer = this.cachingCellRenderer.bind(this);
    this.onSort = this.onSort.bind(this);
    this.tableColumnFilter = this.tableColumnFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.isPanelOpen = this.isPanelOpen.bind(this);
    this.onPreviewIconClick = this.onPreviewIconClick.bind(this);
    this.onEditFieldIconClick = this.onEditFieldIconClick.bind(this);
    this.onConfigureInsightsIconClick =
      this.onConfigureInsightsIconClick.bind(this);
    this.saveAttributeDataIfNecessary =
      this.saveAttributeDataIfNecessary.bind(this);
    this.renderFieldSettingsAnnotation =
      this.renderFieldSettingsAnnotation.bind(this);
    this.findDefaultAnnotation = this.findDefaultAnnotation.bind(this);
    this.removeNonUniqueDefaultAnnotations =
      this.removeNonUniqueDefaultAnnotations.bind(this);
    this.onOrdinalAttributeValueChange =
      this.onOrdinalAttributeValueChange.bind(this);
    this.renderedCellCache = {};
    this.renderedCellCacheData = {};
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = null;
    delete this.renderedCellCache;
    delete this.renderedCellCacheData;
  }

  componentDidUpdate(prevProps, prevState) {
    const compareKeys = [
      'attributes',
      'sortBy',
      'sortDirection',
      'search',
      'activeFilters',
    ];
    const prev = _.pick(prevState, compareKeys);
    const curr = _.pick(this.state, compareKeys);
    if (!_.isEqual(prev, curr)) {
      delete this.renderedCellCache;
      delete this.renderedCellCacheData;
      this.renderedCellCache = {};
      this.renderedCellCacheData = {};
      const { attributes, sortBy, sortDirection, search, activeFilters } = curr;
      const sortedList = TableSpec.sort({
        data: [...attributes],
        sortBy,
        sortDirection,
        search,
        activeFilters,
        getAttributeProfile: name => this.getAttributeProfile(name),
      });
      this.setState({ sortedList });
    }

    if (
      !_.isEqual(
        this.state.updatedAttributeData,
        prevState.updatedAttributeData,
      )
    ) {
      this.setState({ previewListScrollToIndex: 0 });
    } else if (!_.isEqual(this.state.limit, prevState.limit)) {
      this.setState({ previewListScrollToIndex: this.state.limit - 1 });
    }

    if (!_.isEqual(this.props.attributes, prevProps.attributes)) {
      this.setState({ attributes: this.props.attributes });
    }

    if (!_.isEqual(this.state.annotationKeys, this.props.annotationKeys)) {
      this.setState({ annotationKeys: this.props.annotationKeys });
    }
  }

  onSearch(search) {
    this.setState({ search });
  }

  onSort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  onToggleFilter(filter, isActive, event) {
    event.target.blur();
    const activeFilters = isActive
      ? [...this.state.activeFilters, filter]
      : _.difference(this.state.activeFilters, [filter]);
    this.setState({ activeFilters });
  }

  onClearFilters(event) {
    event.target.blur();
    this.setState({ activeFilters: [] });
  }

  // Calls an onChange given by the parent component with the updated data
  onChange(attribute, data) {
    const newAttribute = { ...attribute, ...data };
    const changedProperties = _.keys(data).join(',');
    if (data.formatType === 'Custom') {
      this.props.onChange(newAttribute, changedProperties, true);
    } else if (!_.isEqual(attribute, newAttribute)) {
      // update parent component
      this.props.onChange(newAttribute, changedProperties);
    }

    this.setState({
      saveDisabled: false,
    });
  }

  onPanelSwitch(attribute, panel, callback) {
    // close if clicking the same attribute edit icon
    if (
      this.isPanelOpen(panel) &&
      _.isEqual(
        this.state.updatedAttributeData.originalName,
        attribute.originalName,
      )
    ) {
      this.closePanel();
    } else {
      callback(attribute);
      this.setState({ datasetEditorPanel: panel });
    }
  }

  isPanelOpen(panel) {
    const { datasetEditorPanel } = this.state;

    if (panel) {
      return datasetEditorPanel === panel;
    }
    // if any panel is open
    return _.includes(DATASET_EDITOR_PANELS, datasetEditorPanel);
  }

  closePanel() {
    const { updatedAttributeData } = this.state;

    // updates from Configure Insights should be preserved
    if (this.isPanelOpen(DATASET_EDITOR_PANELS.ANNOTATIONS)) {
      const { configureInsightsFormData } = this.state;

      if (configureInsightsFormData) {
        this.updateAttributeAnnotations(updatedAttributeData);
      }
    }

    // close Attribute
    this.setState({
      previouslySelectedAttributeIdx:
        Number.isInteger(updatedAttributeData) && updatedAttributeData.index,
      originalSelectedAttributeRef: null,
      updatedAttributeData: null,
      datasetEditorPanel: null,
    });
  }

  onPreviewIconClick(attribute) {
    this.onPanelSwitch(
      attribute,
      DATASET_EDITOR_PANELS.DATA_PREVIEW,
      this.onSelectAttributeForPreviewData,
    );
  }

  onEditFieldIconClick(attribute) {
    this.onPanelSwitch(
      attribute,
      DATASET_EDITOR_PANELS.FIELD_SETTINGS,
      this.onSelectAttributeForEditFieldSettings,
    );
  }

  onConfigureInsightsIconClick(attribute) {
    // @NOTE: has all editing for attribute & attribute annotations been performed before this?
    this.onPanelSwitch(
      attribute,
      DATASET_EDITOR_PANELS.ANNOTATIONS,
      this.onSelectAttributeForConfigInsights,
    );
  }

  onSelectAttributeForPreviewData(attribute) {
    const { updatedAttributeData } = this.state;

    this.setState({
      originalSelectedAttributeRef: attribute,
      previouslySelectedAttributeIdx:
        Number.isInteger(updatedAttributeData) && updatedAttributeData.index,
      updatedAttributeData: attribute,
      limit: LIMIT_INC,
      listScrollTop: 0,
    });
  }

  onSelectAttributeForConfigInsights(attribute) {
    const { updatedAttributeData } = this.state;

    this.saveAttributeDataIfNecessary(attribute);

    const correctedAttribute = this.convertBackendData(attribute);

    const configInsightsAnnotations =
      this.buildConfigureInsightsFormData(correctedAttribute);

    this.setState({
      originalSelectedAttributeRef: correctedAttribute,
      previouslySelectedAttributeIdx:
        Number.isInteger(updatedAttributeData) && updatedAttributeData.index,
      updatedAttributeData: correctedAttribute,
      configureInsightsFormData: configInsightsAnnotations,
    });
  }

  onSelectAttributeForEditFieldSettings(attribute) {
    const { updatedAttributeData } = this.state;

    this.saveAttributeDataIfNecessary(attribute);

    const correctedAttribute = this.convertBackendData(attribute);

    const editFieldSettingsFormData =
      this.buildFieldEditorFormData(correctedAttribute);

    const attributeAnnotations = _.map(
      _.get(attribute, 'annotations', []),
      mapDisabledKeyToValue,
    );

    const fieldSettingsAnnotations = _.reduce(
      editFieldSettingsFormData,
      (annotationsAccum, formGroup) => {
        const formGroupAnnotations = _.get(formGroup, 'annotations', undefined);

        if (formGroupAnnotations) {
          annotationsAccum.concat(formGroupAnnotations);
        }

        return annotationsAccum;
      },
      [],
    );

    const mergedAnnotations = this.mergeAnnotations(
      attributeAnnotations,
      fieldSettingsAnnotations,
    );

    const configInsightsAnnotations = this.buildConfigureInsightsFormData(
      correctedAttribute,
    ).filter(({ key }) => key === LINK_URL);

    this.setState({
      originalSelectedAttributeRef: correctedAttribute,
      previouslySelectedAttributeIdx:
        Number.isInteger(updatedAttributeData) && updatedAttributeData.index,
      updatedAttributeData: {
        ...correctedAttribute,
        annotations: mergedAnnotations,
      },
      editFieldSettingsFormData,
      configureInsightsFormData: configInsightsAnnotations,
    });
  }

  onSelectAttributeForFieldNameEdit(attribute) {
    const { updatedAttributeData } = this.state;
    this.saveAttributeDataIfNecessary(attribute);

    if (
      !_.isNil(updatedAttributeData) &&
      !_.isEqual(updatedAttributeData.originalName, attribute.originalName)
    ) {
      this.closePanel();
    }

    const correctedAttribute = this.convertBackendData(attribute);

    this.setState({
      originalSelectedAttributeRef: correctedAttribute,
      previouslySelectedAttributeIdx:
        Number.isInteger(updatedAttributeData) && updatedAttributeData.index,
      updatedAttributeData: correctedAttribute,
    });
  }

  onChangeFieldSettingAnnotation(newAnnotation) {
    const { editFieldSettingsFormData, updatedAttributeData } = this.state;

    const existingDefault = this.findDefaultAnnotation(newAnnotation);

    // set default on change if value was cleared
    if (
      (_.isEmpty(newAnnotation.value) || _.isNil(newAnnotation.value)) &&
      existingDefault
    ) {
      newAnnotation.value = existingDefault.value || '';
    }

    // update the data structure in editFieldSettingsFormData
    const newEditFieldSettingsFormData = editFieldSettingsFormData.map(
      formGroup => {
        const formGroupAnnotations = _.get(formGroup, 'annotations', []);
        const isUpdatingExisting = _.find(
          formGroupAnnotations,
          _.matches({ key: newAnnotation.key }),
        );

        if (isUpdatingExisting) {
          const newAnnotations = formGroupAnnotations.map(annotation => {
            if (annotation.key === newAnnotation.key) {
              return newAnnotation;
            }
            return annotation;
          });

          return { ...formGroup, annotations: newAnnotations };
        }

        return formGroup;
      },
    );

    const updatedAttributeAnnotations = _.get(
      updatedAttributeData,
      'annotations',
      [],
    );

    const mergingAnnotations = [
      // @TODO: TypeScript will be helpful here
      {
        key: newAnnotation.key,
        value: newAnnotation.value,
        disabled: newAnnotation.disabled,
      },
    ];

    const mergeAttributeAnnotations = this.mergeAnnotations(
      updatedAttributeAnnotations,
      mergingAnnotations,
    );
    const updatedAttribute = {
      ...updatedAttributeData,
      annotations: mergeAttributeAnnotations,
    };

    this.updateAttributeAnnotations(updatedAttribute);

    this.setState({
      editFieldSettingsFormData: newEditFieldSettingsFormData,
      updatedAttributeData: updatedAttribute,
    });
  }

  onChangeConfigureInsightAnnotation(newAnnotation) {
    const { configureInsightsFormData, updatedAttributeData, attributes } =
      this.state;

    const existingAnnotations =
      _.find(attributes, {
        index: updatedAttributeData.index,
      })?.annotations ?? [];
    // sometimes annotations have undefined values (e.g. backend provided non-default that is undefined)
    if (_.isNil(newAnnotation.value)) {
      newAnnotation.value = '';
    }

    const existingDefault = this.findDefaultAnnotation(newAnnotation);

    // set default on change if value was cleared
    if (
      (_.isEmpty(newAnnotation.value) || _.isNil(newAnnotation.value)) &&
      existingDefault
    ) {
      newAnnotation.value = existingDefault.value || '';
    }

    const configureInsightAnnotations = configureInsightsFormData.map(anno => {
      if (anno.key === newAnnotation.key) {
        return newAnnotation;
      }
      return anno;
    });

    const mergeAttributeAnnotations = this.mergeAnnotations(
      this.mergeAnnotations(
        existingAnnotations,
        updatedAttributeData.annotations,
      ),
      configureInsightAnnotations,
    );
    const updatedAttribute = {
      ...updatedAttributeData,
      annotations: mergeAttributeAnnotations,
    };

    this.updateAttributeAnnotations(updatedAttribute);

    this.setState({
      configureInsightsFormData: configureInsightAnnotations,
      updatedAttributeData: updatedAttribute,
    });
  }

  // this is a change to a property of the Attribute, which is handled slightly differently than annotations
  onOrdinalAttributeValueChange(newOrdinalAttributeValue) {
    const { updatedAttributeData } = this.state;

    const attributeAnnotations = _.get(updatedAttributeData, 'annotations', []);

    const newUpdatedAttributeData = {
      ...updatedAttributeData,
      ordinalAttribute: newOrdinalAttributeValue,
    };

    this.setState({
      ...this.state,
      updatedAttributeData: newUpdatedAttributeData,
    });

    const attributeAnnotationsAsKeys = this.removeNonUniqueDefaultAnnotations(
      attributeAnnotations,
    ).map(mapDisabledValueToKey);

    this.onChange(updatedAttributeData, {
      ...newUpdatedAttributeData,
      annotations: attributeAnnotationsAsKeys,
    });
  }

  saveAttributeDataIfNecessary(attribute) {
    const { updatedAttributeData } = this.state;

    // If we're already editing one, save that before editing the next
    if (
      !_.isNil(updatedAttributeData) &&
      !_.isEqual(updatedAttributeData.originalName, attribute.originalName)
    ) {
      this.updateAttributeAnnotations(updatedAttributeData);
    }
  }

  // merge newAnnotations into existingAnnotations annotations without removing from existingAnnotations
  mergeAnnotations(existingAnnotations, newAnnotations) {
    const newAnnotationArray = _.isArray(newAnnotations)
      ? newAnnotations
      : [newAnnotations];

    return _.reduce(
      newAnnotationArray,
      (annotations, newAnnotation) => {
        if (_.find(annotations, _.matches({ key: newAnnotation.key }))) {
          return annotations.map(accumulatedAnnotation => {
            // update the annotations with new configInsightAnno
            if (newAnnotation.key === accumulatedAnnotation.key) {
              return newAnnotation;
            }
            return accumulatedAnnotation;
          });
        } else {
          return annotations.concat([newAnnotation]); // insert new annotation
        }
      },
      existingAnnotations,
    );
  }

  removeNonUniqueDefaultAnnotations(annotations) {
    const defaultAnnotationsAsDisabledValues = defaultAnnotations.map(
      mapDisabledKeyToValue,
    );
    return annotations
      .map(annotation => {
        const compare = (s1, s2) => {
          if (_.isEmpty(s1) || _.isEmpty(s2)) {
            return _.isEmpty(s1) === _.isEmpty(s2);
          }
          return s1 === s2;
        };

        const defaultAnnotation = _.find(
          defaultAnnotationsAsDisabledValues,
          _.matches({ key: annotation.key }),
        );

        // if the annotation has not changed from default
        if (
          defaultAnnotation &&
          compare(annotation.value, defaultAnnotation.value) &&
          annotation.disabled === defaultAnnotation.disabled
        ) {
          return null;
        }
        return annotation;
      })
      .filter(Boolean);
  }

  updateAttributeAnnotations(newAttribute) {
    const mergingAnnotations = _.map(
      newAttribute?.annotations,
      mapDisabledKeyToValue,
    );
    const attributeAnnotations = this.mergeAnnotations(
      this.getNonEditableAnnotations(newAttribute),
      mergingAnnotations,
    );

    const newAnnotations =
      this.removeNonUniqueDefaultAnnotations(attributeAnnotations);

    const { originalSelectedAttributeRef } = this.state;
    const originalAnnotations = _.map(
      originalSelectedAttributeRef?.annotations,
      mapDisabledKeyToValue,
    );
    const originalAnnotationsWithoutDefaults =
      this.removeNonUniqueDefaultAnnotations(originalAnnotations);

    // initial equivalence comparison
    if (
      originalAnnotationsWithoutDefaults &&
      newAnnotations &&
      originalAnnotationsWithoutDefaults.length === newAnnotations.length
    ) {
      // deeper equivalence comparison
      const derivativeAnnotations = _.filter(newAnnotations, newAnnotation => {
        const existing = _.find(
          originalAnnotationsWithoutDefaults,
          _.matches({ key: newAnnotation.key }),
        );
        return !existing || !_.isEqual(existing, newAnnotation);
      });

      if (derivativeAnnotations.length === 0) {
        return; // no derivative update
      }
    }

    const attributeAnnotationsAsKeys = newAnnotations.map(
      mapDisabledValueToKey,
    );

    // update is required
    this.onChange(
      {
        ...newAttribute,
        annotations: originalAnnotationsWithoutDefaults.map(
          mapDisabledValueToKey,
        ),
      },
      { annotations: attributeAnnotationsAsKeys },
    );
  }

  buildConfigureInsightsFormData(attribute) {
    const attributeAnnotations = _.get(attribute, 'annotations', []);

    // filter only the annotations we want in the Configure Insights panel
    return _.reject(attributeAnnotations, annotation => {
      // removing annotations meant for Field Editor panel
      return _.find(fieldEditingFormGroups, formGroup => {
        if (_.has(formGroup, 'annotations')) {
          return _.includes(
            _.map(formGroup.annotations, 'key'),
            annotation.key,
          );
        }
      });
    });
  }

  buildFieldEditorFormData(attribute) {
    const attributeAnnotations = _.get(attribute, 'annotations', []);

    const fieldEdits = _.cloneDeep(fieldEditingFormGroups);

    // filter only the annotations we want in the Field Editor panel
    return fieldEdits
      .map(formGroup => {
        if (_.has(formGroup, 'annotations')) {
          formGroup.annotations = _(formGroup.annotations)
            .map(annotation => {
              const attrAnnotation = _.find(
                attributeAnnotations,
                _.matches({ key: annotation.key }),
              );
              if (attrAnnotation) {
                return { ...annotation, ...attrAnnotation }; // hydrate
              } else {
                return null;
              }
            })
            .compact()
            .value();
        }
        return formGroup;
      })
      .filter(Boolean);
  }

  findDefaultAnnotation = annotation => {
    return _.find(defaultAnnotations, _.matches({ key: annotation.key }));
  };

  renderFieldSettingsAnnotation(annotation) {
    let { enabledBy, key, hidden, label, value, readonly, disabled } =
      annotation;
    if (hidden) {
      return <></>;
    }
    const { updatedAttributeData } = this.state;

    const isBooleanValue = annotation.type === 'boolean';

    const onChangeBoolean = newValue => {
      this.onChangeFieldSettingAnnotation({
        ...annotation,
        value: newValue === true ? 'true' : 'false',
        disabled: false,
      });
    };
    const isEnabled =
      _.some(
        updatedAttributeData.annotations,
        _.matches({
          key: 'IS_STAGE_FIELD',
          value: 'true',
        }),
      ) && !readonly;
    const isActive = _.some(
      updatedAttributeData.annotations,
      _.matches({
        key: 'STAGE_STATE',
        value: 'ACTIVE',
      }),
    );
    if (enabledBy) {
      const isEnabledByParentField =
        _.some(
          updatedAttributeData.annotations,
          _.matches({ key: enabledBy, value: 'true' }),
        ) && isActive;
      if (!isEnabledByParentField !== !!disabled) {
        disabled = !isEnabledByParentField;
        this.onChangeFieldSettingAnnotation({ ...annotation, disabled });
      }
    }
    if (isBooleanValue) {
      const setValue = annotation.value === 'true';
      const initialValue = setValue && !disabled;
      if (setValue !== initialValue) {
        onChangeBoolean(initialValue);
      }
      const formControlLabelSx = FormControlLabelStyles();
      const checkboxSx = CheckboxStyles();
      return (
        <div>
          <FormControlLabel
            sx={formControlLabelSx}
            control={
              <Checkbox
                sx={checkboxSx}
                checked={initialValue}
                title={key}
                onChange={e => {
                  onChangeBoolean(e?.target?.checked);
                }}
              />
            }
            label={<label className={'control-label'}>{label}</label>}
          />
        </div>
      );
    } else {
      let validationState;
      let helpText = '';
      if (isEnabled) {
        validationState = isActive ? 'success' : 'warning';
        helpText = isActive
          ? messages.editDatasetPanel.fieldCreated
          : messages.editDatasetPanel.fieldCreatedNextTime;
        disabled = disabled || !isActive;
      }
      disabled = disabled || readonly;
      return (
        <div
          disabled={disabled}
          className={classnames('field-settings-form-group', 'form-group', {
            'has-warning': validationState,
          })}
        >
          <label
            className={classnames('control-label', {
              'disabled-control-label': disabled,
            })}
          >
            {label}
          </label>
          <div>
            <UI.TextField className={disabled ? 'isDisabled' : ''}>
              <UI.GhostInput
                value={value}
                onChange={newValue =>
                  this.onChangeFieldSettingAnnotation({
                    ...annotation,
                    value: newValue,
                    disabled,
                  })
                }
                disabled={disabled}
              />
            </UI.TextField>
            {isEnabled && !isActive && (
              <span
                className='input-group-addon'
                style={{
                  background: 'none',
                  border: 'none',
                }}
              >
                <Tooltip id='tooltip' placement='left' title={helpText} arrow>
                  <div style={{ display: 'inline-block' }}>
                    <SugarIcon icon='warning-lg' iconType='warning' />
                  </div>
                </Tooltip>
              </span>
            )}
          </div>
        </div>
      );
    }
  }

  renderLinkUrl() {
    const linkUrlData = this.state.configureInsightsFormData.find(
      ({ key }) => key === LINK_URL,
    );
    const formControlLabelSx = LinkUrlLabelStyles();
    const checkboxSx = CheckboxStyles();
    return (
      <div className={'field-settings-link-url'} style={{ display: 'flex' }}>
        <FormControlLabel
          sx={formControlLabelSx}
          control={
            <Checkbox
              sx={checkboxSx}
              checked={!linkUrlData.disabled}
              title={LINK_URL}
              onChange={() =>
                this.onChangeConfigureInsightAnnotation({
                  key: LINK_URL,
                  value: '',
                  disabled: !linkUrlData.disabled,
                })
              }
            />
          }
          label={LINK_URL}
        />
        <UI.GhostInput
          value={''}
          defaultValue={''}
          title={messages.editDatasetPanel.editValue}
          onChange={value =>
            this.onChangeConfigureInsightAnnotation({
              key: LINK_URL,
              disabled: true,
              value,
            })
          }
          disabled={linkUrlData.disabled}
        />
      </div>
    );
  }

  // @TODO some day, the backend will provide the true defaults,
  // but for now, we will strip any defaults that are 'equivalent' to defaultAnnotations
  convertBackendData(attribute) {
    // converting to 'value-wise disabled' annotations
    const attributeAnnotations = _.get(attribute, 'annotations', []);
    const annotationsFromDefaults = _(defaultAnnotations)
      .map(defaultAnnotation => {
        const isEqual = (annotation1, annotation2) => {
          // normalize for comparison
          const normalize = annotation => {
            return {
              key: annotation.key,
              value: annotation.value,
              disabled: annotation.disabled,
            };
          };
          annotation1 = normalize(annotation1);
          annotation2 = normalize(annotation2);
          return _.isEqual(annotation1, annotation2);
        };
        const findMatchingAnnotation = list =>
          _.find(list, a =>
            _.includes(
              [
                defaultAnnotation.key,
                defaultAnnotation.key + DISABLED_KEY_SUFFIX,
              ],
              a.key,
            ),
          ) || null;
        const annotation = findMatchingAnnotation(attributeAnnotations);
        const mappedDefaultAnnotation =
          mapDisabledKeyToValue(defaultAnnotation);
        if (!annotation) {
          return mappedDefaultAnnotation;
        }
        const mappedAnnotation = mapDisabledKeyToValue(annotation);
        if (!isEqual(mappedAnnotation, mappedDefaultAnnotation)) {
          return mappedAnnotation;
        } else {
          return mappedDefaultAnnotation;
        }
      })
      .compact()
      .value();

    // strip any defaults in correctedAttributeAnnotations that are 'equivalent' to defaultAnnotations
    const correctedAttributeAnnotations = _.reduce(
      annotationsFromDefaults,
      (correctedAttrAnnos, attrAnno) => {
        const existingDefaultAnno = _.find(
          defaultAnnotations,
          _.matches({ key: attrAnno.key }),
        );
        if (existingDefaultAnno && !_.isEqual(existingDefaultAnno, attrAnno)) {
          return correctedAttrAnnos.concat([attrAnno]);
        } else if (existingDefaultAnno) {
          return correctedAttrAnnos.concat([existingDefaultAnno]);
        }
        return correctedAttrAnnos;
      },
      [],
    );

    return { ...attribute, annotations: correctedAttributeAnnotations };
  }

  getNonEditableAnnotations(attribute) {
    const defaultNonEditableAnnotations = [
      { key: 'SOURCE_NAME', value: '-', disabled: true },
      { key: 'DISPLAY_LABEL', value: '-', disabled: true },
    ];
    return defaultNonEditableAnnotations.reduce((annotations, anno) => {
      const existing = attribute.annotations.find(f => f.key === anno.key);
      if (!_.isNil(existing)) {
        annotations.push(existing);
      }
      return annotations;
    }, []);
  }

  getAttributeProfile(attributeName) {
    const attributeProfile = this.props.attributeProfiles.find(
      p => p.name === attributeName,
    );
    return _.get(attributeProfile, 'profileInfo', []);
  }

  cachingCellRenderer({ rowData, rowIndex, columnIndex }) {
    if (this.props.loading) {
      return (
        <UI.BodyCell>
          <TableSkeleton />
        </UI.BodyCell>
      );
    }
    const { updatedAttributeData, previouslySelectedAttributeIdx } = this.state;
    const isPreviewPanelOpen = this.isPanelOpen(
      DATASET_EDITOR_PANELS.DATA_PREVIEW,
    );
    const isConfigureInsightsPanelOpen = this.isPanelOpen(
      DATASET_EDITOR_PANELS.ANNOTATIONS,
    );
    const isEditFieldSettingsPanelOpen = this.isPanelOpen(
      DATASET_EDITOR_PANELS.FIELD_SETTINGS,
    );
    const col = this.tableColumnFilter()[columnIndex];
    const cacheKey = `${rowIndex}-${columnIndex}`;
    let cell = this.renderedCellCache[cacheKey];
    const cachedCellData = this.renderedCellCacheData[cacheKey];

    // updatedCellData is sent to the cellRenderer in the tableSpec
    const updatedCellData = {
      rowData,
      selectedAttribute: updatedAttributeData,
      onChange: this.onChange,
      removeCalc: this.removeCalc,
      aggregateInfo: this.props.aggregateInfo,
      getAttributeProfile: this.getAttributeProfile,
      onPreviewIconClick: this.onPreviewIconClick,
      onEditFieldIconClick: this.onEditFieldIconClick,
      onConfigureInsightsIconClick: this.onConfigureInsightsIconClick,
      isPreviewPanelOpen,
      isConfigureInsightsPanelOpen,
      isEditFieldSettingsPanelOpen,
      isAdmin: this.props.isAdmin,
      isAppAdmin: this.props.isAppAdmin,
      currencySymbol: this.props.currencySymbol,
      rowIndex,
      columnIndex,
      i18nPrefs: this.props.i18nPrefs,
    };

    //update cache for the previous and currently selected rows.
    //cachedData could be undefined or have an undefined rowData.
    if (
      _.isNil(cachedCellData) ||
      (cachedCellData?.rowData &&
        Number.isInteger(cachedCellData.rowData.index) &&
        (previouslySelectedAttributeIdx === cachedCellData.rowData.index ||
          updatedCellData.rowData.index === cachedCellData.rowData.index))
    ) {
      cell = col.cellRenderer(updatedCellData);
      this.renderedCellCache[cacheKey] = cell;
      this.renderedCellCacheData[cacheKey] = updatedCellData;
    }

    return cell;
  }

  // filter out any explicitly hidden columns or columns that are not found in existing annotations
  tableColumnFilter() {
    return TableSpec.columns.filter(col => {
      return !(
        col.hidden ||
        (col.dynamicAnnotationDisplay === true &&
          !this.state.annotationKeys.find(
            annotationKey => annotationKey === col.annotationKey,
          ))
      );
    });
  }

  render() {
    const {
      sortBy,
      sortDirection,
      sortedList,
      activeFilters,
      updatedAttributeData,
      previewListScrollToIndex,
      limit,
      configureInsightsFormData,
      editFieldSettingsFormData,
      originalSelectedAttributeRef,
    } = this.state;
    const showFilter = TableSpec.filters.find(({ id }) => id === 'show');
    return (
      <UI.Root>
        <UI.TableActionToolBar className='dataset-preview-table__toolbar'>
          <ToolBar>
            <TextSearchField
              placeholder={messages.editDatasetPanel.searchFieldsPlaceholder}
              onChange={this.onSearch}
            />
            <FilterToolbar
              activeFilters={activeFilters}
              onToggleFilter={(f, a, e) => this.onToggleFilter(f, a, e)}
              onClearFilters={e => this.onClearFilters(e)}
            />
          </ToolBar>
          <UI.Specs>
            <UI.BoldText>{this.props.attributes.length}</UI.BoldText>{' '}
            {messages.editDatasetPanel.fieldsTotalLabel}{' '}
            <UI.BoldText>{sortedList.length}</UI.BoldText>{' '}
            {messages.editDatasetPanel.displayedLabel}
          </UI.Specs>
          <FormGroup>
            <FormControlLabel
              sx={{
                margin: 0,
                '.MuiTypography-root': {
                  fontSize: '14px',
                },
              }}
              control={
                <Switch
                  id={'hiddenFieldSwitch'}
                  checked={includes(activeFilters, showFilter)}
                  color={'primary'}
                  onClick={e =>
                    this.onToggleFilter(
                      showFilter,
                      !includes(activeFilters, showFilter),
                      e,
                    )
                  }
                  name={'hiddenFieldSwitch'}
                />
              }
              label={messages.layoutPanel.hiddenFields}
              labelPlacement={'start'}
            />
          </FormGroup>
          <PrimaryButton onClick={() => this.props.addCalc()}>
            {messages.editDatasetPanel.addCalcFieldLabel}
          </PrimaryButton>
        </UI.TableActionToolBar>
        <UI.TableRoot>
          <AutoSizer>
            {({ height, width }) => {
              const isPreviewPanelOpen = this.isPanelOpen(
                DATASET_EDITOR_PANELS.DATA_PREVIEW,
              );
              const isConfigureInsightsPanelOpen = this.isPanelOpen(
                DATASET_EDITOR_PANELS.ANNOTATIONS,
              );
              const isEditFieldSettingsPanelOpen = this.isPanelOpen(
                DATASET_EDITOR_PANELS.FIELD_SETTINGS,
              );
              const TABLE_WIDTH = isPreviewPanelOpen
                ? width - UI.DIMENSIONS.LIST_WIDTH - 18 /* padding */
                : isConfigureInsightsPanelOpen || isEditFieldSettingsPanelOpen
                  ? width -
                    UI.DIMENSIONS.EDIT_ANNOTATIONS_TABLE_WIDTH -
                    18 /* padding */
                  : width;
              return (
                <UI.TableInner
                  style={{
                    width: `${width}px`,
                    '.dataset-preview-table [role="rowgroup"]': {
                      overflow: 'visible !important',
                    },
                  }}
                >
                  <Table
                    className='dataset-preview-table'
                    height={height}
                    width={TABLE_WIDTH}
                    sort={this.onSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    tabIndex={-1}
                    headerHeight={UI.DIMENSIONS.HEADER_HEIGHT}
                    rowHeight={UI.DIMENSIONS.ROW_HEIGHT}
                    rowCount={this.props.loading ? 19 : sortedList.length}
                    rowGetter={({ index }) =>
                      this.props.loading ? {} : sortedList[index]
                    }
                    overscanRowCount={15}
                    onRowClick={({ rowData }) => {
                      this.onSelectAttributeForFieldNameEdit(rowData);
                    }}
                    rowClassName={({ index }) =>
                      classnames({
                        selected:
                          index >= 0 &&
                          !_.isNil(updatedAttributeData) &&
                          _.isEqual(
                            sortedList[index].originalName,
                            updatedAttributeData.originalName,
                          ),
                      })
                    }
                  >
                    {this.tableColumnFilter().map(col => {
                      const colWidth = Math.floor(
                        (col.percentWidth * width) / 100,
                      );
                      return (
                        <DatasetPreviewTableColumn
                          key={col.key}
                          dataKey={col.key}
                          label={_.get(messages, col.label, col.label)}
                          headerRenderer={
                            col.sortable
                              ? UI.HeaderCellRendererWithSortIndicator
                              : UI.HeaderCellRenderer
                          }
                          disableSort={!col.sortable}
                          cellRenderer={this.cachingCellRenderer}
                          width={colWidth}
                          flexGrow={col.flex}
                          maxWidth={col.maxWidth}
                          minWidth={col.minWidth ? col.minWidth : 0}
                          className={`col-${col.key}`}
                        />
                      );
                    })}
                  </Table>
                  {!this.props.loading && _.isEmpty(sortedList) && (
                    <UI.TableEmptyView style={{ width: `${TABLE_WIDTH}px` }}>
                      No matches
                    </UI.TableEmptyView>
                  )}
                  {/* START Preview Attribute Data panel */}
                  {isPreviewPanelOpen && updatedAttributeData && (
                    <UI.PreviewList className='dataset-preview-table__preview-list'>
                      <PanelHeader
                        isPreviewPanelOpen={isPreviewPanelOpen}
                        originalSelectedAttributeRef={
                          originalSelectedAttributeRef
                        }
                        sortedList={sortedList}
                        onSelectAttributeForEditFieldSettings={e =>
                          this.onSelectAttributeForEditFieldSettings(e)
                        }
                        closePanel={() => this.closePanel()}
                      />
                      <UI.ListHeader>{updatedAttributeData.name}</UI.ListHeader>
                      <Query
                        query={DatasetQueries.DatasetPreviewQuery}
                        variables={{
                          id: this.props.datasetId,
                          fields: [updatedAttributeData.originalName],
                          limit,
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) {
                            return (
                              <UI.Spinner>
                                <CorbotoLoading />
                              </UI.Spinner>
                            );
                          }
                          if (error || !data) {
                            return messages.editDatasetPanel
                              .errorLoadingDataPreview;
                          }
                          const results = _.get(
                            data,
                            'executeQuery.results',
                            [],
                          );
                          // Get data formatter for preview column
                          let formatter = DATA_TYPE_FORMAT.getFormatterByName(
                            updatedAttributeData.formatType,
                          );
                          if (_.isNil(formatter)) {
                            formatter =
                              DATA_TYPE_FORMAT.getDefaultFormatterForType(
                                updatedAttributeData.attributeType,
                              );
                          }
                          return (
                            <UI.List>
                              <AutoSizer>
                                {({ height: newHeight }) => (
                                  <List
                                    width={UI.DIMENSIONS.LIST_WIDTH}
                                    height={newHeight}
                                    rowCount={results.length}
                                    rowHeight={UI.DIMENSIONS.LIST_ROW_HEIGHT}
                                    rowRenderer={({ key, index, style }) => {
                                      const content = formatter.format(
                                        results[index][0],
                                        this.props.i18nPrefs,
                                      );
                                      return (
                                        <UI.ListRow
                                          key={key}
                                          style={style}
                                          title={content}
                                        >
                                          {content}
                                        </UI.ListRow>
                                      );
                                    }}
                                    scrollToIndex={previewListScrollToIndex}
                                    onScroll={({
                                      clientHeight,
                                      scrollHeight,
                                      scrollTop,
                                    }) => {
                                      if (
                                        limit === results.length &&
                                        scrollHeight - scrollTop ===
                                          clientHeight
                                      ) {
                                        // Fetch more results
                                        this.setState({
                                          limit: limit + LIMIT_INC,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </AutoSizer>
                            </UI.List>
                          );
                        }}
                      </Query>
                    </UI.PreviewList>
                  )}
                  {/* END Preview Attribute Data panel */}
                  {/* START Attribute "Edit Annotation/Configure Discovery Insights" panel */}
                  {this.props.allowAnnotationEditing &&
                    isConfigureInsightsPanelOpen && (
                      <UI.PreviewList className='dataset-preview-table__preview-list'>
                        <UI.Header>
                          {messages.editDatasetPanel.configureDiscoveryInsights}
                          <span title={messages.close}>
                            <CloseIcon
                              onClick={() => this.closePanel()}
                              size={12}
                            />
                          </span>
                        </UI.Header>
                        <UI.Grid className={'annotation-grid'}>
                          <AutoSizer>
                            {({ height: newHeight }) => [
                              <Table
                                key='edit-dataset-table'
                                width={
                                  UI.DIMENSIONS.EDIT_ANNOTATIONS_TABLE_WIDTH
                                }
                                height={newHeight}
                                headerHeight={UI.DIMENSIONS.LIST_ROW_HEIGHT}
                                rowCount={
                                  this.props.loading
                                    ? 14
                                    : configureInsightsFormData.length
                                }
                                rowGetter={({ index }) =>
                                  this.props.loading
                                    ? {}
                                    : configureInsightsFormData[index]
                                }
                                columnCount={3}
                                columnWidth={({ index }) => {
                                  return [25, 220, 220][index];
                                }}
                                rowHeight={UI.DIMENSIONS.LIST_ROW_HEIGHT}
                              >
                                <Column
                                  dataKey={1}
                                  label={''}
                                  headerRenderer={UI.GridHeaderCellRenderer}
                                  disableSort
                                  cellRenderer={({ rowData }) => {
                                    if (this.props.loading) {
                                      return (
                                        <UI.BodyCell>
                                          <TableSkeleton />
                                        </UI.BodyCell>
                                      );
                                    }
                                    return (
                                      <UI.GridCell title={''}>
                                        <input
                                          type='checkbox'
                                          checked={!rowData.disabled}
                                          className={'annotation_checkbox'}
                                          onChange={() =>
                                            this.onChangeConfigureInsightAnnotation(
                                              {
                                                ...rowData,
                                                disabled: !rowData.disabled,
                                              },
                                            )
                                          }
                                        />
                                      </UI.GridCell>
                                    );
                                  }}
                                  width={25}
                                />
                                <Column
                                  dataKey={2}
                                  label={
                                    messages.editDatasetPanel
                                      .annotationNameLabel
                                  }
                                  headerRenderer={UI.GridHeaderCellRenderer}
                                  disableSort
                                  cellRenderer={({ rowData }) => (
                                    <UI.GridCell
                                      className={classnames({
                                        dim: rowData.hidden,
                                        isDisabled: rowData.disabled,
                                      })}
                                      title={''}
                                    >
                                      {rowData.key}
                                    </UI.GridCell>
                                  )}
                                  width={235}
                                />
                                <Column
                                  dataKey={3}
                                  label={
                                    messages.editDatasetPanel
                                      .annotationValueLabel
                                  }
                                  headerRenderer={UI.GridHeaderCellRenderer}
                                  disableSort
                                  cellRenderer={({ rowData }) => (
                                    <UI.GridInputCell
                                      className={classnames({
                                        dim: rowData.hidden,
                                        isDisabled: rowData.disabled,
                                      })}
                                    >
                                      <UI.GhostInput
                                        value={rowData.value}
                                        defaultValue={
                                          this.findDefaultAnnotation(rowData)
                                            ? this.findDefaultAnnotation(
                                                rowData,
                                              ).value
                                            : ''
                                        }
                                        title={
                                          messages.editDatasetPanel.editValue
                                        }
                                        onChange={value =>
                                          this.onChangeConfigureInsightAnnotation(
                                            { ...rowData, value },
                                          )
                                        }
                                        disabled={rowData.disabled}
                                      />
                                    </UI.GridInputCell>
                                  )}
                                  width={240}
                                />
                              </Table>,
                            ]}
                          </AutoSizer>
                        </UI.Grid>
                      </UI.PreviewList>
                    )}
                  {/* END Attribute "Edit Annotation/Configure Discovery Insights" panel */}
                  {/* START Attribute "Edit Field Settings" panel */}
                  {this.props.allowAnnotationEditing &&
                    isEditFieldSettingsPanelOpen && (
                      <UI.PreviewList className='dataset-preview-table__preview-list'>
                        <PanelHeader
                          isPreviewPanelOpen={isPreviewPanelOpen}
                          originalSelectedAttributeRef={
                            originalSelectedAttributeRef
                          }
                          sortedList={sortedList}
                          onSelectAttributeForEditFieldSettings={e =>
                            this.onSelectAttributeForEditFieldSettings(e)
                          }
                          closePanel={() => this.closePanel()}
                        />
                        <form className={'field-settings-form'}>
                          <label className='control-label'>
                            {
                              messages.editDatasetPanel
                                .useCustomSortFieldOrdinal
                            }
                          </label>
                          <UI.GhostInput
                            value={updatedAttributeData.ordinalAttribute}
                            title={
                              messages.editDatasetPanel
                                .useCustomSortFieldOrdinal
                            }
                            onChange={this.onOrdinalAttributeValueChange}
                            disabled={updatedAttributeData.disabled}
                          />
                          <label className='control-label'>
                            {messages.editDatasetPanel.dropdownIdField}
                          </label>
                          <UI.GhostInput
                            value={
                              _.find(
                                updatedAttributeData.annotations,
                                _.matches({ key: 'ENUM_ID_ATTRIB' }),
                              )?.value
                            }
                            title={messages.editDatasetPanel.enumIdField}
                            onChange={value =>
                              this.onChangeFieldSettingAnnotation({
                                key: 'ENUM_ID_ATTRIB',
                                disabled: false,
                                hidden: true,
                                value,
                              })
                            }
                            disabled={updatedAttributeData.disabled}
                          />
                          {this.renderLinkUrl()}
                        </form>
                        <UI.Grid className={'annotation-grid'}>
                          <AutoSizer>
                            {({ height: newHeight, width: newWidth }) => {
                              // React-virtualized Grid/Table didn't make sense here
                              return (
                                <form
                                  style={{ height: newHeight, width: newWidth }}
                                  className={'field-settings-form'}
                                >
                                  {_.map(
                                    editFieldSettingsFormData,
                                    formGroup => {
                                      const annotations = _.get(
                                        formGroup,
                                        'annotations',
                                        [],
                                      );
                                      return _.map(annotations, annotation => {
                                        return this.renderFieldSettingsAnnotation(
                                          annotation,
                                        );
                                      });
                                    },
                                  )}
                                </form>
                              );
                            }}
                          </AutoSizer>
                        </UI.Grid>
                      </UI.PreviewList>
                    )}
                  {/* END Attribute "Edit Field Settings" panel */}
                </UI.TableInner>
              );
            }}
          </AutoSizer>
        </UI.TableRoot>
      </UI.Root>
    );
  }
}

component.PropTypes = {
  datasetId: PropTypes.string.required,
  attributes: PropTypes.array,
  annotationKeys: PropTypes.array,
  onChange: PropTypes.func,
  addCalc: PropTypes.func,
  removeCalc: PropTypes.func,
  isAdmin: PropTypes.bool,
  allowAnnotationEditing: PropTypes.bool,
  i18nPrefs: PropTypes.any,
};

component.defaultProps = {
  attributes: [],
  annotationKeys: [],
  onChange: _.noop,
  addCalc: _.noop,
  removeCalc: _.noop,
  isAdmin: false,
  allowAnnotationEditing: false,
};

export default component;
