import { css } from '@emotion/react';
import { font } from './mixins';

export const pivotTableStyles = ({
  theme: {
    darkMode,
    colors: {
      MediumFontWeight,
      baseFontColor,
      TableRowHover,
      PivotHeaderBackground,
      Strokes,
      PanelBackground,
      SubtotalBackground,
      PivotTotals,
      PivotFlatHeaderBackground,
      PivotCellHighlight,
      PivotCellHighlightHover,
      PivotCellHighlightSecondary,
      PivotCellHighlightSecondaryHover,
      LightFontWeight,
      PivotCell,
      PivotFlatCell,
      PivotCellBorder,
      TooltipColor,
      Gray70,
      MediumBlue,
      PivotWhite,
    },
  },
}) => css`
  .pivot-cell {
    ${font({ size: '12px', weight: MediumFontWeight, color: baseFontColor })}
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid ${PivotCellBorder};
    border-right: 1px solid ${PivotCellBorder};
    padding: 6px 12px;
    text-align: left;
    vertical-align: middle;

    .link-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sicon {
        display: none;
        margin-left: 10px;
        color: ${Gray70};

        &:hover {
          color: ${MediumBlue};
        }
      }
    }

    &:hover {
      .link-container .sicon {
        display: block;
      }
    }
  }
  .pivot-cell.right-aligned {
    display: flex;
    justify-content: flex-end;
  }
  .pivot-cell .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    user-select: text;

    &:not(&.right-aligned) {
      width: 100%;
    }
  }
  .pivot-cell .value.right-aligned {
    width: 100%;
    text-align: right;
  }
  .pivot-cell.first-col {
    border-left: 1px solid ${PivotCellBorder};
  }
  .pivot-cell.first-row {
    border-top: 1px solid ${PivotCellBorder};
  }
  .pivot-cell.sortable {
    cursor: pointer;
  }
  .pivot-cell.sortable > .sorticon {
    height: 24px;
    width: 24px;
    cursor: pointer;
    text-align: center;
    display: none;
    color: ${darkMode && PivotWhite};
  }
  .pivot-cell.sortable > .sorticon.primary-sort {
    display: initial;
  }
  .pivot-cell.sortable > .sorticon > svg {
    margin-top: 6px;
  }
  .pivot-cell.sortable > .sorticon > svg.columns {
    margin-top: 7px;
    transform: rotate(-90deg);
  }
  .pivot-cell.sortable > .sorticon > svg.primary-sort {
    display: initial;
  }
  .pivot-cell.sortable > .sorticon.desc {
    transform: rotate(180deg);
  }
  .pivot-cell.sortable:hover {
    background-color: ${TableRowHover};
  }
  .pivot-cell.sortable:hover > .sorticon {
    display: initial;
  }
  .pivot-cell.with-record-count {
    flex-direction: column;
    align-items: start;
    padding: 8px 12px;
  }
  .pivot-top-right-columns {
    overflow: hidden hidden;
    position: absolute;
    background-color: ${PivotHeaderBackground};
  }
  .pivot-top-right-columns .pivot-cell {
    border-color: ${Strokes};
    text-align: left;
  }
  .pivot-top-right-columns .pivot-cell.first-col,
  .pivot-top-right-columns .pivot-cell.first-row {
    border-color: ${Strokes};
  }
  .pivot-top-right-columns .pivot-cell.first-col.rows-present {
    border-left: none;
  }
  .pivot-bottom-left {
    overflow: hidden hidden;
    position: absolute;
    /*border-left: 1px solid @Strokes;*/
    /*border-right: 1px solid @Strokes;*/
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .pivot-bottom-left::-webkit-scrollbar {
    display: none;
  }
  .pivot-bottom-left .pivot-cell {
    background-color: ${PanelBackground};
    border-color: ${Strokes};
  }
  .pivot-bottom-left .pivot-cell.first-col,
  .pivot-bottom-left .pivot-cell.first-row {
    border-color: ${Strokes};
  }
  .pivot-bottom-left .pivot-cell.last-row.total-present {
    border-bottom: none;
  }
  .pivot-bottom-left .pivot-cell.first-row.cols-present {
    border-top: none;
  }
  .pivot-bottom-left .pivot-cell.row-subtotal {
    border-right: none;
    background-color: ${SubtotalBackground};
  }
  .pivot-bottom-left .pivot-cell.row-subtotal.last-row-header-col {
    border-right: 1px solid ${Strokes};
  }
  .pivot-top-right-groups {
    overflow: hidden hidden;
    position: absolute;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: ${PanelBackground};
    border-right: 1px solid ${Strokes};
  }
  .pivot-top-right-groups::-webkit-scrollbar {
    display: none;
  }
  .pivot-top-right-groups .pivot-cell {
    border-color: ${Strokes};
  }
  .pivot-top-right-groups .pivot-cell.first-col.rows-present {
    border-left: none;
  }
  .pivot-top-right-groups .pivot-cell.first-row {
    border-color: ${Strokes};
  }
  .pivot-top-right-groups .pivot-cell.first-row.rows-present {
    border-top: none;
  }
  .pivot-top-right-groups .pivot-cell.col-grand-total {
    background-color: ${PivotTotals};
  }
  .pivot-top-right-groups .pivot-cell.merged-grand-total {
    border-bottom: none;
  }
  .viz-type-flat .pivot-top-right-groups {
    background-color: ${PivotFlatHeaderBackground};
  }
  .leftPivotContainer {
    height: 100%;
    overflow: auto hidden;
    white-space: nowrap;
  }
  .pivot-top-right-values {
    overflow: hidden hidden !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-right: 1px solid ${Strokes};
  }
  .pivot-top-right-values::-webkit-scrollbar {
    display: none;
  }
  .pivot-top-right-values .pivot-cell {
    border-color: ${Strokes};
    background-color: ${PivotCellHighlight};
  }
  .pivot-top-right-values .pivot-cell.first-col.rows-present {
    border-color: ${Strokes};
    border-left: none;
  }
  .pivot-top-right-values .pivot-cell.first-row {
    border-color: ${Strokes};
    border-top: 1px solid ${Strokes};
  }
  .pivot-top-right-values .pivot-cell.first-row.cols-present {
    border-top: none;
  }
  .pivot-top-right-values .sortable:hover {
    background-color: ${PivotCellHighlightHover};
  }
  .pivot-top-right-values .target {
    background-color: ${PivotCellHighlightSecondary};
  }
  .pivot-top-right-values .target.sortable:hover {
    background-color: ${PivotCellHighlightSecondaryHover};
  }
  .pivot-bottom-right {
    border-right: 1px solid ${Strokes};
  }
  .pivot-bottom-right .pivot-cell {
    ${font({ size: '12px', weight: LightFontWeight })}
    background-color: ${PivotCell};
  }
  .pivot-bottom-right .pivot-cell.first-row.cols-present {
    border-top: none;
  }
  .pivot-bottom-right .pivot-cell.rows-present {
    border-left-color: ${Strokes};
  }
  .pivot-bottom-right .pivot-cell.rows-present.last-row.total-present {
    border-bottom: none;
  }
  .pivot-bottom-right .pivot-cell.first-col.rows-present {
    border-left: none;
  }
  .pivot-bottom-right .pivot-cell.last-col > .value.right-aligned {
    /* keep the vertical scroll bar from covering part of the label*/
    padding-right: 2px;
  }
  .pivot-bottom-right .pivot-cell.row-subtotal {
    background-color: ${SubtotalBackground};
    font-weight: ${MediumFontWeight};
    border-bottom-color: ${Strokes};
  }
  .pivot-bottom-right .pivot-cell.next-row-is-subtotal {
    border-bottom-color: ${Strokes};
  }
  .pivot-bottom-right .pivot-cell.col-grand-total {
    background-color: ${PivotTotals};
    font-weight: ${MediumFontWeight};
  }
  .viz-type-flat .pivot-bottom-right .pivot-cell {
    background-color: ${PivotFlatCell};
  }
  .pivot-total-left,
  .pivot-total-right {
    background-color: ${PivotTotals};
  }
  .pivot-total-left .pivot-cell.last-col > .value.right-aligned,
  .pivot-total-right .pivot-cell.last-col > .value.right-aligned {
    /* keep the vertical scroll bar from covering part of the label*/
    padding-right: 2px;
  }
  .pivot-top-left-row-header {
    overflow: hidden hidden;
    position: absolute;
    background-color: ${PivotHeaderBackground};
  }
  .pivot-top-left-row-header .pivot-cell {
    border-color: ${Strokes};
    text-align: left;
  }
  .pivot-top-left-row-header .pivot-cell.first-col,
  .pivot-top-left-row-header .pivot-cell.first-row {
    border-color: ${Strokes};
  }
  .pivot-top-right-groups .pivot-cell.last-col,
  .pivot-top-right-values .pivot-cell.last-col,
  .pivot-bottom-right .pivot-cell.last-col,
  .pivot-total-right .pivot-cell.last-col {
    border-right: none;
  }
  .pivot-total-left .pivot-cell,
  .pivot-total-right .pivot-cell {
    border-bottom: none;
  }
  .pivot-total-right .pivot-cell.first-col {
    border-left: none;
  }
  .pivot-total-right .pivot-cell.first-row {
    border-top: 1px solid ${Strokes};
  }
  .pivot-total-left .pivot-cell {
    border-top: 1px solid ${Strokes};
    border-color: ${Strokes};
  }
  .pivot-top-left-spacer {
    border-right: 1px solid ${Strokes};
  }
  .pivot-table-wrapper {
    height: calc(100% - 16px);
    width: 100%;
    padding: 16px;
    overflow: hidden hidden;
  }
  #pivot-tooltip {
    ${font({ size: '12px', weight: LightFontWeight })}
  }
  #pivot-tooltip > .tooltip-inner {
    background-color: ${TooltipColor};
    opacity: 90;
  }
  .leftGridEnd {
    border-right: 1px solid ${Strokes};
  }
`;
