import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { ellipsis } from './../../../common/emotion/mixins';
import { useDiscoverTheme } from '../../../common/emotion/theme';

export const SubMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: inherit;
`;

export const SubMenuTitle = styled.li(_ => {
  const {
    colors: { SubMenuBackground, SubMenuItemText },
  } = useDiscoverTheme();

  return css`
    color: ${SubMenuItemText};
    padding: 0.75rem 0.5rem 0.5rem 0.75rem;
    position: relative;
    width: 100%;
    align-self: flex-start;
    background-color: ${SubMenuBackground};
    border-radius: 8px;
    margin-bottom: 4px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;

    span {
      display: block;
    }

    .sicon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  `;
});

export const IconWrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  margin: 0.375rem 0.25rem 0.375rem 0;
`;

export const MenuListStyled = styled(MenuList)<{ open: boolean }>`
  padding: 0.375rem;
  background-color: ${({ theme }) => theme.colors.SubMenuBorder};
  border-radius: 8px;
  border: none;
  width: 228px;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 1060;

  li:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const MenuItemStyled = styled(MenuItem)(() => {
  const {
    colors: {
      SubMenuItemHover,
      SubMenuItemText,
      SidebarNavNotch,
      SidebarNavIconHover,
      SubMenuBackground,
      DefaultIconColor,
    },
  } = useDiscoverTheme();

  return css`
    height: auto;
    border: 0;
    background-color: ${SubMenuBackground};
    color: ${SubMenuItemText};
    padding: 0;

    > div {
      display: flex;
      align-items: center;
      padding: 0.375rem 0.5rem 0.375rem 0.75rem;
      min-width: 0;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
      ${ellipsis()}
    }

    a {
      padding: 0.375rem 0.5rem 0.375rem 0.75rem;
      color: ${SubMenuItemText};
      display: flex;
      min-width: 0;
      width: 100%;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .sicon {
      color: ${DefaultIconColor};
    }

    svg {
      height: 0.75rem;

      path {
        fill: ${DefaultIconColor};
      }
    }

    &:hover {
      background-color: ${SubMenuItemHover};

      ${IconWrapper} {
        background-color: ${SidebarNavNotch};
        border-radius: 0.25rem;
        color: ${SidebarNavIconHover};
      }

      a,
      span {
        font-weight: 600;
      }
      .sicon {
        color: ${SidebarNavIconHover};
      }

      svg {
        path,
        #Icon > * {
          fill: ${SidebarNavIconHover};
          stroke: ${SidebarNavIconHover};
        }
      }
    }
  `;
});

export const SidebarSubMenuBtn = styled.button(() => {
  const {
    colors: { SidebarSubMenuBtnHover, SidebarNavKebabHover },
  } = useDiscoverTheme();

  return css`
    padding: 0;
    border: 0;
    position: fixed;
    width: 0;
    height: inherit;
    background-color: transparent;
    overflow: hidden;

    .sicon {
      transition-duration: 0.3s;
      transition-property: color;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      padding: 0;
      border: none;
    }

    &:hover,
    &.active {
      background-color: ${SidebarSubMenuBtnHover};
      .sicon {
        color: ${SidebarNavKebabHover} !important;
      }
    }
  `;
});

export const ShowMoreBtn = styled.div`
  span {
    display: block;
    line-height: 1.5;
    margin: 0.375rem 0.25rem 0.375rem 0;
  }
`;

export const ScrollItems = styled.div`
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  max-height: 15rem;
  border-radius: 8px;

  &.not-rounded {
    li:last-child {
      border-radius: 0;
    }
  }
`;
