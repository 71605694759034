import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IDiscoverEmotionTheme } from '../../../common/emotion';

export const MobileSlicerContainer = styled.div(
  ({
    theme: { viewWidth, viewHeight, isDashletMode },
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    overflow: hidden;
    width: ${viewWidth};
    height: calc(${viewHeight} - ${isDashletMode ? '1rem' : '8rem'});
    top: ${isDashletMode ? '3rem' : '52px'};
    left: 0;
    padding-top: 10px;
    position: relative;
  `,
);

export const MobileSlicerRowContainer = styled.div(
  ({ theme: { viewWidth } }: { theme?: IDiscoverEmotionTheme }) => css`
    --viewport-width-in-container: calc(${viewWidth} - 1rem);

    width: var(--viewport-width-in-container);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    & > * {
      --viewport-width: var(--viewport-width-in-container);
    }
  `,
);

export const MobileSlicerModal = styled.div(
  ({
    theme: {
      viewHeight = 0,
      viewWidth = 0,
      isDashletMode = false,
      colors: { ContentBackground } = {} as any,
    } = {},
  }: any) =>
    css({
      zIndex: 81,
      position: 'absolute',
      overflowX: 'hidden',
      overflowY: 'auto',
      top: isDashletMode ? 'auto' : 0,
      right: 0,
      bottom: 0,
      left: 0,
      '.modal-dialog': {
        margin: 0,
        backgroundColor: ContentBackground,
        height: viewHeight,
        width: viewWidth,
        '.modal-content': {
          height: viewHeight,
          width: viewWidth,
          paddingRight: 0,
          border: 0,
          boxShadow: 'none',
          '.transition-wrapper>div': {
            width: '100%',
          },
          '.header-navigation': {
            width: viewWidth,
          },
        },
      },
    }),
);
