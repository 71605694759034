import BarLineChartUtils from '../BarLineChartUtils';
import Util from '../../../common/Util';
import { LineChartUtils } from '../LineChartUtils/LineChartUtils';
import _ from 'lodash';
import * as ReactDOM from 'react-dom';
import palette from '../../../common/d3/ColorPalette';
import { ChartTooltipData } from '../chart-tooltip';
import Areas from '../../../common/d3/Areas';
import { Viz } from '../../VizUtil';
import { join as joinChartData } from '../ChartUtils';
import { METRIC_ORIENTATION } from '../../../common/d3/Axis';
import { max as d3Max, min as d3Min, scaleLinear, scalePoint } from 'd3';

class AreaPlot {
  constructor(parameters) {
    const {
      vizId,
      valuesName,
      xAxisName,
      primaryPlot,
      data,
      layout,
      width,
      height,
      showDataLabels,
      paletteOffset,
      chartPadding,
      presetPadding,
      xScale,
      defaultXAxisHeight,
      hoverFunction,
      labelRotation,
      isMobile,
      xAxisPadding,
      yAxisPadding,
      hideAxis,
      defaultYAxisWidth,
      customFormatToggles,
      disableTooltips,
      colorPalette,
      nullHandling,
      i18nPrefs = {},
      customFormatProps,
      customAggregations,
    } = parameters;
    this.vizId = vizId;
    this.valuesName = valuesName;
    this.xAxisName = xAxisName;
    this.primaryPlot = primaryPlot;
    this.data = data;
    this.layout = layout;
    this.width = width;
    this.height = height;
    this.showDataLabels = showDataLabels;
    this.paletteOffset = paletteOffset;
    this.chartPadding = chartPadding;
    this.presetPadding = presetPadding;
    this.xScale = xScale;
    this.originalXScale = xScale;
    this.defaultXAxisHeight = defaultXAxisHeight;
    this.defaultYAxisWidth = defaultYAxisWidth;
    this.hoverFunction = hoverFunction;
    this.labelRotation = labelRotation || 'vertical';
    this.xAxisPadding = xAxisPadding;
    this.yAxisPadding = yAxisPadding;
    this.isMobile = isMobile;
    this.hideAxis = hideAxis;
    this.scales = this.createScales(width);
    this.customFormatToggles = customFormatToggles;
    this.disableTooltips = disableTooltips;
    this.colorPalette = colorPalette;
    this.nullHandling = nullHandling;
    this.i18nPrefs = i18nPrefs;
    this.customFormatProps = customFormatProps;
    this.customAggregations = customAggregations;

    this.shouldRotateAxisLabels = this.shouldRotateAxisLabels();
  }

  createScales() {
    if (!this.originalXScale) {
      if (
        _.isNumber(this.data.AXIS[0][0].value) &&
        this.data.AXIS.length === 1
      ) {
        // special case for single x-axis that's a number
        this.xScale = scaleLinear()
          .domain(
            Util.expandRange(
              _.flatMap(this.data.AXIS, '0.value'),
              _.isNumber(this.xAxisPadding) ? this.xAxisPadding : 0.05,
            ),
          )
          .range([0, this.width])
          .nice();
      } else if (
        _.isNumber(this.data.AXIS[0][0].value) &&
        this.data.AXIS[0].length === 1
      ) {
        // Single value numeric
        this.xScale = scaleLinear()
          .domain(
            Util.expandRange(
              _.flatMap(this.data.AXIS, '0.value'),
              _.isNumber(this.xAxisPadding) ? this.xAxisPadding : 0.05,
            ),
          )
          .range([0, this.width])
          .nice();
      } else {
        // Series Domain is padded with values on either side which should never present in the dataset, .nice() does not work on this type of scale
        this.xScale = scalePoint()
          .domain(
            [' ']
              .concat(this.data.AXIS.map(ax => joinChartData(ax)))
              .concat(['  ']),
          )
          .range([0, this.width]);
      }
    }

    this.domainMin = Math.min(
      LineChartUtils.reduceValuesByFunc(this.data, d3Min, 'y'),
      LineChartUtils.reduceValuesByFunc(this.data, d3Min, 'y1'),
    );
    this.domainMax = Math.max(
      LineChartUtils.reduceValuesByFunc(this.data, d3Max, 'y'),
      LineChartUtils.reduceValuesByFunc(this.data, d3Max, 'y1'),
    );

    this.yScale = scaleLinear()
      .range([this.height, 0])
      .domain(
        Util.expandRange(
          [this.domainMin, this.domainMax],
          _.isNumber(this.yAxisPadding) ? this.yAxisPadding : 0.05,
        ),
      )
      .nice();

    const labelInfo = Util.calcLabelInfo(this.xScale.domain().map(d => d));
    this.maxChars = this.shouldRotateAxisLabels ? labelInfo.maxChars : 10;
  }

  setHeight(height) {
    this.height = height;
    this.createScales();
  }

  getYAxisLabel() {
    return Viz.getAxisLabel(
      this.layout[this.valuesName],
      this.customAggregations,
    );
  }

  getXAxisLabel() {
    if (!_.isEmpty(this.layout.XAXIS)) {
      return Viz.getAxisLabel(this.layout.XAXIS, this.customAggregations);
    }
  }

  getTicks(numTicks) {
    const hasBars = this.primaryPlot.isActive();
    const lineAxisTicks = hasBars
      ? BarLineChartUtils.generateLineTicks(
          this.primaryPlot.getYScale(),
          this.yScale,
          numTicks,
        )
      : null;
    return lineAxisTicks;
  }

  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'area';
  }

  isActive() {
    return this.layout.LINES.length > 0;
  }

  getXScale() {
    return this.xScale;
  }

  getYScale() {
    return this.yScale;
  }

  getYDomain() {
    return [this.domainMin, this.domainMax];
  }

  setYScale(scale) {
    this.yScale = scale;
  }

  getPreferredWidth() {
    return this.width;
  }

  getPreferredHeight() {
    if (!this.shouldRotateAxisLabels || this.hideAxis) {
      return this.height;
    }
    return this.height - (this.getXAxisHeight() - this.defaultXAxisHeight);
  }

  getXAxisHeight() {
    return this.defaultXAxisHeight;
  }

  getYAxisWidth() {
    return this.defaultYAxisWidth;
  }

  getDistinctGroups() {
    return this.data.AXIS.length;
  }

  shouldRotateAxisLabels() {
    const stepSize = this.getStepSize();
    if (stepSize < 64) {
      return true;
    }
    return false;
  }

  getStepSize() {
    const xaxisDataLength = this.data.AXIS.length;

    // calculate the distance between two steps. Point Scale has step(), Linear does not so we calculate it
    // Guarded against the case where there is only one value
    return this.xScale.step
      ? this.xScale.step()
      : xaxisDataLength > 1
        ? this.xScale(1) - this.xScale(0)
        : 100;
  }

  getComponent(props) {
    return (
      <Areas
        {...props}
        xScale={this.xScale}
        yScale={this.yScale}
        ref={lines => {
          this.lines = lines;
          if (props.plotRef) {
            props.plotRef(lines);
          }
        }}
        height={this.height}
        width={this.width}
        data={this.data}
        chartPadding={this.presetPadding}
        paletteOffset={this.paletteOffset}
        xAxisShelf='XAXIS'
        globalMouseTooltipEnabled={props.showGlobalTooltip}
        showDataLabels={this.showDataLabels}
        offsetX={this.getOffsetX()}
        vizId={this.vizId}
        plot={this}
        disableTooltips={this.disableTooltips}
        onHover={(data, x, y) => this.hoverFunction(data, 'lines', x, y)}
        colorPalette={this.colorPalette}
        nullHandling={this.nullHandling}
      />
    );
  }

  getOffsetX() {
    return this.xScale.bandwidth ? this.xScale.bandwidth() / 2 : 0;
  }

  getComponentRef() {
    return ReactDOM.findDOMNode(this.lines);
  }

  getTooltip(
    dataItem,
    dataFormatters,
    showGlobalTooltip,
    useFiscalCalendar,
    customFormatters,
  ) {
    const data = _.isArray(dataItem.point)
      ? this.getTooltipData(dataItem.point, dataFormatters, customFormatters)
      : {
          rows: LineChartUtils.collectPathTo(
            this.data,
            dataItem.line,
            dataItem.point,
            'XAXIS',
            dataFormatters,
            this.i18nPrefs,
            customFormatters,
          ),
        };
    return this.renderTooltip(data, showGlobalTooltip, useFiscalCalendar);
  }

  getLegendData() {
    // Add lines
    const legendData = new Set();
    LineChartUtils.collectLegend(
      this.data,
      null,
      null,
      this.valuesName,
    ).forEach((item, idx) => {
      legendData.add({
        label: item.name,
        shape: 'CIRCLE',
        color: palette[(idx + this.paletteOffset) % palette.length],
        info: item.info,
      });
    });
    return legendData;
  }

  globalMouseMove(xRelToContainer, yRelToContainer) {
    let axisVal;
    if (this.xScale.invert) {
      // Linear scale
      axisVal = this.xScale.invert(xRelToContainer);
    } else {
      axisVal =
        (xRelToContainer / this.xScale.range()[1]) * this.data.AXIS.length;
    }

    const nearestIdx = Math.round(axisVal);

    const xVal = this.xScale.invert
      ? [nearestIdx]
      : this.data.AXIS[nearestIdx - 1];
    if (xVal) {
      this.hoverFunction(
        { line: null, point: xVal },
        'lines',
        this.xScale(joinChartData(xVal)) + this.getOffsetX(),
        yRelToContainer,
      );
    }
  }

  getTooltipData(xVal, dataFormatters, customFormatters) {
    const tooltipData = { header: [], rows: [] };
    this.layout.XAXIS.forEach((axis, i) => {
      const formatter = dataFormatters[axis.name];
      const customFormat = customFormatters[axis.name];
      let val = xVal[i];
      if (formatter) {
        val = formatter.format(val, this.i18nPrefs, customFormat);
      }
      tooltipData.header[axis.name] = val;
    });

    LineChartUtils.collectLegend(
      this.data,
      this.xScale,
      this.xScale(joinChartData(xVal)),
      this.valuesName,
    ).forEach(i => {
      const formatter = dataFormatters[i.info.valueName];
      const customFormat = customFormatters[i.info.valueName];
      tooltipData.rows[i.name] = formatter
        ? formatter.format(i.value, this.i18nPrefs, customFormat)
        : i.value;
    });
    return tooltipData;
  }

  renderTooltip(hoverItem, globalTooltip = false, useFiscalCalendar = false) {
    const headerLines = [];
    if (globalTooltip && hoverItem && hoverItem.header) {
      const entries = hoverItem.header;
      for (const key in entries) {
        const value = _.isNil(entries[key].value)
          ? entries[key]
          : entries[key].value;
        headerLines.push(
          <div className='tooltip-entry header'>
            <span className='chart-tooltip-label'>{key}</span>
            <span className='chart-tooltip-value'>{value}</span>
          </div>,
        );
      }
    }

    const content = [];

    if (globalTooltip && !_.isEmpty(headerLines)) {
      content.push(
        <div>
          {headerLines}
          <hr />
        </div>,
      );
    }

    content.push(
      <ChartTooltipData
        data={hoverItem?.rows}
        palette={palette}
        shape='CIRCLE'
        showIcons
        useFiscalCalendar={useFiscalCalendar}
      />,
    );

    return content;
  }

  getMetricOrientation() {
    return METRIC_ORIENTATION.VERTICAL;
  }
}

export default AreaPlot;
