import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isFunction, noop, some } from 'lodash';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import { shortid } from '../../common/utilities/shortid-adapter';
import { MenuProps } from '@mui/material/Menu/Menu';
import { StyledMenu } from './sidebar-navigation.styles';

export interface ISideNaveSubMenuContext {
  hasSubMenu: boolean;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  doOpen?: () => void;
  doClose?: () => void;
  setCallbacks: (arg: { doOpen: () => void; doClose: () => void }) => void;
  hasCallbacks?: boolean;
}

export interface MuiMenuProps extends Omit<MenuProps, 'open' | 'anchorEl'> {
  id?: string;
  className?: string;
  children: ReactNode;
}

export const SideNavSubMenuContext = createContext<ISideNaveSubMenuContext>({
  hasSubMenu: false,
  isOpen: false,
  setIsOpen: noop,
  setCallbacks: noop,
  hasCallbacks: false,
});

export const SubMenuContextProvider = ({ children }) => {
  // @NOTE: hasCallbacks is important for change detection after callbacks has been set
  const [hasCallbacks, setHasCallbacks] = useState<boolean>(false);
  const [_isOpen, setIsOpen] = useState<boolean>(false);
  const doOpenRef = useRef(noop);
  const doCloseRef = useRef(noop);

  const setCallbacks = useCallback(
    ({ doOpen, doClose }) => {
      if (hasCallbacks) {
        return;
      }

      doOpenRef.current = () => {
        setIsOpen(true);
        doOpen();
      };
      doCloseRef.current = () => {
        setIsOpen(false);
        doClose();
      };
      setHasCallbacks(true);
    },
    [hasCallbacks],
  );

  const hasSubMenu = some(
    [doOpenRef?.current, doCloseRef?.current],
    isFunction,
  );
  return (
    <SideNavSubMenuContext.Provider
      value={{
        hasSubMenu,
        isOpen: _isOpen,
        setIsOpen,
        doOpen: doOpenRef?.current,
        doClose: doCloseRef?.current,
        setCallbacks,
        hasCallbacks,
      }}
    >
      {children}
    </SideNavSubMenuContext.Provider>
  );
};

export const useSetContextCallbacks = ({
  handleOpen,
  handleClose,
  anchorRef,
  isOpen = false,
}) => {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { setCallbacks } = useContext(SideNavSubMenuContext);
    isFunction(setCallbacks) &&
      setCallbacks({
        doOpen: () => handleOpen(anchorRef?.current),
        doClose: () => handleClose(anchorRef?.current),
      });
  } catch (e) {
    console.log(e);
  }

  const hasOpenChanged = useHasValueChanged({
    value: isOpen,
    defaultValue: false,
  });

  if (hasOpenChanged) {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { setIsOpen } = useContext(SideNavSubMenuContext);
      isFunction(setIsOpen) && setIsOpen(isOpen);
    } catch (e) {
      console.log(e);
    }
  }
};
export const useDropdownMenu = ({
  onOpen = noop,
  onClose = noop,
  onToggle = noop,
  noBorder = false,
} = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef<HTMLElement>();

  const _setAnchor = useCallback(
    (el?: HTMLElement) => {
      const anchor = anchorEl || isOpen ? null : el;
      setIsOpen(!!anchor);
      setAnchorEl(anchor);
    },
    [anchorEl, isOpen],
  );

  const handleClose = useCallback(() => _setAnchor(), [_setAnchor]);
  const handleOpen = useCallback(
    (el: HTMLElement) => _setAnchor(el),
    [_setAnchor],
  );

  const handleToggle = useCallback(
    (el: HTMLElement) => {
      if (!el) {
        return;
      }

      _setAnchor(el);
      setIsOpen(!isOpen);
    },
    [_setAnchor, isOpen],
  );

  const hasIsOpenChanged = useHasValueChanged({
    value: isOpen,
    defaultValue: false,
  });

  useSetContextCallbacks({
    handleOpen,
    handleClose,
    anchorRef,
    isOpen,
  });

  // @NOTE useEffect is too slow here
  if (hasIsOpenChanged) {
    const _onOpen = () => isFunction(onOpen) && onOpen();
    const _onClose = () => isFunction(onClose) && onClose();

    isFunction(onToggle) && onToggle(isOpen);
    isOpen ? _onOpen() : _onClose();
  }

  const MenuComponent = useMemo(
    () =>
      memo(
        ({
          id = `with-dropdown-${shortid.generate()}`,
          children: renderedMenuItems,
          anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin = {
            vertical: 'top',
            horizontal: 'right',
          },
          disablePortal = false,
          slotProps,
          className = `kabob-dropdown-menu`,
          ...remainingProps
        }: MuiMenuProps) => (
          <StyledMenu
            key={'dropdown-menu'}
            id={id}
            className={`${className} ${noBorder ? 'no-border' : ''}`}
            onClick={e => {
              e.stopPropagation();
              handleToggle(e.currentTarget);
            }}
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            disablePortal={disablePortal}
            slotProps={slotProps}
            {...remainingProps}
          >
            {renderedMenuItems}
          </StyledMenu>
        ),
      ),
    [anchorEl, isOpen, handleToggle, noBorder],
  );

  return {
    isOpen,
    anchorRef,
    doOpen: handleOpen,
    doClose: handleClose,
    doToggle: handleToggle,
    MenuComponent,
  };
};
