import {
  concat,
  find,
  isArray,
  isEmpty,
  isString,
  map,
  reject,
  uniq,
  head,
  merge,
  overEvery,
  cloneDeep,
  isNil,
} from 'lodash';
import {
  IDatasetJson,
  IJoiningTable,
  ISugarCrmModule,
} from '../../edit-dataset-settings.interfaces';
import { useCallback, useContext, useState } from 'react';
import { ISearchableDropdownOption } from '../../../../ui/dropdowns/searchable-dropdown';
import { DatasetIngestionContext } from './data-ingestion.context';
import { MAX_ALIAS_LENGTH } from '../../../../common/Constants';
import { useDatasetSettingsContext } from '../../edit-dataset-settings.context';
import { IJoinedTableData } from './data-ingestion.interfaces';
import { makeDropdownOption } from '../../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';

export const useDatasetIngestionCallbacks = () => {
  const { datasetSettings: { datasetAnnotations: { datasetJson } = {} } = {} } =
    useDatasetSettingsContext();
  const { doChange } = useContext(DatasetIngestionContext);

  const handlePropertyChange = useCallback(
    ({ property, value }) => {
      const updatedDatasetJson: IDatasetJson = cloneDeep(datasetJson);

      if (isString(property) && isNil(property)) {
        delete updatedDatasetJson[property as string];
      } else {
        updatedDatasetJson[property] = value;
      }

      doChange(updatedDatasetJson);
    },
    [doChange, datasetJson],
  );

  const handlePrimaryModuleChange = useCallback(
    (options: ISearchableDropdownOption[]) => {
      const option = head(options);
      const primaryModule = option?.value;

      handlePropertyChange({
        property: 'primaryTable',
        value: { name: primaryModule },
      });
    },
    [handlePropertyChange],
  );

  const handleAddJoinTable = useCallback(() => {
    const updatedDatasetJson = merge(datasetJson, {
      joinedTables: concat(datasetJson.joinedTables ?? [], {} as IJoiningTable),
    }) as IDatasetJson;

    doChange(updatedDatasetJson);
  }, [datasetJson, doChange]);

  return {
    handlePrimaryModuleChange,
    handleAddJoinTable,
  };
};

export const validateAlias = (rowData: IJoinedTableData) => {
  const { alias } = rowData;
  if (!alias) {
    return true;
  }

  return /^[A-Z_a-z]+$/.test(alias) && alias.length <= MAX_ALIAS_LENGTH;
};

export const validateRowData = (rowData: IJoinedTableData) => {
  return overEvery(validateAlias)(rowData);
};

const buildJoinTable = (rowData: IJoinedTableData): IJoiningTable => {
  const { name, alias, parentName, foreignKey, filteredAttributes } = rowData;

  return {
    name,
    alias,
    parentName,
    foreignKey,
    filteredAttributes,
  };
};

export const useJoiningTableCallbacks = (rowIdx: number) => {
  const { datasetSettings: { datasetAnnotations: { datasetJson } = {} } = {} } =
    useDatasetSettingsContext();
  const { doChange } = useContext(DatasetIngestionContext);

  const [isValid, setIsValid] = useState(true);

  const joinedTablesData = useJoinedTablesData();

  const handleRowChange = useCallback(
    (property: keyof IJoinedTableData, value) => {
      const prevRowData = joinedTablesData[rowIdx];

      const updatedRow = cloneDeep(prevRowData);
      updatedRow[property] = value;

      const isRowValid = validateRowData(updatedRow);

      setIsValid(isRowValid);

      if (isRowValid) {
        const updatedDatasetJson: IDatasetJson = {
          ...datasetJson,
          joinedTables: map(
            isArray(datasetJson.joinedTables) ? datasetJson.joinedTables : [],
            (joinTable, idx) => {
              if (idx === rowIdx) {
                return buildJoinTable(updatedRow);
              }
              return joinTable;
            },
          ) as IJoiningTable[],
        };
        doChange(updatedDatasetJson);
      }
    },
    [joinedTablesData, rowIdx, datasetJson, doChange],
  );

  const handleDelete = useCallback(() => {
    const updatedDatasetJson = {
      ...datasetJson,
      joinedTables: reject(
        isArray(datasetJson.joinedTables) ? datasetJson.joinedTables : [],
        (joinTable, idx) => idx === rowIdx,
      ),
    };

    doChange(updatedDatasetJson);
  }, [datasetJson, rowIdx, doChange]);

  return {
    handleRowChange,
    handleDelete,
    isValid,
  };
};

export const useDatasetIngestionVars = () => {
  const {
    dataset: { datasetStatusType } = {},
    datasetSettings: { datasetAnnotations: { datasetJson } = {} } = {},
    sugarCrmSchema,
  } = useDatasetSettingsContext();

  const { primaryTable, snapshotOnly = false } = (datasetJson ??
    {}) as IDatasetJson;

  const { modules: allModules = [] } = sugarCrmSchema ?? {};
  const moduleNames = sortAlphabetically(map(allModules ?? [], 'name'));

  const hasPrimaryModule = !!primaryTable?.name;

  const primaryModuleDefaultValue = hasPrimaryModule
    ? makeDropdownOption(primaryTable?.name)
    : undefined;

  const primaryModuleOptions = map(moduleNames, moduleName => ({
    value: moduleName,
    label: moduleName,
  }));

  const importHistory = !snapshotOnly;

  return {
    moduleNames,
    datasetStatusType,
    primaryModule: {
      defaultValue: primaryModuleDefaultValue,
      options: primaryModuleOptions,
    },
    importHistory,
  };
};

export const useJoinedTablesData = (): IJoinedTableData[] => {
  const {
    datasetSettings: { datasetAnnotations: { datasetJson } = {} } = {},
    sugarCrmSchema,
  } = useDatasetSettingsContext();

  const { primaryTable, joinedTables } = (datasetJson ?? {}) as IDatasetJson;

  const { modules: allModules = [] } = sugarCrmSchema ?? {};

  const usedTableNames = reject(
    map(concat([primaryTable], joinedTables as any[]), 'name'),
    isEmpty,
  );

  const tableAliases = reject(map(joinedTables, 'alias'), isEmpty);

  const tableNames = sortAlphabetically(
    uniq(concat(tableAliases, usedTableNames)),
  );

  return map(joinedTables, joinTable => {
    // Note that all properties in joinTable can be undefined
    const {
      name,
      alias,
      parentName,
      foreignKey,
      filteredAttributes = [],
    } = joinTable;

    const nameOptions = map(allModules, ({ name: moduleName }) =>
      makeDropdownOption(moduleName),
    );

    const parentNames = reject(
      tableNames,
      tableName => joinTable.alias === tableName,
    );

    const parentNameOptions = map(parentNames, _name =>
      makeDropdownOption(_name),
    );

    const foreignKeys = sortAlphabetically(
      getModuleFieldNames(parentName, allModules),
    );

    const fieldAttributes = getModuleFieldNames(name, allModules);
    const fieldAttributesOptions = map(fieldAttributes, fAttr =>
      makeDropdownOption(fAttr),
    );

    const foreignKeyOptions = map(foreignKeys, fKey =>
      makeDropdownOption(fKey),
    );

    return {
      name,
      nameOptions,
      alias,
      parentName,
      parentNameOptions,
      foreignKey,
      foreignKeyOptions,
      filteredAttributes,
      fieldAttributesOptions,
    } as IJoinedTableData;
  });
};

export const sortAlphabetically = (options: string[]) => {
  return isArray(options) && !isEmpty(options)
    ? options.sort((a, b) =>
        a.localeCompare(b, undefined, { sensitivity: 'base' }),
      )
    : options;
};

export const getModuleFieldNames = (
  moduleName: string,
  allModules: ISugarCrmModule[],
): string[] => {
  const module = find(allModules, {
    name: moduleName,
  });
  return sortAlphabetically(map(module?.fields ?? [], 'name'));
};
