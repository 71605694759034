import Utils from './PivotTableUtils';
import CommonUtils from '../../../common/Util';
import _, { map, includes, isObject } from 'lodash';
import objectSort, {
  SubtotalAwareExcelLikeComparator,
} from '../../../common/ObjectSort';
import QueryPivot from './QueryPivot';
import {
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  NULL_DISPLAY,
  DELEGATING_FORMATTER,
  CUSTOM_FORMAT_ANNOTATION,
  Types,
} from '../../../common/Constants';
import { TOTALS_FLAG } from './QueryPivotUtils';
import { Viz } from '../../VizUtil';
import { createOrdinalName, requiresOrdinal } from '../ChartUtils';
import { getAllLinksEnabledInReport } from '../../../components/PivotDrillLinks/utils';
import { DefaultFontName } from '../../../components/ui/fonts';
import JSON5 from 'json5';
import { messages } from '../../../i18n';

const pivotFont = Utils.getComputedStyle(
  'pivot-cell',
  'font',
  `500 12px ${DefaultFontName}`,
);

const findColumnIndex = (colNames, name) => {
  return _.findIndex(
    colNames,
    c => _.startsWith(c, name) && c !== createOrdinalName(name),
  );
};

const findOrdinalColumnIndex = (colNames, name) => {
  return _.indexOf(colNames, createOrdinalName(name));
};

const getShelfFieldsWithOrdinals = (shelf, props) => {
  const colsToPivot = props.viz.layout[shelf].map(r => r.name);
  const { columnNames } = props.queryResults.executeQuery;

  const colsToPivotWithOrdinals = [...colsToPivot];
  // Check to see if we need to add ordinal columns to pivot col list

  // Time ordinals go in first
  colsToPivot.forEach(r => {
    const s = createOrdinalName(r);
    const idx = findColumnIndex(columnNames, s);
    if (idx > 0) {
      colsToPivotWithOrdinals.push(s);
    }
  });

  // ordinalAttributes added second
  colsToPivot.forEach(r => {
    const field = Utils.findFieldByName(props, r);
    if (!_.isEmpty(field.ordinalAttribute)) {
      colsToPivotWithOrdinals.push(field.ordinalAttribute);
    }
  });
  return colsToPivotWithOrdinals;
};

const getColsToPivotWithOrdinals = props => {
  return getShelfFieldsWithOrdinals('COLUMNS', props);
};

const getRowsToPivotWithOrdinals = props => {
  return getShelfFieldsWithOrdinals('ROWS', props);
};

const getOrdinalColumnsMap = props => {
  const ordinalColumnsMap = {};

  const allFields = [props.viz.layout.COLUMNS, props.viz.layout.ROWS];

  const { columnNames } = props.queryResults.executeQuery;

  allFields.forEach(c => {
    c.forEach(r => {
      const field = Utils.findFieldByName(props, r.name);
      if (requiresOrdinal(r)) {
        const colIdx = findOrdinalColumnIndex(columnNames, r.name);
        ordinalColumnsMap[r.name] = colIdx;
      } else if (!_.isEmpty(field.ordinalAttribute)) {
        const colIdx = findColumnIndex(columnNames, field.ordinalAttribute);
        ordinalColumnsMap[r.name] = colIdx;
      }
    });
  });
  return ordinalColumnsMap;
};

const getOrdinalFieldNamesMap = props => {
  const ordinalFieldsMap = {};

  const allFields = [props.viz.layout.COLUMNS, props.viz.layout.ROWS];

  allFields.forEach(c => {
    c.forEach(r => {
      const field = Utils.findFieldByName(props, r.name);
      if (requiresOrdinal(r)) {
        ordinalFieldsMap[r.name] = createOrdinalName(r.name);
      } else if (!_.isEmpty(field.ordinalAttribute)) {
        ordinalFieldsMap[r.name] = field.ordinalAttribute;
      } else if (!_.isEmpty(field.ordinalFormula)) {
        // this is probably a YEAR, QUARTER, etc. time field, map the original field as it's own ordinal for potential sort operations
        ordinalFieldsMap[r.name] = r.name;
      }
    });
  });
  return ordinalFieldsMap;
};

const getNumberIfNumeric = (maybeNumber, props, name) => {
  let field = Utils.findFieldByName(props, name);
  if (!_.isNil(field.ordinalAttribute)) {
    // we might be evaluating the ordinal version of this field. see if that is numeric
    const ordinalField = Viz.getOrdinalFor(name, props.viz);
    if (!_.isNil(ordinalField)) {
      field = ordinalField;
    }
  }
  // check if field is numeric, if so return as number for sorting. Note parseFloat handles whole ints as well
  if (
    field.attributeType === Types.NUMBER ||
    field.attributeType === Types.PRIOR_PERIOD_CALC ||
    (field.attributeType === Types.TIME_CALC &&
      !_.isNil(field.timeAttribute) &&
      field.timeAttribute.key === 'WEEK_IN_QTR') ||
    (field.attributeType === Types.CALC &&
      !_.isNil(field.calcType) &&
      field.calcType === Types.NUMBER)
  ) {
    if (maybeNumber === '' || maybeNumber === NULL_DISPLAY) {
      return Number.MIN_SAFE_INTEGER;
    }
    const val = parseFloat(maybeNumber);
    if (_.isNaN(val)) {
      // it might be the __ALL__ token. In that case, return the rawVal for special handling later in the comparator that always makes those last
      if (_.isObject(maybeNumber)) {
        const nval = parseFloat(maybeNumber?.val);
        if (_.isNaN(nval)) {
          return maybeNumber;
        }
        return nval;
      }
      return maybeNumber;
    } else {
      return val;
    }
  } else {
    return maybeNumber;
  }
};

// Return value for sort function. Checks to see if there's an ordinal column and uses that value if so.
const getValueForSortingColumns = (path, sortedArr, props) => {
  const { columnNames } = props.queryResults.executeQuery;
  const name = path[0];
  const field = Utils.findFieldByName(props, name);

  let withOrdinal = findOrdinalColumnIndex(columnNames, name);
  if (withOrdinal === -1 && !_.isEmpty(field.ordinalAttribute)) {
    withOrdinal = findColumnIndex(columnNames, field.ordinalAttribute);
  }

  const maybeNumber =
    withOrdinal > 0
      ? sortedArr[withOrdinal]
      : sortedArr[findColumnIndex(columnNames, name)];

  return getNumberIfNumeric(maybeNumber, props, name);
};

// Return value for sort function. Checks to see if there's an ordinal column and uses that value if so.
const getValueForSortingRows = (props, colHeaderData) => {
  const { columnNames } = props.queryResults.executeQuery;
  // Caching values to speed things up
  const sortingCache = {};

  return path => {
    const cachedValue = sortingCache[path.join(' ')];
    if (cachedValue) {
      return cachedValue;
    }

    // Not in cache. Compute position
    let retVal = -1;
    if (path.length > 1) {
      // Value columns are the only ones with parents ATM. Transverse the ColumnHeaderData array to figure out the current
      // physical position of the field based on it's path.
      let columnIndex = 0;
      let found = false;

      /**
       * 'colHeaderData' contains the header information in the format of [["Column Name", Repeated Count]]. The repeated count
       * is number indicating how many columns it spans over the row below.
       *
       * e.g.
       *   ["Canine":8]                                   // [genus]
       *   ["dog":4, "cat":4]                             // [species]
       *   ["male":2, "female":2, "male":2, "female":2]   // [gender]
       *   [25:1, 4:1, 20:1, 2:1, 55:1, 8:1, 124:1, 4:1]  // [weight, age]
       *                                  ^
       *   path:                          |
       *   [age, "male", "cat", "Canine"] - == 5
       *
       * The algorithm following transverses the columnHeaderData to calculate the absolute position of a "path".
       *
       * In the case of [age, "male", "cat", "Canine"] the position returned would be 5
       *
       * Note Path is in opposite order ["Sales", "Florida", "USA"].
       * Working from the end of the path corresponds to the top of the colHeaderData array [Country, State, Values]
       */

      for (let y = 0, p = path.length - 1; p > 0; p--, y++) {
        let amtToAdd = 0;
        found = false;
        const colLength = (colHeaderData[y] || []).length;
        for (let z = 0; z < colLength; z++) {
          // A given path value may be repeated multiple times per row if nested below another value.
          // Check that we're beyond the current columnIndex before considering a match.
          if (
            amtToAdd >= columnIndex &&
            colHeaderData[y][z][0]?.value === path[p]
          ) {
            found = true;
            break;
          }
          amtToAdd += colHeaderData[y][z][1];
        }
        columnIndex = amtToAdd;
      }

      if (found) {
        retVal =
          columnIndex +
          Utils.numRowsAndOrdinals(props) +
          _.findIndex(props.valuesAndTargets, {
            name: path[0],
          });
      } else {
        retVal = -1;
      }
    } else if (Utils.isPivotLayout(props) && Utils.numColumns(props) === 0) {
      // Rows and values, no columns.

      const name = path[0];

      const field = Utils.findFieldByName(props, name);
      const colIndex = findColumnIndex(columnNames, name);
      // if a row field we'll find it in columnNames, measures are m0, m1, etc. those are found by the position from the layout
      if (findOrdinalColumnIndex(columnNames, name) > -1) {
        retVal = findOrdinalColumnIndex(columnNames, name);
      } else if (
        !_.isEmpty(field.ordinalAttribute) &&
        findColumnIndex(columnNames, field.ordinalAttribute)
      ) {
        retVal = findColumnIndex(columnNames, field.ordinalAttribute);
      } else if (colIndex > -1) {
        retVal = colIndex;
      } else {
        // must be a value, find it by layout, adjust for size of rows
        retVal =
          columnNames.length -
          (Utils.numValues(props) -
            _.findIndex(props.viz.layout.VALUES, { name: path[0] }));
      }
    } else if (Utils.isPivotLayout(props) && Utils.numRows(props) === 0) {
      // there's no sorting of this view as there's only one row
      return -1;
    } else if (Utils.hasRows(props)) {
      // Row header column. No parent information.
      const name = path[0];
      const field = Utils.findFieldByName(props, name);

      const rowColumns = getRowsToPivotWithOrdinals(props);

      // See if there's an ordinal column that should be used.
      let withOrdinal = rowColumns.indexOf(createOrdinalName(name));

      if (withOrdinal === -1 && !_.isEmpty(field.ordinalAttribute)) {
        withOrdinal = rowColumns.indexOf(field.ordinalAttribute);
      }

      // If ordinal found return that otherwise return regular column position
      retVal = withOrdinal > 0 ? withOrdinal : rowColumns.indexOf(name);
    } else {
      // column sort
      const name = path[0];
      const field = Utils.findFieldByName(props, name);

      const cols = getColsToPivotWithOrdinals(props);

      // See if there's an ordinal column that should be used.
      let withOrdinal = cols.indexOf(createOrdinalName(name));

      if (withOrdinal === -1 && !_.isEmpty(field.ordinalAttribute)) {
        withOrdinal = cols.indexOf(field.ordinalAttribute);
      }

      // If ordinal found return that otherwise return regular column position
      retVal = withOrdinal > 0 ? withOrdinal : cols.indexOf(name);
    }
    // Store in cache so the next rows are fast lookups
    sortingCache[path.join(' ')] = retVal;
    return retVal;
  };
};

const getformatters = props => {
  const dataFormatters = Utils.getDataFormatters(props.viz);
  const customDataFormatters = Utils.getDataCustomFormatters(props.viz);
  return {
    body: [props.viz.layout.VALUES.map(c => dataFormatters[c.name])],
    custom: props.viz.layout.VALUES.map(c => customDataFormatters[c.name]),
    columnHeaders: props.viz.layout.COLUMNS.map(c => dataFormatters[c.name]),
    rowHeaders: props.viz.layout.ROWS.map(c => dataFormatters[c.name]),
  };
};

const getSorting = props => Utils.verifyAndOrderSortingFields(props);

const sortColumns = (queryResults, sorting, props) => {
  if (sorting.COLUMNS.length > 0) {
    return objectSort(
      queryResults,
      sorting.COLUMNS.map(
        c => x => getValueForSortingColumns(c.path, x, props),
      ),
      sorting.COLUMNS.map(c => c.direction),
      SubtotalAwareExcelLikeComparator,
    );
  }
  return queryResults;
};

// Numbers in headers cause trouble down the road. Turn any number into a string unless it's a values column
const stringifyNumericHeaders = (queryResults, headerFormatters, props) => {
  const numColsAndRows = Utils.numRows(props) + Utils.numColumns(props);
  return queryResults.map(row =>
    row.map((col, idx) => {
      const f = headerFormatters[idx];
      return idx < numColsAndRows && f ? f.format(col, props.i18nPrefs) : col;
    }),
  );
};

const getValuesAndTargets = props => {
  const { columnInfo } = props.queryResults.executeQuery;
  const valuesNames = [...props.viz.layout.VALUES];
  const measures = columnInfo.filter(m => m.columnType === 'MEASURE');
  let latest;
  return measures.map(v => {
    latest = valuesNames.shift();
    return {
      ...latest,
      delegatingFormatter:
        columnInfo.filter(
          info =>
            info.attributeName === v.attributeName &&
            info.columnType === 'FORMAT',
        ).length > 0,
    };
  });
};

const createQueryPivot = (
  queryResults,
  props,
  colsToPivot,
  colsToPivotWithOrdinals,
  columnNames,
  rowsToPivot,
  rowsToPivotWithOrdinals,
  sorts,
) => {
  // Get a list of values and targets
  const valuesAndTargets = getValuesAndTargets(props);
  const ordinalFieldNameMap = getOrdinalFieldNamesMap(props);

  const headerRow = columnNames
    .slice(0, columnNames.length - valuesAndTargets.length)
    .concat(valuesAndTargets.map(v => v.name));

  const pivotCols = {
    withOrdinals: colsToPivotWithOrdinals,
    withoutOrdinals: colsToPivot,
  };
  const pivotRows = {
    withOrdinals: rowsToPivotWithOrdinals,
    withoutOrdinals: rowsToPivot,
  };
  return new QueryPivot(
    queryResults,
    headerRow,
    pivotRows,
    pivotCols,
    valuesAndTargets.map(v => v.name),
    ordinalFieldNameMap,
    sorts,
    props.showColGrandTotals,
  );
};

/**
 * Return an array containing the longest string per column. This is used later to calculate column widths
 */
const calculateColumnSizes = (
  bodyData,
  rawTable,
  formatters,
  props,
  rowGrandTotals,
  valuesAndTargets,
) => {
  const numColsAndOrdinals = getColsToPivotWithOrdinals(props).length;
  const numRowsAndOrdinals = getRowsToPivotWithOrdinals(props).length;

  const numRows = Utils.numRows(props);
  let data = [...bodyData];
  if (props.showRowGrandTotals && !_.isEmpty(rowGrandTotals)) {
    data = [...data, ...rowGrandTotals];
  }
  // if row subtotals or grad totals are on, account for those labels too
  if (props.showRowSubtotals || props.showRowGrandTotals) {
    const dummyTotalsRow = _.range(
      0,
      numColsAndOrdinals || numRowsAndOrdinals,
    ).map(idx => {
      if (props.showRowGrandTotals && idx === 0) {
        return messages.pivot.grandTotal;
      } else if (props.showRowSubtotals) {
        return messages.pivot.subTotal;
      }
      return '';
    });
    const num = data[0]?.columnData?.length;
    const empty = _.fill(Array(num), '');
    const dummyRowValues = [
      ...dummyTotalsRow,
      ..._.takeRight(empty, empty.length - dummyTotalsRow.length),
    ];
    const dummyColumnData = map(dummyRowValues, _val => ({ value: _val }));

    data = [...data, { type: 'dummy', columnData: dummyColumnData }];
  }

  const format = (val, formatter, i18nPrefs = {}) => {
    if (includes([messages.pivot.grandTotal, messages.pivot.subTotal], val)) {
      return val;
    }
    return formatter ? formatter.formatText(val, i18nPrefs) : val;
  };

  let rotated = Utils.rotateTable(data, -90);

  // Special case where a fake pivot is forced with a placeholder row. We remove that row here
  if (numRowsAndOrdinals === 0 && props.viz.layout.VALUES.length > 0) {
    rotated.shift(); // take off the first row
  }

  // Special case for Tabular form. Format values
  if (Utils.hasColumns(props) && !Utils.isPivotLayout(props)) {
    rotated = rotated.map(({ columnData }, colIdx) => {
      return {
        columnData: columnData?.map(({ value: _val }) => {
          return {
            value: format(
              _val,
              formatters.columnHeaders[colIdx],
              props.i18nPrefs,
            ),
          };
        }),
      };
    });
  }

  /**
   * Column headers have a sort icon when rendered. This String approximates the width of this icon without us having
   * to render headers specially with the icon for measuring
   * @type {string}
   */

  // Add in the values labels. These can be long, but the maximum of 200px will limit them
  if (Utils.hasValues(props)) {
    for (let i = numRowsAndOrdinals, y = 0; i < rotated.length; i++, y++) {
      const metricPos = y % valuesAndTargets.length;
      const metric = valuesAndTargets[metricPos];
      const formatter = formatters.body[metricPos];
      // Format Metrics in column (row in this rotated form)
      if (formatter) {
        const columnData = rotated[i]?.columnData ?? [];
        for (let z = 0; z < columnData.length; z++) {
          const formatted = formatter.formatText(
            rotated[i].columnData[z]?.value,
          );
          rotated[i].columnData[z] = { value: formatted };
        }
      }

      const value = Utils.hasValues(props) ? metric.name : '';

      rotated[i].columnData?.unshift({ value });
    }
  }

  // Format Row column values (links, etc)
  if (Utils.hasRows(props)) {
    for (let i = 0, y = 0; i < numRowsAndOrdinals; i++, y++) {
      const formatter = formatters.rowHeaders[i];
      // Format Metrics in column (row in this rotated form)
      if (formatter) {
        const columnData = rotated[i]?.columnData || [];
        for (let z = 0; z < columnData.length; z++) {
          const formatted = formatter.formatText(
            rotated[i]?.columnData[z]?.value,
          );
          rotated[i].columnData[z] = { value: formatted };
        }
      }
    }
  }

  // Add in the row labels.
  if (Utils.hasRows(props)) {
    for (let i = 0; i < numRows; i++) {
      const rowHeader = props.viz.layout.ROWS[i];
      const val = Utils.getPivotCellDisplayValue(rowHeader);
      const formatter = formatters.columnHeaders[i];
      const formatted = format(val, formatter);
      rotated[i]?.columnData?.unshift({ value: formatted });
    }
  }

  // Add the Column labels
  rawTable.slice(0, numColsAndOrdinals).forEach((colRow, colIdx) => {
    colRow.forEach((col, idx) => {
      if (rotated.length > numRowsAndOrdinals + idx) {
        const formatter = formatters.columnHeaders[colIdx];
        // let formatted = format(value, formatter);
        let formatted = format(isObject(col) ? col?.value : col, formatter);
        // Special case for links
        if (_.includes(formatted, ':: link')) {
          formatted = formatted.substring(0, formatted.indexOf(':: link'));
        }
        rotated[numRowsAndOrdinals + idx]?.columnData?.unshift({
          value: formatted,
        });
      }
    });
  });

  let longestValuesPerColumn = [];

  const rawTableData = new Set(
    rawTable
      .flat()
      .map(({ value }) => value)
      .filter(el => _.isNaN(+el)),
  );

  function measureGroup(collection, groupType) {
    const numValues = Utils.numValues(props);
    longestValuesPerColumn = longestValuesPerColumn.concat(
      collection.map((row, idx) =>
        row.columnData.reduce(
          (prevLongest, { value: val }) => {
            // body has both values and column headers
            // don't measure the __ALL__ placeholder
            val = val === TOTALS_FLAG ? '' : val;
            const formatter = formatters[groupType][idx];
            const formattedVal =
              _.isEmpty(formatter) || formatter.formatType !== 'Custom'
                ? val
                : _.isEmpty(formatters.custom[idx]) || Number.isNaN(+val)
                  ? val
                  : DATA_FORMATTER.CUSTOM.format(
                      val,
                      null,
                      formatters.custom[idx],
                    );

            const current = CommonUtils.calcTextWidth(formattedVal, pivotFont);

            // separate column cells from value cells
            if (numValues > 1 && rawTableData.has(val)) {
              return prevLongest;
            }

            return val && current > prevLongest[1]
              ? [{ value: formattedVal }, current]
              : prevLongest;
          },
          [{ value: '' }, 0],
        ),
      ),
    );
  }

  const rows = rotated.slice(0, numRows);
  // We're skipping ordinals at this point
  const body = rotated.slice(numRowsAndOrdinals);

  measureGroup(rows, 'rowHeaders');
  measureGroup(body, 'body');

  if (Utils.isPivotLayout(props)) {
    // Pivot tables have an aggregated column, remove it
    longestValuesPerColumn.pop();
  } else if (!Utils.isPivotLayout(props) && Utils.hasColumns(props)) {
    // tabular form, remove the ordinals and placeholder value
    longestValuesPerColumn = longestValuesPerColumn.slice(
      0,
      Utils.numColumns(props),
    );
  }

  return longestValuesPerColumn.map(o => o[0]);
};

const mainDataFunc = props => {
  let {
    results: queryResults,
    columnNames,
    columnInfo,
  } = props.queryResults?.executeQuery ?? {};
  if (_.isEmpty(queryResults)) {
    return {};
  }

  // extract formats from results if they exist, merge into values
  for (let i = columnInfo.length - 1; i >= 0; i--) {
    if (columnInfo[i].columnType === 'FORMAT') {
      // purge column from data, merge into values
      columnNames = columnNames.filter((r, idx) => idx !== i);
      // [[123, 'Percent']] => [{val: 123, format: 'Percent'}]
      queryResults = queryResults.map(r =>
        r
          .map((c, idx, arr) =>
            idx === i - 1 ? { val: c, format: arr[i] } : c,
          )
          .filter((c, idx) => idx !== i),
      );
    }
  }

  let colsToPivot = props.viz.layout.COLUMNS.map(r => r.name);
  // swap out for links
  const { viz } = props;
  const links = getAllLinksEnabledInReport(
    viz,
    Viz.getAllFieldsInPlay(viz?.layout),
  );
  colsToPivot = colsToPivot.map(col => (links[col] ? `${col} :: link` : col));

  let colsToPivotWithOrdinals = getColsToPivotWithOrdinals(props);
  colsToPivotWithOrdinals = colsToPivotWithOrdinals.map(col =>
    links[col] ? `${col} :: link` : col,
  );

  const valuesAndTargets = getValuesAndTargets(props);

  // Create a map of Column to Ordinal index
  const ordinalColumnsMap = getOrdinalColumnsMap(props);

  const formatters = getformatters(props);
  const headerFormatters = [
    ...formatters.columnHeaders,
    ...formatters.rowHeaders,
  ];

  const sorting = getSorting(props);

  // Sort Columns. Done prior to pivoting as the data is in the perfect format already for a Column sort.
  queryResults = sortColumns(queryResults, sorting, props);

  let rowsToPivot = props.viz.layout.ROWS.map(r => r.name);
  rowsToPivot = rowsToPivot.map(row => (links[row] ? `${row} :: link` : row));

  let rowsToPivotWithOrdinals = getRowsToPivotWithOrdinals(props);
  rowsToPivotWithOrdinals = rowsToPivotWithOrdinals.map(row =>
    links[row] ? `${row} :: link` : row,
  );

  const queryPivot = createQueryPivot(
    queryResults,
    props,
    colsToPivot,
    colsToPivotWithOrdinals,
    columnNames,
    rowsToPivot,
    rowsToPivotWithOrdinals,
    sorting,
  );

  // don't stringify the headers until after we have pivoted the data.
  queryResults = stringifyNumericHeaders(queryResults, headerFormatters, props);

  const hideGrandTotalsForColumnIndexes = getHiddenGrandTotalColumns(
    queryPivot.measurePositionToColumnIndexes,
    valuesAndTargets,
  );
  formatters.body = getBodyFormatters(
    queryPivot.measurePositionToColumnIndexes,
    valuesAndTargets,
  );
  const customFormats = _.get(formatters, 'custom');
  formatters.custom = getCustomBodyFormatters(
    queryPivot.measurePositionToColumnIndexes,
    valuesAndTargets,
    customFormats,
  );

  const { colHeaderData } = queryPivot;
  let bodyData = props.showRowSubtotals
    ? queryPivot.bodyDataWithRowSubtotals
    : queryPivot.bodyData;
  const { rowGrandTotals } = queryPivot;

  let rawTable = bodyData.map(row => {
    const rawRow = row.columnData;
    // the first n elements in the row array are associated with the row headers, skip them for this
    return rawRow.slice(rowsToPivotWithOrdinals.length, rawRow.length - 1);
  });
  // expand the column header data from its collapsed, col-span form
  const expandedRowHeaders = colHeaderData.map(ch => {
    return ch.reduce((headerRow, colSpan) => {
      if (colSpan[1] === 1) {
        headerRow.push(colSpan[0]);
      } else {
        const expanded = _.fill(Array(colSpan[1]), colSpan[0]);
        headerRow = [...headerRow, ...expanded];
      }
      return headerRow;
    }, []);
  });

  if (!Utils.isPivotLayout(props) && Utils.hasColumns(props)) {
    // tabular form. Add in the column field names for the width calculations
    // rawTable = [[...colsToPivot, ''], ...expandedRowHeaders, ...rawTable];
    rawTable = [
      [...colsToPivot, { value: '' }],
      ...expandedRowHeaders,
      ...rawTable,
    ];
  } else {
    rawTable = [...expandedRowHeaders, ...rawTable];
  }

  const getValueForSortingRowsFunc = getValueForSortingRows(
    { ...props, valuesAndTargets },
    colHeaderData,
  );

  if (!Utils.isPivotLayout(props) && Utils.hasColumns(props)) {
    // Only have column headers. we need to treat these as row values to provide the ability to render a traditional 2-D table
    const headerData = rawTable.slice(1).map(r => {
      return { type: 'colHeader', columnData: r };
    });
    const columnsAsRows = Utils.rotateTable(headerData, -90);
    bodyData = columnsAsRows.map(({ columnData }) => {
      return { type: 'data', columnData };
    });
  }

  // Sort By Rows. We do this after pivoting as the data is in a better form for this sort after pivoting
  if (sorting.ROWS.length > 0) {
    bodyData = objectSort(
      bodyData,
      sorting.ROWS.map(c => _row => {
        const maybeNumber =
          _row.columnData[getValueForSortingRowsFunc(c.path)]?.value;
        if (_row.type === 'rowSubtotal') {
          // if this is row subtotal and a metric field, return the __ALL__ to sort on
          const metricField = props.viz.layout.VALUES.find(
            f => f.name === c.path[0],
          );
          if (!_.isNil(metricField)) {
            return TOTALS_FLAG;
          }
        }
        return getNumberIfNumeric(maybeNumber, props, c.path[0]);
      }),
      sorting.ROWS.map(c => c.direction),
      SubtotalAwareExcelLikeComparator,
    );
  }

  // Rotate and flatten array to allow for us to create the row header data
  const flatData = Utils.rotateTable(bodyData, -90);
  const rowHeaderData = Utils.collapseRepeated(flatData);

  const columnSizes = calculateColumnSizes(
    bodyData,
    rawTable,
    formatters,
    props,
    rowGrandTotals,
    valuesAndTargets,
  );
  const originalData = queryPivot.queryResultObjects.bodyOnly;
  const dataColumnCount = !_.isEmpty(rawTable[0])
    ? rawTable[0].length
    : _.isEmpty(valuesAndTargets)
      ? 1
      : valuesAndTargets.length;
  return {
    rowGrandTotals,
    bodyData,
    rowHeaderData,
    colHeaderData,
    ordinalColumnsMap,
    valuesAndTargets,
    sorting,
    formatters,
    hideGrandTotalsForColumnIndexes,
    columnSizes,
    dataColumnCount,
    originalData,
    rawTable,
  };
};

const getBodyFormatters = (measureToColIndexes, valuesAndTargets) => {
  const unorderedLookup = valuesAndTargets.reduce(
    (colFormatters, measureField, idx) => {
      let formatter =
        DATA_TYPE_FORMAT.getFormatterByName(measureField.formatType) ||
        DATA_TYPE_FORMAT.getDefaultFormatterForType(measureField.attributeType);
      if (
        measureField.defaultAggregation === 'Count' ||
        measureField.defaultAggregation === 'Count (Distinct)'
      ) {
        formatter = DATA_FORMATTER.WHOLE_NUMBER;
      }
      if (measureField.delegatingFormatter) {
        formatter = DELEGATING_FORMATTER;
      }
      if (_.isEmpty(measureToColIndexes)) {
        return [];
      }
      const cols = measureToColIndexes[idx];
      cols.forEach(colIdx => {
        colFormatters.push({
          idx: colIdx,
          colName: measureField.name,
          formatter,
        });
      });
      return colFormatters;
    },
    [],
  );
  const sorted = _(unorderedLookup).sortBy(['idx']).map('formatter').value();
  return sorted;
};

/**
 * Pivot the index of the custom formatter to match the index of the display columns
 * @param measureToColIndexes
 * @param valuesAndTargets
 * @param customFormats
 */
const getCustomBodyFormatters = (
  measureToColIndexes,
  valuesAndTargets,
  customFormats,
) => {
  const unorderedLookup = valuesAndTargets.reduce(
    (colCustomFormatters, measureField, idx) => {
      if (idx >= customFormats.length) {
        return [];
      }
      let customFormatter = customFormats[idx];
      //may be a derived field with a custom formatter on the parent
      if (_.isEmpty(customFormatter)) {
        const parentCustomFormatter = _.find(
          measureField.valueField?.annotations,
          {
            key: CUSTOM_FORMAT_ANNOTATION,
          },
        );
        customFormatter = !_.isEmpty(parentCustomFormatter)
          ? JSON5.parse(parentCustomFormatter.value)
          : customFormatter;
      }

      const cols = measureToColIndexes[idx];
      cols.forEach(colIdx => {
        colCustomFormatters.push({
          idx: colIdx,
          colName: measureField.name,
          customFormatter,
        });
      });
      return colCustomFormatters;
    },
    [],
  );
  const sorted = _(unorderedLookup)
    .sortBy(['idx'])
    .map('customFormatter')
    .value();

  return sorted;
};

const getHiddenGrandTotalColumns = (measureToColIndexes, valuesAndTargets) => {
  //
  // We no longer need to hide grand total columns due to pivot table restrictions.
  // However, in the future we might want to conditionally hide them again.
  // For that reason, I am leaving the capability here but returning no hidden columns.
  //
  const hideGrandTotal = _.stubFalse;

  if (_.isEmpty(measureToColIndexes)) {
    return [];
  }

  const unorderedLookup = valuesAndTargets.reduce(
    (colHidden, measureField, idx) => {
      const cols = measureToColIndexes[idx];
      cols.forEach(colIdx => {
        colHidden.push({
          idx: colIdx,
          colName: measureField.name,
          hidden: hideGrandTotal(measureField),
        });
      });
      return colHidden;
    },
    [],
  );
  const sorted = _.sortBy(unorderedLookup, ['idx']).map(
    lookup => lookup.hidden,
  );
  return sorted;
};

export default mainDataFunc;

const __private__ = {
  calculateColumnSizes,
  findColumnIndex,
  findOrdinalColumnIndex,
  getValuesAndTargets,
  getRowsToPivotWithOrdinals,
  stringifyNumericHeaders,
  sortColumns,
  getSorting,
  getformatters,
  getValueForSortingRows,
  getValueForSortingColumns,
  getNumberIfNumeric,
  getOrdinalColumnsMap,
  getColsToPivotWithOrdinals,
  mainDataFunc,
  getBodyFormatters,
  getCustomBodyFormatters,
  getHiddenGrandTotalColumns,
  pivotFont,
};
export { __private__ };
