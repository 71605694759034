import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DatasetSection } from '../../edit-dataset-settings.styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export const FiscalDatasetSection = styled(DatasetSection)`
  max-width: 40em;
`;

// going with span(display block) because of a current global styling conflict
export const DatasetSectionTitle = styled.span(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    font-size: 12px;
    color: ${Gray70};
    margin-bottom: 0.35em;
    display: block;
  `,
);

export const DatasetDateSelectionContainer = styled.div`
  display: inline-block;
`;

export const DatePreviewBox = styled.div(
  ({
    theme: { colors: { DatePreviewBox } = {} as any } = {} as any,
  }: any) => css`
    background-color: ${DatePreviewBox};
    border-radius: 0.2em;
    padding: 0.8em 0.9em;
    min-width: 25em;
    display: inline-block;

    ${DatePreviewBoxData}:last-child {
      margin-bottom: 0;
    }
  `,
);

export const DatePreviewBoxData = styled.span`
  font-size: 14px;
  margin-bottom: 1em;
  display: block;
`;

export const DatasetSettingsContent = styled.div`
  padding: 20px 30px;
  width: 100%;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: -2px;
  margin-bottom: 0;
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`;

export const RadioStyled = styled(Radio)(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    color: ${Gray70};
    padding: 2px;
    &.Mui-checked {
      color: ${Gray70};
    }
    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  `,
);
