import { css } from '@emotion/react';
import { fontsStyles } from './fonts.styles';
import { commonStyles } from './common.styles';
import { d3Styles } from './d3.styles';
import { bootstrapOverridesStyles } from './bootstrap-overrides.styles';
import { activityFeedStyles } from './activity-feed.styles';
import { mainNavStyles } from './main-nav.styles';
import { iconStyles } from './icon.styles';
import { widgetsStyles } from './widgets.styles';
import { formsStyles } from './forms.styles';
import { dialogsStyles } from './dialogs.styles';
import { settingsStyles } from './settings.styles';
import { discoverStyles } from './discover.styles';
import { pivotTableStyles } from './pivot-table.styles';
import { tableStyles } from './table.styles';
import { componentStyles } from './component.styles';
import { loginStyles } from './login.styles';
import { datasetWizardStyles } from './dataset-wizard.styles';
import { sliderStyles } from './slider.styles';
import { thumbnailStyles } from './thumbnail.styles';
import { mobileStyles } from './mobile';
import { sugarIconStyles } from '../../icons/common/icons.styles';
import { IDiscoverEmotionTheme } from './theme';
import { sugarToastStyles } from '../../components/sugar-toast/sugar-toast.styles';

export const mainStyles = ({
  mobileMaxWidth,
  appUrl,
  isDashletMode,
  isMobile,
  theme,
}: {
  mobileMaxWidth;
  appUrl?: string;
  isDashletMode?: boolean;
  isMobile?: boolean;
  theme: IDiscoverEmotionTheme;
}) => css`
  ${fontsStyles};
  ${sugarToastStyles({ appUrl, theme })}
  ${sugarIconStyles({ appUrl })};
  ${commonStyles({ isDashletMode, isMobile, theme })};
  ${d3Styles({ theme })};
  ${bootstrapOverridesStyles({ mobileMaxWidth, theme })};
  ${activityFeedStyles({ theme })};
  ${mainNavStyles({ mobileMaxWidth, appUrl, isMobile, isDashletMode, theme })};
  ${iconStyles({ theme })};
  ${widgetsStyles({ theme })};
  ${formsStyles({ theme })};
  ${dialogsStyles({ appUrl, theme })};
  ${settingsStyles({ theme })};
  ${discoverStyles({ theme })};
  ${pivotTableStyles({ theme })};
  ${componentStyles({ mobileMaxWidth, theme })};

  @media (min-width: ${mobileMaxWidth}) {
    ${loginStyles({ theme })};
    ${tableStyles({ theme })};
    ${datasetWizardStyles({ appUrl, theme })};
    ${sliderStyles({ appUrl, theme })};
    ${thumbnailStyles({ theme })};
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${mobileStyles({ isDashletMode, theme })};
  }

  .has-error input[type='text'],
  .has-error input[type='tel']:focus,
  .has-error input[type='tel'] {
    border: 1px solid ${theme?.colors?.ErrorColor};
    box-shadow: 0 0 4px 0px ${theme?.colors?.ErrorColor};
  }

  .input-group .form-control:last-of-type,
  .input-group-addon:last-of-type,
  .input-group-btn:first-of-type > .btn-group:not(:first-of-type) > .btn,
  .input-group-btn:first-of-type > .btn:not(:first-of-type),
  .input-group-btn:last-of-type > .btn,
  .input-group-btn:last-of-type > .btn-group > .btn,
  .input-group-btn:last-of-type > .dropdown-toggle {
    border-radius: 2px;
  }
  .input-group .form-control:first-of-type,
  .input-group-addon:first-of-type,
  .input-group-btn:first-of-type > .btn,
  .input-group-btn:first-of-type > .btn-group > .btn,
  .input-group-btn:first-of-type > .dropdown-toggle,
  .input-group-btn:last-of-type > .btn-group:not(:last-of-type) > .btn,
  .input-group-btn:last-of-type
    > .btn:not(:last-of-type):not(.dropdown-toggle) {
    border-radius: 2px;
  }
`;
