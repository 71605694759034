import { NOW } from '../../Filter';
import moment from '../../../../common/Moment';
import { Query } from '@apollo/client/react/components';
import DiscoverGql from '../../../../common/graphql/DiscoverQueries';
import { messages } from '../../../../i18n';
import {
  RelativeDateAnchorModes,
  RelativeOperators,
  TimePeriods,
} from '../relative-date-condition.utils';

export const FormatDateRange = ({
  useFiscalCalendar,
  datasetId,
  relativeDatesState,
  dateTimeFormat,
}) => {
  let anchor = moment.utc().format(moment.ISO8601);
  if (
    relativeDatesState.anchorEnabled &&
    relativeDatesState.anchorDate !== NOW
  ) {
    anchor = relativeDatesState.anchorDate;
  }

  const offset = Math.abs(relativeDatesState.offset);
  const period = TimePeriods[relativeDatesState.periodOperand];

  const operands = [
    `${offset}`,
    period.key,
    `${relativeDatesState.includeFractionalPeriod}`,
  ];

  if (relativeDatesState.anchorEnabled) {
    if (relativeDatesState.anchorDate) {
      operands.push(anchor);
    }

    if (relativeDatesState.anchorMode === RelativeDateAnchorModes.PREVIOUS) {
      operands[3] = relativeDatesState.anchorOffset || '1';
      operands.push(relativeDatesState.anchorDay);
    }
  }

  const selectedOperator = RelativeOperators[relativeDatesState.operator];

  const request = {
    filter: {
      attributeName: '',
      operator: selectedOperator.queryOperator,
      operands,
    },
    useFiscalCalendar,
    datasetId,
  };

  // @TODO move query to hook for reuse in x-axis of waterfall
  return (
    <Query
      query={DiscoverGql.ComputeDateRangeQuery}
      skip={false}
      pollInterval={0}
      variables={{ request }}
    >
      {({ loading, error, data }: any) => {
        if (loading || error) {
          return (
            <div>
              {messages.formatString(messages.activeFilterPanel.calculating)}
            </div>
          );
        }
        const { start, end } = data.dateRange;
        return (
          <div>
            {messages.formatString(
              messages.filters.effectiveDateRange,
              moment(start).format(dateTimeFormat),
              moment(end).subtract(1, 'seconds').format(dateTimeFormat),
            )}
          </div>
        );
      }}
    </Query>
  );
};
