import { useQuery } from '@apollo/client';
import { DiscoverQueries } from '../../../common/graphql';
import { IExpressionSegment, IFilter } from '../../../datasets';
import { getOperatorObj } from '../exports/FilterFormatter';
import {
  useOpenVizDatasetSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import { USE_FISCAL_REPORTING } from '../../../common';
import { useDateTimeFormats } from '../../../common/redux/selectors/account-selector.hook';
import moment from '../../../common/Moment';
import { get, head, isEqual, isNil, some } from 'lodash';
import { useMemo } from 'react';
import { NOW } from '../Filter';

export type UesDateRangeProps = {
  vizId: string;
  filter: IFilter;
};

export const useDateRange = ({ vizId, filter }: UesDateRangeProps) => {
  const useFiscalCalendar = useVizOptionSelector({
    discoveryId: vizId,
    option: USE_FISCAL_REPORTING,
  });
  const datasetId = useOpenVizDatasetSelector({ discoveryId: vizId })?.id;

  const queryVariables = useMemo(() => {
    if (
      isNil(filter) ||
      (!isNil(filter.expression.right) &&
        !isEqual(head(filter.expression.right.operands), NOW))
    ) {
      return null;
    }

    return {
      request: {
        filter: {
          attributeName: filter.field,
          operator: getOperatorObj(filter, filter.expression.left.operator)
            .queryOperator,
          operands: (filter.expression.left as IExpressionSegment)?.operands,
        },
        useFiscalCalendar,
        datasetId,
      },
    };
  }, [datasetId, filter, useFiscalCalendar]);

  const shouldSkipQuery = some(
    [filter, useFiscalCalendar, datasetId, queryVariables],
    isNil,
  );

  const queryResult = useQuery(DiscoverQueries.ComputeDateRangeQuery, {
    skip: shouldSkipQuery,
    variables: queryVariables,
  });

  const userProvidedDateRange = {
    start: get(filter?.expression, 'left.operands[0]', null),
    end: get(filter?.expression, 'right.operands[0]', null),
  };

  return !shouldSkipQuery
    ? queryResult
    : {
        data: { dateRange: userProvidedDateRange },
        loading: false,
        error: null,
      };
};

export const useFormattedDateRange = ({ vizId, filter }: UesDateRangeProps) => {
  const { data: { dateRange } = {}, loading } = useDateRange({
    vizId,
    filter,
  });

  const dateTimeFormats = useDateTimeFormats();
  const { dateFormat } = dateTimeFormats ?? {};

  if (loading || some([dateRange?.start, dateRange?.end], isNil)) {
    return {
      start: undefined,
      end: undefined,
    };
  }

  const start = moment(dateRange?.start).format(dateFormat);
  const end = moment(dateRange?.end).subtract(1, 'seconds').format(dateFormat);

  return {
    start,
    end,
  };
};
