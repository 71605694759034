import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IInternationalizationPreferences } from '../../../account/interfaces';
import {
  convertSugarDateFormat,
  convertSugarTimeFormat,
} from '../../Constants';
import { ACCOUNT_SELECTORS } from '../../redux/selectors/AccountSelectors';

export const useDatetimeFormatter = () => {
  const {
    dateFormat: profileDateFormat,
    timeFormat: profileTimeFormat,
  }: IInternationalizationPreferences = useSelector(state => {
    try {
      return ACCOUNT_SELECTORS.getI18nPrefs(state);
    } catch {
      return {
        dateFormat: '',
        timeFormat: '',
      };
    }
  });
  const dateFormat = useMemo(
    () => convertSugarDateFormat(profileDateFormat),
    [profileDateFormat],
  );
  const timeFormat = useMemo(
    () => convertSugarTimeFormat(profileTimeFormat),
    [profileTimeFormat],
  );
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  return {
    dateFormat,
    timeFormat,
    dateTimeFormat,
  };
};
