import { IAnyAttribute, ITimeCalcAttribute } from './attribute.interface';

export type FilterType = 'DATE' | 'STRING' | 'NUMERIC' | 'BOOLEAN';
export type FilterSubType =
  | 'RELATIVE_DATES'
  | 'SET_CONDITION'
  | 'SELECT_ITEMS'
  | 'TOP_BOTTOM'
  | 'PERIOD_TO_DATE';

export interface IOperator {
  displayText: string;
  key: string;
  queryOperator: string;
  requiresOperand: boolean;
  hideOperand?: boolean;
  disableTime?: boolean;
  shortDisplayText: string;
  fiscalDisplayText?: string;
  shortFiscalDisplayText?: string;
  requiresFieldPicker?: boolean;
}

export interface IExpressionSegment {
  operator: string;
  operands: string[];
}

export interface IExpression {
  left: IExpression | IExpressionSegment;
  right?: IExpressionSegment;
  operator?: string;
}

export interface IAggregationContext {
  field?: string;
  aggregation?: string;
}

export interface IAppliedFilters {
  [attributeName: string]: IFilter;
}

export enum FilterDialogTypes {
  STANDARD = 'STANDARD',
  AGGREGATE = 'AGGREGATE',
}

export interface IFilter {
  expression: IExpression;
  field: string;
  subType?: FilterSubType;
  type: FilterType;
  aggregationContext?: IAggregationContext;
  info?: any;
  dialogType?: FilterDialogTypes;
}

export interface IPreFilterOperand {
  attribute: IAnyAttribute | ITimeCalcAttribute;
  value: any[];
}

export interface IQueryFilter {
  attributeName: string;
  operator: string;
  operands: string[];
  aggregation?: string;
}
