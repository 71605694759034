import {
  FilterTypes,
  Operator,
  StringFilterSubTypes,
  TimestampFilterSubTypes,
} from './Filter';
import _ from 'lodash';
import {
  FilterType,
  FilterSubType,
} from '../../datasets/interfaces/filter.interface';
import { messages } from '../../i18n';

export const aggregateFilterOperators = {
  measure: new Operator({
    key: 'measure',
    displayText: 'filters.measure',
    shortDisplayText: '',
    queryOperator: 'Measure',
    requiresOperand: true,
  }),
};

const numericOperators: { [key: string]: Operator } = {
  gt: new Operator({
    key: 'gt',
    displayText: 'filters.gt',
    shortDisplayText: '>',
    queryOperator: 'Greater Than',
    requiresOperand: true,
  }),
  gte: new Operator({
    key: 'gte',
    displayText: 'filters.gte',
    shortDisplayText: '>=',
    queryOperator: 'Greater Than or Equal To',
    requiresOperand: true,
  }),
  lt: new Operator({
    key: 'lt',
    displayText: 'filters.lt',
    shortDisplayText: '<',
    queryOperator: 'Less Than',
    requiresOperand: true,
  }),
  lte: new Operator({
    key: 'lte',
    displayText: 'filters.lte',
    shortDisplayText: '<=',
    queryOperator: 'Less Than or Equal To',
    requiresOperand: true,
  }),
  eq: new Operator({
    key: 'eq',
    displayText: 'filters.eq',
    shortDisplayText: '=',
    queryOperator: 'Equals',
    requiresOperand: true,
  }),
  neq: new Operator({
    key: 'neq',
    displayText: 'filters.neq',
    shortDisplayText: 'filters.neqShort',
    queryOperator: 'Not Equal',
    requiresOperand: true,
  }),
  null: new Operator({
    key: 'null',
    displayText: 'filters.null',
    shortDisplayText: 'filters.nullShort',
    queryOperator: 'Is NULL',
    requiresOperand: false,
  }),
  notNull: new Operator({
    key: 'notNull',
    displayText: 'filters.notNull',
    shortDisplayText: 'filters.notNullShort',
    queryOperator: 'Not NULL',
    requiresOperand: false,
  }),
};

const numericOperatorsWithOperands = _.pickBy(numericOperators, [
  'requiresOperand',
  true,
]);

const booleanOperators: { [key: string]: Operator } = _.pickBy(
  numericOperators,
  (v, k) => _.includes(['eq', 'neq'], k),
);

const stringOperators: { [key: string]: Operator } = {
  dynamicField: new Operator({
    key: 'dynamicField',
    displayText: 'filters.isCurrentUser',
    shortDisplayText: 'filters.currentUsers',
    queryOperator: 'Equals',
    requiresOperand: true,
    hideOperand: true,
  }),
  contains: new Operator({
    key: 'contains',
    displayText: 'filters.contains',
    shortDisplayText: 'filters.containsShort',
    queryOperator: 'Contains',
    requiresOperand: true,
  }),
  beginsWith: new Operator({
    key: 'beginsWith',
    displayText: 'filters.beginsWith',
    shortDisplayText: 'filters.beginsWithShort',
    queryOperator: 'Begins With',
    requiresOperand: true,
  }),
  endsWith: new Operator({
    key: 'endsWith',
    displayText: 'filters.endsWith',
    shortDisplayText: 'filters.endsWithShort',
    queryOperator: 'Ends With',
    requiresOperand: true,
  }),
  eq: new Operator({
    key: 'eq',
    displayText: 'filters.stringEq',
    shortDisplayText: 'filters.stringEqShort',
    queryOperator: 'Equals',
    requiresOperand: true,
  }),
  matchesPattern: new Operator({
    key: 'matchesPattern',
    displayText: 'filters.matchesPattern',
    shortDisplayText: 'filters.matchesPattern',
    queryOperator: 'Matches Pattern',
    requiresOperand: true,
  }),
  notMatchesPattern: new Operator({
    key: 'notMatchesPattern',
    displayText: 'filters.notMatchesPattern',
    shortDisplayText: 'filters.notMatchesPattern',
    queryOperator: 'Not Matches Pattern',
    requiresOperand: true,
  }),
  notContains: new Operator({
    key: 'notContains',
    displayText: 'filters.notContains',
    shortDisplayText: 'filters.notContainsShort',
    queryOperator: 'Does Not Contain',
    requiresOperand: true,
  }),
  notBeginsWith: new Operator({
    key: 'notBeginsWith',
    displayText: 'filters.notBeginsWith',
    shortDisplayText: 'filters.notBeginsWithShort',
    queryOperator: 'Does Not Begin With',
    requiresOperand: true,
  }),
  notEndsWith: new Operator({
    key: 'notEndsWith',
    displayText: 'filters.notEndsWith',
    shortDisplayText: 'filters.notEndsWithShort',
    queryOperator: 'Does Not End With',
    requiresOperand: true,
  }),
  neq: new Operator({
    key: 'neq',
    displayText: 'filters.stringNeq',
    shortDisplayText: 'filters.stringNeqShort',
    queryOperator: 'Not Equal',
    requiresOperand: true,
  }),
  null: new Operator({
    key: 'null',
    displayText: 'filters.stringNull',
    shortDisplayText: 'filters.stringNullShort',
    queryOperator: 'Is NULL',
    requiresOperand: false,
  }),
  notNull: new Operator({
    key: 'notNull',
    displayText: 'filters.stringNotNull',
    shortDisplayText: 'filters.stringNotNullShort',
    queryOperator: 'Not NULL',
    requiresOperand: false,
  }),
  empty: new Operator({
    key: 'empty',
    displayText: 'filters.empty',
    shortDisplayText: 'filters.emptyShort',
    queryOperator: 'Is Empty',
    requiresOperand: false,
  }),
  notEmpty: new Operator({
    key: 'notEmpty',
    displayText: 'filters.notEmpty',
    shortDisplayText: 'filters.notEmptyShort',
    queryOperator: 'Not Empty',
    requiresOperand: false,
  }),
  inTree: new Operator({
    key: 'inTree',
    displayText: 'filters.inTree',
    shortDisplayText: 'filters.inTreeShort',
    queryOperator: 'In Tree',
    requiresOperand: true,
    requiresFieldPicker: true,
  }),
};

export const timestampOperators: { [key: string]: Operator } = {
  eq: new Operator({
    key: 'eq',
    displayText: 'filters.timeEq',
    shortDisplayText: 'filters.timeEqShort',
    queryOperator: 'Equals',
    requiresOperand: true,
  }),
  on: new Operator({
    key: 'on',
    displayText: 'filters.timeOn',
    shortDisplayText: 'filters.timeOnShort',
    queryOperator: 'On',
    requiresOperand: true,
    disableTime: true,
  }),
  neq: new Operator({
    key: 'neq',
    displayText: 'filters.timeNeq',
    shortDisplayText: 'filters.timeNeqShort',
    queryOperator: 'Not Equal',
    requiresOperand: true,
    disableTime: true,
  }),
  gt: new Operator({
    key: 'gt',
    displayText: 'filters.timeGt',
    shortDisplayText: 'filters.timeGtShort',
    queryOperator: 'Greater Than',
    requiresOperand: true,
  }),
  gte: new Operator({
    key: 'gte',
    displayText: 'filters.timeGte',
    shortDisplayText: 'filters.timeGteShort',
    queryOperator: 'Greater Than or Equal To',
    requiresOperand: true,
    disableTime: true,
  }),
  lt: new Operator({
    key: 'lt',
    displayText: 'filters.timeLt',
    shortDisplayText: 'filters.timeLtShort',
    queryOperator: 'Less Than',
    requiresOperand: true,
  }),
  lte: new Operator({
    key: 'lte',
    displayText: 'filters.timeLte',
    shortDisplayText: 'filters.timeLteShort',
    queryOperator: 'Less Than or Equal To',
    requiresOperand: true,
    disableTime: true,
  }),
  null: new Operator({
    key: 'null',
    displayText: 'filters.timeNull',
    shortDisplayText: 'filters.timeNullShort',
    queryOperator: 'Is NULL',
    requiresOperand: false,
  }),
  notNull: new Operator({
    key: 'notNull',
    displayText: 'filters.timeNotNull',
    shortDisplayText: 'filters.timeNotNullShort',
    queryOperator: 'Not NULL',
    requiresOperand: false,
  }),
  empty: new Operator({
    key: 'empty',
    displayText: 'filters.timeEmpty',
    shortDisplayText: 'filters.timeEmptyShort',
    queryOperator: 'Is Empty',
    requiresOperand: false,
  }),
  notEmpty: new Operator({
    key: 'notEmpty',
    displayText: 'filters.timeNotEmpty',
    shortDisplayText: 'filters.timeNotEmptyShort',
    queryOperator: 'Not Empty',
    requiresOperand: false,
  }),
  inWeek: new Operator({
    key: 'inWeek',
    displayText: 'filters.timeInWeek',
    shortDisplayText: 'filters.timeInWeekShort',
    queryOperator: 'In Week',
    requiresOperand: true,
  }),
  inMonth: new Operator({
    key: 'inMonth',
    displayText: 'filters.timeInMonth',
    shortDisplayText: 'filters.timeInMonthShort',
    queryOperator: 'In Month',
    requiresOperand: true,
  }),
  inQuarter: new Operator({
    key: 'inQuarter',
    displayText: 'filters.timeInQuarter',
    fiscalDisplayText: 'filters.timeInQuarterFiscal',
    shortDisplayText: 'filters.timeInQuarterShort',
    shortFiscalDisplayText: 'filters.timeInQuarterFiscalShort',
    queryOperator: 'In Quarter',
    requiresOperand: true,
  }),
  weekInQuarter: new Operator({
    key: 'weekInQuarter',
    displayText: 'filters.timeWeekInQuarter',
    fiscalDisplayText: 'filters.timeWeekInQuarterFiscal',
    shortDisplayText: 'filters.timeWeekInQuarterShort',
    shortFiscalDisplayText: 'filters.timeWeekInQuarterFiscalShort',
    queryOperator: 'Week In Quarter',
    requiresOperand: true,
  }),
  inYear: new Operator({
    key: 'inYear',
    displayText: 'filters.timeInYear',
    fiscalDisplayText: 'filters.timeInYearFiscal',
    shortDisplayText: 'filters.timeInYearShort',
    shortFiscalDisplayText: 'filters.timeInYearFiscalShort',
    queryOperator: 'In Year',
    requiresOperand: true,
  }),
};

export const convertYearOperandLabel = _yearOperand => {
  const _year: any = _.toString(_yearOperand);
  if (_year === 'ALL') {
    return messages.filters.allYears;
  }
  return _.toString(_year);
};

export const relativeTimestampOperators: { [key: string]: Operator } = {
  past: new Operator({
    key: 'past',
    displayText: 'filters.past',
    shortDisplayText: 'filters.pastShort',
    queryOperator: 'In the Past',
    requiresOperand: true,
  }),
  thisAndPast: new Operator({
    key: 'thisAndPast',
    displayText: 'filters.thisAndPast',
    shortDisplayText: 'filters.thisAndPastShort',
    queryOperator: 'In This and the Past',
    requiresOperand: true,
  }),
  this: new Operator({
    key: 'this',
    displayText: 'filters.this',
    shortDisplayText: 'filters.thisShort',
    queryOperator: 'This',
    requiresOperand: true,
  }),
  thisAndNext: new Operator({
    key: 'thisAndNext',
    displayText: 'filters.thisAndNext',
    shortDisplayText: 'filters.thisAndNextShort',
    queryOperator: 'In This and the Next',
    requiresOperand: true,
  }),
  next: new Operator({
    key: 'next',
    displayText: 'filters.next',
    shortDisplayText: 'filters.nextShort',
    queryOperator: 'In the Next',
    requiresOperand: true,
  }),
};

const topBottomOperators: { [key: string]: Operator } = {
  topN: new Operator({
    key: 'topN',
    displayText: 'filters.topN',
    shortDisplayText: 'filters.topNShort',
    queryOperator: 'Top',
    requiresOperand: true,
  }),
  topNPercent: new Operator({
    key: 'topNPercent',
    displayText: 'filters.topNPercent',
    shortDisplayText: 'filters.topNPercentShort',
    queryOperator: 'Top N Percent',
    requiresOperand: true,
  }),
  bottomN: new Operator({
    key: 'bottomN',
    displayText: 'filters.bottomN',
    shortDisplayText: 'filters.bottomNShort',
    queryOperator: 'Bottom',
    requiresOperand: true,
  }),
  bottomNPercent: new Operator({
    key: 'bottomNPercent',
    displayText: 'filters.bottomNPercent',
    shortDisplayText: 'filters.bottomNPercentShort',
    queryOperator: 'Bottom N Percent',
    requiresOperand: true,
  }),
};

const legacyOperatorsMap: { [key: string]: Operator } = {
  blank: new Operator({
    key: 'null',
    displayText: 'filters.blank',
    shortDisplayText: 'filters.blankShort',
    queryOperator: 'Is NULL',
    requiresOperand: false,
  }),
  notBlank: new Operator({
    key: 'notNull',
    displayText: 'filters.notBlank',
    shortDisplayText: 'filters.notBlankShort',
    queryOperator: 'Not NULL',
    requiresOperand: false,
  }),
};

export const IN_LIST = new Operator({
  key: 'IN_LIST',
  shortDisplayText: 'filters.inList',
  displayText: 'filters.inList',
  queryOperator: 'In List',
  requiresOperand: true,
});
export const NOT_IN_LIST = new Operator({
  key: 'NOT_IN_LIST',
  shortDisplayText: 'filters.notInList',
  displayText: 'filters.notInList',
  queryOperator: 'Not In List',
  requiresOperand: true,
});
export const PERIOD = new Operator({
  key: 'PERIOD',
  shortDisplayText: 'filters.period',
  displayText: 'filters.period',
  queryOperator: 'Period',
  requiresOperand: true,
});

export const TimeAttributeToInOperator = {
  YEAR: timestampOperators.inYear.key,
  QTR: timestampOperators.inQuarter.key,
  WEEK_IN_QTR: timestampOperators.weekInQuarter.key,
  MONTH: timestampOperators.inMonth.key,
  WEEK: timestampOperators.inWeek.key,
};

export const FilterOperators = {
  forFilterType: (
    filterType: FilterType,
    subType: FilterSubType = null,
  ): { [key: string]: Operator } => {
    switch (filterType) {
      case FilterTypes.BOOLEAN:
        return booleanOperators;
      case FilterTypes.NUMERIC:
        if (
          !_.isNil(subType) &&
          _.isEqual(subType, StringFilterSubTypes.SET_CONDITION)
        ) {
          return numericOperatorsWithOperands;
        }
        return numericOperators;
      case FilterTypes.DATE:
        if (
          !_.isNil(subType) &&
          subType === TimestampFilterSubTypes.RELATIVE_DATES
        ) {
          return relativeTimestampOperators;
        } else if (
          !_.isNil(subType) &&
          subType === TimestampFilterSubTypes.PERIOD_TO_DATE
        ) {
          return { PERIOD };
        } else {
          return timestampOperators;
        }
      case FilterTypes.STRING: {
        if (
          !_.isNil(subType) &&
          subType === StringFilterSubTypes.SELECT_ITEMS
        ) {
          return { IN_LIST, NOT_IN_LIST };
        } else if (
          !_.isNil(subType) &&
          subType === StringFilterSubTypes.TOP_BOTTOM
        ) {
          return topBottomOperators;
        } else {
          return stringOperators;
        }
      }
    }
    return null;
  },
  replaceLegacyFilterOperators: operator => {
    if (!operator?.key) {
      return operator;
    }

    if (_.has(legacyOperatorsMap, operator.key)) {
      return legacyOperatorsMap[operator.key].key;
    } else {
      return operator;
    }
  },
};
