import { graphql } from '@apollo/client/react/hoc';
import AccountQueries from '../../common/graphql/Account';
import { isFunction } from 'lodash';
import { IUserPreferences } from '../interfaces';

export interface IUserPreferencesMutationInputProps {
  refreshUserPreferences?(prefs: IUserPreferences);
}

interface IUserPreferencesMutationOutputProps {
  updateUserPreferences(user: IUserPreferences): Promise<any>;
}

interface IUserPreferencesMutationData {
  updateUserPreferences: IUserPreferences;
}

export interface IUserPreferencesMutationProps {
  updateUserPreferences: any;
}

export const UserPreferencesMutation = graphql<
  IUserPreferencesMutationInputProps,
  IUserPreferencesMutationData,
  object,
  IUserPreferencesMutationOutputProps
>(AccountQueries.UpdateUserPreferences, {
  props: ({ ownProps, mutate }): IUserPreferencesMutationProps => ({
    updateUserPreferences(userPreferences) {
      return mutate({
        variables: {
          userPreferences,
        },
        refetchQueries: [
          { query: AccountQueries.AccountQuery },
          { query: AccountQueries.CurrentUserQuery },
        ],
      })
        .then(({ data }) => {
          if (isFunction(ownProps.refreshUserPreferences)) {
            ownProps.refreshUserPreferences(data.updateUserPreferences);
          }
        })
        .catch(error => {
          console.error('Error updating user preferences', error);
        });
    },
  }),
});
