import { useCallback, useMemo } from 'react';
import FilterExpression from './FilterExpression';
import { createFilterForField, StringFilterSubTypes } from './Filter';
import _ from 'lodash';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { branch, compose, renderNothing } from 'react-recompose';
import { IFilter } from '../../datasets/interfaces/filter.interface';
import { IAnyAttribute } from '../../datasets';

interface IPropTypes {
  filter: IFilter;
  changeFilter: (filter: IFilter) => void;
  changeAggregateFilter: (filter: IFilter) => void;
  field: IAnyAttribute;
  vizId: string;
}

const StringCondition = ({
  filter,
  changeFilter,
  field,
  vizId,
}: IPropTypes) => {
  const { expression } = filter;
  const onExpressionChanged = useCallback(
    expression => {
      const newFilter = { ...filter, expression: { ...expression } };
      changeFilter(newFilter);
    },
    [changeFilter, filter],
  );

  const debounced = useMemo(
    () => _.debounce(onExpressionChanged, 300),
    [onExpressionChanged],
  );

  return (
    <div className='string-condition'>
      <FilterExpression
        expression={expression}
        onChange={debounced}
        filter={filter}
        filterType={filter.type}
        filterSubType={StringFilterSubTypes.SET_CONDITION}
        field={field}
        vizId={vizId}
      />
    </div>
  );
};

const createSelectItemsFilter = field => {
  const filter = createFilterForField(field);
  filter.subType = StringFilterSubTypes.SET_CONDITION;
  return filter;
};

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  let { filter } = ownProps;
  const emptyFilter = createSelectItemsFilter(ownProps.field);
  if (_.isNil(filter)) {
    filter = emptyFilter;
  }
  const { viz } = discovery ?? {};
  return {
    datasetId: viz?.dataset?.id,
    filter: { ...filter },
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default compose<IPropTypes, any>(
  branch((props: IPropTypes) => {
    return (
      _.isNil(props.filter) ||
      props.filter.subType !== StringFilterSubTypes.SET_CONDITION
    );
  }, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
)(StringCondition);
