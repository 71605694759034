import gql from 'graphql-tag';
const Graphql = {
  GetActivityQuery: gql`
    query getActivity($activityId: String) {
      activity(activityId: $activityId) {
        id
        tenantId
        message
        date
        activityType
        referenceType
        referenceId
        monitorEvent {
          id
          startTimestamp
          endTimestamp
          eventType
          payload
          monitor {
            config
            id
            name
            dataset {
              id
              name
              attributes {
                name
                attributeType
                formatType
                annotations {
                  key
                  value
                }
              }
            }
            ... on CustomMonitor {
              monitorType
            }
          }
        }
      }
    }
  `,

  ApproveActivities: gql`
    mutation ($ids: [String!]!, $sendNotification: Boolean!) {
      approveActivity(ids: $ids, sendNotification: $sendNotification) {
        id
      }
    }
  `,

  SendNotification: gql`
    mutation ($id: String!) {
      resendNotification(id: $id)
    }
  `,
};
export default Graphql;
