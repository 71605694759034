import { useState } from 'react';
import { capitalize, get, toLower } from 'lodash';
import { useHistory } from '../../common/utilities/with-router';
import HeaderNavigation from '../../components/HeaderNavigation';
import { Segue } from './utils';
import { messages } from '../../i18n';
import { SettingsUserProfile } from '../settings-user-profile';
import MobileMenuButton from '../../components/MobileMenuButton';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import { PromptDialog } from '../../common/widgets/dialogs/prompt-dialog/';
import { useAccountQuery } from '../graphql';
import { useDispatch } from 'react-redux';
import LoginActions from '../../common/redux/actions/LoginActions';
import { ROUTER_DIRS } from '../../common';

const homePanel = 'account';

export const MobileAccountSettings = () => {
  const history = useHistory();
  const { userInfo } = useAccountQuery();
  const dispatch = useDispatch();
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);

  const onClose = () => {
    history.push(ROUTER_DIRS.ROOT);
  };

  return (
    <div className='mobile-account-settings'>
      <div className='mobile-account-settings__screen'>
        <div className='mobile-account-settings__navigation'>
          <Segue classNames='fade'>
            <HeaderNavigation
              key={homePanel}
              title={capitalize(get(messages.account, homePanel))}
              left={({ LinkButton }) => (
                <LinkButton onClick={onClose}>{messages.close}</LinkButton>
              )}
            />
          </Segue>
        </div>
        <div className='mobile-account-settings__content'>
          <div className='account-profile-wrapper'>
            <div className='mobile-account-profile-section'>
              <SettingsUserProfile userInfo={userInfo} />
              <MobileMenuButton
                icon={false}
                onClick={() => setShowLogoutPrompt(true)}
              >
                {messages.account.logout}
              </MobileMenuButton>
              <PromptDialog
                show={showLogoutPrompt}
                className='prompt-dialog--mobile'
                title={messages.account.logout}
                yesText={messages.account.logout}
                noText={messages.cancel}
                detail={
                  <HelpBlock className='promptMessage'>
                    {messages.formatString(
                      messages.account.logOutConfirmPrompt,
                      <strong>{toLower(messages.account.logout)}</strong>,
                    )}
                  </HelpBlock>
                }
                doYes={() => dispatch(LoginActions.logout)} //this.logout()}
                doNo={() => setShowLogoutPrompt(false)} //this.cancelLogout()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
