import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useVizQueryDiscoverAction } from '../../common/redux/actions/discover-actions.hook';
import {
  useHasUndoItemsSelector,
  useLiveQuerySelector,
  useMonitorEventIdSelector,
  useQueryErrorHttpCodeSelector,
  useQueryErrorSelector,
  useQueryIdSelector,
  useVizNameSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { messages } from '../../i18n';
import { IVizQueryError } from '../interfaces';
import { IErrorMap } from './query-error.interfaces';
import { useIsMobileOrDashletMode } from '../../common/redux/selectors/main-selector-hooks';

const ErrorMappings: IErrorMap[] = [
  {
    pattern: /Hit data limit of \d+ bytes/,
    friendlyErrorMessage: 'queryError.dataLimitError',
  },
  {
    pattern: /Network Error:.*/,
    friendlyErrorMessage: 'queryError.maxTimeError',
  },
  {
    pattern: /(GraphQL error:.*|.*SQLException.*)/,
    friendlyErrorMessage: 'queryError.graphqlError',
  },
  {
    pattern: /timeout_edit/,
    friendlyErrorMessage: 'queryError.timeoutEdit',
  },
  {
    pattern: /timeout_open/,
    friendlyErrorMessage: 'queryError.timeoutOpen',
  },
  {
    // default error message if we don't find any other matches
    pattern: /.*/,
    friendlyErrorMessage: 'queryError.defaultError',
  },
];

export const useRetryQuery = ({
  vizId: discoveryId,
}: {
  vizId?;
} = {}) => {
  const queryError = useQueryErrorSelector({ discoveryId });
  const vizQuery = useVizQueryDiscoverAction();
  const monitorEventId = useMonitorEventIdSelector({ discoveryId });
  const queryId = useQueryIdSelector({ discoveryId });
  const vizName = useVizNameSelector({ discoveryId });
  const { variables } = queryError?.queryOptions || {};
  const retryQuery = useCallback(() => {
    vizQuery(discoveryId, vizName, monitorEventId, variables, queryId);
  }, [monitorEventId, queryId, variables, discoveryId, vizName, vizQuery]);
  return { retryQuery, variables };
};

export interface IQueryErrorHookResult {
  queryError: IVizQueryError;
  hasUndoItems: boolean;
  isTimeout: boolean;
  errorMessage: string;
}

export const useQueryError: (opts?: {
  vizId?: string;
  errorMappings?: IErrorMap[];
}) => IQueryErrorHookResult = ({
  vizId: discoveryId,
  errorMappings = [],
} = {}) => {
  const queryError = useQueryErrorSelector({ discoveryId });
  const hasUndoItems = useHasUndoItemsSelector({ discoveryId });
  const useLiveQuery = useLiveQuerySelector({ discoveryId });

  const statusCode = useQueryErrorHttpCodeSelector({ discoveryId });
  const isTimeout = statusCode === 504;
  const errorMessage = useMemo(() => {
    const msg = isTimeout
      ? hasUndoItems
        ? 'timeout_edit'
        : 'timeout_open'
      : (queryError?.error?.message ?? '');
    const friendlyMessage =
      (
        _.find([...errorMappings, ...ErrorMappings], m => {
          return msg.match(m.pattern);
        }) as IErrorMap
      )?.friendlyErrorMessage || msg;
    if (isTimeout && !useLiveQuery) {
      return `${friendlyMessage}NoLQ`;
    }
    return friendlyMessage;
  }, [
    errorMappings,
    hasUndoItems,
    isTimeout,
    queryError?.error?.message,
    useLiveQuery,
  ]);

  const returnValue = useMemo(
    () => ({
      queryError,
      hasUndoItems,
      isTimeout,
      errorMessage: _.get(messages, errorMessage, errorMessage),
    }),
    [errorMessage, hasUndoItems, isTimeout, queryError],
  );
  return returnValue;
};

export const usePromptText = ({ hasUndoItems, useLiveQuery }) => {
  const isMobileOrDashletMode = useIsMobileOrDashletMode();

  return {
    cancelText:
      hasUndoItems &&
      (isMobileOrDashletMode
        ? messages.queryError.returnToReportMobile
        : messages.queryError.returnToReport),
    noText:
      useLiveQuery &&
      (isMobileOrDashletMode
        ? messages.queryError.disableLiveQueryMobile
        : messages.queryError.disableLiveQuery),
    yesText: isMobileOrDashletMode
      ? messages.queryError.retryQueryMobile
      : messages.queryError.retryQuery,
  };
};
