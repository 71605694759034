import {
  ApolloClient,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import { ApolloLink, from } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { Reference } from '@apollo/client/utilities';
import Main from './redux/actions/MainActions';
import {
  AuthHeadersApolloLink,
  getAuthHeaders,
  getAuthToken,
  handleNewAccessToken,
  isDashletMode,
} from '../auth';
import _, { includes, isFunction } from 'lodash';
import fragmentTypes from './fragmentTypes.json';
import { setClient, versionAndDispatchHolder } from './ApolloClient.common';
import {
  addDashletSuffix,
  getFrontendUrl,
  hasDashletSuffix,
  IDM_TOKEN_HEADER_NAME,
  stripDashletSuffix,
} from './Constants';
import URLs from './Urls';
import { FetchResult } from '@apollo/client/link/core/types';
import { getVisualizationOp } from './graphql/VizGql';
import {
  updateVisualizationOp,
  deleteVisualizationOp,
} from './graphql/DiscoverQueries';
import { hasValidAuth } from './redux/actions/common.actions';

class RemoveDashletSuffixLink extends ApolloLink {
  constructor() {
    super();
  }
  hasSuffix = new Set();
  requiresSuffixReplacement(operation: Operation) {
    return (
      includes(
        [getVisualizationOp, updateVisualizationOp, deleteVisualizationOp],
        operation?.operationName,
      ) && hasDashletSuffix(operation?.variables?.id)
    );
  }
  request(operation: Operation, forward: NextLink) {
    try {
      if (
        this.requiresSuffixReplacement(operation) &&
        hasDashletSuffix(operation?.variables?.id) &&
        !this.hasSuffix.has(operation?.variables?.id)
      ) {
        operation.variables.id = stripDashletSuffix(operation?.variables?.id);
        operation.setContext({ hasDashletSuffix: true });
        this.hasSuffix.add(operation.variables.id);
      }
    } catch (e) {
      console.log(e);
    }

    if (!this.hasSuffix.has(operation?.variables?.id)) {
      return forward(operation);
    }

    return forward(operation).map((data: FetchResult) => {
      try {
        if (
          data?.data?.visualization?.id &&
          this.hasSuffix.has(operation?.variables?.id) &&
          isFunction(operation?.getContext) &&
          !!(operation.getContext() ?? {})?.hasDashletSuffix
        ) {
          // restore client-side id with suffix
          data.data.visualization.id = addDashletSuffix(
            data?.data?.visualization?.id,
          );
        }
      } catch (e) {
        console.log(e);
      }
      return data;
    });
  }
}
export const removeSuffixLink = new RemoveDashletSuffixLink();

const httpLink = createHttpLink({
  uri: '/api/graphql',
  headers: {},
  fetch: (input, init) => {
    if (isDashletMode()) {
      input = URLs.joinUrls(getFrontendUrl(), input as string);
    }
    return fetch(input, init);
  },
});

/**
 * Subclass of the default cache which ignores subscription result. Cache reconciliation is slow and the items
 * presently place within are never used by another query.
 */
class SubscriptionIgnoringCache extends InMemoryCache {
  write(bundle): Reference {
    // We never store the results of a subscription in the cache (it's just too slow and never used)
    if (
      bundle.query.definitions.length > 0 &&
      bundle.query.definitions[0].operation === 'subscription'
    ) {
      return;
    }
    super.write(bundle);
  }
}

const retryQueue: Operation[] = [];
const refresh = async (): Promise<Response | string> => {
  if (isDashletMode()) {
    return getAuthToken({ shouldRefresh: true });
  }
  return fetch('/api/auth/refresh', {
    credentials: 'include',
    headers: await getAuthHeaders({}),
  }).then(res => {
    if (res?.status === 200) {
      return res?.headers?.get(IDM_TOKEN_HEADER_NAME);
    } else {
      console.error('Failed to refresh token', res);
      throw new Error('Failed to refresh token');
    }
  });
};

const refreshToken = (operation: Operation, forward: NextLink) => {
  retryQueue.push(operation);
  if (retryQueue.length == 1) {
    return new Observable(observer => {
      refresh()
        .then(res => {
          if (_.isString(res)) {
            return res;
          } else if (res?.status === 200) {
            return res?.headers?.get(IDM_TOKEN_HEADER_NAME);
          }
        })
        .then(newToken => {
          if (newToken) {
            handleNewAccessToken(newToken);
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            while (retryQueue.length !== 0) {
              const op = retryQueue.pop();
              op.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  [IDM_TOKEN_HEADER_NAME]: newToken,
                },
              }));
              try {
                forward(op).subscribe(subscriber);
              } catch (e) {
                console.error('error retrying operation', e);
              }
            }
          } else {
            versionAndDispatchHolder.dispatch(Main.unAuthedLogout());
          }
        })
        .catch(error => {
          console.error(error);
          versionAndDispatchHolder.dispatch(Main.unAuthedLogout());
        });
    });
  }
};

export const errorLink = onError(({ networkError, operation, forward }) => {
  const status = (networkError as any)?.statusCode;
  if (status === 401) {
    return new Observable(observer => {
      hasValidAuth({
        success: () => {
          console.error('Apollo Network Error: 401. Refreshing token');
          const refreshObservable = refreshToken(operation, forward);
          if (refreshObservable) {
            refreshObservable.subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: () => observer.complete(),
            });
          } else {
            observer.complete();
          }
        },
        error: () => {
          console.error('No auth token. Waiting for new token...');
          observer.complete();
        },
      });
    });
  } else if (status === 500) {
    versionAndDispatchHolder.dispatch(Main.internalServerError());
  } else if (networkError) {
    console.error('Network Error:', networkError);
  }
  if (operation?.query) {
    console.debug('Failed GraphQL query:', operation.query.loc.source.body);
    console.debug(
      'Failed GraphQL query variables:',
      JSON.stringify(operation.variables, null, 2),
    );
  }
});

// use with apollo-client
const versionMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const version = context.response.headers.get('corvana-version');
    const existingVersion = versionAndDispatchHolder.version;
    if (!existingVersion) {
      versionAndDispatchHolder.version = version;
    } else if (existingVersion !== version) {
      versionAndDispatchHolder.dispatch(Main.showAppOutOfDate());
    }
    const {
      response: { headers },
    } = context;

    if (headers) {
      // handles refreshed access tokens
      const oauthToken = headers.get(IDM_TOKEN_HEADER_NAME);

      if (!_.isEmpty(oauthToken)) {
        handleNewAccessToken(oauthToken);
      }
    }

    return response;
  });
});

const possibleTypes = {};
fragmentTypes.data.__schema.types.forEach(supertype => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      subtype => subtype.name,
    );
  }
});

class VersionAndDispatchHolderLink extends ApolloClient<any> {
  setDispatch(dispatch) {
    versionAndDispatchHolder.dispatch = dispatch;
  }
}

const client = new VersionAndDispatchHolderLink({
  link: from(
    [
      AuthHeadersApolloLink,
      errorLink,
      versionMiddleware,
      removeSuffixLink,
      httpLink,
    ].filter(Boolean),
  ),
  cache: new SubscriptionIgnoringCache({
    typePolicies: {
      Shelf: {
        keyFields: ['id', 'fields'],
      },
    },
    possibleTypes,
  }).restore((window as any).__APOLLO_STATE__),
} as any);
setClient(client);
export { client, versionAndDispatchHolder };
