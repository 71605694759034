import React from 'react';
import { useFeatureFlagProvider } from './feature-flag.provider';
import { StyledToggle } from './feature-toggle.styles';
import { Switch } from '@mui/material';
import { isEmpty } from 'lodash';

export const FeatureToggle = React.memo(() => {
  const { featureFlags, updateFlag } = useFeatureFlagProvider();

  return (
    !isEmpty(featureFlags) && (
      <StyledToggle>
        {Object.keys(featureFlags).map(flag => (
          <div key={flag}>
            <label>
              <Switch
                id={`switch-${flag}`}
                checked={!!featureFlags[flag]}
                color={'primary'}
                onClick={() => updateFlag(flag, !featureFlags[flag])}
                name={`label-${flag}`}
              />
              {flag}
            </label>
          </div>
        ))}
      </StyledToggle>
    )
  );
});
