import { useState } from 'react';
import { TimestampFilterSubTypes } from '../Filter';
import { get, head, isNil, map, toNumber } from 'lodash';
import { messages } from '../../../i18n';
import {
  IRelativeDateCondition,
  ITimePeriod,
} from './relative-date-condition.interface';
import {
  LabelWrapper,
  StyledFormGroup,
  StyledSearchableDropdown,
  StyledSelectDropdown,
} from './relative-date-condition.styles';
import { ISearchableDropdownOption } from '../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.interfaces';
import {
  DaysOfWeek,
  getDisplayText,
  RelativeDateAnchorModes,
  RelativeOperators,
  TimePeriods,
} from './relative-date-condition.utils';
import { FormInput } from '../../../components/ui/form-input';
import { useRelativeDateCondition } from './relative-date-condition.hook';
import { FormatDateRange } from './format-date-range';
import { SelectItem } from '../../../ui/dropdowns/select-dropdown';
import { DatePicker } from '../../../components/calendar/date-picker';
import { ZonedDateTime } from '@internationalized/date';
import {
  nowOrSpecifiedDate,
  toIso8601,
} from '../../../components/calendar/date-picker/date-picker.hook';

export const RelativeDateCondition = ({
  vizId,
  filter,
  field,
}: IRelativeDateCondition) => {
  const {
    dateTimeFormat,
    datasetId,
    useFiscalCalendar,
    relativeDatesState,
    onOperatorChange,
    onPeriodOperatorChange,
    onIncludeFractionalPeriod,
    onOffsetChange,
    onEnableAnchor,
    onAnchorChanged,
    onAnchorModeChange,
    onAnchorOffsetChange,
    onAnchorDayChange,
  } = useRelativeDateCondition({ vizId, filter, field });

  // Note: This is never set, but is used currently by the validation check.
  const [operand] = useState<string>();

  if (
    isNil(filter) ||
    filter.subType !== TimestampFilterSubTypes.RELATIVE_DATES
  ) {
    return null;
  }

  const periodOperatorMenuitems = () => {
    return Object.values(TimePeriods).map((timePeriod: ITimePeriod) => {
      const displayMessage = getDisplayText(timePeriod, useFiscalCalendar);
      return {
        value: timePeriod.key,
        label: get(messages, displayMessage, displayMessage),
      };
    });
  };

  const operatorOptions = () => {
    return Object.values(RelativeOperators).map(op => ({
      value: op.key,
      label: get(messages, op.displayText, op.displayText),
    }));
  };

  const getValidationState = (): 'success' | 'warning' | 'error' | null => {
    // NOTE: because operand is never set, validation state is always null.
    // We should determine if we want user feedback on this component and if so
    // fix this
    if (!isNil(operand) && operand.length > 0) {
      if (isNaN(toNumber(operand))) {
        return 'error';
      }
    }
  };

  const anchorModeOptions = map(RelativeDateAnchorModes, value => ({
    value,
    label: get(messages.filters, value.toLowerCase()),
  }));

  const anchorDayOptions = DaysOfWeek.map(value => ({
    value,
    label: get(messages.daysOfWeek, value.toLowerCase()),
  }));

  const selectedOperator = RelativeOperators[relativeDatesState.operator];
  const timePeriod = TimePeriods[relativeDatesState.periodOperand || 'DAYS'];
  const periodDisplay = getDisplayText(timePeriod, useFiscalCalendar);

  return (
    <div className='relative-date-condition'>
      <div className='condition'>
        <div>
          <StyledFormGroup className={getValidationState() ?? ''}>
            <LabelWrapper>
              <label
                style={{ whiteSpace: 'nowrap' }}
                className={'control-label'}
              >
                {messages.filters.showItemsWhenValueIs}
              </label>
            </LabelWrapper>
            <StyledSearchableDropdown
              id='relative-date-operator-dropdown'
              onSelect={(options: ISearchableDropdownOption[]) => {
                const option = head(options);
                onOperatorChange(option?.value);
              }}
              title={
                <span className={'dropdown-title'}>
                  {get(
                    messages,
                    selectedOperator.displayText,
                    selectedOperator.displayText,
                  )}
                </span>
              }
              options={operatorOptions()}
              popperSx={{ zIndex: 1050 }}
            />
            <FormInput
              className='relative-date-value'
              type={'text'}
              onChange={event => {
                onOffsetChange(event?.target?.value);
              }}
              value={
                isNil(relativeDatesState.offset) ||
                relativeDatesState.operator === 'this'
                  ? ''
                  : relativeDatesState.offset
              }
              disabled={relativeDatesState.operator === 'this'}
            />
            <StyledSearchableDropdown
              id='relative-date-period-dropdown'
              onSelect={(options: ISearchableDropdownOption[]) => {
                const option = head(options);
                onPeriodOperatorChange(option?.value);
              }}
              title={
                <span className={'dropdown-title'}>
                  {get(messages, periodDisplay, periodDisplay)}
                </span>
              }
              options={periodOperatorMenuitems()}
              popperSx={{ zIndex: 1050 }}
            />
            {relativeDatesState.displayFractionalPeriod &&
              (relativeDatesState.operator === 'past' ||
                relativeDatesState.operator === 'next') && (
                <input
                  type='checkbox'
                  id='include-fractional-period'
                  checked={relativeDatesState.includeFractionalPeriod}
                  onChange={event =>
                    onIncludeFractionalPeriod(event?.target?.checked)
                  }
                />
              )}
            {relativeDatesState.displayFractionalPeriod &&
              (relativeDatesState.operator === 'past' ||
                relativeDatesState.operator === 'next') && (
                <label
                  className={'control-label'}
                  htmlFor='include-fractional-period'
                >
                  {messages.filters.includeCurrentPartialPeriod}
                </label>
              )}
          </StyledFormGroup>
        </div>
        <div>
          <StyledFormGroup className={`${getValidationState()}`}>
            <LabelWrapper className='relative-date-row-2'>
              <input
                type='checkbox'
                id='relative-date-anchor-chk'
                checked={relativeDatesState.anchorEnabled}
                onChange={event => onEnableAnchor(event)}
              />
              <label
                className={'control-label'}
                htmlFor='relative-date-anchor-chk'
              >
                {messages.filters.anchorRelativeTo}
              </label>
            </LabelWrapper>
            <StyledSelectDropdown
              headerSx={{ width: '160px' }}
              id='relative-date-anchor-mode-dropdown'
              className={!relativeDatesState.anchorEnabled && 'disabled'}
              title={get(
                messages.filters,
                relativeDatesState.anchorMode.toLowerCase(),
              )}
            >
              {anchorModeOptions.map(({ value, label }) => (
                <SelectItem
                  key={label}
                  onClick={() => onAnchorModeChange(value)}
                >
                  {label}
                </SelectItem>
              ))}
            </StyledSelectDropdown>
            {relativeDatesState.anchorMode ===
              RelativeDateAnchorModes.EXACT && (
              <DatePicker
                isDisabled={!relativeDatesState?.anchorEnabled}
                onChange={(selectedDate: ZonedDateTime) => {
                  onAnchorChanged(toIso8601(selectedDate));
                }}
                defaultValue={nowOrSpecifiedDate(relativeDatesState.anchorDate)}
                showTime
              />
            )}
            {relativeDatesState.anchorMode ===
              RelativeDateAnchorModes.PREVIOUS && (
              <>
                <FormInput
                  className='relative-date-value days-value'
                  type='number'
                  disabled={!relativeDatesState.anchorEnabled}
                  onChange={event => {
                    onAnchorOffsetChange(event.target.value);
                  }}
                  value={relativeDatesState.anchorOffset}
                />
                <StyledSelectDropdown
                  id='relative-date-anchor-days-dropdown'
                  className={!relativeDatesState.anchorEnabled && 'disabled'}
                  placeholder={messages.filters.dayOfWeek}
                  title={get(
                    messages.daysOfWeek,
                    relativeDatesState.anchorDay.toLowerCase(),
                  )}
                  headerSx={{
                    width: '100px',
                  }}
                >
                  {anchorDayOptions.map(({ value, label }) => (
                    <SelectItem
                      key={label}
                      onClick={() => onAnchorDayChange(value)}
                    >
                      {label}
                    </SelectItem>
                  ))}
                </StyledSelectDropdown>
              </>
            )}
          </StyledFormGroup>
        </div>
        <div>
          <FormatDateRange
            useFiscalCalendar={useFiscalCalendar}
            datasetId={datasetId}
            relativeDatesState={relativeDatesState}
            dateTimeFormat={dateTimeFormat}
          />
        </div>
      </div>
    </div>
  );
};
