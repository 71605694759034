import palette from './ColorPalette';
import { isNil, get } from 'lodash';
import { EMPTY_STRING_TOKEN } from '../Constants';
import { scaleOrdinal } from 'd3';

class ColorManager {
  constructor() {
    this.db = {};
    this.custom = {};
  }

  addGroup(name, force = false) {
    if (!this.db[name] || force) {
      this.db[name] = scaleOrdinal().range(palette);
    }
    return this.db[name];
  }

  getGroup(name) {
    return this.db[name] ? this.db[name] : this.addGroup(name);
  }

  getCustomGroup(name) {
    return this.custom[name];
  }

  getColor(groupName, colorName, theme) {
    if (isNil(colorName)) {
      return theme?.colors?.ContentBackground;
    }
    if (colorName === '') {
      colorName = EMPTY_STRING_TOKEN;
    }
    const group = this.getGroup(groupName);
    const defaultColor = group(colorName);
    const custom = get(this.custom, groupName);

    return get(custom, colorName, defaultColor);
  }

  setCustomColors(groupName, colors) {
    this.custom[groupName] = colors;
  }
  clearCustomColors(groupName) {
    this.custom[groupName] = {};
  }

  reset(groupName) {
    return this.addGroup(groupName, true);
  }

  updateGroupKey(groupName, newKey) {
    if (this.db[groupName]) {
      this.db[newKey] = this.db[groupName];
      delete this.db[groupName];
    }
  }
}

const colorManager = new ColorManager();

export default colorManager;
