import { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import * as Fonts from '../../components/ui/fonts';
import { EditIcon } from '../../components/ui/icons';
import { isEmpty, isNil } from 'lodash';
import { EditDiscoveryDialog } from '../../views/edit-discovery-dialog';
import CopyQuerySqlButton from './CopyQuerySqlButton';
import { messages } from '../../i18n';
import { css } from '@emotion/react';
import { FilterWidgetContextProvider } from '../../discovery/filter-widget/filter-widget.context';
import { FilterWidget } from '../../discovery/filter-widget/filter-widget.component';
import { ReportHistory } from '../../components/report-history';
import { ModernBorderRadius, useDiscoverTheme } from '../../common/emotion';
import { Box } from '@mui/system';
import InputBase from '@mui/material/InputBase';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;
const FormGroupHeader = styled.label`
  font-size: 14px;
  font-weight: ${Fonts.RegularFontWeight};
`;
const PanelInputGroup = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  border-radius: ${ModernBorderRadius};
  &:not(:hover) {
    .hover-blur,
    svg {
      display: none;
    }
  }
`;
const PanelFormGroup = styled(Box)(
  ({ theme: { colors: { Strokes, ContentBackground } = {} } = {} }) => css`
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: ${ModernBorderRadius};
    padding: 8px;
    margin-bottom: 16px;
  `,
);

const VersionFormGroup = styled(PanelFormGroup)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
  min-height: 126px;
`;

const TagWidgetGroup = styled.div`
  margin-bottom: 16px;
`;

const AddOnButton = styled(EditIcon)`
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 5;
  cursor: pointer;
`;

const CustomTextArea = styled.div(
  ({
    theme: {
      colors: { Strokes, ContentBackground, LightFontWeight } = {},
    } = {},
  }) => css`
    font-size: 12px;
    font-weight: ${LightFontWeight};
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: ${ModernBorderRadius};
    padding: 8px;
    width: 100%;
    height: 126px;
    cursor: text;
    overflow: auto;
  `,
);

const CustomInputArea = styled(InputBase)(
  ({
    theme: {
      colors: { Strokes, ContentBackground, LightFontWeight } = {},
    } = {},
  }) => css`
    font-size: 12px;
    font-weight: ${LightFontWeight};
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: ${ModernBorderRadius};
    padding: 8px;
    width: 100%;
    height: 40px;
    cursor: text;
    overflow-x: auto;
    overflow-y: hidden;
  `,
);

const VersionOptionGroup = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

class component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      editKey: 'name',
      scrolledEnabled: true,
      descHeight: 0,
      maxDescHeight: 112,
    };
  }

  componentDidMount() {
    this.setTextAreaProps();
  }

  componentDidUpdate(prevProps) {
    this.setTextAreaProps();

    const updatedDescription = prevProps.description;
    if (updatedDescription !== this.state.description) {
      this.setState({ description: updatedDescription });
    }
  }

  setTextAreaProps() {
    if (!isNil(this.rootRef)) {
      const rootEl = ReactDOM.findDOMNode(this.rootRef);
      const maxDescHeight =
        rootEl.offsetHeight -
        274; /* current offset from other element heights */
      if (maxDescHeight !== this.state.maxDescHeight) {
        this.setState({ maxDescHeight });
      }
    }
    if (!isNil(this.textAreaRef)) {
      const textAreaEl = ReactDOM.findDOMNode(this.textAreaRef);
      const descHeight = textAreaEl.offsetHeight;
      if (descHeight !== this.state.descHeight) {
        this.setState({ descHeight });
      }
      const scrolledEnabled = textAreaEl.scrollHeight > textAreaEl.clientHeight;
      if (scrolledEnabled !== this.state.scrolledEnabled) {
        this.setState({ scrolledEnabled });
      }
    }
  }

  showEdit(editKey) {
    if (!this.props.canUpdate) {
      return;
    }
    this.setState({ editKey, showEdit: true });
  }

  doEditNo() {
    const { editKey } = this.state;
    switch (editKey) {
      case 'name':
        break;
      case 'description':
        break;
    }
    this.setState({ showEdit: false });
  }

  doEditYes(value) {
    const { editKey } = this.state;
    switch (editKey) {
      case 'name':
        this.props.changeDiscoveryName(this.props.discoveryId, value);
        this.setState({ name: value, showEdit: false });
        break;
      case 'description':
        this.props.setSettingForViz(
          this.props.discoveryId,
          'description',
          value,
        );
        this.setState({ description: value, showEdit: false });
        break;
    }
  }

  render() {
    return (
      <Root
        ref={ref => {
          this.rootRef = ref;
        }}
        className='report-detail'
      >
        <div>
          <FormGroupHeader>{messages.reportDetail.name}</FormGroupHeader>
          <PanelInputGroup>
            <CustomInputArea
              type='text'
              readOnly
              title={this.props.name}
              value={this.props.name}
              onClick={() => this.showEdit('name')}
            />
            {this.props.canUpdate && [
              <div
                key='hover-blur'
                className='hover-blur'
                style={{ height: '26px' }}
              />,
              <span
                key='rename-report-button'
                title={messages.layoutPanel.rename}
                onClick={() => this.showEdit('name')}
              >
                <AddOnButton />
              </span>,
            ]}
          </PanelInputGroup>
        </div>
        {(this.props.canUpdate || !isEmpty(this.props.description)) && (
          <div>
            <FormGroupHeader key='report-detail-header'>
              {messages.reportDetail.description}
            </FormGroupHeader>
            <PanelInputGroup key='report-input-group'>
              <CustomTextArea
                disabled={!this.props.canUpdate}
                onClick={() => this.showEdit('description')}
                ref={ref => {
                  this.textAreaRef = ref;
                }}
              >
                {this.props.description}
              </CustomTextArea>
              {this.props.canUpdate && [
                <div
                  key='report-detail-form-group-hover-blur'
                  className='hover-blur'
                  style={{
                    height: `${this.state.descHeight - 6}px`,
                    right: this.state.scrolledEnabled ? '16px' : '',
                  }}
                />,
                <span
                  title={messages.layoutPanel.editDescription}
                  key='report-description-button'
                  onClick={() => this.showEdit('description')}
                >
                  <AddOnButton
                    style={this.state.scrolledEnabled ? { right: '16px' } : {}}
                  />
                </span>,
              ]}
            </PanelInputGroup>
          </div>
        )}
        <DatsetDetails datasetName={this.props.datasetName} />
        {this.props.canUpdate && (
          <div>
            <FormGroupHeader key='report-detail-header'>
              {messages.tags.tags}
            </FormGroupHeader>
            <TagWidgetGroup key='report-detail-tags-body'>
              <FilterWidgetContextProvider>
                <FilterWidget
                  onSave={tags => {
                    this.props.changeDiscoveryTags(
                      this.props.discoveryId,
                      tags,
                    );
                  }}
                  headerSx={{ width: '100%' }}
                />
              </FilterWidgetContextProvider>
            </TagWidgetGroup>
          </div>
        )}
        {!this.props.newDiscovery && (
          <VersionOptionGroup key='report-detail-version-group'>
            <FormGroupHeader key='report-detail-version-header'>
              {messages.reportDetail.versionHistory}
            </FormGroupHeader>
            <VersionFormGroup key='report-detail-version-body'>
              <ReportHistory
                vizId={this.props.discoveryId}
                revisionSelected={this.props.revisionSelected}
              />
            </VersionFormGroup>
          </VersionOptionGroup>
        )}
        {this.state.showEdit && (
          <EditDiscoveryDialog
            show={this.state.showEdit}
            detail={this.state.editKey}
            value={
              this.state.editKey === 'name'
                ? this.props.name
                : this.props.description
            }
            onOk={this.doEditYes.bind(this)}
            onCancel={this.doEditNo.bind(this)}
          />
        )}
        <CopyQuerySqlButton />
      </Root>
    );
  }
}

const DatsetDetails = ({ datasetName }) => {
  const {
    colors: { LightFontWeight },
  } = useDiscoverTheme();

  if (isEmpty(datasetName)) {
    return null;
  }

  return (
    <div>
      <FormGroupHeader>{messages.dataset}</FormGroupHeader>
      <PanelInputGroup
        style={{
          fontWeight: LightFontWeight,
          fontSize: '12px',
        }}
      >
        {datasetName}
      </PanelInputGroup>
    </div>
  );
};

export default component;
