import { css, CSSObject } from '@emotion/react';
import { getLuminance, math, setLightness } from 'polished';
import _ from 'lodash';
import Util from '../../common/Util';

export const DesktopMainHeaderHeight = '56px';
export const DiscoveryToolbarNavbarHeight = '50px';
export const VizTabNavHeight = 40;
export const VizTabNavMarginBottom = '12px';
export const VizTabNavOuterHeight = '115px';
export const ModernBorderRadius = '8px';
export const ModernLgSearchRadius = '20px';
export const DetailTopRightPanelRadius = '24px';

export const invertLuminance = color => {
  try {
    const luminance = getLuminance(color);
    return setLightness(1 - luminance, color);
  } catch {
    return color;
  }
};

export const mainHeaderHeight = ({ isMobile, isDashletMode }) =>
  isMobile || isDashletMode ? '7vh !important' : DesktopMainHeaderHeight;

export const mainHeaderHeightProperty = ({ isMobile, isDashletMode }) => css`
  height: ${mainHeaderHeight({ isMobile, isDashletMode })};
`;

export const mainContentHeightProperty = ({ isMobile, isDashletMode }) => css`
  height: calc(100vh - ${mainHeaderHeight({ isMobile, isDashletMode })});
`;

export const multiply = (...args) => {
  return math(args.join('*'));
};

export const add = (...args) => {
  return math(args.join('+'));
};

export const truncateLines = ({ lines } = {} as any) => css`
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`;

interface IFontOpts {
  size?: string;
  weight?: any;
  style?: string;
  color?: string;
}

const makeImportant = opts => _.mapValues(opts, opt => `${opt} !important`);

const getFontOpts = ({
  size: fontSize = 'inherit',
  weight: fontWeight = 'inherit',
  style: fontStyle = 'inherit',
  color = 'inherit',
}: IFontOpts = {}): CSSObject => ({
  fontWeight,
  fontStyle,
  fontSize,
  color,
});

export const backgroundImage = ({ appUrl, path }) => css`
  background-image: url(${Util.assetUrl({ appUrl, path })});
`;

export const font = (opts?: IFontOpts) => css(getFontOpts(opts));
export const fontImportant = (opts: IFontOpts) =>
  css(makeImportant(getFontOpts(opts)));

export const createSelectors =
  selectorFn =>
  (...selectors) => css`
    ${_(selectors)
      .flatMap(s => _.split(s, ','))
      .map(_.trim)
      .map(selectorFn)
      .reduce(
        (acc, next) => css`
          ${acc}${next}
        `,
        css``,
      )}
  `;

export const svgColorize = ({ color = 'inherit' }) =>
  createSelectors(
    selector => css`
      ${selector} g[fill],
      ${selector} line[fill],
      ${selector} polyline[fill],
      ${selector} path[fill],
      ${selector} circle[fill],
      ${selector} use[fill],
      ${selector} rect[fill] {
        fill: ${color};
      }
      ${selector} g[stroke],
      ${selector} line[stroke],
      ${selector} polyline[stroke],
      ${selector} path[stroke],
      ${selector} rect[stroke],
      ${selector} use[stroke],
      ${selector} circle[stroke] {
        stroke: ${color};
      }
    `,
  );

export const svgColorizePartial = ({
  fromColor = 'inherit',
  toColor = 'inherit',
}) =>
  createSelectors(
    selector => css`
      ${selector} g[fill="${fromColor}"],
      ${selector} line[fill="${fromColor}"],
      ${selector} polyline[fill="${fromColor}"],
      ${selector} path[fill="${fromColor}"],
      ${selector} circle[fill="${fromColor}"],
      ${selector} use[fill="${fromColor}"],
      ${selector} rect[fill="${fromColor}"] {
        fill: ${toColor};
      }

      ${selector} g[stroke="${fromColor}"],
      ${selector} line[stroke="${fromColor}"],
      ${selector} polyline[stroke="${fromColor}"],
      ${selector} path[stroke="${fromColor}"],
      ${selector} rect[stroke="${fromColor}"],
      ${selector} use[stroke="${fromColor}"],
      ${selector} circle[stroke="${fromColor}"] {
        stroke: ${toColor};
      }
    `,
  );

export const multiLineEllipsis = ({
  lineHeight = '1.2em',
  lineCount = 3,
  backgroundColor = 'white',
}) => css`
  overflow: hidden;
  position: relative;
  /* use this value to count block height */
  line-height: ${lineHeight};
  max-height: ${multiply(lineHeight, lineCount)};
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;

  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: ${backgroundColor};
  }
`;

export const flexBox = () => css`
  display: flex;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch;
`;

export const hbox = () => css`
  ${flexBox()};
  flex-direction: row;
`;

export const vbox = () => css`
  ${flexBox()};
  flex-direction: column;
`;

export const centeredPanel = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const flexFill = () => css`
  flex: 1;
`;

export const ellipsis = () => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const positionAbsoluteContainer = () => css`
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 0;
  right: 0;
`;

export const noSelect = () => css`
  user-select: none;
`;

export const hex2rgba = (hex, alpha = 1) => {
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = hex.slice(1).match(new RegExp(`.{${chunkSize}}`, 'g'));
  const [r, g, b] = hexArr.map(hexStr =>
    parseInt(hexStr.repeat(2 / hexStr.length), 16),
  );
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
