import BarLineChartUtils from '../BarLineChartUtils';
import Lines from '../../../common/d3/Lines';
import Util from '../../../common/Util';
import { LineChartUtils } from '../LineChartUtils';
import _ from 'lodash';
import palette from '../../../common/d3/ColorPalette';
import { ChartTooltipData, ChartTooltipReportLinkNote } from '../chart-tooltip';
import { Viz } from '../../VizUtil';
import { join as joinChartData } from '../../charts/ChartUtils';
import { METRIC_ORIENTATION } from '../../../common/d3/Axis';
import ColorManager from '../../../common/d3/ColorManager';
import { messages, i18nUtils } from '../../../i18n';
import ReactResizeDetector from 'react-resize-detector';
import {
  bisector,
  max as d3Max,
  min as d3Min,
  scaleLinear,
  scalePoint,
} from 'd3';
import { shortid } from '../../../common/utilities/shortid-adapter';

class LinePlot {
  constructor(parameters) {
    const {
      vizId,
      valuesName,
      xAxisName,
      primaryPlot,
      data,
      layout,
      width,
      height,
      showDataLabels,
      paletteOffset,
      chartPadding,
      presetPadding,
      xScale,
      defaultXAxisHeight,
      hoverFunction,
      labelRotation,
      isMobile,
      hidePoints,
      showLastPoint,
      colorPalette,
      disableLineSmoothing,
      xAxisPadding,
      yAxisPadding,
      hideAxis,
      defaultYAxisWidth,
      customFormatToggles,
      disableTooltips,
      enableReportLink,
      linkToReport,
      nullHandling,
      focusedData,
      focusedDataPoints,
      useDataPointSelectionMode,
      dataPointSelectionMode,
      onFocusedDataPointUpdate = _.noop,
      querySort,
      i18nPrefs = {},
      customFormatProps,
      customAggregations,
      isSecondaryPlot = false,
    } = parameters;
    this.vizId = vizId;
    this.valuesName = valuesName;
    this.xAxisName = xAxisName;
    this.primaryPlot = primaryPlot;
    this.data = data;
    this.layout = layout;
    this.width = width;
    this.height = height;
    this.showDataLabels = showDataLabels;
    this.paletteOffset = paletteOffset;
    this.chartPadding = chartPadding;
    this.presetPadding = presetPadding;
    this.xScale = xScale;
    this.originalXScale = xScale;
    this.defaultXAxisHeight = defaultXAxisHeight;
    this.defaultYAxisWidth = defaultYAxisWidth;
    this.hoverFunction = hoverFunction;
    this.labelRotation = labelRotation || 'vertical';
    this.isMobile = isMobile;
    this.xAxisPadding = xAxisPadding;
    this.yAxisPadding = yAxisPadding;
    this.scales = this.createScales(width);
    this.hidePoints = hidePoints;
    this.disableLineSmoothing = disableLineSmoothing;
    this.hideAxis = hideAxis;
    this.disableTooltips = disableTooltips;
    this.shouldRotateAxisLabels = this.shouldRotateAxisLabels();
    this.customFormatToggles = customFormatToggles;
    this.showLastPoint = _.isNil(showLastPoint) ? false : showLastPoint;
    this.colorPalette = _.isNil(colorPalette) ? palette : colorPalette;
    this.enableReportLink = enableReportLink && focusedData?.length <= 1;
    this.linkToReport = linkToReport;
    this.nullHandling = nullHandling;
    this.focusedData = focusedData;
    this.focusedDataPoints = focusedDataPoints;
    this.useDataPointSelectionMode = useDataPointSelectionMode;
    this.dataPointSelectionMode = dataPointSelectionMode;
    this.onFocusedDataPointUpdate = onFocusedDataPointUpdate;
    this.querySorts = querySort;
    this.i18nPrefs = i18nPrefs;
    this.customFormatProps = customFormatProps;
    this.customAggregations = customAggregations;
    this.isSecondaryPlot = isSecondaryPlot;

    // componentDidMount alternative
    this.updateComponent(parameters);
  }

  // we should not be using vanilla javascript classes in react
  component = null;

  createScales() {
    if (!this.originalXScale) {
      const xField = this.layout.XAXIS[0];
      // only expand the numeric x-axis domain if the source field is NOT a time-related field.
      if (
        _.isNumber(this.data.AXIS[0][0].value) &&
        this.data.AXIS.length === 1 &&
        _.isNil(xField.timeAttribute)
      ) {
        // special case for single x-axis that's a number
        this.xScale = scaleLinear()
          .domain(
            Util.expandRange(
              _.flatMap(this.data.AXIS, '0.value'),
              _.isNumber(this.xAxisPadding) ? this.xAxisPadding : 0.05,
            ),
          )
          .range([0, this.width])
          .nice();
      } else if (
        _.isNumber(this.data.AXIS[0][0].value) &&
        this.data.AXIS[0].length === 1 &&
        _.isNil(xField.timeAttribute)
      ) {
        // Single value numeric
        this.xScale = scaleLinear()
          .domain(
            Util.expandRange(
              _.flatMap(this.data.AXIS, '0.value'),
              _.isNumber(this.xAxisPadding) ? this.xAxisPadding : 0.05,
            ),
          )
          .range([0, this.width])
          .nice();
      } else {
        // Series Domain is padded with values on either side which should never present in the dataset, .nice() does not work on this type of scale
        this.xScale = scalePoint()
          .domain(
            [' ']
              .concat(this.data.AXIS.map(ax => joinChartData(ax)))
              .concat(['  ']),
          )
          .range([0, this.width]);
      }
    }

    const domainMin = LineChartUtils.reduceValuesByFunc(this.data, d3Min, 'y');
    const domainMax = LineChartUtils.reduceValuesByFunc(this.data, d3Max, 'y');

    this.yScale = scaleLinear()
      .range([this.height, 0])
      .domain(
        Util.expandRange(
          [domainMin, domainMax],
          _.isNumber(this.yAxisPadding) ? this.yAxisPadding : 0.05,
        ),
      )
      .nice();

    const labelInfo = Util.calcLabelInfo(this.xScale.domain().map(d => d));
    this.maxChars = this.shouldRotateAxisLabels ? labelInfo.maxChars : 10;
  }

  setHeight(height) {
    this.height = height;
    this.createScales();
  }

  getYAxisLabel() {
    return Viz.getAxisLabel(
      this.layout[this.valuesName],
      this.customAggregations,
    );
  }
  getXAxisLabel() {
    if (!_.isEmpty(this.layout.XAXIS)) {
      return Viz.getAxisLabel(this.layout.XAXIS, this.customAggregations);
    }
  }
  getTicks(numTicks) {
    const hasBars = this.primaryPlot.isActive();
    const lineAxisTicks = hasBars
      ? BarLineChartUtils.generateLineTicks(
          this.primaryPlot.getYScale(),
          this.yScale,
          numTicks,
        )
      : null;
    return lineAxisTicks;
  }

  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'lines';
  }

  isActive() {
    return this.layout.LINES.length > 0;
  }

  getXScale() {
    return this.xScale;
  }

  getYScale() {
    return this.yScale;
  }

  getXAccessor() {
    return LineChartUtils.getX;
  }

  getYAccessor() {
    return LineChartUtils.getY;
  }

  getPosYFromData(pointData) {
    let posY = 0;
    try {
      posY = this.getYScale()(this.getYAccessor()(pointData));
    } catch {
      // some y values in data can be null
    }
    return posY;
  }

  getYDomain() {
    return [
      LineChartUtils.reduceValuesByFunc(this.data, d3Min, 'y'),
      LineChartUtils.reduceValuesByFunc(this.data, d3Max, 'y'),
    ];
  }

  setYScale(scale) {
    this.yScale = scale;
  }

  getPreferredWidth() {
    return this.width;
  }

  getPreferredHeight() {
    if (!this.shouldRotateAxisLabels || this.hideAxis) {
      return this.height;
    }
    return this.height - (this.getXAxisHeight() - this.defaultXAxisHeight);
  }

  getXAxisHeight() {
    return this.defaultXAxisHeight;
  }

  getYAxisWidth() {
    return this.defaultYAxisWidth;
  }

  getDistinctGroups() {
    return this.data.AXIS.length;
  }

  shouldRotateAxisLabels() {
    const stepSize = this.getStepSize();
    if (stepSize < 64) {
      return true;
    }
    return false;
  }

  getStepSize() {
    const xaxisDataLength = this.data.AXIS.length;

    // calculate the distance between two steps. Point Scale has step(), Linear does not so we calculate it
    // Guarded against the case where there is only one value
    return this.xScale.step
      ? this.xScale.step()
      : xaxisDataLength > 1
        ? this.xScale(1) - this.xScale(0)
        : 100;
  }

  getChartClickPointData(tooltipData) {
    const xAxisPointData = tooltipData?.hoverData?.point ?? [];

    const getPointDataInLine = _lineData =>
      _.filter(
        _lineData,
        _pointData =>
          !_.isNil(_.get(_pointData, 'x')) && !_.isNil(_.get(_pointData, 'y')),
      );

    const allLineData = _.map(tooltipData?.hoverData?.line ?? [], _lineData => {
      const { pathInfo } = _lineData; // pathInfo object is included in _lineData, along with array-like indices (0, 1, etc)

      // few notes here:
      //  - 'x' in pointData is not a strictly cartesian value - we treat it more like a label
      //  - 'y' in pointData is a number, but is not the related to the 'y' chart coordinates
      return {
        ..._.map(getPointDataInLine(_lineData), _pointData => {
          let posY = _pointData.y; // setting default. Should be value based on chart scale
          if (_.isFunction(this.getPosYFromData)) {
            posY = this.getPosYFromData(_pointData);
          }
          return {
            ..._pointData,
            posX: tooltipData.hoverX,
            posY,
            xAxis: _pointData.x,
            pathInfo,
          };
        }),
        pathInfo,
      };
    });

    let pointData = [];
    let lineData = [];

    // toggle line selection if some points are already selected
    if (_.isEmpty(this.focusedDataPoints)) {
      const pointDataMatcher = _pointInfo => {
        const comparativeAxis = _axisInfo =>
          _.map(_axisInfo, _axis => ({
            attributeName: _axis.attributeName,
            value: _axis.value,
          }));
        return _.isEqual(
          comparativeAxis(_pointInfo?.axises),
          comparativeAxis(xAxisPointData),
        );
      };

      const selectedLineData = _.filter(allLineData, _lineData =>
        _.some(getPointDataInLine(_lineData), pointDataMatcher),
      );
      const allPointData = _.reduce(
        selectedLineData,
        (acc = [], _lineData) => {
          return _.concat(acc, getPointDataInLine(_lineData));
        },
        [],
      );
      pointData = _.filter(allPointData, pointDataMatcher);
      lineData = _.map(selectedLineData, 'pathInfo');
    } else {
      const lineDataFromFocusedData = _.filter(allLineData, _lineData => {
        return _.some(this.focusedData, _focusedData =>
          _.isEqual(_.get(_lineData, 'pathInfo'), _focusedData),
        );
      });
      lineData = _.map(lineDataFromFocusedData, 'pathInfo');
    }

    return { pointData, lineData };
  }

  getDrillContextArgs(lineData, pointData) {
    const dataItem = _.isArray(pointData) ? _.head(pointData) : pointData;
    return {
      chartUtilities: LineChartUtils,
      data: this.data,
      lineData,
      dataItem,
      shelf: 'XAXIS',
    };
  }

  updateComponent(props) {
    // crude 'prop' update - trying to fix bad practices
    _.forEach(
      [
        'showLabels',
        'showDataLabels',
        'getX',
        'getX0',
        'getY',
        'getY0',
        'focus',
        'showGlobalTooltip',
        'className',
        'dataFormatters',
        'focusedDataPoints',
        'useDataPointSelectionMode',
        'dataPointSelectionMode',
      ],
      _key => {
        if (!_.isNil(props[_key])) {
          Object.assign(this, {
            [_key]: props[_key],
          });
        }
      },
    );

    this.component = (
      <ReactResizeDetector handleWidth handleHeight>
        {dimensions => {
          this.linesDimensions = dimensions;
          return (
            <Lines
              {...props}
              isSecondaryPlot={this.isSecondaryPlot}
              getX={this.getXAccessor()}
              getY={this.getYAccessor()}
              xScale={this.xScale}
              yScale={this.yScale}
              ref={lines => {
                if (props.plotRef) {
                  props.plotRef(lines);
                }
              }}
              height={this.height}
              width={this.width}
              data={this.data}
              chartPadding={this.presetPadding}
              paletteOffset={this.paletteOffset}
              xAxisShelf='XAXIS'
              shelves={this.layout}
              globalMouseTooltipEnabled={props.showGlobalTooltip}
              showDataLabels={this.showDataLabels}
              offsetX={this.getOffsetX()}
              vizId={this.vizId}
              plot={this}
              hidePoints={this.hidePoints}
              disableLineSmoothing={this.disableLineSmoothing}
              disableTooltips={this.disableTooltips}
              showGlobalTooltip={props.showGlobalTooltip}
              dataFormatters={props.dataFormatters}
              onHover={(data, x, y) => {
                if (_.isFunction(this.hoverFunction)) {
                  this.hoverFunction(data, 'lines', x, y);
                }
              }}
              showLastPoint={this.showLastPoint}
              colorPalette={this.colorPalette}
              enableReportLink={this.enableReportLink}
              nullHandling={this.nullHandling}
              focusedData={this.focusedData}
              customFormatProps={this.customFormatProps}
              useSelectionMode={this.useDataPointSelectionMode}
              selectionMode={this.dataPointSelectionMode}
              getAnchoredPointData={() => this.getAnchoredPointData()}
              getNearestPointData={(lineData, xPos) =>
                this.getNearestPointData(lineData, xPos)
              }
              getHoverData={posX => this.getHoverData(posX)}
              onFocusedDataPointUpdate={focusedDataPoints =>
                this.onFocusedDataPointUpdate(focusedDataPoints)
              }
              getDrillContextArgs={(lineData, dataItem) => {
                return this.getDrillContextArgs(lineData, dataItem);
              }}
            />
          );
        }}
      </ReactResizeDetector>
    );
  }

  getComponent(props) {
    this.updateComponent(props);

    return this.component;
  }

  getOffsetX() {
    return this.xScale?.bandwidth ? this.xScale.bandwidth() / 2 : 0;
  }

  getTooltip(
    dataItem,
    dataFormatters,
    showGlobalTooltip,
    useFiscalCalendar,
    customFormatters,
  ) {
    // may need to pull data from focusedDataPoints
    let data;
    if (_.isArray(dataItem.point)) {
      data = this.getTooltipData(
        dataItem.point,
        dataFormatters,
        customFormatters,
      );
    } else {
      // only show tooltip info for focused lines
      if (
        !_.isEmpty(this.focusedData) &&
        !_.some(this.focusedData, dataItem.line.pathInfo)
      ) {
        return [];
      }
      data = {
        rows: LineChartUtils.collectPathTo(
          this.data,
          dataItem.line,
          dataItem.point,
          'XAXIS',
          dataFormatters,
          this.i18nPrefs,
          customFormatters,
        ),
      };
    }
    return this.renderTooltip(data, showGlobalTooltip, useFiscalCalendar);
  }

  getLegendTitle(isComboComponent = false) {
    if (isComboComponent) {
      if (!_.isEmpty(this.layout.LINES)) {
        return messages.line.linesShelf;
      } else {
        return '';
      }
    }
    if (!_.isEmpty(this.layout.LINES)) {
      return joinChartData(this.layout.LINES.map(f => f.name));
    } else if (!_.isEmpty(this.layout.VALUES)) {
      return messages.line.valuesShelf;
    } else {
      return '';
    }
  }

  getLegendData() {
    // Add lines
    const legendData = new Set();
    // TODO: [DSC-3532] This should probably have already happened by the time
    // this component in instantiated
    LineChartUtils.collectLegend(
      this.data,
      null,
      null,
      this.layout,
      this.valuesName,
    ).forEach(item => {
      const colorKey = LineChartUtils.getColorKey(item.info);
      legendData.add({
        label: item.name,
        shape: item.shape,
        color: ColorManager.getColor(this.vizId, colorKey),
        info: item.info,
      });
    });
    return legendData;
  }

  getFocusedDataPointAnchor() {
    const renderedPoints = _.filter(
      this.focusedDataPoints,
      _focusedDataPoint => {
        const isXValid = _.isNumber(
          _.get(_focusedDataPoint, 'posX', undefined),
        );
        const isYValid = _.isNumber(
          _.get(_focusedDataPoint, 'posY', undefined),
        );
        return isXValid && isYValid;
      },
    );

    let topmostPoint;

    if (_.isEmpty(renderedPoints)) {
      topmostPoint = _.last(this.focusedDataPoints);
      topmostPoint.posX = 0;
      topmostPoint.posY = 0;
    } else {
      const sorted = _.sortBy(renderedPoints, ['y']);
      topmostPoint = _.last(sorted);
    }

    return topmostPoint;
  }

  getNearestPointData(lineData, xPos) {
    if (_.isEmpty(lineData)) {
      return;
    }
    const axisBisector = bisector(d =>
      this.xScale(this.getXAccessor()(d)),
    ).left;
    const idxOnLeft = axisBisector(lineData, xPos);
    const dataOnLeft = lineData[Math.max(0, idxOnLeft - 1)]; // select data to left, or first

    // Calculate the nearest point
    let nearestPoint;
    if (idxOnLeft === lineData?.length) {
      // at the end no need to compare
      nearestPoint = dataOnLeft;
    } else {
      const idxOnRight = idxOnLeft + 1;
      const dataOnRight = lineData[idxOnRight - 1];
      nearestPoint =
        xPos - this.xScale(dataOnLeft.x) < this.xScale(dataOnRight.x) - xPos
          ? dataOnLeft
          : dataOnRight;
    }

    return nearestPoint;
  }

  getAnchoredPointData() {
    const allLineData = this.data.LINES;

    const dataPointForAnchor = this.getFocusedDataPointAnchor();

    const lineDataContainingPoint = _.find(allLineData, _lineData => {
      return (
        _.isEqual(dataPointForAnchor?.pathInfo, _lineData?.pathInfo) &&
        !(_.isNil(dataPointForAnchor?.pathInfo) && _.isNil(_lineData?.pathInfo))
      );
    });

    const pointData = this.getNearestPointData(
      lineDataContainingPoint,
      dataPointForAnchor?.posX,
    );

    return { anchor: dataPointForAnchor, pointData };
  }

  showAnchoredTooltip() {
    if (!(this.useDataPointSelectionMode && this.dataPointSelectionMode)) {
      return;
    }

    const { anchor, pointData } = this.getAnchoredPointData();

    const { posX: x, posY: y } = anchor ?? {};
    const line = this.data.LINES ?? {};
    const point = pointData?.axises ?? [];

    if (_.isFunction(this.hoverFunction)) {
      this.hoverFunction({ line, point }, 'lines', x, y);
    }
  }

  getHoverData(xPos) {
    let axisVal;

    if (this.xScale.invert) {
      // Linear scale
      axisVal = this.xScale.invert(xPos);
    } else {
      // Non linear scales have a padding of one on either side: this.props.data.AXIS.length + 1 == zero-based + 2
      axisVal =
        (xPos / this.linesDimensions.width) * (this.data.AXIS.length + 1);
    }

    const nearestIdx = Math.round(axisVal);

    const point = this.xScale.invert
      ? [nearestIdx]
      : this.data.AXIS[nearestIdx - 1];

    const line = this.data.LINES ?? null;

    return { point, line };
  }

  globalMouseMove(xRelToContainer, yRelToContainer) {
    if (this.useDataPointSelectionMode && this.dataPointSelectionMode) {
      return;
    }

    const { point, line } = this.getHoverData(xRelToContainer);
    if (point) {
      this.hoverFunction(
        { line, point },
        'lines',
        this.xScale(joinChartData(point)) + this.getOffsetX(),
        yRelToContainer,
      );
    }
  }

  getTooltipData(xVal, dataFormatters, customFormatProps = undefined) {
    // may need to pull data from focusedDataPoints
    const tooltipData = {
      header: {},
      rows: [],
      paletteMapping: [],
      shapeMapping: [],
    };
    const paletteMapping = [];
    const shapeMapping = [];
    this.layout.XAXIS.forEach((field, i) => {
      const formatter = dataFormatters[field.name];
      const customFormat = _.get(customFormatProps, field.name);
      let val = xVal[i];
      if (formatter && !_.isNil(val.value)) {
        val = formatter.format(val.value, this.i18nPrefs, customFormat);
      }
      tooltipData.header[field.name] = val;
    });

    let legendData = LineChartUtils.collectLegend(
      this.data,
      this.xScale,
      this.xScale(joinChartData(xVal)),
      this.layout,
      this.valuesName,
      this.querySorts,
    );

    if (!_.isEmpty(this.focusedDataPoints)) {
      legendData = _.filter(legendData, _legendData =>
        _.find(this.focusedDataPoints, _dataPoint => {
          return _.isEqual(_dataPoint.pathInfo, _legendData?.info);
        }),
      );
    }

    legendData.forEach(item => {
      // only show tooltip info for focused lines
      if (
        !_.isEmpty(this.focusedData) &&
        !_.some(this.focusedData, item.info)
      ) {
        return;
      }
      const formatter = dataFormatters[item.info.valueName];
      const customFormat = _.get(customFormatProps, item.info.valueName);
      tooltipData.rows[item.name] = formatter
        ? formatter.format(item.value, this.i18nPrefs, customFormat)
        : item.value;
      // Get palette color
      const colorKey = LineChartUtils.getColorKey(item.info);
      const color = ColorManager.getColor(this.vizId, colorKey);
      paletteMapping.push(color);
      // Get icon shape
      shapeMapping.push(item.shape);
    });
    tooltipData.paletteMapping = paletteMapping;
    tooltipData.shapeMapping = shapeMapping;
    return tooltipData;
  }

  renderTooltip(hoverItem, globalTooltip = false, useFiscalCalendar = false) {
    const headerLines = [];
    if (globalTooltip && hoverItem && hoverItem.header) {
      const entries = hoverItem.header;
      for (const key in entries) {
        const label = i18nUtils.translateFieldName(
          messages,
          key,
          useFiscalCalendar,
        );
        const value = _.isNil(entries[key]?.value)
          ? entries[key]
          : entries[key].value;

        headerLines.push(
          <div
            className='tooltip-entry header'
            key={`tooltip-entry-${shortid.generate()}`}
          >
            <span className='chart-tooltip-label'>{label}</span>
            <span className='chart-tooltip-value'>{value}</span>
          </div>,
        );
      }
    }

    const content = [];

    if (globalTooltip && !_.isEmpty(headerLines)) {
      content.push(
        <div key={`header-lines-${shortid.generate()}`}>
          {headerLines}
          <hr />
        </div>,
      );
    }

    content.push(
      <ChartTooltipData
        key={`chart-tooltip-data-${shortid.generate()}`}
        data={hoverItem?.rows}
        palette={this.colorPalette}
        paletteMapping={hoverItem.paletteMapping}
        shape='LINE'
        showIcons
        shapeMapping={hoverItem.shapeMapping}
        useFiscalCalendar={useFiscalCalendar}
      />,
    );

    if (this.enableReportLink) {
      if (this.useDataPointSelectionMode) {
        if (_.isEmpty(this.focusedDataPoints)) {
          content.push(
            <ChartTooltipReportLinkNote
              key={`chart-tooltip-report-link-note-${shortid.generate()}`}
              body={messages.chartTooltip.clickDotsForDetails}
              name={this.linkToReport.name}
            />,
          );
        } else if (_.get(this.layout, 'LINES').length === 1) {
          content.push(
            <ChartTooltipReportLinkNote
              key={`chart-tooltip-data-${shortid.generate()}`}
              body={messages.formatString(
                messages.chartTooltip.clickViewDetails,
                messages.chartTooltip.viewDetails,
              )}
              name={this.linkToReport.name}
            />,
          );
        }
      } else {
        content.push(
          <ChartTooltipReportLinkNote
            key={`chart-tooltip-data-${shortid.generate()}`}
            name={this.linkToReport.name}
          />,
        );
      }
    }

    return content;
  }

  getMetricOrientation() {
    return METRIC_ORIENTATION.VERTICAL;
  }

  hasFocusedData() {
    return _.some(this.focusedData, f => _.has(f, 'lines'));
  }
}

export default LinePlot;
