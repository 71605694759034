import { css } from '@emotion/react';
import { font, vbox } from '../../common/emotion/mixins';

export const accountSettingsStyles = ({
  theme: {
    darkMode,
    colors: {
      AccountProfileSectionBackground,
      LightFontWeight,
      ContentText,
      PanelBackground,
      ContentTextFocus,
      MediumFontWeight,
      RegularFontWeight,
      baseFontColor,
      PhoneNumberTextColor,
      ErrorColor,
      ActiveLinkText,
      ContentBackground,
      ProfileContentBackground,
    },
  },
}) => css`
  .account-settings .modal-body {
    ${font({ weight: LightFontWeight })}
    padding: 0px;
  }
  .account-settings .nav {
    background: ${ContentBackground};
    width: 240px;
    padding: 20px;
  }
  .account-settings .nav a {
    color: ${ContentText};
  }
  .account-settings .nav li.active a {
    background: ${PanelBackground};
    color: ${ContentTextFocus};
  }
  .account-settings__content {
    ${vbox()}
    flex-direction: initial;
    background-color: ${ProfileContentBackground};
    flex: 1;

    & .account-profile-wrapper {
      ${vbox()}
      background-color: ${ContentBackground};
      height: 100%;
      width: 100%;

      & .account-profile-section {
        background-color: ${AccountProfileSectionBackground};
        border-radius: 4px;
        max-width: 700px;
        margin: 0 0 30px 30px;
        padding: 24px;
        &:last-of-type {
          margin-bottom: 0;
        }

        & h3.account-profile-header {
          ${font({ size: '16px', weight: '600', color: ContentText })}
          margin: 0;
        }
      }
    }
  }

  .account-settings__panel-content {
    padding: 40px;
    background-color: ${!darkMode && PanelBackground};
    flex: 1;
  }
  .account-settings__panel-content h3 {
    ${font({ size: '20px', weight: MediumFontWeight })}
    margin: 0px 0px 24px 0px;
  }
  .account-settings__panel-content p {
    margin-bottom: 40px;
  }
  .account-settings__panel-content label.control-label {
    ${font({
      size: '16px',
      weight: RegularFontWeight,
      color: baseFontColor,
    })}
    margin-bottom: 8px;
    padding-top: 8px;
  }
  .account-settings__panel-content .pull-right.aligned {
    padding-top: 8px;
  }
  .account-settings__panel-content label.control-label.disabled {
    opacity: 0.5;
  }
  .account-settings__panel-content .form-group {
    margin-bottom: 24px;
  }
  .account-settings__panel-content input.form-control {
    height: 40px;
    padding: 8px 16px;
  }
  .account-settings__panel-content svg.info {
    margin-left: 3px;
  }
  .account-settings__panel-content .help-block {
    ${font({ size: '12px' })}
  }
  .account-settings__panel-content .inline {
    display: inline-block;
  }
  .account-settings .settings-profile {
    height: initial;
    width: 540px;
  }
  .account-settings .settings-profile__form .form-group.first-name {
    margin-left: 0px;
    margin-right: 8px;
    max-width: 262px;
  }
  .account-settings .settings-profile__form .form-group.last-name {
    margin-left: 8px;
    margin-right: 0px;
    max-width: 262px;
  }
  .account-settings .notifications .phone-number-text {
    color: ${PhoneNumberTextColor};
  }
  .account-settings .password .form-group {
    position: relative;
  }
  .account-settings .password .form-group.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .account-settings .password .error {
    color: ${ErrorColor};
    border-color: ${ErrorColor};
  }
  .account-settings .password input.form-control {
    padding-right: 40px;
  }
  .account-settings .password .custom-input-addon {
    ${font({ size: '12px', weight: RegularFontWeight })}
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 10;
    cursor: pointer;
  }
  .account-settings .password .custom-input-addon:hover {
    ${font({
      size: '12px',
      weight: RegularFontWeight,
      color: ActiveLinkText,
    })}
  }
  .account-settings .password .password-validation .password-content-text {
    ${font({
      size: '16px',
      weight: MediumFontWeight,
      color: ContentText,
    })}
  }
  .account-settings
    .password
    .password-validation
    li.password-validation-indicator {
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: ContentText,
    })}
  }
`;
