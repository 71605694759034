import { Fragment, useMemo, useState } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
  HomeIcon,
  HelpIcon,
  HamburgerIcon,
  CloseIcon,
  StatisticsIcon,
  DatabaseIcon,
  SugarLogoIcon,
} from '../../icons';
import { isReadOnly as _isReadOnly } from '../../common/redux/selectors/AccountSelectors';
import { messages } from '../../i18n';
import {
  SidebarNavItemOption,
  SidebarNavOverlay,
  SidebarNavWrapper,
  IconWrapper,
  NavTooltip,
} from './sidebar-navigation.styles';
import { HELP_LINK, ROUTER_DIRS, SUGARCRM_WEBSITE } from '../../common';
import { useOpenDiscoveriesSelector } from '../../common/redux/selectors/viz-selector.hook';
import { DiscoverSubMenu } from './discover-sub-menu';
import SugarcrmLogo from '../../../images/sdd/sugarcrm_logo_modern.svg';
import { QuickCreateSubMenu } from './quick-create-sub-menu/quick-create-sub-menu.component';
import { SubMenuContextProvider } from './sidebar-navigation.context';

enum SUBMENUS {
  DISCOVER,
  QUICK_CREATE,
}

export const SidebarNavigation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openedSubMenu, setOpenedSubMenu] = useState<SUBMENUS>(null);
  const openDiscoveries = useOpenDiscoveriesSelector();
  const discoverEnabled = Object.keys(openDiscoveries).length > 0;
  const isReadOnly = useSelector(({ account }: any) => _isReadOnly(account));
  const { displayDiscovery } = useSelector(
    ({ discover }: any = {}) => discover,
  );

  const toggleSidebar = () => setIsExpanded(prevState => !prevState);
  const hideSidebar = () => setIsExpanded(false);

  const menuItemSelected = (e, disabled = false) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    hideSidebar();
  };

  const toggleSubmenu = useMemo(() => {
    return (submenuKey: SUBMENUS) => isOpen => {
      setOpenedSubMenu(isOpen ? submenuKey : null);
    };
  }, []);

  return (
    <nav role='navigation'>
      <SidebarNavWrapper>
        <SidebarNavOverlay
          data-testid='sidebar-overlay'
          className={classnames({
            expanded: isExpanded,
          })}
          onClick={hideSidebar}
        />
        <CSSTransition
          in={isExpanded}
          timeout={300}
          classNames={{
            enterActive: 'expanding',
            enterDone: 'expanded',
            exit: 'expanded',
            exitActive: 'collapsing',
          }}
        >
          <div className={'sidebar-nav'}>
            <div className='sidebar-nav-item-group'>
              <div className='sidebar-nav-item sidebar-nav-item-toggle'>
                {!isExpanded && (
                  <NavTooltip
                    title={messages.nav.openSideBar}
                    data-testid={messages.nav.openSideBar}
                    className={'collapsed tooltip-btn-toggle'}
                  >
                    <button
                      className='sidebar-nav-item-btn sidebar-nav-item-btn-toggle'
                      onClick={toggleSidebar}
                    >
                      <HamburgerIcon hover />
                    </button>
                  </NavTooltip>
                )}
                <button
                  className='expanded sidebar-nav-item-btn sidebar-nav-item-btn-toggle'
                  onClick={toggleSidebar}
                >
                  <CloseIcon hover />
                </button>
              </div>
            </div>
            <div className='sidebar-nav-item-group'>
              <div className='sidebar-nav-item'>
                <NavLink
                  to={ROUTER_DIRS.LIBRARY}
                  className={({ isActive }) =>
                    `sidebar-nav-item-btn home-menu-item${
                      isActive ? ' active' : ''
                    }`
                  }
                  onClick={e => menuItemSelected(e)}
                >
                  <span className='animated-background' />

                  <NavTooltip title={messages.nav.home} className={'collapsed'}>
                    <Fragment>
                      <HomeIcon hover />
                      <span className='sidebar-nav-item-tab' />
                    </Fragment>
                  </NavTooltip>

                  <SidebarNavItemOption className='expanded sidebar-nav-item-option'>
                    <HomeIcon hover />
                    <span>{messages.nav.home}</span>
                  </SidebarNavItemOption>
                </NavLink>
              </div>
              <SubMenuContextProvider>
                <QuickCreateSubMenu
                  hideSidebar={hideSidebar}
                  selected={openedSubMenu === SUBMENUS.QUICK_CREATE}
                  onToggle={toggleSubmenu(SUBMENUS.QUICK_CREATE)}
                />
              </SubMenuContextProvider>
            </div>
            <div className='sidebar-nav-item-group'>
              <div
                className={classnames('sidebar-nav-item', {
                  selected: openedSubMenu === SUBMENUS.DISCOVER,
                  disabled: !discoverEnabled,
                })}
              >
                <NavLink
                  to={ROUTER_DIRS.OPEN_VIZ(displayDiscovery)}
                  className={({ isActive }) =>
                    classnames(
                      'sidebar-nav-item-btn',
                      'discover-menu-item',
                      isActive ? 'active' : '',
                      {
                        disabled: !discoverEnabled,
                      },
                    )
                  }
                  onClick={e => menuItemSelected(e, !discoverEnabled)}
                >
                  <span className='animated-background' />

                  <NavTooltip
                    title={messages.nav.reports}
                    className={'collapsed'}
                  >
                    <Fragment>
                      <StatisticsIcon hover />
                      <span className='sidebar-nav-item-tab' />
                    </Fragment>
                  </NavTooltip>

                  <SidebarNavItemOption className='expanded sidebar-nav-item-option'>
                    <StatisticsIcon hover />
                    <span>{messages.nav.reports}</span>
                  </SidebarNavItemOption>
                </NavLink>
                {discoverEnabled && (
                  <DiscoverSubMenu
                    onToggle={toggleSubmenu(SUBMENUS.DISCOVER)}
                    hideSidebar={hideSidebar}
                  />
                )}
              </div>
              {!isReadOnly && (
                <div className='sidebar-nav-item'>
                  <NavLink
                    to={ROUTER_DIRS.DATASETS}
                    className={({ isActive }) =>
                      `sidebar-nav-item-btn datasets-menu-item${
                        isActive ? ' active' : ''
                      }`
                    }
                    onClick={e => menuItemSelected(e)}
                  >
                    <span className='animated-background' />

                    <NavTooltip
                      title={messages.nav.datasets}
                      className={'collapsed'}
                    >
                      <Fragment>
                        <DatabaseIcon hover />
                        <span className='sidebar-nav-item-tab' />
                      </Fragment>
                    </NavTooltip>

                    <SidebarNavItemOption className='expanded sidebar-nav-item-option'>
                      <DatabaseIcon hover />
                      <span>{messages.nav.datasets}</span>
                    </SidebarNavItemOption>
                  </NavLink>
                </div>
              )}
            </div>
            <div className='sidebar-nav-item-group'>
              <div className='sidebar-nav-item hidden' />
              <div className='sidebar-nav-item'>
                <a
                  href={HELP_LINK}
                  className='sidebar-nav-item-btn'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='animated-background' />

                  <NavTooltip
                    title={messages.nav.viewDocumentation}
                    className={'collapsed'}
                  >
                    <Fragment>
                      <HelpIcon hover />
                      <span className='sidebar-nav-item-tab' />
                    </Fragment>
                  </NavTooltip>

                  <SidebarNavItemOption className='expanded sidebar-nav-item-option'>
                    <HelpIcon hover />

                    <span>{messages.nav.viewDocumentation}</span>
                  </SidebarNavItemOption>
                </a>
              </div>
              <div className='sidebar-nav-item sidebar-nav-item-sugar'>
                <a
                  className='sidebar-nav-item-btn collapsed'
                  href={SUGARCRM_WEBSITE}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='animated-background' />
                  <NavTooltip title={messages.nav.viewSugarCrmWebsite}>
                    <Fragment>
                      <SugarLogoIcon hover size={16} />
                      <span className='sidebar-nav-item-tab' />
                    </Fragment>
                  </NavTooltip>
                </a>
                <a
                  className='expanded sidebar-nav-item-btn'
                  href={SUGARCRM_WEBSITE}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='animated-background expanded-full-fill' />
                  <IconWrapper>
                    <SugarcrmLogo />
                  </IconWrapper>
                </a>
              </div>
            </div>
          </div>
        </CSSTransition>
      </SidebarNavWrapper>
    </nav>
  );
};
