import { isEmpty, isEqual } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import classnames from 'classnames';
import { EyeIconShow, DownChevron } from '../../../icons';
import { messages } from '../../../i18n';
import { Tabs } from '@mui/material';
import { ToolbarButton } from '../ui/toolbar-button';
import { ActionToolBarWrapper } from './action-toolbar.styles';
import { IconDropdown } from '../../../components/icon-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { isAppAdmin as _isAppAdmin } from '../../../common/redux/selectors/AccountSelectors';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { useDiscoverTheme } from '../../../common/emotion';
import { ActionToolbarProps } from './action-toolbar.interfaces';

export const ActionToolbar = ({
  rowData,
  showFields,
  hideFields,
  removeCalc,
  onPreviewIconClick,
  selectedAttribute,
  isPreviewPanelOpen,
  onEditFieldIconClick,
  isEditFieldSettingsPanelOpen,
}: ActionToolbarProps) => {
  const isAppAdmin = useSelector(({ account }: any) => _isAppAdmin(account));
  const isCalc = !isEmpty(rowData.calculation);
  const dispatch = useDispatch();
  const editCalc = (rowData: any) =>
    dispatch(Discover.setShowFieldCalcDialog(true, rowData));
  const {
    darkMode,
    colors: { PanelNavBackground, TableRowHover, BaseWhite },
  } = useDiscoverTheme();
  return (
    <ActionToolBarWrapper>
      <Tabs
        value={0}
        sx={{
          minHeight: '32px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {!isCalc && (
          <ToolbarButton
            title={messages.editDatasetPanel.previewData}
            onClick={e => {
              e.stopPropagation();
              onPreviewIconClick(rowData);
            }}
            className={classnames({
              active:
                selectedAttribute &&
                isEqual(selectedAttribute.name, rowData.name) &&
                isPreviewPanelOpen,
            })}
            sx={{
              '&:hover .sicon': {
                color: darkMode && BaseWhite,
              },
              '&.active': {
                backgroundColor: darkMode ? TableRowHover : PanelNavBackground,
              },
            }}
            label=''
            icon={<EyeIconShow hover />}
          />
        )}
      </Tabs>
      <IconDropdown IconComponent={DownChevron}>
        {rowData.hidden && (
          <MenuItem onClick={showFields}>
            {messages.editDatasetPanel.showField}
          </MenuItem>
        )}
        {!rowData.hidden && (
          <MenuItem onClick={hideFields}>
            {messages.editDatasetPanel.hideField}
          </MenuItem>
        )}
        {isCalc && [
          <MenuItem
            key={`dataset-preview-table-edit-field-${rowData.index}`}
            onClick={() => editCalc(rowData)}
          >
            {messages.editDatasetPanel.editCalculatedField}
          </MenuItem>,
          <MenuItem
            key={`dataset-preview-table-delete-field-${rowData.index}`}
            onClick={() => removeCalc(rowData)}
          >
            {messages.editDatasetPanel.deleteCalculatedField}
          </MenuItem>,
        ]}
        {isAppAdmin && (
          <MenuItem
            onClick={() => onEditFieldIconClick(rowData)}
            className={classnames({
              active:
                selectedAttribute &&
                isEqual(selectedAttribute.name, rowData.name) &&
                isEditFieldSettingsPanelOpen,
            })}
          >
            {messages.editDatasetPanel.fieldSettings}
          </MenuItem>
        )}
      </IconDropdown>
    </ActionToolBarWrapper>
  );
};
