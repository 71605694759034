import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useWindowResizeListener } from './window-resize.hook';
import { DiscoverModalContainerContext } from '../discover-modal';

export const useViewportDimensions = () => {
  const containerRef = useRef<HTMLElement>();
  const container = useContext<HTMLElement>(DiscoverModalContainerContext);

  containerRef.current = container;
  const { width: resultWidth, height: resultHeight } = useResizeDetector({
    targetRef: containerRef,
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setWidth((resultWidth || container?.clientWidth) ?? 0);
    setHeight((resultHeight || container?.clientHeight) ?? 0);
  }, [container, resultHeight, resultWidth]);
  const { width: windowWidth, height: windowHeight } =
    useWindowResizeListener();
  const retVal = useMemo(() => {
    if (container) {
      return { width, height };
    } else {
      return { width: windowWidth, height: windowHeight };
    }
  }, [container, height, width, windowHeight, windowWidth]);
  return retVal;
};

export const ViewportDimensionsInjector = ({ children }) => {
  const dimensions = useViewportDimensions();
  return children(dimensions);
};
