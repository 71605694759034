import { useDatetimeFormatter } from '../../../common/formatting/datetime';
import { setTimeToZero } from '../../../views/edit-dataset-settings/panels/fiscal-calendar/fiscal-calendar.utils';
import { now, parseAbsolute, ZonedDateTime } from '@internationalized/date';
import moment, { getActiveTimeZone } from '../../../common/Moment';
import { useCallback, useRef, useState } from 'react';
import { head, isBoolean } from 'lodash';
import { DateValue } from 'react-aria-components';
import { RangeValue } from '@react-types/shared';
import { DatePickerChildrenProps } from './date-picker.interfaces';
import { NOW } from '../../../discovery/filter/Filter';

const toMomentFromTimeAgnosticDate = (datetime: string) => {
  // make datetime agnostic of time
  const _withoutTimeFormat = head(datetime.split('T'));
  const _moment = moment.utc(_withoutTimeFormat);

  return _moment.tz(getActiveTimeZone(), true).startOf('day');
};

export const toDateValue = (datetime: string): ZonedDateTime => {
  const _moment = toMomentDate(datetime);

  const iso8601Str = _moment.format(moment.ISO8601);

  return parseAbsolute(iso8601Str, getActiveTimeZone());
};

export const toMomentDate = (datetime: string) => {
  const _moment = toMomentFromTimeAgnosticDate(datetime);

  return _moment.startOf('day');
};

export const toIso8601 = (zonedDateTime: ZonedDateTime) =>
  toMomentDate(zonedDateTime.toAbsoluteString()).format(moment.ISO8601);

export const nowOrSpecifiedDate = (maybeNowOrDate: string): ZonedDateTime =>
  maybeNowOrDate === NOW ? defaultDate() : toDateValue(maybeNowOrDate);

export const useDateFormat = (iso8601Date: string, withTime = false) => {
  const { dateFormat, dateTimeFormat } = useDatetimeFormatter();

  const format = withTime ? dateTimeFormat : dateFormat;

  return setTimeToZero(moment(iso8601Date)).format(format);
};

export const defaultDate = () =>
  now(getActiveTimeZone()).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

export const useDatepickerProps = ({
  isOpen,
  isDisabled = false,
  onChange: _onChange,
}) => {
  const [_isOpen, _setIsOpen] = useState(!!isOpen);
  const [_isWrapperLoaded, _setIsWrapperLoaded] = useState(false);
  const onWrapperMount = useCallback(() => _setIsWrapperLoaded(true), []);

  const stylesRef = useRef();

  const isExternallyControlled = isDisabled || isBoolean(isOpen);

  const toggleInternalOpen = useCallback(
    () => !isExternallyControlled && _setIsOpen(!_isOpen),
    [_isOpen, isExternallyControlled],
  );

  const closeInternalOpen = useCallback(
    () => !isExternallyControlled && _setIsOpen(false),
    [isExternallyControlled],
  );

  const shouldCloseOnInteractOutside = useCallback(() => {
    closeInternalOpen();
    return true;
  }, [closeInternalOpen]);

  const onChange = useCallback(
    (dateOrRange: DateValue | RangeValue<DateValue>) => {
      _onChange(dateOrRange);

      // close on click
      closeInternalOpen();
    },
    [_onChange, closeInternalOpen],
  );

  const wrapperProps = {
    ref: stylesRef,
    onMount: onWrapperMount,
  };
  const datePickerChildrenProps: Omit<
    DatePickerChildrenProps,
    'formattedDate' | 'CalendarComponent'
  > = {
    toggleInternalOpen,
    isExternallyControlled,
    isPopoverOpen: (!isExternallyControlled && _isOpen) || isOpen,
    portalContainer: _isWrapperLoaded ? stylesRef?.current : undefined,
    shouldCloseOnInteractOutside,
  };

  return {
    onChange,
    wrapperProps,
    datePickerChildrenProps,
  };
};
