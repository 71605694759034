import { Component } from 'react';
import _ from 'lodash';
import { messages } from '../../i18n';

import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  InvisibleButton,
} from '../../ui';
import {
  ModalLabel,
  FormRoot,
  HelpBlock,
  StyledModal,
} from './viz-save-dialog.styles';
import { FormInput } from '../../components/ui/form-input';
import { VIZ_OPTION_IDS } from '../../common';

const MODES = {
  SAVE_NEW: 'SAVE_NEW',
  SAVE_AS: 'SAVE_AS',
  SAVE_UNSAVED: 'SAVE_UNSAVED',
  SAVE_DIRTY: 'SAVE_DIRTY',
  SAVE_RENAME: 'SAVE_RENAME',
};

export class VizSaveDialog extends Component {
  constructor(props) {
    super(props);
    const {
      discovery,
      viz,
      saveActive,
      isSaveAs,
      openDiscoveryNames,
      saveError,
    } = props;

    let _state = {
      isSaving: false,
      isDisabled: false,
    };

    if (_.isNil(viz.id) && saveActive) {
      // New visualization that has not been saved on save
      _state = {
        ..._state,
        mode: MODES.SAVE_NEW,
        defaultName: discovery.name,
        isDisabled: _.isEmpty(_.trim(discovery.name)),
      };
    } else if (isSaveAs && saveActive) {
      // New visualization save as
      const numberOfCopiesToAppend = openDiscoveryNames.reduce(
        (accum, curr) => {
          const copies = curr.split(messages.copy);
          if (_.startsWith(discovery.name, copies[0].trim())) {
            // make sure all of the tokens are empty, otherwise that means the name was actually different
            const numberOfCopy = copies.filter(c => c.trim().length === 0);
            return Math.max(accum, numberOfCopy.length + 1);
          }
          return accum;
        },
        1,
      );
      const baseName = discovery.name.split(messages.copy)[0];
      const name =
        baseName.trim() +
        Array(numberOfCopiesToAppend + 1).join(` ${messages.copy}`);
      _state = {
        ..._state,
        mode: MODES.SAVE_AS,
        defaultName: name,
      };
    } else if (_.isNil(viz.id) && !saveActive) {
      // New visualization that has not been saved on close
      _state = {
        ..._state,
        mode: MODES.SAVE_UNSAVED,
        defaultName: discovery.name,
      };
    } else if (discovery.dirty && !saveActive) {
      // Visualization that has been changed and not saved on close
      _state = {
        ..._state,
        mode: MODES.SAVE_DIRTY,
        defaultName: discovery.name,
      };
    } else if (discovery.dirty && !_.isEmpty(saveError)) {
      _state = {
        ..._state,
        mode: MODES.SAVE_RENAME,
        defaultName: discovery.name,
      };
    }
    this.state = _state;
  }

  onEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!this.state.disabled) {
        this.onSave();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('keypress', this.onEnter);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEmpty(this.props.saveError) &&
      prevProps.saveError !== this.props.saveError
    ) {
      this.setState({ isSaving: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.onEnter);
  }

  onSaveCancel() {
    this.props.cancelDiscoverySave();
  }

  onSave() {
    this.setState({ isSaving: true }, () => this.doSave());
  }

  doSave() {
    const { mode, defaultName, isSaving } = this.state;
    const name = _.trim(_.get(this.nameInput, 'value', ''));

    if (_.isEmpty(name)) {
      this.setState({
        isSaving: false,
        isDisabled: true,
      });
    }

    const description = _.get(this.descriptionInput, 'value', '');
    let viz = _.get(this.props, 'discovery.viz', {});
    const commitMessage = _.trim(_.get(this.commitMessageInput, 'value', ''));
    if (!_.isEmpty(commitMessage)) {
      viz = {
        ...viz,
        commitMessage,
        newRevision: true,
      };
    }

    if (!_.includes([MODES.SAVE_DIRTY, MODES.SAVE_RENAME], mode)) {
      if (mode !== MODES.SAVE_AS && name !== defaultName) {
        this.props.changeDiscoveryName(this.props.discovery.id, name);
      }
      const options = _.get(viz, 'options', {});
      viz = {
        ...viz,
        options: {
          ...options,
          description,
        },
      };
      this.props.saveNew({
        name,
        optionalViz: viz,
        closeAfterSave: this.props.closeDialogAfterSave,
      });
    } else if (mode === MODES.SAVE_RENAME) {
      this.props.changeDiscoveryName(this.props.discovery.id, name);
      this.props.update({
        optionalViz: { ...viz, name },
        closeAfterSave: this.props.closeDialogAfterSave,
      });
    } else {
      this.props.update({
        optionalViz: viz,
        closeAfterSave: this.props.closeDialogAfterSave,
      });
    }
    // If save is not active, user is trying to close viz
    if (!this.props.saveActive && !isSaving) {
      this.props.closeViz(this.props.discovery.id);
    }
  }

  render() {
    const { mode, defaultName, isSaving, isDisabled } = this.state;
    const { isReadOnlyUser, saveError, clearSaveError, viz } = this.props;
    const showSaveError = !_.isEmpty(saveError);
    const resetError = () => {
      if (showSaveError && _.isFunction(clearSaveError)) {
        clearSaveError();
      }
    };
    const showCloseVizButton =
      mode !== MODES.SAVE_NEW && mode !== MODES.SAVE_AS;
    const CancelButtonComponent = showCloseVizButton
      ? InvisibleButton
      : SecondaryButton;

    const onSubmit = e => {
      e?.preventDefault();

      resetError();
      this.onSave();
    };
    const title = messages.formatString(
      _.isEqual(mode, MODES.SAVE_DIRTY)
        ? messages.vizSaveDialog.keepUnsavedChanges
        : messages.vizSaveDialog.keepChanges,
      defaultName,
    );
    return (
      <StyledModal
        title={title}
        contentClassName='viz-save-dialog'
        buttonGroup={
          <ButtonContainer
            style={{
              marginBottom: '0.8rem',
              alignSelf: 'flex-end',
              marginRight: '1rem',
            }}
          >
            <CancelButtonComponent onClick={() => this.onSaveCancel()}>
              {messages.cancel}
            </CancelButtonComponent>
            {showCloseVizButton && (
              <SecondaryButton
                onClick={() => this.props.closeViz(this.props.discovery.id)}
              >
                {mode === MODES.SAVE_DIRTY
                  ? messages.confirmLogout.discardChanges
                  : messages.vizSaveDialog.delete}
              </SecondaryButton>
            )}
            <PrimaryButton
              className='save-button'
              type={'submit'}
              onClick={onSubmit}
              disabled={isDisabled || isReadOnlyUser || isSaving}
            >
              {isSaving ? (
                <span key={'spinner-loader-viz-save-message'}>
                  {messages.confirmLogout.saving}
                </span>
              ) : (
                messages.confirmLogout.save
              )}
            </PrimaryButton>
          </ButtonContainer>
        }
      >
        <FormRoot className='viz-save-form'>
          {_.includes(
            [MODES.SAVE_RENAME, MODES.SAVE_NEW, MODES.SAVE_AS],
            mode,
          ) && (
            <HelpBlock>{`${messages.vizSaveDialog.enterNameAndDesc} ${messages.vizSaveDialog.saveVersionSummary}`}</HelpBlock>
          )}
          {mode === MODES.SAVE_UNSAVED && (
            <HelpBlock>
              {`${messages.vizSaveDialog.chooseSaveOrDelete} ${messages.vizSaveDialog.saveVersionSummary}`}
            </HelpBlock>
          )}
          {mode === MODES.SAVE_DIRTY && (
            <HelpBlock>{`${messages.vizSaveDialog.chooseSaveOrDiscard} ${messages.vizSaveDialog.saveVersionSummary}`}</HelpBlock>
          )}
          {mode !== MODES.SAVE_DIRTY && (
            <>
              <ModalLabel>
                {mode === MODES.SAVE_UNSAVED
                  ? messages.vizSaveDialog.saveAs
                  : messages.vizSaveDialog.name}
              </ModalLabel>
              <FormInput
                className={`name-input${showSaveError ? ' error' : ''}`}
                name='name'
                placeholder={messages.name}
                defaultValue={defaultName}
                inputRef={el => {
                  this.nameInput = el;
                }}
                onFocus={el => {
                  el.target.select();
                }}
                autoFocus
                onChange={e => {
                  resetError();
                  this.setState({
                    isDisabled: _.isEmpty(_.trim(e.target.value)),
                  });
                }}
              />
              {showSaveError && (
                <HelpBlock className={'save-error-msg'} title={saveError}>
                  {messages.vizSaveDialog.errorAlreadyInUse}
                </HelpBlock>
              )}
            </>
          )}
          {mode !== MODES.SAVE_DIRTY && (
            <>
              <ModalLabel>{messages.vizSaveDialog.description}</ModalLabel>
              <FormInput
                multiline
                className='description-input'
                name='description'
                placeholder={messages.optional}
                defaultValue={
                  (viz?.options ?? {})[VIZ_OPTION_IDS.description] ?? ''
                }
                inputRef={el => {
                  this.descriptionInput = el;
                }}
              />
            </>
          )}
          <ModalLabel>{messages.vizSaveDialog.saveVersionLabel}</ModalLabel>
          <FormInput
            multiline
            className='version-input'
            name='version'
            placeholder={`${messages.vizSaveDialog.saveVersionPlaceholder} ${messages.vizSaveDialog.saveVersionOptional}`}
            value={this.state.commitMessage}
            inputProps={{ maxLength: 256 }}
            inputRef={el => {
              this.commitMessageInput = el;
            }}
            onChange={e => this.setState({ commitMessage: e.target.value })}
          />
        </FormRoot>
      </StyledModal>
    );
  }
}
