import PivotConditionalFormatting from './component.js';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { get } from 'lodash';
import { VIZ_OPTION_IDS } from '../../common';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setConditionalFormatting: data => {
      dispatch(
        Discover.setSettingForViz({
          id: ownProps.vizId,
          setting: VIZ_OPTION_IDS.conditionalFormatting,
          value: data,
        }),
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const open = state.discover.openDiscoveries[ownProps.vizId].present;
  return {
    viz: open.viz,
    metrics: open.viz.layout.VALUES,
    conditionalFormatting: JSON.parse(
      get(open, `viz.options.${VIZ_OPTION_IDS.conditionalFormatting}`, '[]'),
    ),
  };
};

export default compose(
  pure,
  connect(mapStateToProps, mapDispatchToProps),
)(PivotConditionalFormatting);
