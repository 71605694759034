import { PureComponent } from 'react';
import { SugarIcon } from '../icons';
import styled from '@emotion/styled';
import { DiscoverThemeInjector } from '../common/emotion';
import _ from 'lodash';
import { shortid } from '../common/utilities/shortid-adapter';

const FieldGroupController = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .field-pill-drag-wrapper {
    flex: 1;
    width: calc(100% - 18px);
  }

  .viz-field-pill {
    margin: 0;
    flex: 1;
  }
`;

const ExpandButton = styled.div`
  padding: 0 1px 0 7px;
  cursor: pointer;
`;

const FieldGroupPillContainer = styled.div`
  padding-left: 16px;
`;

class FieldGroupPill extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded ?? false,
      id: props?.id ?? `field-group-${shortid.generate()}`,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  clickHandler() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
    if (_.isFunction(this.props.onExpandToggle)) {
      this.props.onExpandToggle();
    }
  }

  render() {
    const { expanded } = this.state;
    const iconType = expanded ? 'chevron-down' : 'chevron-right';

    return (
      <div id={this.state.id} className={this.props.className}>
        <FieldGroupController>
          <DiscoverThemeInjector>
            {({ colors: { IconExpandField } = {} } = {}) => (
              <ExpandButton
                className={'field-expand-toggle'}
                onClick={() => {
                  this.clickHandler();
                }}
                aria-expanded={this.state.expanded}
                aria-controls={this.state.id}
              >
                <SugarIcon
                  hover
                  hoverColor={IconExpandField}
                  size={10}
                  icon={iconType}
                />
              </ExpandButton>
            )}
          </DiscoverThemeInjector>
          {this.props.root}
        </FieldGroupController>
        {expanded && (
          <FieldGroupPillContainer>
            {this.props.children}
          </FieldGroupPillContainer>
        )}
      </div>
    );
  }
}

export default FieldGroupPill;
