import { useOpenDiscoverySelector } from '../../redux/selectors/viz-selector.hook';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IUserInfo } from '../../../account/interfaces';
import { useAccount } from '../account';
import {
  getSortedQueryData,
  ISortedQueryData,
} from '../../redux/selectors/viz-selectors';
import {
  ExportWorkerMessage,
  ExportWorkerResponse,
  IFinishedExportResponse,
  IStartedExportResponse,
} from './export-to-excel.interfaces';
import { useWebWorker, WorkerRefContext } from '../web-worker';
import { isObject, isFunction } from 'lodash';
import { ToastContext } from '../../../components/toast/toast-launcher';
import { ISugarToast } from '../../../components/sugar-toast/sugar-toast.interfaces';
import { messages } from '../../../i18n';

// global variable provided by build script
declare const EXPORT_EXCEL_WORKER_URL: string;

const EXPORT_TIME_THRESHOLD_SECONDS = 60;
const EXPORT_TIME_THRESHOLD_MILLI = EXPORT_TIME_THRESHOLD_SECONDS * 1000;
const EXPORT_SUCCESS_REMINDER_SECONDS = 10;
const EXPORT_SUCCESS_REMINDER_TIME = EXPORT_SUCCESS_REMINDER_SECONDS * 1000;

export const useExportToExcel = ({ vizId }) => {
  const { present: discovery } = useOpenDiscoverySelector({
    discoveryId: vizId,
  });
  const messageActions = useRef<{
    [key in ExportWorkerResponse['action']]?: (e: ExportWorkerResponse) => void;
  }>({});

  const { showToast } = useContext(ToastContext) as any;

  const { setWorkerMeta, getWorkerMeta } = useContext(WorkerRefContext);
  const workerId = EXPORT_EXCEL_WORKER_URL;

  const { isExporting: isExportingMeta = false } = getWorkerMeta(workerId);

  // using useState so dependent components will re-render
  // using useRef so setTimeout will have accurate value
  const [isExportingLocal, setIsExportingLocal] = useState(isExportingMeta);
  const isExportingRef = useRef(isExportingMeta);
  const isExporting = isExportingLocal || isExportingMeta;

  const setIsExporting = useCallback(
    (_isExporting: boolean) => {
      if (isObject(isExportingRef)) {
        isExportingRef.current = _isExporting;
      }
      setIsExportingLocal(_isExporting);
      setWorkerMeta(workerId, {
        isExporting: _isExporting,
      });
    },
    [setWorkerMeta, workerId],
  );

  const showCompletionReminder = useRef(false);

  const stopExport = useCallback(() => {
    setWorkerMeta(workerId, {
      isExporting: false,
    });
    setIsExporting(false);
    showCompletionReminder.current = false;
  }, [setIsExporting, setWorkerMeta, workerId]);

  const { currentUser = {} as IUserInfo } = useAccount();
  const { i18nPrefs = {} } = currentUser;

  const queryResults: ISortedQueryData = getSortedQueryData(
    discovery,
  ) as ISortedQueryData;
  const viz = discovery?.viz;

  const onMessage = useCallback(
    (e: ExportWorkerResponse) => {
      if (isFunction(messageActions.current[e.action])) {
        messageActions.current[e.action](e);
      }
    },
    [messageActions],
  );

  const onError = useCallback(
    error => {
      console.log('error exporting to excel', error);
      stopExport();

      showToast({
        children: messages.formatString(
          messages.toast.excelExportError,
          viz?.name ?? messages.untitled,
        ),
        type: 'error',
      } as ISugarToast);
    },
    [showToast, stopExport, viz?.name],
  );

  const { postMessage, terminate } = useWebWorker<
    ExportWorkerMessage,
    ExportWorkerResponse
  >({
    workerId,
    relativeUrlToBase: EXPORT_EXCEL_WORKER_URL,
    onMessage,
    onError,
  });

  const onComplete = useCallback(
    (e: IFinishedExportResponse) => {
      try {
        const { url, reportName } = e.reportObject;
        // download and click
        const a = document.createElement('a');
        a.download = `${reportName}.xlsx`;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        if (showCompletionReminder.current) {
          showToast({
            children: messages.formatString(
              messages.toast.excelExportSuccess,
              reportName,
            ),
            type: 'success',
          } as ISugarToast);
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopExport();
      }
    },
    [showToast, stopExport],
  );

  const onStartExport = useCallback(
    (_e: IStartedExportResponse) => {
      setIsExporting(true);

      // handle locked export
      setTimeout(() => {
        if (isExportingRef.current) {
          terminate();

          showToast({
            children: messages.formatString(
              messages.toast.excelExportAborted,
              viz?.name ?? messages.untitled,
            ),
            type: 'error',
          } as ISugarToast);
        }

        stopExport();
      }, EXPORT_TIME_THRESHOLD_MILLI);

      setTimeout(() => {
        if (isExportingRef.current) {
          showCompletionReminder.current = true;
        }
      }, EXPORT_SUCCESS_REMINDER_TIME);
    },
    [setIsExporting, showToast, stopExport, terminate, viz?.name],
  );

  useEffect(() => {
    messageActions.current.started = onStartExport;
    messageActions.current.finished = onComplete;
    messageActions.current.error = onError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startExport = useCallback(() => {
    showToast({
      children: messages.formatString(
        messages.toast.excelExportStarted,
        viz?.name ?? messages.untitled,
      ),
      type: 'info',
    } as ISugarToast);

    postMessage({
      action: 'start_export',
      viz,
      queryResults,
      i18nPrefs,
    } as ExportWorkerMessage);
  }, [showToast, viz, postMessage, queryResults, i18nPrefs]);

  return {
    exportToExcel: startExport,
    exporting: isExporting,
    workerId,
  };
};
