import MobileLibrary from './component';
import { connect } from 'react-redux';
import { compose, withProps } from 'react-recompose';
import { withRouter } from '../../common/utilities/with-router';
import { sortBy } from 'lodash';
import { withSkeletonListLoadingPanel } from '../../common/hoc';
import {
  mapStateToProps,
  mapDispatchToProps,
  VizAndDatasetQuery,
} from '../Library';

export default compose(
  withRouter,
  VizAndDatasetQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => {
    const sortedList = sortBy(props.visualizations, ['name']);
    return {
      sortedList,
      sortBy: 'name',
      sortDirection: 'ASC',
      domain: 'library',
    };
  }),
  withSkeletonListLoadingPanel(),
)(MobileLibrary);
