import {
  Button,
  Calendar,
  RangeCalendar,
  CalendarCell,
  CalendarGrid,
  DatePicker as AdobeDatePicker,
  DateRangePicker as AdobeDateRangePicker,
  Dialog,
  Group,
  Heading,
  Popover,
} from 'react-aria-components';
import { ComponentProps, forwardRef, memo, useEffect } from 'react';
import { DatePickerStyles, DateRangePickerStyles } from './date-picker.styles';
import {
  Calendar as CalendarIcon,
  LeftChevron,
  RightChevron,
} from '../../../icons';
import { join, noop, omit } from 'lodash';
import { ZonedDateTime } from '@internationalized/date';
import { RangeValue } from '@react-types/shared';
import {
  useDateFormat,
  defaultDate,
  useDatepickerProps,
} from './date-picker.hook';
import { DatePickerChildrenProps } from './date-picker.interfaces';
import classnames from 'classnames';
import { messages } from '../../../i18n';
import { useIsDashletMode, useIsResponsive } from '../../../auth';
import { enableShadowDOM } from '@react-stately/flags';

/**
 * NOTE that these components are derived from Adobe's react-aria components
 */

// derived from https://react-spectrum.adobe.com/react-aria/DatePicker.html
export const DatePicker = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof AdobeDatePicker> & {
    defaultValue?: ZonedDateTime;
    showTime?: boolean;
  }
>(
  (
    {
      onChange: _onChange,
      isDisabled,
      defaultValue = defaultDate(),
      granularity = 'day',
      hideTimeZone = true,
      isOpen,
      showTime = false,
      ...remainingProps
    },
    forwardingRef,
  ) => {
    const { onChange, wrapperProps, datePickerChildrenProps } =
      useDatepickerProps({
        isOpen,
        isDisabled,
        onChange: _onChange,
      });

    const formattedDate = useDateFormat(
      defaultValue.toAbsoluteString(),
      showTime,
    );

    // prevent console logs
    const spreadProps = omit(remainingProps, ['key']);

    return (
      <DatePickerStyles {...wrapperProps}>
        <AdobeDatePicker
          ref={forwardingRef}
          onChange={onChange}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          granularity={granularity}
          hideTimeZone={hideTimeZone}
          {...spreadProps}
        >
          <DatePickerChildren
            {...datePickerChildrenProps}
            formattedDate={formattedDate}
            CalendarComponent={Calendar}
          />
        </AdobeDatePicker>
      </DatePickerStyles>
    );
  },
);

// derived from https://react-spectrum.adobe.com/react-aria/DateRangePicker.html
export const DateRangePicker = forwardRef<
  any,
  ComponentProps<typeof AdobeDateRangePicker> & {
    defaultValue?: RangeValue<ZonedDateTime>;
    showTime?: boolean;
  }
>(
  (
    {
      onChange: _onChange,
      isDisabled,
      defaultValue = {
        start: defaultDate(),
        end: defaultDate(),
      },
      granularity = 'day',
      hideTimeZone = true,
      isOpen,
      showTime = false,
      ...remainingProps
    },
    forwardingRef,
  ) => {
    const { onChange, wrapperProps, datePickerChildrenProps } =
      useDatepickerProps({
        isOpen,
        isDisabled,
        onChange: _onChange,
      });

    // prevent console logs
    const spreadProps = omit(remainingProps, ['key']);

    const { start: startDateValue, end: endDateValue } = defaultValue;

    const startDate = useDateFormat(
      startDateValue.toAbsoluteString(),
      showTime,
    );
    const endDate = useDateFormat(endDateValue.toAbsoluteString(), showTime);
    const formattedDate = join([startDate, endDate], ' - ');

    return (
      <DateRangePickerStyles {...wrapperProps}>
        <AdobeDateRangePicker
          ref={forwardingRef}
          onChange={onChange}
          isDisabled={isDisabled}
          defaultValue={defaultValue}
          granularity={granularity}
          hideTimeZone={hideTimeZone}
          {...spreadProps}
        >
          <DatePickerChildren
            {...datePickerChildrenProps}
            formattedDate={formattedDate}
            CalendarComponent={RangeCalendar}
          />
        </AdobeDateRangePicker>
      </DateRangePickerStyles>
    );
  },
);

const DateLabel = ({ children, onClick = noop, showIcon = false }) => (
  <div
    data-testid={'date-label'}
    className={classnames('react-aria-DateInput', showIcon && 'with-icon')}
    role={'presentation'}
    onClick={onClick}
  >
    {children}
  </div>
);

// DatePicker and DateRangePicker share the same child render
export const DatePickerChildren = memo(
  ({
    toggleInternalOpen,
    formattedDate,
    isExternallyControlled,
    isPopoverOpen,
    portalContainer,
    shouldCloseOnInteractOutside,
    CalendarComponent = Calendar,
  }: DatePickerChildrenProps) => {
    const isDashletMode = useIsDashletMode();
    const isResponsive = useIsResponsive();

    // See this issue & fix (https://github.com/adobe/react-spectrum/pull/6046)
    // associated with clicking/focus in our dashlet shadow environment.
    // this feature flag will be enabled by default in a later adobe version
    useEffect(() => {
      isDashletMode && enableShadowDOM();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
      !isResponsive && (
        <Group
          id={'date-picker-group'}
          onClick={toggleInternalOpen}
          key={'date-field'}
        >
          <DateLabel showIcon={!isExternallyControlled}>
            {formattedDate}
          </DateLabel>
          {!isExternallyControlled && (
            <span>
              <Button>
                <CalendarIcon aria-label={messages.editDatasetPanel.setDate} />
              </Button>
            </span>
          )}
        </Group>
      ),
      // truthiness of portalContainer here prevents a flash effect
      portalContainer && (
        <Popover
          key={'calendar-popover'}
          isOpen={isPopoverOpen}
          UNSTABLE_portalContainer={portalContainer}
          shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
        >
          <Dialog id={'date-picker-dialog'}>
            <CalendarComponent id={'date-picker-calendar'}>
              <header>
                <Button id={'date-picker-button-left'} slot='previous'>
                  <LeftChevron />
                </Button>
                <Heading />
                <Button id={'date-picker-button-right'} slot='next'>
                  <RightChevron />
                </Button>
              </header>
              <CalendarGrid>
                {date => <CalendarCell date={date} />}
              </CalendarGrid>
            </CalendarComponent>
          </Dialog>
        </Popover>
      ),
    ].filter(Boolean);
  },
);
