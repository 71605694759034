import { includes, isFunction, isNumber, keys, merge, omitBy } from 'lodash';
import {
  EMPTY_CELL_DISPLAY,
  NULL_DISPLAY,
  VIZ_QUERY_TOTALS_FLAG,
} from '../../../common/Constants';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { ILinkToReport } from '../../viz-redirect';

type cellMeta = {
  [fieldName: string]: string;
};

export const BodyRowContext = createContext<{
  rowMeta?: any;
  getCellMeta?: (
    rowIndex: number,
    columnIndex: number,
  ) => { cellRowMeta: cellMeta; cellColMeta: cellMeta };
  linkToReport?: ILinkToReport;
  isReportLinkEnabled?: boolean;
  callDrillLink?: (cellMeta: cellMeta) => void;
}>({});

export const usePivotCellDrillLink = ({
  rowIndex,
  columnIndex,
  formattedValue,
}) => {
  const { getCellMeta, linkToReport, isReportLinkEnabled, callDrillLink } =
    useContext(BodyRowContext) ?? {};

  const { cellColMeta = {}, cellRowMeta = {} } =
    (isFunction(getCellMeta) &&
      isNumber(rowIndex) &&
      isNumber(columnIndex) &&
      getCellMeta(rowIndex, columnIndex)) ??
    {};

  const hasLinkedReport = !!(linkToReport && isReportLinkEnabled);
  const cellMeta = useMemo(
    () => merge({}, cellRowMeta, cellColMeta),
    [cellRowMeta, cellColMeta],
  );
  const hasCellMeta = keys(cellMeta)?.length > 0;
  const isCellEmpty = includes(
    [EMPTY_CELL_DISPLAY, NULL_DISPLAY],
    formattedValue,
  );
  const linkStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'inherit',
  };
  const canDrillLink = !isCellEmpty && hasLinkedReport && hasCellMeta;

  const drillLink = useCallback(
    (_rI, _cI) => {
      const drillMeta = omitBy(cellMeta, val => val === VIZ_QUERY_TOTALS_FLAG);
      isFunction(callDrillLink) && callDrillLink(drillMeta);
    },
    [callDrillLink, cellMeta],
  );

  return {
    linkStyle,
    canDrillLink,
    drillLink,
    cellMeta,
    targetReportName: linkToReport?.name,
  };
};
