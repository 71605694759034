import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Popper from '@mui/material/Popper';
import { CheckIcon } from '../../components/Icons';
import { PaperStyled as PaperStyledFieldDropdown } from '../field-dropdown/field-dropdown.styles';
import { useDiscoverTheme } from '../../common/emotion';

export const ListButtonStyled = styled(ListItemButton)(
  () => css`
    font-size: 0.75rem;
    font-weight: 300;
    padding: 4px 8px 4px 14px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;

    &:hover {
      background-color: transparent;
    }

    .MuiSvgIcon-root {
      max-width: 20px;
    }
  `,
);

export const PaperStyled = styled(PaperStyledFieldDropdown)`
  border: none;
`;

export const CheckIconStyled = styled(CheckIcon)`
  position: absolute;
  left: -14px;
  top: 4px;
`;

export const MenuItemSpacedForCheck = styled(ListItem)`
  ${ListButtonStyled} {
    padding-left: 8px;
  }
  padding: 0 0 0 18px;
`;

export const PopperStyled = styled(Popper)(() => {
  const {
    colors: { MediumBorder },
  } = useDiscoverTheme();
  return css`
    border: 1px solid ${MediumBorder};
    &#time-hierarchy-submenu {
      ${MenuItemSpacedForCheck} {
        padding: 0;
      }
    }
  `;
});
