import BaseReducerSet from './reducers/BaseReducerSet';
import promiseMiddleware from 'redux-promise-middleware';
import REDUX_PROMISE_AXIOS from 'redux-promise-axios';
import ReduxThunk from 'redux-thunk';
import Main from './actions/MainActions';
import { applyMiddleware, compose, createStore } from 'redux';
import { save, load } from 'redux-localstorage-simple';

const localStorageConfig = {
  namespace: 'corvana_hd',
  states: ['storage'],
  preloadedState: {},
};

localStorageConfig.states.forEach(name => {
  const lsid = `${localStorageConfig.namespace}_${name}`;
  defendAgainstLocalStorageErrors(lsid);
});

const store = createStore(
  BaseReducerSet,
  load(localStorageConfig),
  compose(
    applyMiddleware(
      promiseMiddleware.default
        ? promiseMiddleware.default()
        : promiseMiddleware(),
      REDUX_PROMISE_AXIOS,
      ReduxThunk,
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined
      ? window.__REDUX_DEVTOOLS_EXTENSION__({
          trace: false,
          traceLimit: 25,
        })
      : f => f,
    applyMiddleware(save(localStorageConfig)),
  ),
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers/BaseReducerSet', () => {
    const updatedBaseReducer = require('./reducers/BaseReducerSet');
    store.replaceReducer(updatedBaseReducer);
  });
}

export default store;
export const init = () => store.dispatch(Main.init());

function defendAgainstLocalStorageErrors(name) {
  try {
    JSON.parse(localStorage[name]);
  } catch {
    localStorage[name] = '{}';
  }
}
