import { Component, ComponentClass } from 'react';
import FilterExpression from './FilterExpression';
import {
  createFilterForField,
  FilterTypes,
  TimestampFilterSubTypes,
} from './Filter';
import _ from 'lodash';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { IFilter } from '../../datasets/interfaces/filter.interface';

interface ITimestampConditionProps {
  filter: IFilter;
  changeFilter: any;
}

class TimestampCondition extends Component<ITimestampConditionProps, object> {
  state;

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.filter.subType === TimestampFilterSubTypes.SET_CONDITION;
  }
  onExpressionChanged(expression) {
    const filter = { ...this.props.filter, expression: { ...expression } };
    this.props.changeFilter(filter);
  }
  render() {
    const { expression } = this.props.filter;
    return (
      <div className='date-condition'>
        <FilterExpression
          expression={expression}
          onChange={exp => this.onExpressionChanged(exp)}
          filterType={FilterTypes.DATE}
        />
      </div>
    );
  }
}

const createTimestampFilter = field => {
  const filter = createFilterForField(field);
  filter.subType = TimestampFilterSubTypes.SET_CONDITION;
  return filter;
};

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  let { filter } = ownProps;
  if (_.isNil(filter)) {
    filter = createTimestampFilter(ownProps.field);
  }
  const { viz } = discovery ?? {};
  return {
    datasetId: viz?.dataset?.id,
    filter: { ...filter },
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimestampCondition as ComponentClass<ITimestampConditionProps>);
