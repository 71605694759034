/** Unauthenticated Redux reducers. Do no link to any source not involved in the login process here **/
import { combineReducers } from 'redux';

import login from './LoginReducers';
import main from './MainReducers';
import account from './AccountReducer';
import storage from './Storage';
import dashlet from './DashletReducers';

export default combineReducers({
  login,
  account,
  main,
  storage,
  dashlet,
});
