import { css, withTheme } from '@emotion/react';
import { Component, ComponentClass, ComponentType } from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose, pure } from 'react-recompose';
import _ from 'lodash';
import Discover from '../../../common/redux/actions/DiscoverActions';
import ColorManager from '../../../common/d3/ColorManager';
import { LineChartUtils } from '../LineChartUtils/LineChartUtils';
import { IfTrue } from '../../../components/ui/conditional';
import { VizLegendUtils } from './utils';
import { sortLegendData } from '../../LegendUtils';
import { getVizQuerySorts } from '../../../common/redux/selectors/viz-selectors';
import { ChartSpecs } from '../../ChartSpecs';
import { ChartLegendItem } from '../chart-legend-item';
import { PivotLegendItem } from '../pivot-legend-item';
import { IViz, IDiscovery, IBaseChartSpec } from '../../interfaces';
import { ILegendDatum } from '../viz-legend';
import { LegendContent, LegendPanel } from './viz-legend.styles';
import { IInternationalizationPreferences } from '../../../account/interfaces';
import { useEllipsisDetector, VIZ_OPTION_IDS } from '../../../common';
import { IDiscoverEmotionTheme } from '../../../common/emotion';
import { messages } from '../../../i18n';
import { SkeletonListLoader } from '../../../common/loaders/skeleton-list-loader';

interface IDispatchProps {
  setFocusedVizData?: any;
}

export interface IProps {
  viz?: IViz;
  vizId: string;
  data?: any;
  focusedData?: any;
  isMobile?: boolean;
  isForcedMobile?: boolean;
  isDashletMode?: boolean;
  showTitles?: boolean;
  forceHorizontal?: boolean;
  panelStyle?: any;
  isResizable?: boolean;
  theme?: IDiscoverEmotionTheme;
}

const LegendItems = ({ style, children }) => {
  const { isEllipsized, ref } = useEllipsisDetector();
  return (
    <div
      className='legend-items'
      css={css({
        overflowX: isEllipsized ? 'auto' : undefined,
      })}
      ref={ref}
      style={style}
    >
      {children}
    </div>
  );
};

class UnconnectedVizLegend extends Component<
  IProps & IDispatchProps & IVizLegendStateToProps
> {
  state;

  static defaultProps = {
    data: [],
    focusedData: [],
    i18nPrefs: {},
  };

  constructor(props: IProps & IDispatchProps & IVizLegendStateToProps) {
    super(props);
  }

  componentDidUpdate() {
    const node: any = ReactDOM.findDOMNode(this);
    const items = node.querySelectorAll('.legend-item:not(.dim)');
    if (!this.props.isDashletMode && !_.isNil(items) && items.length === 1) {
      setTimeout(() => {
        // Scroll single focused item into view
        items[0].scrollIntoView();
      }, 200);
    }
  }

  renderLegendData() {
    const {
      viz,
      vizId,
      data,
      focusedData,
      isMobile,
      isForcedMobile,
      isDashletMode,
    } = this.props;
    const chartSpec = ChartSpecs[viz.chartType];
    // TODO: [DSC-3532] We shouldn't be handling the sorting here -- this should
    // be refactored such that the data is already sorted appropriately by the
    // time the component gets it
    const querySorts = getVizQuerySorts(viz);
    const sortedData: ILegendDatum[] = sortLegendData(querySorts, data);
    return sortedData.map((item: ILegendDatum) => {
      switch (chartSpec.id) {
        case 'pivot': {
          return (
            <PivotLegendItem
              isForcedMobile={isForcedMobile}
              key={item.label}
              {..._.pick(item, ['label', 'colorScale', 'min', 'max'])}
              focused
              isMobile={this.props.isMobile}
              i18nPrefs={this.props?.i18nPrefs}
            />
          );
        }
        default: {
          if (item.title) {
            return this.props.showTitles && !isMobile ? (
              <div
                key={`legend-title-${item.title}`}
                className='title-legend-item'
                title={item.title}
              >
                {item.title}
              </div>
            ) : (
              []
            );
          }

          const focused = VizLegendUtils.isFocusItem(
            focusedData,
            this.getDataItem(item),
          );
          let { label } = item;
          if (item.shape === 'LINE') {
            label = LineChartUtils.getColorKey(item.info);
          }
          const color = ColorManager.getColor(vizId, label, this.props.theme);
          return (
            <ChartLegendItem
              key={`chart-legend-item-${label}`}
              {..._.pick(item, ['label', 'shape'])}
              color={color}
              isForcedMobile={isForcedMobile}
              isDashletMode={isDashletMode}
              focused={focused}
              onClick={e => {
                e.stopPropagation();
                this.setFocusedVizData(item);
              }}
            />
          );
        }
      }
    });
  }

  getDataItem(item) {
    return item?.info;
  }

  setFocusedVizData(item: ILegendDatum) {
    const chartSpec = ChartSpecs[this.props.viz.chartType];

    if (chartSpec.supportsLegendSelection) {
      if (_.isNil(item)) {
        this.props.setFocusedVizData(null);
      } else {
        if (_.isFunction(item?.focus)) {
          item?.focus();
        }
        this.props.setFocusedVizData(this.getDataItem(item));
      }
    }
  }

  render() {
    const { loading, isMobile, forceHorizontal, panelStyle = {} } = this.props;
    const style = {};
    if (forceHorizontal) {
      Object.assign(style, {
        display: 'flex',
        flexDirection: 'row',
      });
    }
    return (
      <LegendPanel
        className='viz-legend-panel'
        aria-label={messages.formatting.legend}
        onClick={e => {
          e.stopPropagation();
          this.setFocusedVizData(null);
        }}
        style={{ ...panelStyle, overflowY: 'hidden' }}
        isMobile={isMobile}
      >
        <LegendContent
          className='viz-legend'
          css={css({ ...style, width: 'inherit', overflowY: 'hidden' })}
        >
          <IfTrue value={loading && !isMobile}>
            <SkeletonListLoader />
          </IfTrue>
          <IfTrue value={!loading}>
            <LegendItems style={style}>{this.renderLegendData()}</LegendItems>
          </IfTrue>
        </LegendContent>
      </LegendPanel>
    );
  }
}

interface IVizLegendStateToProps {
  viz: IViz;
  data: ILegendDatum[];
  isAdvancedMode: boolean;
  isForcedMobile: boolean;
  showTitles: boolean;
  focusedData: any[];
  isMobile: boolean;
  loading?: boolean;
  isDashletMode: boolean;
  i18nPrefs?: IInternationalizationPreferences;
}

const mapStateToProps = (state, props) => {
  const {
    main: { advanced: isAdvancedMode, isForcedMobile, isMobile },
  } = state;

  const { i18nPrefs = {} } = state?.account?.currentUser;

  const discovery = state.discover.openDiscoveries[props.vizId]
    .present as IDiscovery;
  const { viz, legendData: data, focusedData, vizLoading: loading } = discovery;

  const chartSpec = ChartSpecs[viz.chartType] as IBaseChartSpec;

  const legendTitlesDisabled = chartSpec.isToggleDisabled(
    'showLegendTitles',
    viz,
  );

  const showTitles =
    JSON.parse(
      _.get(viz, `options.${VIZ_OPTION_IDS.showLegendTitles}`, 'true'),
    ) && !legendTitlesDisabled;
  const { isDashletMode } = state.dashlet;

  return {
    viz,
    data,
    isAdvancedMode,
    isForcedMobile,
    showTitles,
    focusedData,
    isMobile,
    loading,
    isDashletMode,
    i18nPrefs,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFocusedVizData(dataItem) {
      dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
    },
  };
};
export const VizLegend = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
  pure,
)(UnconnectedVizLegend as ComponentType<IProps>) as ComponentClass<IProps>;
