import {
  PrimaryButton,
  SecondaryButton,
  InvisibleButton,
  SmallModalButtonContainer,
} from '../../../../ui/button';
import { messages } from '../../../../i18n';
import { ModalLabel } from '../../../../views/VizSaveDialog/ui';
import { ModalPortal } from '../modal-portal';
import { IPromptDialogProps } from './prompt-dialog.interfaces';

export const PromptDialog = ({
  className = '',
  okDisabled = false,
  yesDisabled = false,
  noDisabled = false,
  cancelDisabled = false,
  hideAllButtons = false,
  detail,
  children,
  title = '',
  show = false,
  asInfo = false,
  doCancel,
  doOk,
  doYes,
  doNo,
  okText,
  yesText,
  noText,
  cancelText,
}: IPromptDialogProps) => {
  const CancelButtonComponent = asInfo ? SecondaryButton : InvisibleButton;
  return (
    show && (
      <ModalPortal
        className={className}
        title={<ModalLabel>{title}</ModalLabel>}
        buttonGroup={
          !hideAllButtons && (
            <SmallModalButtonContainer>
              {doCancel && (
                <CancelButtonComponent
                  onClick={doCancel}
                  className='promptCancel'
                  disabled={cancelDisabled}
                >
                  {cancelText || messages.cancel}
                </CancelButtonComponent>
              )}
              {asInfo ? (
                <PrimaryButton onClick={doOk} disabled={okDisabled}>
                  {okText || messages.ok}
                </PrimaryButton>
              ) : (
                [
                  doNo ? (
                    <SecondaryButton
                      key='no'
                      onClick={doNo}
                      className='promptNo'
                      disabled={noDisabled}
                    >
                      {noText || messages.no}
                    </SecondaryButton>
                  ) : null,
                  <PrimaryButton
                    key='yes'
                    onClick={doYes}
                    className='promptYes'
                    disabled={yesDisabled}
                  >
                    {yesText || messages.yes}
                  </PrimaryButton>,
                ].filter(Boolean)
              )}
            </SmallModalButtonContainer>
          )
        }
      >
        <form>
          {detail}
          {children}
        </form>
      </ModalPortal>
    )
  );
};
