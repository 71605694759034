import { PureComponent, createRef } from 'react';
import { branch, compose, pure, renderNothing } from 'react-recompose';
import { connect } from 'react-redux';
import { isAppAdmin } from '../../common/redux/selectors/AccountSelectors';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';
import { Viz } from '../../discovery/VizUtil';
import DiscoveryQueries from '../../common/graphql/DiscoverQueries';
import { client } from '../../common/ApolloClient';
import _ from 'lodash';
import LoadingButton from '../../common/widgets/LoadingButton';
import MainActions from '../../common/redux/actions/MainActions';
import copy from 'copy-to-clipboard';
import { postModifyVariables } from '../../common/redux/actions/DiscoverActions';

class CopyQuerySqlButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.hiddenInputRef = createRef();
  }

  async onClick() {
    this.setState({ loading: true });
    const variables = Viz.buildQueryVariables(
      this.props.viz,
      this.props.chartSpec,
    );
    const modifiedVariablesObj = await postModifyVariables({
      viz: this.props.viz,
      variables,
    });
    const modifiedVariables = modifiedVariablesObj.variables;
    const queryOptions = {
      fetchPolicy: 'network-only',
      query: DiscoveryQueries.GetQuerySql,
      variables: modifiedVariables,
    };

    const promises = [];
    const primaryQueryPromise = client.query(queryOptions);

    promises.push(primaryQueryPromise);

    const secondaryQueryVariables =
      this.props.chartSpec.buildSecondaryQueryVariables(
        this.props.viz,
        variables,
      );
    let modifiedSecondaryVariables = secondaryQueryVariables;
    if (!_.isNil(secondaryQueryVariables)) {
      const modifiedSecondaryVariablesObj = await postModifyVariables({
        viz: this.props.viz,
        variables: secondaryQueryVariables,
      });
      modifiedSecondaryVariables = modifiedSecondaryVariablesObj.variables;

      const secondaryQueryOptions = {
        fetchPolicy: 'network-only',
        query: DiscoveryQueries.GetQuerySql,
        variables: modifiedSecondaryVariables,
      };
      const secondaryQueryPromise = client.query(secondaryQueryOptions);
      promises.push(secondaryQueryPromise);
    }

    Promise.all(promises)
      .then(queryData => {
        let textData = [];
        queryData.forEach(data => {
          const queryText = _.get(data, 'data.getSql', []);
          textData = [
            ...textData,
            '----------------------------------------------------------------------------------------------',
            ...queryText,
          ];
        });
        const text = textData.join('\n\n');
        console.info(text);

        // try using the clipboard api to avoid potential user prompt before the copy happens
        navigator.permissions
          .query({ name: 'clipboard-write' })
          .then(result => {
            if (result.state === 'granted') {
              navigator.clipboard.writeText(text).then(() => {
                this.props.showToast('Sql copied to clipboard');
              });
            } else {
              // if it the clipboard api isn't available, just use the copy-to-clipboard method that might display a prompt
              copy(text);
              this.props.showToast('Sql copied to clipboard');
            }
          });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        <LoadingButton
          loading={this.state.loading}
          loadingText='Getting SQL...'
          bsStyle='primary'
          onClick={() => this.onClick()}
        >
          Copy Query SQL
        </LoadingButton>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const viz = VIZ_SELECTORS.getActiveViz(state, ownProps);
  const chartSpec = VIZ_SELECTORS.getActiveVizChartSpec(state, ownProps);
  const isAdvancedMode = state.main.advanced;
  const isAdmin = isAppAdmin(state.account);
  return {
    viz,
    chartSpec,
    isAdvancedMode,
    isAppAdmin: isAdmin,
  };
};
let toastTimer;
const mapDispatchToProps = dispatch => {
  return {
    showToast: msg => {
      dispatch(MainActions.showToast({ text: msg }));
      clearTimeout(toastTimer);
      toastTimer = setTimeout(() => {
        dispatch(MainActions.closeToast());
      }, 2000);
    },
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  pure,
  branch(
    props => !props.isAdvancedMode || !props.isAppAdmin || _.isNil(props.viz),
    renderNothing,
  ),
)(CopyQuerySqlButton);
