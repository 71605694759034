import {
  CloseIcon,
  LeftChevron,
  RightChevron,
} from '../../../icons/icons/icons.component';
import * as UI from '../ui';
import { messages } from '../../../i18n';
import { findIndex, isEmpty } from 'lodash';

export const PanelHeader = ({
  isPreviewPanelOpen,
  originalSelectedAttributeRef,
  sortedList,
  onSelectAttributeForEditFieldSettings,
  closePanel,
}) => {
  const getAttributeDetails = () => {
    const list = isPreviewPanelOpen
      ? sortedList.filter(({ calculation }) => isEmpty(calculation))
      : sortedList;
    const listLength = list.length;
    const idx = findIndex(list, {
      name: originalSelectedAttributeRef.name,
    });

    return {
      list,
      listLength: list.length,
      idx,
      prevDisabled: idx < 1,
      nextDisabled: idx > listLength - 2,
    };
  };

  const switchPanel = direction => {
    const { list, idx } = getAttributeDetails();
    onSelectAttributeForEditFieldSettings(list[idx + direction]);
  };

  const { prevDisabled, nextDisabled } = getAttributeDetails();

  return (
    <UI.Header className='attribute-header'>
      {isPreviewPanelOpen
        ? messages.editDatasetPanel.dataPreview
        : messages.editDatasetPanel.fieldSettings}
      <span style={{ marginLeft: 'auto' }}>
        <LeftChevron
          onClick={() => !prevDisabled && switchPanel(-1)}
          disabled={prevDisabled}
        />
      </span>
      <span style={{ marginLeft: '18px' }}>
        <RightChevron
          onClick={() => !nextDisabled && switchPanel(1)}
          disabled={nextDisabled}
        />
      </span>
      <span title={messages.close} style={{ marginLeft: '18px' }}>
        <CloseIcon onClick={closePanel} size={12} />
      </span>
    </UI.Header>
  );
};
