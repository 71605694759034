import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import moment from '../common/Moment';
import { TABLE_SELECTORS } from '../common/redux/selectors/TableSelectors';
import {
  isAdmin,
  isAppAdmin,
} from '../common/redux/selectors/AccountSelectors';
import TableUtils from '../common/TableUtils';
import { handlesResize, SectionLayout } from '../common/hoc/';
import { PromptDialog } from '../common/widgets/dialogs/prompt-dialog';
import Discover from '../common/redux/actions/DiscoverActions';
import Dataset from '../common/redux/actions/DatasetActions';
import Main from '../common/redux/actions/MainActions';
import { getPrimarySugarCRMDataset, DatasetStatusIcon } from './utils';
import { graphql } from '@apollo/client/react/hoc';
import {
  compose,
  pure,
  withHandlers,
  withState,
  withProps,
} from 'react-recompose';
import { DatasetQueries } from '../common/graphql/index';
import DiscoverQueries from '../common/graphql/DiscoverQueries';
import * as _ from 'lodash';
import FieldConfiguration from './FieldConfiguration';
import { Root } from '../components/ui';
import { TextSearchField, ClickToCopy } from '../components/ui/form';
import UITable from '../components/UITable';
import {
  TableToolBar,
  HorizontalCell,
  HorizontalCellInteractive,
  VerticalCellInteractive,
  SubCell,
} from '../components/ui/table';
import { IconDropdown } from '../components/icon-dropdown';
import Util from '../common/Util';
import { messages } from '../i18n';
import { DatasetSettings } from '../views/edit-dataset-settings';
import { EditDiscoveryDialog } from '../views/edit-discovery-dialog';
import { client } from '../common/ApolloClient';
import { HelpBlock } from '../views/VizSaveDialog/ui';
import { CreateDatasetModal } from './create-dataset-modal';
import { withErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryHOCOptions } from '../components/error-boundary';
import { TableSkeleton } from '../common/loaders';
import {
  CreateDatasetButton,
  MainSectionHeader,
} from '../components/main-section-header';
import { DownChevron } from '../icons';
import { DatasetHOC } from './dataset.hook';
import { ROUTER_DIRS } from '../common';

const POLL_FREQUENCY = 10000;

const ButtonActions = withHandlers({
  newDiscovery: props => dataset => {
    props.newVisualization(dataset);
  },

  editDataset: props => (id, editable) => {
    props.setShowDatasetEditDialog(id, editable);
  },

  showDeleteDatasetPrompt: props => dataset => {
    props.setShowDelete(true);
    props.setDatasetToDelete(dataset);
    props.getContentForDataset(dataset.id);
  },
  openDatasetSettings: props => datasetId => {
    props.setDatasetId(datasetId);
    props.openDatasetSettings(datasetId);
  },
  closeDatasetSettings: props => datasetId => {
    props.setDatasetId(null);
    props.closeDatasetSettings(datasetId);
  },
});

const DataListPanelHandlers = withHandlers({
  getDeleteDetail: props => () => {
    if (
      props.datasetToDelete &&
      props.linkedContentForDatasetId === props.datasetToDelete.id &&
      props.linkedContent &&
      props.linkedContent.length > 0
    ) {
      const contentCount = messages.formatString(
        messages.datasets.contentCount,
        {
          count: props.linkedContent.length,
          subject: 'content',
        },
      );
      const template =
        props.linkedContent.length === 1
          ? messages.datasets.deletePromptSingular
          : messages.datasets.deletePromptPlural;
      const promptMessage = messages.formatString(template, {
        contentCount: <strong>{contentCount}</strong>,
        name: (
          <strong>
            {props.datasetToDelete ? props.datasetToDelete.name : ''}
          </strong>
        ),
      });

      return (
        <div className='promptMessage'>
          <HelpBlock>{promptMessage}</HelpBlock>
          <HelpBlock>{messages.datasets.warningOperationIsPermanent}</HelpBlock>
        </div>
      );
    } else {
      return (
        <div className='promptMessage'>
          <HelpBlock>
            {messages.formatString(
              messages.datasets.confirmPermanentDelete,
              <strong>{_.get(props, 'datasetToDelete.name', '')}</strong>,
            )}
          </HelpBlock>
          <HelpBlock>{messages.datasets.warningOperationIsPermanent}</HelpBlock>
        </div>
      );
    }
  },

  doDeleteYes: props => () => {
    props.deleteDataset(props.datasetToDelete.id);
    props.setShowDelete(false);
  },

  doDeleteNo: props => () => {
    props.setShowDelete(false);
  },

  onSearch: props => search => {
    props.setSearch(search);
  },

  onRowClick:
    props =>
    ({ rowData }) => {
      if (!props.actionsOpen) {
        props.newDiscovery(rowData);
      }
    },

  nameFormat:
    props =>
    ({ rowData }) => {
      if (props.listLoading) {
        return <TableSkeleton />;
      }
      const { name, description } = rowData;
      return (
        <VerticalCellInteractive title={name}>
          {name}
          <SubCell title={description}>{description}</SubCell>
        </VerticalCellInteractive>
      );
    },

  creatorNameFormat:
    props =>
    ({ rowData }) => {
      if (props.listLoading) {
        return <TableSkeleton />;
      }
      const { creatorName } = rowData;
      return (
        <HorizontalCellInteractive title={creatorName}>
          {creatorName}
        </HorizontalCellInteractive>
      );
    },

  formatDate: () => (date, dateTimeFormat) => {
    if (date && date > 0) {
      const m = moment(date);
      return m.format(dateTimeFormat);
    } else {
      return '---';
    }
  },

  actionsFormat:
    props =>
    ({ rowData }) => {
      if (props.listLoading) {
        return <TableSkeleton />;
      }
      const {
        isUserAdmin,
        isUserAppAdmin,
        currentUser: { email },
      } = props;
      const { id, isDeletable, parentDataset, creator } = rowData;
      const userCanEdit = isUserAppAdmin || isUserAdmin || creator === email;
      const isEditable = Util.isDatasetEditable(rowData) && userCanEdit;
      const _editMenuItemDisabled = !isEditable;
      const _isEditDatasetSettingsDisabled =
        _editMenuItemDisabled || !_.isNil(parentDataset);
      const _isDatasetRefreshDisabled = !_.isNil(parentDataset);
      const _isDatasetDeleteDisabled = !isDeletable;
      if (_isDatasetRefreshDisabled && !isEditable && !isDeletable) {
        return <div />;
      }
      return (
        <HorizontalCellInteractive>
          <IconDropdown
            id={`actions-dropdown-${_.kebabCase(id)}`}
            onOpen={() => {
              props.setActionsOpen(true);
            }}
            onClose={() => {
              props.setActionsOpen(false);
            }}
            IconComponent={DownChevron}
          >
            <MenuItem
              disabled={_editMenuItemDisabled}
              onClick={() => {
                if (_editMenuItemDisabled) {
                  return;
                }
                props.setEditDialog({
                  dataset: rowData,
                  editKey: 'name',
                });
              }}
            >
              {messages.datasets.rename}
            </MenuItem>
            <MenuItem
              disabled={_editMenuItemDisabled}
              onClick={() => {
                if (_editMenuItemDisabled) {
                  return;
                }
                props.setEditDialog({
                  dataset: rowData,
                  editKey: 'description',
                });
              }}
            >
              {messages.datasets.editDescription}
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={_editMenuItemDisabled}
              onClick={() => {
                if (_editMenuItemDisabled) {
                  return;
                }
                props.editDataset(id, isEditable);
              }}
            >
              {messages.datasets.fieldConfiguration}
            </MenuItem>
            <MenuItem
              disabled={_isEditDatasetSettingsDisabled}
              onClick={() => {
                if (_isEditDatasetSettingsDisabled) {
                  return;
                }
                props.openDatasetSettings(id);
              }}
            >
              <span title={messages.datasets.datasetSettings}>
                {messages.datasets.datasetSettings}
              </span>
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={_isDatasetDeleteDisabled}
              onClick={e => {
                if (_isDatasetDeleteDisabled) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                props.showDeleteDatasetPrompt(rowData);
              }}
            >
              {messages.datasets.deleteDatasetMenuItem}
            </MenuItem>
          </IconDropdown>
        </HorizontalCellInteractive>
      );
    },

  idFormat:
    props =>
    ({ rowData }) => {
      if (props.listLoading) {
        return <TableSkeleton />;
      }

      const { id } = rowData;
      return (
        <HorizontalCell>
          <ClickToCopy text={id} />
        </HorizontalCell>
      );
    },
});

const DatasetListPanelFunc = props => {
  const {
    nameFormat,
    creatorNameFormat,
    actionsFormat,
    idFormat,
    listLoading,
  } = props;

  const showDeleteDialog =
    props.showDelete &&
    props.linkedContentForDatasetId === props.datasetToDelete.id;

  const ingestStateFormatter = ({ rowData }) => {
    if (listLoading) {
      return <TableSkeleton />;
    }
    const { datasetStatusType } = rowData;
    return (
      <HorizontalCellInteractive>
        <DatasetStatusIcon statusType={datasetStatusType} />
      </HorizontalCellInteractive>
    );
  };

  const searchOptions = {
    keys: ['name', 'creatorName', 'description'],
  };

  return [
    <TableToolBar key='dataset-toolbar'>
      <TextSearchField
        placeholder={messages.datasets.searchPlaceholder}
        value={props.search}
        onChange={props.onSearch}
        large
      />
    </TableToolBar>,
    <UITable
      key='dataset-edit-table'
      data={props.list}
      domain='datasets'
      search={props.search}
      searchOptions={searchOptions}
      onRowClick={props.onRowClick}
      loading={listLoading}
    >
      {({ Column, HeaderRendererWithSort }) => {
        return [
          <Column
            label={messages.table.nameLabel}
            key='name'
            dataKey='name'
            width={440}
            maxWidth={500}
            flexGrow={1}
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={nameFormat}
          />,
          <Column
            label={messages.table.creatorNameLabel}
            key='creatorName'
            dataKey='creatorName'
            width={212}
            maxWidth={272}
            flexGrow={1}
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={creatorNameFormat}
          />,
          <Column
            label={messages.datasets.ingestStatusLabel}
            key='datasetStatusType'
            dataKey='datasetStatusType'
            width={216}
            maxWidth={272}
            flexGrow={1}
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={ingestStateFormatter}
          />,
          <Column
            label=''
            key='actions'
            dataKey='actions'
            width={78}
            flexGrow={1}
            cellRenderer={actionsFormat}
            disableSort
          />,
          props.advanced && (
            <Column
              label=''
              key='id'
              dataKey='id'
              width={304}
              cellRenderer={idFormat}
              disableSort
            />
          ),
        ];
      }}
    </UITable>,
    <PromptDialog
      key='delete-dataset-dialog'
      show={showDeleteDialog}
      title={messages.datasets.deleteDatasetDialogTitle}
      detail={props.getDeleteDetail()}
      doYes={() => props.doDeleteYes()}
      doNo={() => props.doDeleteNo()}
    />,
  ];
};

DatasetListPanelFunc.defaultProps = {
  onRowClick: _.noop,
};

const DatasetListPanel = compose(
  withState('selectRowOnRowSelect', 'setSelectRowOnRowSelect', false),
  withState('datasetToDelete', 'setDatasetToDelete'),
  withState('showDelete', 'setShowDelete', false),
  withState('actionsOpen', 'setActionsOpen', false),
  withProps(({ currentUser }) => ({
    isUserAdmin: isAdmin({ currentUser }),
    isUserAppAdmin: isAppAdmin({ currentUser }),
  })),
  ButtonActions,
  DataListPanelHandlers,
)(DatasetListPanelFunc);

export { DatasetListPanel };

const DatasetsFunc = props => {
  let showCreateDatasetModal = false;
  if (props.datasetPage !== 'datasetList') {
    showCreateDatasetModal = true;
  }

  if (!_.isNil(props.datasetAddedId)) {
    props.handleNewSugarCRMDataset(props.datasetAddedId);
  }

  return (
    <Root className='Datasets'>
      <MainSectionHeader
        headerLabel={messages.nav.datasets}
        actionButtons={[
          props.allowDatasetCreate && (
            <CreateDatasetButton key={'create-dataset'} />
          ),
        ].filter(Boolean)}
      />
      <DatasetListPanel
        key='datasetList'
        setDatasetId={datasetId => props.setDatasetId(datasetId)}
        setDatasetPage={datasetPage => props.setDatasetPage(datasetPage)}
        deleteDataset={props.deleteDataset}
        refreshSugarCRMDataset={datasetId =>
          props.refreshSugarCRMDataset(datasetId)
        }
        list={props.list}
        setDatasetAdded={props.setDatasetAdded}
        getContentForDataset={props.getContentForDataset}
        openDatasetSettings={props.openDatasetSettings}
        setEditDialog={props.setEditDialog}
        closeDatasetSettings={props.closeDatasetSettings}
        linkedContent={props.linkedContent}
        linkedContentForDatasetId={props.linkedContentForDatasetId}
        advanced={props.advanced}
        currentUser={props.currentUser}
        tableHeight={props.props.tableHeight}
        newVisualization={dataset => props.newVisualization(dataset)}
        setShowDatasetEditDialog={(datasetId, editable) =>
          props.setShowDatasetEditDialog(datasetId, editable)
        }
        search={props.search}
        setSearch={search => props.setSearch(search)}
        listLoading={props.listLoading}
      />
      {showCreateDatasetModal && (
        <CreateDatasetModal
          doCancel={() => props.setDatasetPage('datasetList')}
        />
      )}
      <FieldConfiguration />
      {props?.datasetId && (
        <DatasetSettings
          onClose={() => props.closeDatasetSettings(props.datasetId)}
          primaryDataset={getPrimarySugarCRMDataset(props.list)}
          dataset={_.find(props.list, { id: props.datasetId })}
        />
      )}
      {!!props.hasEditDialog && (
        <EditDiscoveryDialog
          detail={props.hasEditDialog.editKey}
          value={props.hasEditDialog.dataset[props.hasEditDialog.editKey]}
          onOk={textValue => {
            const { id, name, description, attributes, annotations } =
              props.hasEditDialog.dataset;

            const updateDatasetRequest = {
              id,
              name,
              description,
              attributes,
              annotations,
            };
            updateDatasetRequest[props.hasEditDialog.editKey] = textValue;

            props.updateDataset(updateDatasetRequest, () =>
              props.setEditDialog(false),
            );
          }}
          onCancel={() => props.setEditDialog(false)}
        />
      )}
    </Root>
  );
};

const Datasets = compose(
  DatasetHOC,
  withState('datasetId', 'setDatasetId', 'NONE'),
  withState('hasEditDialog', 'setEditDialog', null), // dataset, editKey
  withHandlers({
    setDatasetPage: props => datasetPage => {
      props.setDatasetPage(datasetPage);
    },
    handleNewSugarCRMDataset: props => _datasetId => {
      props.setDatasetAdded(null); // remove reference to newly created dataset
      props.setDatasetId(_datasetId, () =>
        props.openDatasetSettings(_datasetId, true),
      );
    },
  }),
)(DatasetsFunc);

const mapStateToProps = function (state, props) {
  const {
    dataset: {
      datasetPage,
      datasetAddedId,
      linkedContent,
      linkedContentForDatasetId,
    },
    main: { advanced },
    account: { currentUser },
    discover: { openDiscoveries },
  } = state;

  return {
    datasetPage,
    datasetAddedId,
    linkedContent,
    linkedContentForDatasetId,
    advanced,
    currentUser,
    search: TABLE_SELECTORS.getSearch(state, props),
    openDiscoveries,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setDatasetPage: datasetPage => {
      dispatch(Dataset.setDatasetPage(datasetPage));
    },
    refreshSugarCRMDataset: datasetId => {
      dispatch(Dataset.refreshSugarCRMDataset(datasetId));
    },
    setDatasetAdded: (datasetId, callback) => {
      dispatch(Dataset.setDatasetAdded(datasetId));

      if (_.isFunction(callback)) {
        _.attempt(callback);
      }
    },
    getContentForDataset: datasetId => {
      dispatch(Dataset.getContentForDataset(datasetId));
    },
    newVisualization: dataset => {
      dispatch(Discover.newVisualization({ dataset }));
    },
    setShowDatasetEditDialog: (datasetId, editable) => {
      dispatch(Dataset.editDataset(datasetId, editable));
    },
    setSearch: search => {
      dispatch(Main.setSearch(ownProps.domain, search));
    },
    openDatasetSettings(datasetId, isFinalizingAddDataset = false) {
      dispatch(
        Discover.openDatasetSettings(datasetId, true, isFinalizingAddDataset),
      );
    },
    closeDatasetSettings(datasetId) {
      dispatch(Discover.openDatasetSettings(datasetId, false));
    },
    updateDatasetInViz: (vizId, dataset) => {
      dispatch(Discover.updateDatasetForDiscovery(vizId, dataset));
    },
  };
};

const DatasetQuery = graphql(DatasetQueries.DatasetQuery, {
  options: () => {
    return { pollInterval: POLL_FREQUENCY };
  },
  props: store => {
    const datasets = [...(store?.data?.datasets ?? [])];

    // only poll for updates if the datasets list is showing
    let currentUrl = 'none';
    if (
      !_.isNil(store.ownProps.props) &&
      !_.isNil(store.ownProps.props.match)
    ) {
      currentUrl = store.ownProps.props.match.url;
    }
    if (currentUrl === ROUTER_DIRS.DATASETS) {
      store.data.startPolling(POLL_FREQUENCY);
    } else {
      store.data.stopPolling();
    }

    return {
      list: datasets,
      listLoading: store.data.loading,
    };
  },
});

const DeleteDatasetMutation = graphql(DatasetQueries.DeleteDatasetMutation, {
  options: {
    // Update Datasets query, we delete opportunistically but this makes sure the local state is consistent
    refetchQueries: [
      { query: DatasetQueries.DatasetQuery },
      { query: DiscoverQueries.DiscoveriesQuery },
    ],
    // Manually remove item from cache
    update: (store, { data: { deleteDataset } }) => {
      store.modify({
        fields: {
          datasets(preList, { readField }) {
            return preList.filter(d => readField('id', d) !== deleteDataset.id);
          },
        },
      });
    },
  },
  props: ({ mutate }) => ({
    deleteDataset(datasetId) {
      return mutate({
        variables: {
          datasetId,
        },
      });
    },
  }),
});

const UpdateDatasetMutation = graphql(DatasetQueries.UpdateDataset, {
  options: props => ({
    refetchQueries: [
      {
        query: DatasetQueries.DatasetDetail,
        variables: { id: props.datasetId },
      },
      {
        query: DatasetQueries.DatasetQuery,
      },
    ],
  }),
  props: ({ ownProps }) => ({
    // Delete dataset attributes, and then update
    updateDataset(dataset, cb) {
      const cbFn = _.once(cb);
      client
        .mutate({
          mutation: DatasetQueries.UpdateDataset,
          variables: {
            dataset: Util.removeTypeNameRecursively(dataset),
            removeAttributeIdxs: [],
          },
        })
        .then(({ data }) => {
          const vizByPresentDatasetId = _.groupBy(
            _.values(ownProps.openDiscoveries),
            'present.datasetId',
          );
          const affectedDiscoveries = _.get(vizByPresentDatasetId, dataset.id);
          // Update open discoveries using this dataset
          if (!_.isEmpty(affectedDiscoveries)) {
            _.forEach(affectedDiscoveries, open => {
              console.log(
                `FORCE UPDATE OF VIZ: ${open.present.name}`,
                data.updateDataset,
              );
              const id = open.present.viz.id || open.present.id;
              ownProps.updateDatasetInViz(id, data.updateDataset);
            });
          }
          cbFn();
        })
        .catch(error => {
          console.log('Error updating dataset', error);
          cbFn(false);
          ownProps.showError();
        });
    },
  }),
});

const ComposedDatasets = compose(
  TableUtils,
  withProps(() => {
    return {
      domain: 'datasets',
    };
  }),
  connect(mapStateToProps),
  connect(SectionLayout.mapStateToProps, mapDispatchToProps),
  SectionLayout('datasets', 'Datasets', [DatasetQueries.DatasetQuery]),
  pure,
  UpdateDatasetMutation,
  DeleteDatasetMutation,
  DatasetQuery,
  pure,
)(Datasets);

class DatasetResizeWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ComposedDatasets {...this.props} />;
  }
}

export default withErrorBoundary(
  handlesResize(DatasetResizeWrapper),
  ErrorBoundaryHOCOptions,
);
