import _ from 'lodash';
import BarChartUtils from './BarChartUtils';
import { Viz } from '../VizUtil';
import ChartUtils, {
  createOrdinalName,
  join as joinChartData,
} from './ChartUtils';
import {
  DATA_FORMATTER,
  EMPTY_STRING_TOKEN,
  FIELD_ORDINAL_SUFFIX,
  NULL_DISPLAY,
  SortDirection,
  VIZ_OPTION_IDS,
} from '../../common/Constants';
import { SortFunctionManager } from '../../common/redux/selectors/viz-selectors';
import { messages } from '../../i18n';

const StackChartUtils = {
  findShelfByFieldName: (columnInfo, layout) => {
    for (const [shelf] of _.toPairs(layout)) {
      if (layout[shelf]) {
        const found = layout[shelf].find(
          f => f.name === columnInfo.attributeName,
        );
        if (found) {
          return shelf;
        }
      }
    }
  },

  /**
   *    SHELFS :
   *    XAXIS = Year_6
   *    STACK = Territory_4
   *    VALUES = Sales (m0)
   *
   *    Query results that look like this...
   *    {
   *      "data": {
   *        "executeQuery": {
   *          "columnNames": ["Territory_4", "Year_6", "m0"],
   *          "results": [
   *            ["East", 2016, 150000],
   *            ["East", 2017, 320000],
   *            ["North", 2016, 111000],
   *            ["North", 2017, 240000],
   *            ["South", 2016, 189000],
   *            ["South", 2017, 400000],
   *            ["West", 2016, 228000],
   *            ["West", 2017, 416000]
   *          ],
   *        }
   *      }
   *    }
   *
   *    should come out like this...
   *    [
   *      {
   *        XAXIS: 2016,
   *        VALUES: [
   *          {
   *            Territory_4: 'East',
   *            Sales:  150000,
   *            bottom: 0,
   *            top:    150000
   *          },
   *          {
   *            Territory_4: 'North',
   *            Sales:  111000,
   *            bottom: 150000,
   *            top:    161000
   *          },
   *          {
   *            Territory_4: 'South',
   *            Sales:  189000,
   *            bottom: 161000,
   *            top:    350000,
   *          },
   *          {
   *            Territory_4: 'West',
   *            Sales:  228000,
   *            bottom: 350000,
   *            top:    578000
   *          }
   *        ]
   *      },
   *      {
   *        XAXIS: 2017,
   *        VALUES: [
   *          {
   *            Territory_4: 'East',
   *            Sales:  320000,
   *            bottom: 0,
   *            top:    320000
   *          },
   *          {
   *            Territory_4: 'North',
   *            Sales:  240000,
   *            bottom: 320000,
   *            top:    560000
   *          },
   *          {
   *            Territory_4: 'South',
   *            Sales:  400000,
   *            bottom: 560000,
   *            top:    960000
   *          },
   *          {
   *            Territory_4: 'West',
   *            Sales:  416000,
   *            bottom: 960000,
   *            top:    1376000
   *          }
   *        ]
   *      }
   *    ]
   *
   *    Then, we can use the d3.stack capabilities to create a dataset that is easier to use with a stack chart:
   *
   *
   * @param queryResults
   * @param layout
   * @param valueShelfName
   * @param reverseStackOrder - Stack column charts should read top down, meaning the order should be reversed. Stacked Bar should not
   * @returns {*}
   */

  transformResult: (
    queryResults,
    viz,
    customFormatToggles = [],
    valueShelfName = 'VALUES',
    reverseStackOrder = true,
    valueMapping = {},
    i18nPrefs = {},
  ) => {
    // what are the xaxis field indexes in the results?
    const layout = _.omit(viz?.layout ?? {}, 'SLICER');
    const vizFilters = Viz.getFiltersFromViz(viz);
    const vizCalcs = Viz.getCalcsFromViz(viz);
    const fieldIndexMapping = {
      XAXIS: [],
      VALUES: [],
      STACK: [],
    };

    const querySort = JSON.parse(
      _.get(viz, `options.${VIZ_OPTION_IDS.querySort}`, '{}'),
    );
    const xAxisQuerySorts = _.pickBy(querySort, ({ fieldName }) =>
      _.includes(_.map(layout.XAXIS, 'name'), fieldName),
    );

    const { columnInfo, results } = queryResults.executeQuery;

    const dataFormatters = Viz.getDataFormatters(viz);
    const customDataFormatters = Viz.getDataCustomFormatters(viz);

    const percentageToggle = customFormatToggles.find(
      t => t.key === 'asPercentage',
    );
    const isPercentage = _.isNil(percentageToggle)
      ? false
      : percentageToggle.on;

    const fieldLookup = testColumnInfo => {
      const shelf = StackChartUtils.findShelfByFieldName(
        testColumnInfo,
        layout,
      );
      return _.find(layout[shelf], { name: testColumnInfo.attributeName });
    };

    const formatValue = (testColumnInfo, value, small = false) => {
      const shelf = StackChartUtils.findShelfByFieldName(
        testColumnInfo,
        layout,
      );
      const field = _.find(layout[shelf], {
        name: testColumnInfo.attributeName,
      });

      return ChartUtils.formatValue(
        dataFormatters,
        customDataFormatters,
        i18nPrefs,
        field.name,
        value,
        small,
      );
    };

    columnInfo.forEach((testColumnInfo, idx) => {
      let info = testColumnInfo;
      if (!_.isNil(valueMapping[testColumnInfo.attributeName])) {
        info = { attributeName: valueMapping[testColumnInfo.attributeName] };
      }
      const shelf = StackChartUtils.findShelfByFieldName(info, layout);
      if (!_.isNil(fieldIndexMapping[shelf])) {
        let ordinalAttrName = columnInfo[idx]?.attributeName;
        const layoutColumnInfo =
          _.find(layout[shelf], { name: ordinalAttrName }) ?? {};
        if (!_.isEmpty(layoutColumnInfo?.ordinalAttribute)) {
          ordinalAttrName = layoutColumnInfo.ordinalAttribute;
        } else if (!_.includes(ordinalAttrName, FIELD_ORDINAL_SUFFIX)) {
          ordinalAttrName = createOrdinalName(ordinalAttrName);
        }
        const ordinalColumnIdx = _.findIndex(columnInfo, {
          attributeName: ordinalAttrName,
        });
        const ordinalIdx = ordinalColumnIdx > -1 ? ordinalColumnIdx : idx;

        fieldIndexMapping[shelf].push({
          idx,
          ordinalIdx,
          fieldName: info.attributeName,
        });
      }
    });
    reverseStackOrder && _.reverse(fieldIndexMapping.VALUES);

    const getKey = val => {
      // Append string character to prevent ES6 property key order from sorting alphanumerically
      // This preserves XAXIS field ordering
      // TO-DO: update uniqueXaxisValues to use more reliable data structure (i.e. Map)
      return `${val} `;
    };

    // find all unique values on the x axis
    const uniqueXaxisValues = results.reduce((unique, current) => {
      const combo = {};
      fieldIndexMapping.XAXIS.forEach(({ idx, ordinalIdx, fieldName }) => {
        let xAxisValue = current[idx];
        xAxisValue = formatValue(columnInfo[idx], xAxisValue, true);
        combo[getKey(current[idx])] = {
          value: xAxisValue,
          type: layout.XAXIS[idx].attributeType,
          sortVal: current[ordinalIdx],
          sortDirection: xAxisQuerySorts[fieldName]?.direction ?? 'asc',
          sortPriority: xAxisQuerySorts[fieldName]?.priority ?? 10,
          fieldName,
        };
      });
      const exists = unique.findIndex(e => {
        return _.isEqual(e, combo);
      });
      if (exists === -1) {
        unique.push(combo);
      }
      return unique;
    }, []);

    const getSortedProperty = (_xAxisValue, property) => {
      const sortedValues = _.sortBy(_.values(_xAxisValue), 'sortPriority');
      return _.map(sortedValues, property);
    };

    const sortDirections = getSortedProperty(
      _.head(uniqueXaxisValues),
      'sortDirection',
    );

    const xValAndStacks = _.map(uniqueXaxisValues, xVal => ({
      val: xVal,
      stacks: results.filter(r => {
        let match = true;
        fieldIndexMapping.XAXIS.forEach(({ idx }) => {
          match = match && !_.isUndefined(xVal[getKey(r[idx])]);
        });
        return match;
      }),
    }));

    const sortXAxisAsStacks = () => {
      const highestQuerySort = _.last(
        _.orderBy(_.values(querySort), 'priority'),
      );

      const maybeValuesField = _.find(layout?.VALUES, {
        name: highestQuerySort?.fieldName,
      });

      if (maybeValuesField) {
        const { idx: columnInfoIdx } = _.find(fieldIndexMapping?.VALUES, {
          fieldName: maybeValuesField?.name,
        });

        const orderedXValAndStacks = _.map(
          _.orderBy(
            _.map(xValAndStacks, xValAndStack => {
              const { val, stacks } = xValAndStack;
              const orderVal = _.reduce(
                stacks,
                (acc, resultRow) => acc + resultRow[columnInfoIdx],
                0,
              );
              return {
                val,
                stacks,
                orderVal,
              };
            }),
            'orderVal',
          ),
          xValAndStack => _.omit(xValAndStack, ['orderVal']),
        );

        if (
          querySort[maybeValuesField?.name]?.direction ===
          SortDirection.DESCENDING
        ) {
          return _.reverse(orderedXValAndStacks);
        }

        return orderedXValAndStacks;
      } else {
        return _.orderBy(
          xValAndStacks,
          ({ val }) => getSortedProperty(val, 'sortVal'),
          sortDirections,
        );
      }
    };

    const xAxisValuesSorted =
      viz?.chartType === 'funnel'
        ? xValAndStacks
        : sortXAxisAsStacks(xValAndStacks);

    const sortOrdinals = StackChartUtils.extractOrdinals(queryResults, layout);
    // for each one, get all of its values to stack
    const stackData = _.map(
      xAxisValuesSorted,
      ({ val: xVal, stacks: xStackData }) => {
        // filter the results to only the ones that are for this xaxis value
        let floor = 0;
        let subFloor = 0;
        const orderedResults = reverseStackOrder
          ? xStackData.reverse()
          : xStackData;
        const fullStackTooltipInfo = {};
        const unformattedTooltipInfo = {};
        const vals = orderedResults.reduce((stacks, d) => {
          fieldIndexMapping.VALUES.forEach(({ idx: valueIndex }) => {
            let stackName = joinChartData(
              fieldIndexMapping.STACK.map(({ idx }) => d[idx]),
            );
            const stackSort = joinChartData(
              fieldIndexMapping.STACK.map(({ ordinalIdx }) => d[ordinalIdx]),
            );
            // in the case of the funnel, this is supposed to take the mapped "window_Opportunity ID" and return
            // the "Opportunity ID" attribute,
            // need something else to make this magic happen now that we don't have m0, m1, etc
            // maybe send in an attribute Name map from the funnel to get thing aligned?
            let valCol = BarChartUtils.getValueField(
              columnInfo[valueIndex].attributeName,
              layout,
              valueShelfName,
            );
            if (_.isNil(valCol)) {
              valCol = BarChartUtils.getValueField(
                valueMapping[columnInfo[valueIndex].attributeName],
                layout,
                valueShelfName,
              );
            }
            const valColName = valCol.name;
            const stackValue =
              d[valueIndex] === NULL_DISPLAY ? 0 : d[valueIndex];
            const tooltipInfo = {};
            const drillContext = {
              filters: vizFilters,
              calcs: vizCalcs,
              attributes: [],
              metrics: [...layout[valueShelfName]],
              linkToReport: JSON.parse(
                _.get(viz, `options.${VIZ_OPTION_IDS.linkToReport}`, '{}'),
              ),
            };

            fieldIndexMapping.STACK.forEach(({ idx: stackIndex }, idx) => {
              const val = formatValue(columnInfo[stackIndex], d[stackIndex]);
              tooltipInfo[columnInfo[stackIndex].attributeName] = val;
              drillContext.attributes.push({
                attribute: fieldLookup(columnInfo[stackIndex]),
                value: ChartUtils.valueLookup(
                  layout.STACK[idx],
                  d,
                  columnInfo,
                  stackIndex,
                ),
              });
            });

            fieldIndexMapping.XAXIS.forEach(({ idx: xIndex }, idx) => {
              const val = formatValue(columnInfo[xIndex], d[xIndex], true);
              tooltipInfo[columnInfo[xIndex].attributeName] = val;
              fullStackTooltipInfo[columnInfo[xIndex].attributeName] = val;
              unformattedTooltipInfo[columnInfo[xIndex].attributeName] =
                d[xIndex];
              drillContext.attributes.push({
                attribute: fieldLookup(columnInfo[xIndex]),
                value: ChartUtils.valueLookup(
                  layout.XAXIS[idx],
                  d,
                  columnInfo,
                  xIndex,
                ),
              });
            });

            tooltipInfo[valColName] = formatValue(
              { attributeName: valColName },
              stackValue,
            );
            if (_.isEmpty(fieldIndexMapping.STACK)) {
              fullStackTooltipInfo[valColName] = formatValue(
                { attributeName: valColName },
                stackValue,
              );
            }
            /**
             * This code was altered to resolve Jira case DSC-3423, where chart
             * colors did not match legend colors. It seems that stacked bar
             * and funnel charts are the most vulnerable to this, so you probably
             * want to at least check any code changes in here against both of
             * those chart types.
             *      --Rob Lee
             *      This continues to happen. Colors get reversed - Ryan
             */
            if (fieldIndexMapping.VALUES.length > 0) {
              stackName = _.isEmpty(stackName)
                ? valColName
                : fieldIndexMapping.VALUES.length > 1
                  ? joinChartData([stackName, valColName])
                  : stackName;
            } else if (_.isNil(stackName)) {
              stackName = valColName;
            } else if (stackName === '') {
              stackName = EMPTY_STRING_TOKEN;
            }
            let bottom;
            let top;
            if (stackValue >= 0) {
              bottom = floor;
              top = floor + stackValue;
              floor = top;
            } else {
              top = subFloor;
              bottom = subFloor + stackValue;
              subFloor = bottom;
            }
            const stack = {
              stackName,
              sort: stackSort,
              [valColName]: stackValue,
              bottom,
              top,
              tooltipInfo: { ...tooltipInfo },
              drillContext,
              formatter: dataFormatters[valColName],
              valueColName: valColName,
            };
            stacks.push(stack);
          });
          return stacks;
        }, []);
        const xaxisValue = joinChartData(_.values(xVal));
        return {
          XAXIS: xaxisValue,
          VALUES: [...vals],
          ordinals: sortOrdinals,
          fullStackTooltipInfo,
          unformattedTooltipInfo,
        };
      },
    );

    // Add the totals of the stack (positive and negative) to the tooltip
    if (fieldIndexMapping.STACK.length > 0) {
      stackData.forEach(data => {
        const totals = StackChartUtils.getStackTotals(data.VALUES);
        data.VALUES.forEach(v => {
          // Add percent info to tooltip
          if (isPercentage) {
            const percentage = StackChartUtils.getStackPercentage(
              v[v.valueColName],
              totals,
            );
            const percentFormatter = DATA_FORMATTER.PERCENT;
            v.tooltipInfo[v.valueColName] += ` (${percentFormatter.format(
              percentage,
              i18nPrefs,
            )})`;
          }
          if (totals.positive) {
            v.tooltipInfo[messages.stackBar.total] = v.formatter.format(
              totals.positive,
              i18nPrefs,
              customDataFormatters[v.valueColName],
            );
          }
          if (totals.negative) {
            v.tooltipInfo[messages.stackBar.negativeTotal] = v.formatter.format(
              totals.negative,
              i18nPrefs,
              customDataFormatters[v.valueColName],
            );
          }
        });
        if (totals.positive) {
          data.fullStackTooltipInfo[messages.stackBar.total] =
            data.VALUES[0].formatter.format(
              totals.positive,
              i18nPrefs,
              customDataFormatters[data.VALUES[0].valueColName],
            );
        }
        if (totals.negative) {
          data.fullStackTooltipInfo[messages.stackBar.negativeTotal] =
            data.VALUES[0].formatter.format(
              totals.negative,
              i18nPrefs,
              customDataFormatters[data.VALUES[0].valueColName],
            );
        }
      });
    }
    return stackData;
  },

  extractOrdinals: (queryResults, layout) => {
    if (!layout.STACK) {
      return;
    }
    // find the names of the ordinal attributes in the query results that are NOT present in the shelves
    const ordinalAttributeMap = layout.STACK.filter(
      attr => !_.isEmpty(attr.ordinalAttribute),
    ).map(attrWithOrdinal => {
      const ordinalName = attrWithOrdinal.ordinalAttribute;
      const attrName = attrWithOrdinal.name;
      const idx = queryResults.executeQuery.columnNames.indexOf(ordinalName);
      const attrIdx = queryResults.executeQuery.columnNames.indexOf(attrName);
      return {
        ordinalIndex: idx,
        ordinalName,
        attributeName: attrName,
        attributeIndex: attrIdx,
      };
    });
    // iterate over the data, look for ordinal columns data.
    const ordinalValues = queryResults.executeQuery.results.reduce(
      (ordinals, row) => {
        // get any data for the ordinals
        ordinalAttributeMap.forEach(ordinalInfo => {
          const ordinalValue = row[ordinalInfo.ordinalIndex];
          const dataValue = row[ordinalInfo.attributeIndex];
          const values = _.get(ordinals, ordinalInfo.attributeName, []);
          const exists = values.find(x => {
            return x.index === ordinalValue && x.value === dataValue;
          });
          if (!exists) {
            let oVal = ordinalValue;
            // is the ordinal null?
            if (ordinalValue === NULL_DISPLAY) {
              // create an ordinal for it, but make sure its at the end
              oVal = values.length + 1000;
            }
            values.push({ index: oVal, value: dataValue });
          }
          ordinals[ordinalInfo.attributeName] = values;
        });
        return ordinals;
      },
      {},
    );

    const ordinalMap = _.mapValues(ordinalValues, ordinals =>
      _.sortBy(ordinals, ['index']),
    );
    return ordinalMap;
  },

  getX: d => {
    return d.XAXIS;
  },
  getX0: d => {
    return d.XAXIS;
  },
  getY: d => {
    const { bottom } = d.VALUES[d.VALUES.length - 1];
    const { top } = d.VALUES[d.VALUES.length - 1];
    return top - Math.abs(bottom);
  },
  getStack: d => {
    if (_.isNil(d)) {
      return '';
    } else if (_.isArray(d)) {
      return d.map(stack => stack.stackName);
    } else {
      return d.stackName;
    }
  },
  getStackTotals(stackData) {
    return stackData.reduce(
      (totals, v) => {
        if (v.bottom < 0) {
          totals.negative -= v.top - v.bottom;
        } else {
          totals.positive += v.top - v.bottom;
        }
        return totals;
      },
      { positive: 0, negative: 0 },
    );
  },
  getStackPercentage(value, stackTotals) {
    const total =
      Math.abs(stackTotals.negative) + Math.abs(stackTotals.positive);
    if (total === 0) {
      // handle special case where taking percentage of 0
      return 0;
    } else {
      return value / total;
    }
  },
  getUniqueStackNames({ layout, queryResults, reverse = false }) {
    const { executeQuery } = queryResults;
    const { columnNames } = executeQuery;
    const { results } = executeQuery;

    const stackUserSorts = executeQuery.querySort;

    let metricNames = layout.VALUES.map(d => d.name);
    if (reverse) {
      metricNames = metricNames.reverse();
    }
    if (!layout.STACK || layout.STACK.length <= 0) {
      return metricNames;
    }

    const layoutStack = reverse
      ? layout.STACK.slice(0)?.reverse()
      : layout.STACK.slice(0);
    const stackCalcSorts = layoutStack.reduce((acc, item, index) => {
      let ordinal = columnNames.indexOf(item.ordinalAttribute);
      if (ordinal < 0) {
        ordinal = columnNames.indexOf(item.name);
      }
      const obj = {
        direction: 'asc',
        fieldName: item.name,
        index: ordinal,
        priority: -(index + 1),
        shelfName: 'stackBar.stackShelf',
      };
      acc[item.name] = obj;
      return acc;
    }, {});

    const stackSorts = _.values(_.assign({}, stackCalcSorts, stackUserSorts))
      .filter(s => s.shelfName === 'stackBar.stackShelf')
      .map(item => {
        return {
          ...item,
          priority: item.priority * -1,
        };
      });

    const sortManager = new SortFunctionManager();
    stackSorts.map(sort => {
      const fun = item => {
        const ordinalIdx = _.indexOf(
          columnNames,
          createOrdinalName(sort.fieldName),
        );
        let sortVal = item[ordinalIdx === -1 ? sort.index : ordinalIdx];
        sortVal = _.includes(['-', '', null, undefined], sortVal)
          ? Number.NEGATIVE_INFINITY
          : sortVal;
        return sortVal;
      };
      sortManager.addFunc(fun, sort.direction, sort.priority);
    });

    const sortedData = _.isEmpty(stackSorts)
      ? results
      : sortManager.order(results);

    const stackNames = layout.STACK.map(item => {
      return item.name;
    });
    const stackIndexes = stackNames.map(name => {
      return columnNames.indexOf(name);
    });

    const unique = sortedData.reduce((data, item) => {
      const parts = stackIndexes.map(i => {
        return item[i];
      });
      if (metricNames.length > 1) {
        // get stack name associated with each metric
        metricNames.forEach(m => {
          const name = joinChartData([...parts, m]);
          if (!data.has(name)) {
            data.add(name);
          }
        });
      } else {
        const name = joinChartData(parts);
        if (!data.has(name)) {
          data.add(name);
        }
      }
      return data;
    }, new Set());

    return unique;
  },
};

export default StackChartUtils;
