import { isStandalone } from '../../Util';
import Const from './ActionConst';
import { AccountQueries, DatasetQueries } from '../../graphql';
import { isDashletMode } from '../../../auth/dashlet.util';
import URLs from '../../Urls';
import _ from 'lodash';
import { messages } from '../../../i18n';
import { IDM_PATH_HINT, ROUTER_DIRS, setDatetimeFormat } from '../../Constants';
import { currentUserSelector, isNoAccess } from '../selectors/AccountSelectors';
import { handleNewAccessToken } from '../../../auth/auth.utils';
import { getClient } from '../../ApolloClient.common';
import { getConfig } from '../../global-discover-config';
import { PendoClient } from '../../PendoClient';

export const unAuthedLogout = () => {
  return dispatch => {
    dispatch(setShowLoginDialog());
  };
};

export const hasValidAuth = ({ success, error }) => {
  const { IDM_TOKEN } = getConfig();
  if (!_.isEmpty(IDM_TOKEN) || isDashletMode()) {
    success();
  } else {
    error('Invalid authentication');
  }
};

const applicationInitialization = user => {
  return async dispatch => {
    const aggregateInfoList = await getAggregateInfo(user);
    dispatch({
      type: Const.Main.SET_AGGREGATE_INFO,
      list: aggregateInfoList,
    });
  };
};

const setCurrentUser = currentUser => {
  return { type: Const.Account.SET_CURRENT_USER, currentUser };
};

const shouldLogoutOtherSessions = shouldLogout => {
  return {
    type: Const.Account.SHOULD_LOGOUT_OTHER_SESSIONS,
    shouldLogoutOtherSessions: shouldLogout,
  };
};

const login = () => {
  return function (dispatch, getState) {
    getClient()
      .query({ query: AccountQueries.CurrentUserQuery })
      .then(res => {
        const user = currentUserSelector(res?.data);
        const { dateFormat, timeFormat } = user?.i18nPrefs ?? {};
        setDatetimeFormat({ dateFormat, timeFormat });
        if (!_.isEmpty(user.locale)) {
          messages.setLanguage(user.locale);
        }
        dispatch(setCurrentUser(user));
        dispatch({ type: Const.Login.LOGIN });
        dispatch(applicationInitialization(user));

        const {
          account: { shouldLogoutOtherSessions },
          dashlet: { isDashletMode },
        } = getState();
        if (shouldLogoutOtherSessions) {
          dispatch(logoutOtherSessions()).then(
            dispatch(shouldLogoutOtherSessions(false)),
          );
        }

        const {
          storage: { pathname },
        } = getState();
        if (isStandalone() && pathname) {
          URLs.goTo(pathname);
        } else {
          URLs.redirectAfterLogin();
        }
        PendoClient.initialize(user, isDashletMode);
      })
      .catch(err => {
        console.log(`Error retrieving current user:${err}`, err.stack);
      });
  };
};

const setShowLoginDialog = () => {
  return (dispatch, getState) => {
    const {
      dashlet: { isDashletReportMode = false },
      main: { tenantId, tenantIdmId },
    } = getState();
    let loginUrl = ROUTER_DIRS.LOGIN;
    /**
     * If the user provides a tenant ID manually via a query parameter, we
     * assume that we are running in IDM mode. In this case, we support using
     * the friendly discover name as the tenant ID (ex: slapshot), or the full
     * SRN number from IDM (this is important to support as we already have
     * access to it in Mango). This is in support of DSC-4213.
     */

    loginUrl = `${loginUrl}?${_({
      tenantId,
      tenantIdmId,
      pathHint: encodeURIComponent(
        _.trimStart(window.location.pathname) + window.location.search || '',
      ),
    })
      .mapKeys((val, key) => _.snakeCase(key))
      .omitBy(_.isEmpty)
      .map((val, key) => `${key}=${val}`)
      .join('&')}`;
    // Only redirect to login if we aren't already there
    if (!_.includes(window.location.href, 'login')) {
      if (!isDashletReportMode) {
        localStorage.setItem(
          IDM_PATH_HINT,
          _.trimStart(window.location.pathname) + window.location.search || '',
        );
        if (!_.includes(window.location.pathname, 'logout')) {
          window.location.assign(loginUrl);
        }
      }
    }
  };
};

const isLoggedIn = () => {
  return dispatch => {
    hasValidAuth({
      success: () => {
        // trigger login if successful, otherwise stay logged out.
        dispatch(login());
      },
      error: e => {
        console.log(e);
        dispatch(setShowLoginDialog());
      },
    });
  };
};

const init = () => {
  return function (dispatch) {
    // check header for token
    const { IDM_TOKEN = '' } = getConfig();

    if (!_.isEmpty(IDM_TOKEN)) {
      handleNewAccessToken(IDM_TOKEN);
    }

    dispatch(isLoggedIn());
  };
};

const returnToLogin = () => {
  return (dispatch, getState) => {
    const {
      dashlet: { isDashletMode },
    } = getState();

    if (!isDashletMode) {
      URLs.goTo(ROUTER_DIRS.LOGIN);
      return;
    }

    // show logOUT dialog
    dispatch(setShowLoginDialog());
  };
};

const getAggregateInfo = currentUser => {
  const isNoAccessUser = isNoAccess({ currentUser });

  if (isNoAccessUser) {
    return [];
  }

  const queryOptions = {
    query: DatasetQueries.CalcFunctionInfo,
  };
  return getClient()
    .query(queryOptions)
    .then(res => res?.data?.aggregateInfo);
};

const logoutOtherSessions = () => {
  return async () => {
    // log out of all sessions except this current session
    await getClient()
      .mutate({ mutation: AccountQueries.LogoutOtherSessions })
      .catch(err => {
        console.log(`cannot logout of other sessions: ${err}`);
      });
  };
};

const setChangePasswordError = status => {
  return {
    type: Const.Account.SET_PASSWORD_CHANGE_ERROR,
    passwordChangeError: status,
  };
};

export const CommonActions = {
  applicationInitialization,
  setCurrentUser,
  shouldLogoutOtherSessions,
  login,
  setShowLoginDialog,
  isLoggedIn,
  init,
  returnToLogin,
  getAggregateInfo,
  logoutOtherSessions,
  setChangePasswordError,
  unAuthedLogout,
};
