import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../../../../common/emotion/theme';

export const SubMenu = styled.div(() => {
  const {
    mobileMaxWidth,
    colors: { MediumBorder, ContentBackground, ContentText },
  } = useDiscoverTheme();

  return css`
    min-width: 245px;
    border: 1px solid ${MediumBorder};
    border-radius: 8px;
    padding: 10px;
    background-color: ${ContentBackground};
    color: ${ContentText};

    @media (max-width: ${mobileMaxWidth}px) {
      width: 100%;
      border: none;
    }
  `;
});

export const CustomRangeItem = styled.label`
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: block;
  font-weight: inherit;
  width: 100%;

  input {
    padding: 0 8px;
    width: 100%;
    height: 32px;
    font-size: 14px;
  }
`;
