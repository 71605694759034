import { Component } from 'react';
import _, { isEmpty } from 'lodash';
import { LegendShape } from '../../charts/legend-shape';
import { throttle } from 'throttle-debounce';
import classnames from 'classnames';
import {
  EMPTY_STRING_DISPLAY,
  EMPTY_STRING_TOKEN,
  hasDashletSuffix,
} from '../../../common/Constants';
import { messages, i18nUtils } from '../../../i18n';
import { branch, compose, renderComponent } from 'react-recompose';
import { connect } from 'react-redux';
import {
  defaultRenderStrategy,
  viewDetailsRenderStrategy,
} from './chart-tooltip.util';
import { isLineChartWithMultipleFields } from '../../viz-config.util';
import { withDiscoverRouter } from '../../../common/utilities/with-router';
import { PrimaryButton } from '../../../ui/button/button.component';
import { pointer, scaleOrdinal, select } from 'd3';
import { shortid } from '../../../common/utilities/shortid-adapter';

export class UnconnectedChartToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // approx 30fps rate limiter.
    this.setSizeRateLimited = throttle(
      30,
      ({ height, width, viewDetailsHeight, viewDetailsWidth }) => {
        this.setState({
          ...this.state,
          width,
          height,
          viewDetailsHeight,
          viewDetailsWidth,
        });
      },
    );
  }

  componentWillUnmount() {
    // remove listeners
    select(this.tooltip).on('mouseover', null).on('mouseout', null);
  }

  componentDidMount() {
    this.updateSize();

    if (!this.props.onMouseOver) {
      return;
    }
    select(this.tooltip)
      .on('mouseover', event => {
        if (!this.props.showGlobalTooltip) {
          // suppress mouse events bubbling passed the tooltip unless we're in global mode
          event.stopPropagation();
        }

        this.props.onMouseOver(true);
      })
      .on('mouseout', event => {
        // Sometimes we get this even though we're over the element
        const [x, y] = pointer(event, this.tooltip);
        const bounds = this.tooltip.getBoundingClientRect();
        if (x > 0 && x < bounds.width && y > 0 && y < bounds.height) {
          // Were still over ignore
        } else {
          this.props.onMouseOver(false);
        }
      });

    select(this.viewDetailsHtmlContainer)
      .on('mouseover', event => {
        event.stopPropagation();
      })
      .on('mouseout', event => {
        event.stopPropagation();
      })
      .on('click', event => {
        event.stopPropagation();
        if (_.isFunction(this.props.openReportLink)) {
          this.props.openReportLink();
        }
      });
  }

  componentDidUpdate() {
    this.updateSize();
  }

  /**
   * Set's size of SVG based on HTML content
   */
  updateSize() {
    const { width: updatingWidth, height: updatingHeight } =
      this.htmlContent.getBoundingClientRect();
    const {
      width: updatingViewDetailsWidth = 0,
      height: updatingViewDetailsHeight = 0,
    } = this.viewDetailsHtmlContainer?.getBoundingClientRect() ?? {};

    const { width, height, viewDetailsHeight, viewDetailsWidth } = this.state;

    if (
      !_.isNil(this.setSizeRateLimited) &&
      _.some(
        [
          [width, updatingWidth],
          [height, updatingHeight],
          [viewDetailsHeight, updatingViewDetailsHeight],
          [viewDetailsWidth, updatingViewDetailsWidth],
        ],
        _tuple => !_.isEqual(_tuple[0], _tuple[1]),
      )
    ) {
      this.setSizeRateLimited({
        height: updatingHeight,
        width: updatingWidth,
        viewDetailsHeight: updatingViewDetailsHeight,
        viewDetailsWidth: updatingViewDetailsWidth,
      });
    }
  }

  render() {
    const {
      width,
      height,
      x = 0,
      y = 0,
      arrowHeight,
      tooltipRectRadius,
      tooltipPaddingX,
      tooltipArrowWidth,
      tooltipTextOuterPadding,
      className,
      showViewDetails,
      isInSideDrawer,
    } = this.props;

    const show = this.props.show || this.props.data;
    const makeFinite = num => (_.isFinite(num) ? num : 0);

    const tooltipRectHeight = makeFinite(
      this.state.height + tooltipTextOuterPadding * 2,
    );
    const tooltipRectWidth = makeFinite(
      this.state.width + tooltipTextOuterPadding * 2,
    );
    const tooltipWidth = makeFinite(
      tooltipRectWidth + tooltipTextOuterPadding * 2,
    );

    const renderStrategy =
      showViewDetails && !isInSideDrawer
        ? viewDetailsRenderStrategy
        : defaultRenderStrategy;

    const {
      xPos,
      yPos,
      arrow,
      arrowYOffset,
      downArrowXOffset,
      upArrowXOffset,
      xPosDetailsButton = 0,
      yPosDetailsButton = 0,
      viewDetailsWidth: _viewDetailsWidth,
      viewDetailsHeight: _viewDetailsHeight,
    } = renderStrategy({
      width,
      height,
      x,
      xPos: this.props.xPos,
      y,
      arrowHeight,
      tooltipWidth,
      tooltipRectWidth,
      tooltipRectHeight,
      tooltipPaddingX,
      tooltipTextOuterPadding,
      arrow: this.props.arrow,
      viewDetailsWidth: makeFinite(this.state.viewDetailsWidth),
      viewDetailsHeight: makeFinite(this.state.viewDetailsHeight),
    });

    const tooltip = (
      <g
        key={`tooltip-card`}
        style={{ pointerEvents: 'none' }}
        ref={el => {
          this.tooltip = el;
        }}
        className={`chart-tooltip-container ${show ? 'visible' : 'hidden'}`}
        transform={`translate(${xPos}, ${yPos})`}
      >
        <rect
          style={{ visibility: 'hidden' }}
          width={tooltipRectWidth}
          height={tooltipRectHeight}
        />
        <path
          className={`leftArrow ${arrow === 'left' ? 'visible' : 'hidden'}`}
          d='M0 4 L6 0 L6 8 Z'
          transform={`translate(0, ${arrowYOffset})`}
        />
        <path
          className={`rightArrow ${arrow === 'right' ? 'visible' : 'hidden'}`}
          d='M6 4 L0 0 L0 8 Z'
          transform={`translate(${
            tooltipRectWidth + tooltipArrowWidth
          }, ${arrowYOffset})`}
        />
        <path
          className={`downArrow ${arrow === 'down' ? 'visible' : 'hidden'}`}
          d='M0 0 L4 6 L8 0 Z'
          transform={`translate(${
            tooltipRectWidth / 2 + tooltipArrowWidth / 2 + downArrowXOffset
          }, ${tooltipRectHeight})`}
        />
        <path
          className={`upArrow ${arrow === 'up' ? 'visible' : 'hidden'}`}
          d='M0 8 L4 2 L8 8 Z'
          transform={`translate(${
            tooltipRectWidth / 2 + tooltipArrowWidth / 2 + upArrowXOffset
          }, ${0 - arrowHeight})`}
        />
        <rect
          className='tooltipRect'
          width={tooltipRectWidth}
          height={tooltipRectHeight}
          rx={tooltipRectRadius}
          ry={tooltipRectRadius}
          x={tooltipArrowWidth}
          y='0'
        />
        {/* Safari won't render a foreign object without height & width properties set */}
        <foreignObject width={tooltipRectWidth} height={tooltipRectHeight}>
          <div
            className={classnames(className, 'tooltip-content')}
            ref={content => {
              this.htmlContent = content;
            }}
            style={{ paddingLeft: `${tooltipArrowWidth}px` }}
          >
            {this.props.children}
          </div>
        </foreignObject>
      </g>
    );

    const renderedItems = [tooltip];

    if (showViewDetails) {
      const viewDetailsButton = (
        <g
          key={'view-details'}
          transform={`translate(${xPosDetailsButton}, ${yPosDetailsButton})`}
          className={`${show ? 'visible' : 'hidden'}`}
        >
          <foreignObject
            width={_.max([_viewDetailsWidth, 120])}
            height={_viewDetailsHeight}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                position: 'fixed',
              }}
            >
              <PrimaryButton
                ref={content => {
                  this.viewDetailsHtmlContainer = content;
                }}
              >
                {messages.chartTooltip.viewDetails}
              </PrimaryButton>
            </div>
          </foreignObject>
        </g>
      );

      renderedItems.push(viewDetailsButton);
    }

    return renderedItems;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tooltipData, enableReportLink, openReportLink } = ownProps;
  if (!tooltipData) {
    return {};
  }

  const {
    discover: { openDiscoveries, displayDiscovery, drillContext },
  } = state;

  const currentViz = _.get(openDiscoveries, [displayDiscovery, 'present']);

  // Query Engine currently does not support multiple attributes in LINES
  const isViewDetailsSupportedViz = !isLineChartWithMultipleFields(
    currentViz.chartType,
    _.get(tooltipData, 'originPlot.layout'),
  );

  const isInSideDrawer = hasDashletSuffix(currentViz?.id);

  return {
    hoverX: tooltipData.hoverX,
    hoverY: tooltipData.hoverY,
    hoverData: tooltipData.hoverData,
    originPlot: tooltipData.originPlot,
    showGlobalTooltip: state.main.controlDown || ownProps.showGlobalTooltip,
    dataFormatters: tooltipData.dataFormatters,
    useFiscalCalendar: tooltipData.useFiscalCalendar,
    showViewDetails:
      isViewDetailsSupportedViz &&
      ((enableReportLink && _.isFunction(openReportLink)) ||
        (isInSideDrawer && !isEmpty(drillContext))),
    isInSideDrawer,
  };
};

const mapDispatchToProps = _.constant({});

export const ChartToolTip = compose(
  withDiscoverRouter,
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    props => !(props.mouseOverChart || props.showViewDetails),
    renderComponent(() => <div />),
  ),
  ChartToolTipComponent => props => {
    if (_.isNil(props.hoverData) || _.isNil(props.originPlot)) {
      return [];
    }
    // Get HTML content
    const tooltip = props.originPlot.getTooltip(
      props.hoverData,
      props.dataFormatters,
      props.showGlobalTooltip,
      props.useFiscalCalendar,
      props.originPlot?.customFormatProps,
    );
    if (_.isEmpty(tooltip)) {
      return [];
    }

    const tti = _.get(props, 'tooltipData.hoverData.tooltipInfo', {
      id: shortid.generate(),
    });
    const key = _(tti)
      .map((val, ttiKey) => `${ttiKey}_${val}`)
      .join('|');
    return (
      <g>
        <ChartToolTipComponent
          key={key}
          show={props.mouseOverChart || props.showViewDetails}
          height={props.height}
          width={props.width}
          x={props.hoverX}
          y={props.hoverY}
          onMouseOver={over => {
            props.mouseOverTooltip(over);
          }}
          showGlobalTooltip={props.showGlobalTooltip}
          enableReportLink={props.enableReportLink ?? false}
          showViewDetails={props.showViewDetails ?? false}
          isInSideDrawer={props.isInSideDrawer ?? false}
          openReportLink={() => props.openReportLink()}
          customFormatProps={props.originPlot.customFormatProps}
        >
          {tooltip}
        </ChartToolTipComponent>
      </g>
    );
  },
)(UnconnectedChartToolTip);

const arrowHeight = 8;
const tooltipRectHeight = 80;

UnconnectedChartToolTip.defaultProps = {
  arrowHeight,
  arrowYOffset: tooltipRectHeight / 2 - arrowHeight / 2,
  tooltipRectRadius: 2,
  tooltipRectWidth: 146,
  tooltipRectHeight,
  tooltipPaddingX: 26,
  tooltipArrowWidth: 6,
  tooltipWidth: 146 + 8 * 2,
  tooltipTextOuterPadding: 8,
  tooltipLabelSpacing: 16,
  xPos: 0,
  x: 0,
  arrow: undefined,
};

const scrubData = data => {
  // round to only 2 decimal places (if its a number and there are decimals)
  if (_.isNil(data)) {
    return data;
  }
  const scrubbed = Object.entries(data).reduce((accum, current) => {
    const key = current[0];
    let value = current[1];
    value =
      value === '' || value === EMPTY_STRING_TOKEN
        ? EMPTY_STRING_DISPLAY
        : value;
    if (isNaN(value)) {
      accum[key] = value;
    } else {
      // round it to 2 decimal places
      accum[key] = _.round(value, 2);
    }

    return accum;
  }, {});
  return scrubbed;
};

export const ChartTooltipData = props => {
  const data = scrubData(props.data);
  let content = [];

  const paletteMapping = _.isArray(props.paletteMapping)
    ? props.paletteMapping
    : null;
  const shapeMapping = _.isArray(props.shapeMapping)
    ? props.shapeMapping
    : null;
  const colors = props.palette ? scaleOrdinal().range(props.palette) : null;
  if (!_.isNil(data)) {
    content = Object.entries(data).map((entry, i) => {
      let icon = '';
      if (props.showIcons) {
        const defaultIconColor = _.isFunction(colors) ? colors(i) : '';
        const iconColor = !_.isNil(paletteMapping)
          ? paletteMapping[i]
          : defaultIconColor;
        const iconShape = !_.isNil(shapeMapping)
          ? shapeMapping[i]
          : props.shape;
        icon = <LegendShape shape={iconShape} color={iconColor} />;
      }
      const label = i18nUtils.translateFieldName(
        messages,
        entry[0],
        props.useFiscalCalendar,
      );
      const value = _.get(entry, '[1].value', entry[1]);
      return (
        <div key={`chart-tooltip-${label}-${value}`} className='tooltip-entry'>
          <span className='chart-tooltip-label'>
            {icon}
            {label}
          </span>
          <span className='chart-tooltip-value'>{value}</span>
        </div>
      );
    });
  }
  return <div>{content}</div>;
};

export const ChartTooltipReportLinkNote = props => {
  const {
    body: noteBody = messages.formatString(
      messages.chartTooltip.clickToViewDetails,
      props.name,
    ),
  } = props;
  return (
    <div>
      <hr />
      <div
        style={{
          fontStyle: 'unset',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='chart-tooltip-extra'
      >
        {noteBody}
      </div>
    </div>
  );
};
