import { darken, lighten } from '@mui/material/styles';

export const getAdjustedColors = ({ isDarkMode, baseColor }) => {
  const adjustedBaseColor = isDarkMode ? darken(baseColor, 0.2) : baseColor;

  const adjustedDerivativeColor = isDarkMode
    ? lighten(adjustedBaseColor, 0.2)
    : lighten(baseColor, 0.2);
  return { adjustedDerivativeColor, adjustedBaseColor };
};

export const getGradientId = (chartType: string, id: string) =>
  `${chartType}-gradient-${id}`;
