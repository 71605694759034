import { Fragment } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { withRouter } from './common/utilities/with-router';
import { SecureRoutes } from './common/utilities/secure-router';
import { Administration } from './views/Administration';
import Datasets from './datasets/Datasets';
import { MobileAccountSettings, AccountSettings } from './account';
import CustomDragLayer from './CustomDragLayer';
import AuthenticatedReducerSet from './common/redux/reducers/AuthenticatedReducerSet';
import store from './common/redux';
import { connect, useSelector } from 'react-redux';
import { compose } from 'react-recompose';
import { messages } from './i18n';
import { ModalPortal } from './common/widgets/dialogs/modal-portal';
import { LogoutDialog } from './common/widgets/dialogs/logout-dialog';
import classnames from 'classnames';
import GlassPane from './common/widgets/GlassPane';
import ErrorDetailDialog from './common/widgets/dialogs/ErrorDetailDialog';
import AppOutOfDateDialog from './common/widgets/dialogs/AppOutOfDateDialog';
import { ROUTER_DIRS } from './common';
import {
  WithNav,
  RedirectReportLink,
  AuthenticatedView,
  TrackedDiscoverView,
  TrackedLibraryView,
} from './components/routed-views';
import { rolesMap } from './common/Util';
import { NoAccessDialog } from './components/no-access-dialog';

store.replaceReducer(AuthenticatedReducerSet);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./common/redux/reducers/AuthenticatedReducerSet', () => {
    const updatedAuthenticatedReducer = require('./common/redux/reducers/AuthenticatedReducerSet');
    store.replaceReducer(updatedAuthenticatedReducer);
  });
}

const UnconnectedMainComponent = _props => {
  const {
    main: { showGlassPane, glassPaneContent, isMobile } = {},
    login: { isLoggedIn } = {},
  } = useSelector(({ main, login }) => ({ main, login }));

  return [
    <Routes key='router'>
      <Route
        path={ROUTER_DIRS.LOGIN}
        element={<Navigate to={ROUTER_DIRS.ROOT} />}
      />
      <Route path={ROUTER_DIRS.LOGOUT} element={<LogoutDialog />} />
      <Route path={ROUTER_DIRS.REPORT_LINK} element={<RedirectReportLink />} />

      <Route element={<AuthenticatedView />}>
        <Route
          element={
            <div
              className={classnames('vbox', 'flex-fill', 'content-container', {
                'pl-60': !isMobile && isLoggedIn,
              })}
            >
              <WithNav>
                <Outlet />
              </WithNav>
              <GlassPane
                show={showGlassPane}
                content={glassPaneContent ?? null}
              />

              <ErrorDetailDialog />
              <AppOutOfDateDialog />
            </div>
          }
        >
          <Route
            path={ROUTER_DIRS.ACCOUNT}
            element={isMobile ? <MobileAccountSettings /> : <AccountSettings />}
          />
          <Route
            element={<SecureRoutes disallowedRoles={[rolesMap.READONLY]} />}
          >
            <Route path={ROUTER_DIRS.DATASETS} element={<Datasets />} />
          </Route>
          <Route
            path={ROUTER_DIRS.OPEN_VIZ_PATTERN}
            element={<TrackedDiscoverView />}
          />
          <Route path={ROUTER_DIRS.LIBRARY} element={<TrackedLibraryView />} />
          <Route element={<SecureRoutes allowedRoles={[rolesMap.ADMIN]} />}>
            <Route path={ROUTER_DIRS.ADMIN} element={<Administration />} />
          </Route>
        </Route>

        <Route
          path={'*'}
          element={
            <SecureRoutes
              redirect={ROUTER_DIRS.LIBRARY}
              allowedRoles={[rolesMap.NOACCESS, rolesMap.DASHLETUSER]}
            />
          }
        >
          <Route path={'*'} element={<NoAccessDialog noAccess />} />
        </Route>
      </Route>
    </Routes>,
    <CustomDragLayer key='drag-layer' snapToGrid={false} />,
  ];
};

const MainComponent = compose(
  withRouter,
  connect(state => {
    const {
      dashlet: { isDashletMode, hasSaveError },
      main: { hasInternalServerError, showGlassPane, glassPaneContent },
      login: { isLoggedIn },
    } = state;
    return {
      hasSaveError,
      hasInternalServerError,
      isDashletMode,
      showGlassPane,
      glassPaneContent,
      isLoggedIn,
    };
  }),
)(props => {
  const {
    isLoggedIn,
    isDashletMode,
    isDashletLibraryMode,
    hasInternalServerError,
    hasSaveError,
  } = props;

  if (hasInternalServerError && isDashletMode) {
    return (
      <ModalPortal
        className={'regular-modal full-screen fixed-error-modal'}
        title={messages.formatString(
          messages.dashlet.dashletOutOfDate,
          messages.nonTranslated.discover,
        )}
        buttonGroup={''}
      >
        <></>
      </ModalPortal>
    );
  } else if (hasSaveError && isLoggedIn && !isDashletLibraryMode) {
    return (
      <ModalPortal
        className={'regular-modal full-screen fixed-error-modal'}
        title={messages.formatString(
          messages.dashlet.errorSavingDashlet,
          messages.nonTranslated.dashletLowerCase,
        )}
        buttonGroup={''}
      >
        <div>
          {messages.formatString(
            messages.dashlet.errorSavingDashletMessage,
            messages.nonTranslated.dashletLowerCase,
            messages.nonTranslated.discover,
          )}
        </div>
      </ModalPortal>
    );
  }
  return <UnconnectedMainComponent {...props} />;
});

export { MainComponent, AuthenticatedReducerSet };
