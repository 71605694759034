import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  useDiscoveryIdSelector,
  useVizOptionSelector,
} from '../selectors/viz-selector.hook';
import Discover from './DiscoverActions';
import { VIZ_OPTION_IDS } from '../../Constants';
import { VIZ_SELECTORS } from '../selectors/viz-selectors';
import { IVizOptions } from '../../../discovery';

export const useUndoDiscoveryAction = ({ vizId }: { vizId?: string } = {}) => {
  const discoveryId = useDiscoveryIdSelector({ discoveryId: vizId });
  const dispatch = useDispatch();
  const undo = useCallback(() => {
    dispatch(Discover.undoDiscovery(discoveryId));
  }, [discoveryId, dispatch]);
  return undo;
};

export const useVizQueryDiscoverAction = () => {
  const dispatch = useDispatch();
  const vizQuery = useCallback(
    (
      id,
      sourceName,
      monitorEventId,
      variables,
      queryId,
      replaceNulls = true,
      secondaryQueryVariables?,
      skipAnalyze = false,
    ) => {
      dispatch(Discover.setVizLegendData(id, []));
      dispatch(
        Discover.vizQuery({
          id,
          sourceName,
          monitorEventId,
          variables,
          queryId,
          replaceNulls,
          secondaryQueryVariables,
          skipAnalyze,
        }),
      );
    },
    [dispatch],
  );
  return vizQuery;
};

export const useIgnoreErrorDiscoverAction = ({
  vizId,
}: { vizId?: string } = {}) => {
  const discoveryId = useDiscoveryIdSelector({ discoveryId: vizId });
  const dispatch = useDispatch();
  const ignoreError = useCallback(() => {
    dispatch(Discover.ignoreQueryError(discoveryId));
  }, [discoveryId, dispatch]);
  return ignoreError;
};

export const useVizOptionSetter = <T = any>({
  vizId,
  option,
  skipParse = false,
}: { vizId?: string; option?: string; skipParse?: boolean } = {}) => {
  const discoveryId = useDiscoveryIdSelector({ discoveryId: vizId });
  const dispatch = useDispatch();
  const setOption = useCallback(
    (newValue: T, setting = option) => {
      let value: any = newValue;
      if (!skipParse) {
        value = JSON.stringify(value);
      }
      if (_.isEqual(setting, VIZ_OPTION_IDS.useLiveQuery)) {
        dispatch(
          Discover.setLiveQueryForViz({
            id: discoveryId,
            value,
          }),
        );
      } else {
        dispatch(
          Discover.setSettingForViz({
            id: discoveryId,
            setting,
            value,
          }),
        );
      }
    },
    [option, skipParse, dispatch, discoveryId],
  );
  return setOption;
};

/**
 * @description This works similarly to React's get state -- the return is a tuple where the
 *   first element is the getter, and the second the setter.
 * @example
 *   const [useFiscalCalendar, setUseFiscalCalendar] = useVizOptionState(
 *      { option: VIZ_OPTION_IDS.useFiscalCalendar }
 *    );
 * @template T
 * @param {{
 *   vizId?: string;
 *      @description: Optional -- defaults to the currently open viz
 *   option: string;
 *      @description: The name of the option you wish to select
 *      @example: VIZ_OPTION_IDS.useLiveQuery
 *   skipParse?: boolean;
 *      @default: false
 *      @description: Enable this to skip parsing and stringifying the value
 *        when getting/setting.
 *   defaultValue?: T;
 * }} {
 *   vizId,
 *   option,
 *   skipParse = false,
 *   defaultValue,
 * }
 * @return [T, (newVal: T) => void]
 *   @description a getter/setter tuple similar to React's setState
 */
export const useVizOptionState = <T = any>({
  vizId,
  option,
  skipParse = false,
  defaultValue,
}: {
  vizId?: string;
  option: keyof IVizOptions;
  skipParse?: boolean;
  defaultValue?: T;
}) => {
  const value = useVizOptionSelector<T>({
    discoveryId: vizId,
    option,
    skipParse,
    defaultValue,
  });
  const set = useVizOptionSetter<T>({ vizId, option, skipParse });
  const tuple: [T, (val: T) => void] = useMemo(
    () => [value, set],
    [value, set],
  );
  return tuple;
};

/**
 * @description This is a specialized getter/setter for boolean viz options. It
 *   provides helpers to enable, disable, set to a particular value, or toggle
 *   the value in question.
 * @example
 *   const {
 *     enable,
 *     disable,
 *     toggle,
 *     set,
 *     value,
 *   } = useToggleableVizOptionState({ option: VIZ_OPTION_IDS.useFiscalCalendar });
 * @template T
 * @param {{
 *   vizId?: string;
 *      @description: Optional -- defaults to the currently open viz
 *   option: string;
 *      @description: The name of the option you wish to select
 *      @example: VIZ_OPTION_IDS.useLiveQuery
 *   defaultValue?: T;
 * }} {
 *   vizId,
 *   option,
 *   defaultValue,
 * }
 * @return {{
 *  set,
 *  toggle,
 *  enable,
 *  disable,
 *  value
 * }}
 */
export const useToggleableVizOptionState = ({
  vizId,
  option,
  defaultValue,
}: {
  vizId?: string;
  option: keyof IVizOptions;
  defaultValue?: boolean;
}) => {
  const [value, _set] = useVizOptionState<boolean>({
    vizId,
    option,
    defaultValue,
  });
  const set = useCallback(
    newVal => {
      if (newVal !== value) {
        _set(newVal);
      }
    },
    [_set, value],
  );
  const toggle = useCallback(() => {
    set(!value);
  }, [value, set]);
  const enable = useCallback(() => {
    if (!value) {
      set(true);
    }
  }, [value, set]);
  const disable = useCallback(() => {
    if (value) {
      set(false);
    }
  }, [value, set]);
  const result = useMemo(
    () => ({
      value,
      set,
      enable,
      disable,
      toggle,
    }),
    [disable, enable, set, toggle, value],
  );
  return result;
};

export const useLiveQueryOptionGetterSetter = ({
  vizId,
}: { vizId?: string } = {}) => {
  const { value, set, enable, disable, toggle } = useToggleableVizOptionState({
    vizId,
    option: VIZ_OPTION_IDS.useLiveQuery,
    defaultValue: (VIZ_SELECTORS.getOptionDefault(
      VIZ_OPTION_IDS.useLiveQuery,
    ) ?? false) as boolean,
  });
  const returnVal = useMemo(
    () => ({
      useLiveQuery: value,
      setUseLiveQuery: set,
      enableUseLiveQuery: enable,
      disableUseLiveQuery: disable,
      toggleUseLiveQuery: toggle,
    }),
    [disable, enable, set, toggle, value],
  );
  return returnVal;
};
