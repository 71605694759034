import { graphql } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';

import AccountQueries from '../../common/graphql/Account';
import { currentUserSelector } from '../../common/redux/selectors/AccountSelectors';
import { IUserInfo } from '../interfaces';

export interface IAccountQueryProps {
  userInfo: IUserInfo;
  loading: boolean;
  error: any;
}

interface IAccountQueryData {
  currentUser: IUserInfo;
}

const mapAccountQueryResult: (result: any) => IAccountQueryProps = result => {
  const { loading: resultLoading, data } = result;
  if (resultLoading || !data) {
    return {
      loading: resultLoading,
      userInfo: {} as any,
      error: null,
    };
  }
  const { loading, error } = data;
  const currentUser = currentUserSelector(data);
  return { userInfo: { ...currentUser }, loading, error };
};

export const AccountQuery = graphql<
  object,
  IAccountQueryData,
  object,
  IAccountQueryProps
>(AccountQueries.AccountQuery, {
  props: mapAccountQueryResult,
});

export const useAccountQuery = () => {
  const res = useQuery(AccountQueries.AccountQuery);
  return mapAccountQueryResult(res);
};
