import Discover from '../../../common/redux/actions/DiscoverActions';

export const dispatchSetLegendPanelWidth =
  (dispatch, ownProps) => (width: number) => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'legendPanelWidth',
        value: width,
      }),
    );
  };
