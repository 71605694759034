import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, getIndicatorColor } from './color-palette.const';
import { colorsMatch } from './color.util';
import {
  useDiscoverTheme,
  useDiscoverThemeColors,
} from '../../../common/emotion';

const SelectedIndicator = ({ color }) => {
  const { SlicerShadowColor } = useDiscoverThemeColors();
  const { fill, stroke } = getIndicatorColor(color);
  return (
    <svg
      css={css({
        width: 16,
        height: 16,
        filter: `drop-shadow(0px 0px 2px ${SlicerShadowColor}`,
      })}
    >
      <circle cx={7} cy={7} r={3} fill={fill} stroke={stroke} />
    </svg>
  );
};

export const ColorBox = ({ color, isSelected = false, ...props }) => {
  const { colors: { TableHeaderTextColor, ColorBoxBorderColor } = {} as any } =
    useDiscoverTheme();
  return (
    <div
      {...props}
      css={css({
        borderColor: colorsMatch(Colors.LightGray1, color)
          ? ColorBoxBorderColor
          : color,
        borderStyle: 'solid',
        backgroundColor: color,
        display: 'inline-block',
        width: 16,
        height: 16,
        margin: 4,
        padding: 0,
        borderRadius: 2,
        borderWidth: 1,
        cursor: 'pointer',
        '&:hover': {
          borderColor: TableHeaderTextColor,
        },
      })}
    >
      {isSelected && <SelectedIndicator color={color} />}
    </div>
  );
};

export const ColorSeriesLink = styled.span(
  ({
    disabled,
    theme: {
      colors: { CorvanaLightText, ActiveLinkText } = {} as any,
    } = {} as any,
  }: any) =>
    css`
      font-weight: 200;
      font-style: inherit;
      font-size: 12px;
      color: ${disabled ? CorvanaLightText : ActiveLinkText};
      cursor: ${disabled ? 'default' : 'pointer'};
      pointer-events: ${disabled ? 'none' : 'auto'};
    ` as any,
) as any;
