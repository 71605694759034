import SortIcon from '../../../../images/sdd/sort_arrow.svg';
import SortHover from '../../../../images/sdd/sort_hover.svg';
import _, { isObject, isFunction } from 'lodash';
import styled from '@emotion/styled';
import {
  EMPTY_CELL_TOKEN,
  EMPTY_CELL_DISPLAY,
  NULL_DISPLAY,
} from '../../../common/Constants';
import { css } from '@emotion/react';
import { usePivotCellDrillLink } from './pivot-cell.hook';
import { Tooltip } from '../../../components/ui/tooltip';
import { messages } from '../../../i18n';

const RecordCount = styled.div(
  ({ theme: { colors: { TableRowSecondaryTextColor } = {} } = {} }) => css`
    font-size: 10px;
    color: ${TableRowSecondaryTextColor};
    line-height: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1px;
  `,
);

/**
 * Pivot Table Cell. Supports Sorting and Tooltips
 * @param value raw value
 * @param sorting object containing {sortFunc, sortType, sortIndex, direction}
 *        or empty object if sorting disabled
 * @param sortDirection current sort direction of column/row
 * @param classes list of classes to add to cell
 * @param style style to apply to cell
 * @param key unique key used for cell measuring / caching
 * @returns {XML} Pivot Cell
 */
export default ({
  value: valObj,
  sorting: { sortFunc, sortType, highlight, direction },
  classes = '',
  style = {},
  key = '',
  formatter,
  recordCount,
  i18nPrefs = {}, // note that react-virtualized does not play well with Redux
  customFormatter,
  rowIndex,
  columnIndex,
}) => {
  const sortDirection = direction;
  let addTooltip = true;
  let { value } = isObject(valObj) ? valObj : { value: valObj };

  const getSortIcon = () => {
    const sortTypeClasses = [sortType.toLowerCase()];
    switch (sortDirection) {
      case 'desc':
        return (
          <SortIcon className={sortTypeClasses.concat('desc').join(' ')} />
        );
      case 'asc':
        return <SortIcon className={sortTypeClasses.concat('asc').join(' ')} />;
      default:
        return (
          <SortHover
            className={sortTypeClasses.concat('sort-placeholder').join(' ')}
          />
        );
    }
  };

  const classNames = sortFunc ? classes.concat(' sortable') : classes;

  // Return the new sorting direction DESC -> ASC -> None -> DESC
  const getNewSortDirection = () => {
    if (!sortDirection || sortDirection === 'asc') {
      return 'desc';
    } else {
      return 'asc';
    }
  };

  if (_.isNil(value)) {
    value = NULL_DISPLAY;
    addTooltip = false;
  }
  // round decimal values
  let formattedValue = formatter
    ? formatter.format(value, i18nPrefs, customFormatter)
    : value; // not formatting correctly
  const tooltip = formatter
    ? formatter.formatText(value, i18nPrefs, customFormatter)
    : value;
  const valueClassNames = _.isNumber(value) ? 'value right-aligned' : 'value';

  if (_.isNil(formattedValue)) {
    formattedValue = NULL_DISPLAY;
    addTooltip = false;
  }

  if (formattedValue === EMPTY_CELL_TOKEN) {
    formattedValue = EMPTY_CELL_DISPLAY;
    addTooltip = false;
  }

  const sortClassName = ['sorticon'].concat([
    sortDirection || 'sort-placeholder',
  ]);

  if (highlight) {
    sortClassName.push('primary-sort');
  }
  const sortIcon = sortFunc && (
    <div className={sortClassName.join(' ')}>{getSortIcon()}</div>
  );

  const {
    linkStyle,
    canDrillLink,
    drillLink,
    targetReportName = '',
  } = usePivotCellDrillLink({
    formattedValue,
    rowIndex,
    columnIndex,
  });

  return (
    <div
      className={classNames}
      title={(addTooltip && tooltip) || ''}
      key={key}
      style={style}
      onClick={() => {
        if (!sortFunc) {
          return;
        }
        sortFunc(getNewSortDirection());
      }}
    >
      <div className={valueClassNames}>
        {canDrillLink ? (
          <Tooltip
            arrow
            placement='top'
            title={messages.formatString(
              messages.pivot.drillLinkMessage,
              targetReportName,
            )}
          >
            <span
              aria-label={formattedValue}
              onClick={() => {
                isFunction(drillLink) && drillLink(rowIndex, columnIndex);
              }}
              style={linkStyle}
            >
              {formattedValue}
            </span>
          </Tooltip>
        ) : (
          formattedValue
        )}
      </div>{' '}
      {sortIcon}
      {!_.isNil(recordCount) && _.isNumber(recordCount) && (
        <RecordCount>
          {recordCount} record{recordCount > 1 ? 's' : ''}
        </RecordCount>
      )}
    </div>
  );
};
