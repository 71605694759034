import { messages } from '../../i18n';
import { PrimaryButton } from '../../ui';
import { IMainHeaderSectionProps } from './main-section-header.interface';
import {
  ActionButtons,
  StyledMainSectionHeader,
} from './main-section-header.styles';
import { DownChevron } from '../../icons';
import React, { MouseEvent as ReactMouseEvent, useCallback } from 'react';
import { map } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { useCreateReport } from '../../discovery/sidebar-navigation/quick-create-sub-menu/quick-create-sub-menu.component';
import { useIsAdvancedMode } from '../../common/redux/selectors/main-selector-hooks';
import { useDispatch } from 'react-redux';
import DatasetActions from '../../common/redux/actions/DatasetActions';
import { useDropdownMenu } from '../../discovery/sidebar-navigation/sidebar-navigation.context';

export const CreateReportButton = () => {
  const { createNewViz, datasets } = useCreateReport();

  const { MenuComponent, doToggle: doToggleMenu } = useDropdownMenu();

  return (
    <>
      <PrimaryButton
        key={'button'}
        disabled={datasets.length === 0}
        onClick={(e: ReactMouseEvent<HTMLElement>) =>
          datasets.length === 1 ? createNewViz() : doToggleMenu(e.currentTarget)
        }
        sx={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
        endIcon={
          datasets?.length <= 1 ? undefined : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '17.5px',
              }}
              role={'listbox'}
            >
              <DownChevron color={'inherit'} />
            </div>
          )
        }
      >
        {messages.calcDialog.createButtonText}
      </PrimaryButton>
      <MenuComponent
        disablePortal={true}
        key={'dropdown-menu'}
        sx={{
          maxWidth: '12rem',
          '& li': {
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        }}
      >
        {map(datasets, ({ id: datasetId, name: datasetName }) => (
          <MenuItem
            key={`dataset-${datasetId}`}
            onClick={() => createNewViz(datasetId)}
          >
            {datasetName}
          </MenuItem>
        ))}
      </MenuComponent>
    </>
  );
};

export const CreateDatasetButton = () => {
  const dispatch = useDispatch();

  const createDataset = useCallback(() => {
    dispatch(DatasetActions.setDatasetPage('wizardSourceSelect'));
  }, [dispatch]);

  return (
    <>
      <PrimaryButton
        key={'button'}
        onClick={createDataset}
        sx={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        {messages.calcDialog.createButtonText}
      </PrimaryButton>
    </>
  );
};

export const MainSectionHeader = ({
  headerLabel,
  actionButtons = [<></>],
  advancedActions = <></>,
}: IMainHeaderSectionProps) => {
  const isAdvanced = useIsAdvancedMode();

  return (
    <StyledMainSectionHeader>
      <span>{headerLabel}</span>
      <ActionButtons>
        {isAdvanced && advancedActions}
        {actionButtons}
      </ActionButtons>
    </StyledMainSectionHeader>
  );
};
