import MUIRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLabelStyles, useRadioStyles } from './radio.styles';
import { noop } from 'lodash';

export const Radio = ({
  value,
  label,
  name,
  classes = {},
  disabled = false,
  checked = false,
  onChange = noop,
  labelSx: providedLabelSx = {},
  radioSx: providedRadioSx = {},
}) => {
  const labelSx = useLabelStyles(providedLabelSx);
  const radioSx = useRadioStyles(providedRadioSx);
  return (
    <FormControlLabel
      value={value}
      label={label}
      sx={labelSx}
      classes={classes}
      control={
        <MUIRadio
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          sx={radioSx}
        />
      }
    />
  );
};
