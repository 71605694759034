import { Component } from 'react';
import palette from './ColorPalette';
import { compose, onlyUpdateForKeys, pure } from 'react-recompose';
import { connect } from 'react-redux';
import Discover from '../redux/actions/DiscoverActions';
import Area from './Area';
import { curveMonotoneX } from 'd3';
import { shortid } from '../utilities/shortid-adapter';

class Areas extends Component {
  render() {
    const { height, width, xScale, yScale, className } = this.props;

    // Deconstruct data into simple array of lines
    const rawData = this.props.data;
    const areaData = rawData.LINES;
    this.areaData = areaData;

    const getX = d => d.x;
    const getY = d => d.y;
    const getY1 = d => d.y1;
    const areas = [];

    // Populate line and circle collections for render
    this.areaData.forEach((lineD, idx) => {
      const color =
        this.props.colorPalette[
          (idx + this.props.paletteOffset) % this.props.colorPalette.length
        ];
      areas.push(
        <Area
          key={shortid.generate()}
          data={lineD}
          xScale={xScale}
          yScale={yScale}
          getX={getX}
          getY={getY}
          getY1={getY1}
          offsetX={this.props.offsetX}
          className={className || 'area-chart-area'}
          color={color}
          curve={curveMonotoneX}
          width={width}
          height={height}
        />,
      );
    });

    return (
      <g width={width} height={height} className={this.props.className}>
        <g
          ref={el => {
            this.container = el;
          }}
          className='captureMouseEvents'
        >
          {areas}
          <g ref={el => (this.labelContainer = el)} />
        </g>
      </g>
    );
  }
}

Areas.defaultProps = {
  className: 'area',
  showLabels: false,
  colorPalette: palette,
  focus: null,
  paletteOffset: 0, // Start color cycle offset by this amount
  offsetX: 0,
  xAxisShelf: 'XAXIS',
  globalMouseTooltipEnabled: true,
};

export default compose(
  pure,
  connect(
    state => {
      return {
        focusedData: null,
        showGlobalTooltip: state.main.controlDown,
        hoverData: null,
      };
    },
    (dispatch, ownProps) => ({
      setFocusedData(dataItem) {
        dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
      },
    }),
  ),
  onlyUpdateForKeys(['data', 'height', 'width']),
)(Areas);
