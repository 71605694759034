import { useEffect, useState } from 'react';

export const useDropdownRender = () => {
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState(true);
  const rerenderDropdown = () => setShouldRenderDropdown(false);
  useEffect(() => {
    if (!shouldRenderDropdown) {
      setShouldRenderDropdown(true);
    }
  }, [shouldRenderDropdown]);

  return { shouldRenderDropdown, rerenderDropdown };
};
