import { useCallback, useContext, ReactNode, createContext } from 'react';
import { isEmpty, isNil, noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  AggregateFilterSubTypes,
  FilterTypes,
  createFilterForField,
} from '../Filter';
import {
  useOpenDiscoveryPresentStateSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import { IFilterDialogContext } from './filter-dialog.interface';
import Discover from '../../../common/redux/actions/DiscoverActions';
import {
  FilterDialogTypes,
  IFilter,
} from '../../../datasets/interfaces/filter.interface';
import { Viz } from '../../VizUtil';
import { VIZ_OPTION_IDS } from '../../../common';

type Props = {
  children: ReactNode;
  discoveryId: string;
};

export const createAggregateFilterForField = field => {
  const filter: IFilter = createFilterForField(field);
  filter.subType = AggregateFilterSubTypes.SET_CONDITION;
  filter.type = FilterTypes.NUMERIC;
  filter.dialogType = FilterDialogTypes.AGGREGATE;
  return filter;
};

export const FilterDialogContext = createContext<IFilterDialogContext>({
  field: null,
  filter: null,
  isFilterAggregateDialog: false,
  changeAggregateFilter: noop,
  changeFilter: noop,
  vizCalcs: [],
});

export const FilterDialogContextProvider = ({
  children,
  discoveryId,
}: Props) => {
  const discover = useSelector((state: any) => state.discover);
  const discovery = useOpenDiscoveryPresentStateSelector({ discoveryId });

  const isFilterAggregateDialog =
    discover?.showFieldFilterDialog === FilterDialogTypes.AGGREGATE;
  const filters = useVizOptionSelector({
    discoveryId,
    option: VIZ_OPTION_IDS.filters,
    defaultValue: {},
  });
  const field = discover.activeFilterField;
  let filter;
  let savedFilter;

  if (!isEmpty(filters) && !isNil(field)) {
    if (!isNil(filters[field.name])) {
      savedFilter = filters[field.name];
    }
  }

  if (!isEmpty(discover.activeFilter)) {
    filter = { ...discover.activeFilter };
  } else if (!isNil(savedFilter)) {
    const isSavedFilterAggregate =
      savedFilter.dialogType === FilterDialogTypes.AGGREGATE;
    if (!(isFilterAggregateDialog && !isSavedFilterAggregate)) {
      filter = { ...savedFilter };
    } else if (isFilterAggregateDialog && !isSavedFilterAggregate) {
      filter = createAggregateFilterForField(field);
    }
  } else if (isFilterAggregateDialog && !isEmpty(field)) {
    filter = createAggregateFilterForField(field);
  }

  const vizCalcs = Viz.getCalcsFromViz(discovery.viz);

  const dispatch = useDispatch();
  const changeAggregateFilter = useCallback(
    filter => {
      dispatch(
        Discover.setActiveFieldFilter({
          ...filter,
          dialogType: FilterDialogTypes.AGGREGATE,
        }),
      );
    },
    [dispatch],
  );
  const changeFilter = useCallback(
    filter => {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
    [dispatch],
  );

  return (
    <FilterDialogContext.Provider
      value={{
        field,
        filter,
        isFilterAggregateDialog,
        changeAggregateFilter,
        changeFilter,
        vizCalcs,
      }}
    >
      {children}
    </FilterDialogContext.Provider>
  );
};

export const useFilterDialogContext = () => useContext(FilterDialogContext);
