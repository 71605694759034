import FieldGroupPill from '../FieldGroupPill';
import { EditIcon } from '../../icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ModernBorderRadius } from '../../common/emotion/mixins';
import FieldPill from '../FieldPill';

export const fieldListItemStyled = css`
  padding-left: 5px;
`;

export const HiddenFieldsHeader = styled.div(
  ({ theme: { colors: { LightFontWeight } = {} } = {} }) => css`
    font-weight: ${LightFontWeight};
    font-size: 14px;
    padding-left: 5px;
    margin: 7px 0px;
  `,
);

// may not be needed anymore
export const FieldPillStyled = styled(FieldPill)`
  ${fieldListItemStyled}
  margin-left: 10px;

  .viz-field-pill {
    margin-left: 0;
  }
`;

// may not be needed anymore
export const FieldPillGroupStyled = styled(FieldGroupPill)`
  ${fieldListItemStyled}
`;

export const EditIconStyled = styled(EditIcon)`
  margin: 0 0.5em;
`;

export const VizFieldSearchContainer = styled.div(
  ({
    theme: {
      colors: { PanelBorder, Strokes, ContentText, ContentBackground } = {},
    } = {},
  }) => css`
    position: relative;
    background: ${ContentBackground};
    border: 1px solid ${PanelBorder};
    border-radius: ${ModernBorderRadius} ${ModernBorderRadius} 0 0;
    border-bottom: none;

    > div {
      width: 100%;
    }

    &.search-input {
      input {
        margin-bottom: 0px;
        border-radius: 0px;
        box-shadow: none !important;
        padding-right: 24px;
        padding-left: 10px;
        border: none;
        border-radius: 8px 8px 0 0;
        width: 100%;
      }

      input:focus {
        border-color: ${Strokes};
        color: ${ContentText};
      }
    }

    hr {
      position: absolute;
      bottom: 5px;
      right: 0px;
      left: 0px;
      margin: 0px 8px;
    }

    .sicon-search {
      padding-bottom: 3px;
    }
  `,
);
