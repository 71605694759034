import { Component } from 'react';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  InvisibleButton,
} from '../ui/button';
import { DatasetQueries } from '../common/graphql';
import { client } from '../common/ApolloClient';
import { graphql } from '@apollo/client/react/hoc';
import _, { cloneDeep, every, isEmpty, isEqual } from 'lodash';
import { FullScreenPortal } from '../common/widgets/dialogs/full-screen-portal';
import DatasetPreviewTable from '../views/DatasetPreviewTable';
import { branch, renderNothing, withState } from 'react-recompose';
import { connect } from 'react-redux';
import Dataset from '../common/redux/actions/DatasetActions';
import Discover from '../common/redux/actions/DiscoverActions';
import Main from '../common/redux/actions/MainActions';
import { PromptDialog } from '../common/widgets/dialogs/prompt-dialog';
import Util from '../common/Util';
import {
  CUSTOM_FORMAT_ANNOTATION,
  Types,
  VIZ_OPTION_IDS,
} from '../common/Constants';
import CalcDialog from '../discovery/calc/CalcDialog';
import { Hierarchy } from '../discovery/VizUtil';
import { messages } from '../i18n';
import { compose } from 'react-recompose';
import JSON5 from 'json5';
import { CustomFormatModal } from '../discovery/custom-format-modal';
import { HelpBlock } from '../views/VizSaveDialog/ui';
import DiscoverQueries from '../common/graphql/DiscoverQueries';

class FieldConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetName: '',
      datasetDescription: '',
      attributes: [],
      removeAttributes: [],
      removeCalcField: null,
      newCalcFields: [],
      attributeAnnotationKeys: [],
      showDeleteCalcDialog: false,
      saveDisabled: true,
      saveInProgress: false,
      showCustomFormatModal: false,
      currentEditField: {},
    };

    this.saveDatasetSettings = this.saveDatasetSettings.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loading &&
      !isEqual(this.props.loading, prevProps.loading) &&
      this.props.datasetDetail
    ) {
      if (
        every(
          [
            this.state.datasetName,
            this.state.datasetDescription,
            this.state.attributes,
          ],
          isEmpty,
        )
      ) {
        // build a reference list of annotations with at least one value of each annotation key
        const attributeAnnotationKeys =
          this.props.datasetDetail.attributes.reduce(
            (annotationKeys, attribute) => {
              const keys = attribute.annotations.reduce((annotations, anno) => {
                // return the list of annotations in the attribute that have a value
                if (!_.isEmpty(anno.value)) {
                  annotations.push(anno.key);
                }
                return annotations;
              }, []);

              // merge the list from the attribute into the annotationKeys list
              annotationKeys = _.union(annotationKeys, keys);

              return annotationKeys;
            },
            [],
          );

        this.setState({
          datasetName: this.props.datasetDetail.name,
          datasetDescription: this.props.datasetDetail.description,
          attributes: this.props.datasetDetail.attributes,
          attributeAnnotationKeys,
        });
      }
    }

    if (
      !_.isNil(this.props?.datasetDetail?.annotations) &&
      !_.isEqual(
        prevProps?.datasetDetail?.annotations,
        this.props?.datasetDetail?.annotations,
      )
    ) {
      // clean annotation fields
      const nextAnnotations = _.isEmpty(this.props.datasetDetail.annotations)
        ? []
        : this.props.datasetDetail.annotations.map(anno => {
            return {
              key: anno.key,
              value: anno.value,
            };
          });

      // annotations can change after dataset mutation
      this.setState({
        datasetAnnotations: nextAnnotations,
      });
    }
  }

  updateColumnInfo(
    newColumn,
    changePropertyName,
    triggerCustomFormatModal = false,
  ) {
    if (triggerCustomFormatModal) {
      this.setState({
        showCustomFormatModal: true,
        currentEditField: newColumn,
      });
    }
    const attrs = [...this.state.attributes];
    const { originalName } = newColumn;
    let index = _.findIndex(attrs, { originalName });
    if (index !== -1) {
      // editing existing attribute
      attrs.splice(index, 1, newColumn);
      this.setState({ attributes: [...attrs], saveDisabled: false });
    } else {
      const newCalcFields = [...this.state.newCalcFields];
      index = _.findIndex(newCalcFields, { originalName });
      if (index !== -1) {
        // editing new calc
        newCalcFields.splice(index, 1, newColumn);
        this.setState({
          newCalcFields: [...newCalcFields],
          saveDisabled: false,
        });
      }
    }
  }

  saveCalc(
    name,
    description,
    formula,
    previousField,
    dataType = Types.NUMBER,
    aggregation = 'Sum',
    aggregateMeasure = false,
    formatType = '',
    customFormatProps,
  ) {
    let field = {
      name,
      description,
      attributeType: dataType,
      defaultAggregation: aggregateMeasure ? 'NONE' : aggregation,
      aggregateMeasure,
      calculation: formula,
      formatType,
      customFormatProps,
    };
    if (!_.isNil(previousField)) {
      field = { ...previousField, ...field };
    }
    this.saveCalcField(field);
  }

  saveCalcField(updatingCalc) {
    const newCalcFields = [...this.state.newCalcFields];
    let calc = _.cloneDeep(updatingCalc);
    const { originalName, customFormatProps } = calc;
    calc = _.omit(calc, ['customFormatProps']);
    let annotation = null;
    if (!_.isNil(customFormatProps)) {
      annotation = {
        key: CUSTOM_FORMAT_ANNOTATION,
        value: JSON5.stringify(customFormatProps),
      };
    }
    if (!_.isNil(calc.annotations) && !_.isNil(annotation)) {
      calc.annotations = [...calc.annotations, annotation];
    } else if (_.isEmpty(calc.annotations) && !_.isNil(annotation)) {
      calc.annotations = [annotation];
    } else if (_.isEmpty(calc.annotations) && _.isNil(annotation)) {
      calc.annotations = [];
    }
    let index = _.findIndex(newCalcFields, { originalName });
    if (index !== -1) {
      // editing new calc
      newCalcFields.splice(index, 1, calc);
      this.setState({ newCalcFields: [...newCalcFields], saveDisabled: false });
    } else {
      const attrs = [...this.state.attributes];
      index = _.findIndex(attrs, { originalName });
      if (index !== -1) {
        // editing existing calc
        attrs.splice(index, 1, calc);
        this.setState({ attributes: [...attrs], saveDisabled: false });
      } else {
        // adding new calc
        calc = {
          ...calc,
          originalName: calc.name,
          hidden: false,
          ordinalAttribute: '',
          annotations: [...calc.annotations],
        };
        newCalcFields.push(calc);
        this.setState({
          newCalcFields: [...newCalcFields],
          saveDisabled: false,
        });
      }
    }
    this.props.setShowFieldCalcDialog(false);
  }

  removeCalcField() {
    const calc = this.state.removeCalcField;
    const { originalName } = calc;
    if (!_.isNil(calc)) {
      const newCalcFields = [...this.state.newCalcFields];
      const attrs = [...this.state.attributes];
      const removeAttrs = [...this.state.removeAttributes];
      const index = _.findIndex(newCalcFields, { originalName });
      if (index === -1) {
        // add to remove attributes list
        removeAttrs.push(calc.index);
        // remove from current attributes
        _.remove(attrs, { originalName });
        this.setState({
          attributes: [...attrs],
          removeAttributes: [...removeAttrs],
          removeCalcField: null,
          saveDisabled: false,
        });
      } else {
        _.remove(newCalcFields, { originalName });
        this.setState({
          newCalcFields: [...newCalcFields],
          removeCalcField: null,
          saveDisabled: false,
        });
      }
    }
  }

  // Dataset Settings currently saves the fiscal calendar annotation
  saveDatasetSettings(updatingData) {
    const { annotations } = updatingData;

    if (!_.isNil(annotations)) {
      this.setState({
        datasetAnnotations: annotations,
        saveDisabled: false,
      });
    }
  }

  submit(apply) {
    this.setState({
      [apply ? 'applyInProgress' : 'saveInProgress']: true,
      saveDisabled: true,
    });
    apply && this.props.setApplyInProgress(true);

    const attributes = this.state.attributes.map(attr => {
      const a = { ...attr };
      delete a.originalName;
      return a;
    });
    const newCalcFields = this.state.newCalcFields.map(calc => {
      const c = { ...calc };
      delete c.originalName;
      return c;
    });
    // perform checks
    // Attribute names must be unique (collect names in a map to get a distinct count)
    const checkAttributes = attributes.concat(newCalcFields);
    if (
      checkAttributes.length >
      _.keys(
        checkAttributes.reduce((acc, attr) => {
          acc[attr.name] = 1;
          return acc;
        }, {}),
      ).length
    ) {
      this.setState({
        showError: true,
        errorTitle: messages.editDatasetPanel.errorSavingDataset,
        errorDetail: messages.editDatasetPanel.attributeNamesMustBeUnique,
      });
      return;
    }

    const datasetAnnotations = _.isEmpty(this.state.datasetAnnotations)
      ? []
      : this.state.datasetAnnotations;
    const dataset = {
      id: this.props.datasetId,
      name: this.state.datasetName,
      description: this.state.datasetDescription,
      attributes: attributes.concat(newCalcFields),
      annotations: datasetAnnotations,
    };
    this.props.updateDataset(
      dataset,
      this.state.removeAttributes,
      !apply,
      success => {
        this.props.setApplyInProgress(false);
        this.setState({
          saveDisabled: success,
        });
      },
    );
  }

  updateCurrentField = formData => {
    const serializedForm = JSON5.stringify(formData);
    const index = _.findIndex(this.state.attributes, {
      index: this.state.currentEditField.index,
    });

    const newAttrs = cloneDeep(this.state.attributes);
    const customFormatAnnotation = {
      key: CUSTOM_FORMAT_ANNOTATION,
      value: serializedForm,
    };
    const otherAnnotations = _.reject(newAttrs[index].annotations, {
      key: CUSTOM_FORMAT_ANNOTATION,
    });
    newAttrs[index].annotations = [...otherAnnotations, customFormatAnnotation];

    this.setState({
      attributes: newAttrs,
      updateCurrentField: {},
    });
  };

  getCurrentFieldCustomProps = () => {
    const customFormat = _.findLast(this.state.currentEditField.annotations, {
      key: CUSTOM_FORMAT_ANNOTATION,
    });

    return !_.isNil(customFormat) ? JSON5.parse(customFormat.value) : null;
  };

  render() {
    const columns = this.state.attributes.concat(this.state.newCalcFields);
    const columnsWithTimeHierarchies = expandTimeHierarchyColumns(columns);
    const isFullEditMode =
      _.get(this, 'props.datasetDetail.editMode', 'LIMITED') === 'FULL';

    const currencySymbol = _.find(this.state?.datasetAnnotations, {
      key: 'DEFAULT_CURRENCY_SYMBOL',
    })?.value;

    return (
      <>
        <FullScreenPortal
          className='dataset-edit-dialog'
          titlePanel={messages.editDatasetPanel.title}
          doCancel={() => this.props.close()}
          buttonGroup={
            <ButtonContainer>
              <InvisibleButton
                className='cancel-button'
                onClick={() => this.props.close()}
              >
                {messages.cancel}
              </InvisibleButton>
              <SecondaryButton
                onClick={() => {
                  this.submit(true);
                }}
                disabled={this.props.applyInProgress || this.state.saveDisabled}
              >
                {this.props.applyInProgress
                  ? messages.editDatasetPanel.applying
                  : messages.editDatasetPanel.applyChanges}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  this.submit();
                }}
                disabled={
                  this.state.saveInProgress ||
                  this.props.applyInProgress ||
                  this.state.saveDisabled
                }
              >
                {this.state.saveInProgress
                  ? messages.editDatasetPanel.updating
                  : messages.editDatasetPanel.saveChangesAndExit}
              </PrimaryButton>
            </ButtonContainer>
          }
        >
          {this.state.showCustomFormatModal && (
            <CustomFormatModal
              show={this.state.showCustomFormatModal}
              onHide={() => {
                this.setState({ showCustomFormatModal: false });
              }}
              onSubmit={formData => {
                this.updateCurrentField(formData);
                this.setState({
                  showCustomFormatModal: false,
                });
              }}
              customFormatProps={this.getCurrentFieldCustomProps()}
              currencySymbol={currencySymbol}
            />
          )}
          <PromptDialog
            asInfo
            show={this.state.showError}
            title={this.state.errorTitle}
            detail={
              <HelpBlock className='centered-panel'>
                {this.state.errorDetail}
              </HelpBlock>
            }
            doOk={() =>
              this.setState({
                showError: false,
                saveInProgress: false,
              })
            }
          />
          <DatasetPreviewTable
            datasetId={this.props.datasetId}
            allowAnnotationEditing={isFullEditMode}
            annotationKeys={this.state.attributeAnnotationKeys}
            attributes={columns}
            currencySymbol={currencySymbol}
            onChange={(newColumn, changePropertyName, triggerModal) =>
              this.updateColumnInfo(newColumn, changePropertyName, triggerModal)
            }
            addCalc={() => this.props.setShowFieldCalcDialog(true)}
            removeCalc={calc =>
              this.setState({
                removeCalcField: calc,
                showDeleteCalcDialog: true,
              })
            }
            loading={this.props.loading}
          />
          {this.props.showCalcDialog && (
            <CalcDialog
              datasetId={this.props.datasetId}
              allFields={[]}
              fields={columnsWithTimeHierarchies}
              newDatasetAttribs={columns}
              currencySymbol={currencySymbol}
              fieldNamesFromVizCalcs={this.props.reportCalcFieldNames}
              saveCalc={(
                name,
                description,
                formula,
                previousField,
                dataType,
                aggregation,
                aggregateMeasure,
                formatType,
                customFormatProps,
              ) =>
                this.saveCalc(
                  name,
                  description,
                  formula,
                  previousField,
                  dataType,
                  aggregation,
                  aggregateMeasure,
                  formatType,
                  customFormatProps,
                )
              }
            />
          )}
          {this.state.removeCalcField && (
            <PromptDialog
              show={this.state.showDeleteCalcDialog}
              detail={
                <HelpBlock className='delete-calc-detail'>
                  <p>
                    {messages.formatString(
                      messages.editDatasetPanel.deleteCalcFieldConfirm,
                      this.state.removeCalcField.name,
                    )}
                  </p>
                  <p>{messages.editDatasetPanel.deleteCalcWarning}</p>
                </HelpBlock>
              }
              doYes={() => this.removeCalcField()}
              doNo={() => this.setState({ showDeleteCalcDialog: false })}
            />
          )}
        </FullScreenPortal>
      </>
    );
  }
}

const DatasetDetail = graphql(DatasetQueries.DatasetDetail, {
  // don't run the query if we don't have a datasetId
  skip: ownProps => _.isEmpty(ownProps.datasetId),
  options: ({ datasetId }) => ({
    variables: {
      id: datasetId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ data }) => {
    if (data?.dataset && !data.loading) {
      const attributes = data.dataset.attributes.map(attr => {
        return { ...attr, originalName: attr.name };
      });
      // default dataset annotations
      const annotations = data?.dataset?.annotations
        ? data?.dataset?.annotations
        : [];
      return {
        loading: false,
        datasetDetail: { ...data.dataset, attributes, annotations },
      };
    } else {
      return { loading: true };
    }
  },
});

const AllReportCalcsQuery = graphql(DiscoverQueries.AllVizOptionsQuery, {
  skip: ownProps => _.isEmpty(ownProps.datasetId),
  props: ({ data, ownProps }) => {
    const { visualizations } = data;
    const reportCalcFieldNames = _(visualizations)
      .filter({
        datasetId: ownProps.datasetId,
      })
      .flatMap('options')
      .filter({ key: VIZ_OPTION_IDS.calcFields })
      .flatMap('value')
      .flatMap(val => {
        try {
          const parsed = JSON5.parse(val);
          return _.map(parsed, 'name');
        } catch {
          console.error('parse error', val);
          return [];
        }
      })
      .uniq()
      .value();

    return { reportCalcFieldNames };
  },
});

const UpdateDatasetMutation = graphql(DatasetQueries.UpdateDataset, {
  options: props => ({
    refetchQueries: [
      {
        query: DatasetQueries.DatasetDetail,
        variables: { id: props.datasetId },
      },
    ],
  }),
  props: ({ mutate, ownProps }) => ({
    // Delete dataset attributes, and then update
    updateDataset(dataset, removeAttributeIdxs, closeDialog, cb) {
      const cbFn = _.once(cb);
      mutate({
        variables: {
          dataset: {
            ...dataset,
            attributes: [],
          },
          removeAttributeIdxs,
        },
      })
        .then(() => {
          // Re-index updated attributes
          dataset.attributes.forEach((attr, idx) => {
            attr.index = idx;
          });
          client
            .mutate({
              mutation: DatasetQueries.UpdateDataset,
              variables: {
                dataset: Util.removeTypeNameRecursively(dataset),
                removeAttributeIdxs: [],
              },
            })
            .then(({ data }) => {
              // Update open discoveries using this dataset
              if (!_.isEmpty(ownProps.openDiscoveriesUsingThisDataset)) {
                ownProps.openDiscoveriesUsingThisDataset.forEach(open => {
                  console.log(
                    `FORCE UPDATE OF VIZ: ${open.present.name}`,
                    data.updateDataset,
                  );
                  const id = open.present.viz.id || open.present.id;
                  ownProps.updateDatasetInViz(id, data.updateDataset);
                });
              }
              if (closeDialog) {
                ownProps.close();
                if (_.isFunction(ownProps.onClose)) {
                  ownProps.onClose();
                }
              } else {
                // apply
                ownProps.setApplyInProgress(false);
              }
            })
            .catch(error => {
              console.log('Error updating dataset', error);
              cbFn(false);
              ownProps.showError();
            });
        })
        .catch(error => {
          console.log('Error updating dataset', error);
          cbFn(false);
          ownProps.showError();
        })
        .finally(() => ownProps.setApplyInProgress(false));
    },
  }),
});

export const expandTimeHierarchyColumns = attributes => {
  // overlay any hierarchy fields saved with this viz
  const fieldsWithHierarchies = attributes.reduce((fields, current) => {
    const field = { ...current };
    if (field.attributeType === Types.TIMESTAMP) {
      // show all supported levels by default
      const showTimeAttributes = [
        Hierarchy.TIME_ATTRIBUTES.YEAR,
        Hierarchy.TIME_ATTRIBUTES.QTR,
        Hierarchy.TIME_ATTRIBUTES.MONTH,
        Hierarchy.TIME_ATTRIBUTES.WEEK,
        Hierarchy.TIME_ATTRIBUTES.DAY,
        Hierarchy.TIME_ATTRIBUTES.EXACT_DATE,
        Hierarchy.TIME_ATTRIBUTES.DATE,
      ];
      const hierarchyFields = Hierarchy.createTimeCalcFields(
        field,
        showTimeAttributes,
      );
      if (!_.isEmpty(hierarchyFields)) {
        field.children = [...hierarchyFields.filter(thf => !thf.hidden)];
      }
    }
    fields.push(field);
    return fields;
  }, []);
  return fieldsWithHierarchies;
};

export default compose(
  withState('applyInProgress', 'setApplyInProgress', false),
  connect(
    state => {
      const openDiscoveriesUsingThisDataset = Object.values(
        state.discover.openDiscoveries,
      ).filter(
        open => open?.present?.dataset?.id === state.dataset.editDataset,
      );
      return {
        datasetId: state.dataset.editDataset,
        editable: !state.dataset.editDatasetPreviewMode,
        openDiscoveriesUsingThisDataset,
        showCalcDialog: state.discover.showFieldCalcDialog,
      };
    },
    dispatch => {
      return {
        close: () => {
          dispatch(Dataset.editDataset(null));
        },
        updateDatasetInViz: (vizId, dataset) => {
          dispatch(Discover.updateDatasetForDiscovery(vizId, dataset));
        },
        showError: () => {
          dispatch(
            Main.showErrorDetail(
              true,
              messages.editDatasetPanel.errorSavingDataset,
              messages.editDatasetPanel.unknownError,
            ),
          );
        },
        setShowFieldCalcDialog(show, field) {
          dispatch(Discover.setShowFieldCalcDialog(show, field));
        },
        openDatasetSettings(datasetId) {
          dispatch(Discover.openDatasetSettings(datasetId, true));
        },
        closeDatasetSettings(datasetId) {
          dispatch(Discover.openDatasetSettings(datasetId, false));
        },
      };
    },
  ),
  branch(props => !props.datasetId, renderNothing),
  DatasetDetail,
  AllReportCalcsQuery,
  UpdateDatasetMutation,
)(FieldConfiguration);
