import ConfirmLogoutPromptComponent from './component';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys, withHandlers } from 'react-recompose';
import _ from 'lodash';
import { isReadOnly } from '../../common/redux/selectors/AccountSelectors';
import Login from '../../common/redux/actions/LoginActions';
import { DiscoverQueries } from '../../common/graphql';
import { Viz } from '../../discovery/VizUtil';

import { graphql } from '@apollo/client/react/hoc';

const mapStateToProps = state => {
  let unsaved = [];
  const { openDiscoveries } = state.discover;
  if (!_.isEmpty(openDiscoveries)) {
    unsaved = Object.values(openDiscoveries)
      .filter(
        d => !_.isNil(d.present) && d.present.dirty && d.present.canUpdate,
      )
      .map(d => d.present);
  }

  return {
    isReadOnlyUser: isReadOnly(state.account),
    unsaved,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    logout() {
      Login.logout(dispatch);
    },
  };
};

const SaveVizMutation = graphql(DiscoverQueries.CreateVisualizationMutation, {
  props: ({ mutate }) => ({
    newVisualization(viz, name) {
      const vizRequest = Viz.updateVizForSave(viz, undefined, name);
      return mutate({
        variables: {
          viz: vizRequest,
        },
      });
    },
  }),
});

const UpdateVizMutation = graphql(DiscoverQueries.UpdateVisualizationMutation, {
  props: ({ mutate }) => ({
    updateVisualization(viz) {
      const vizRequest = Viz.updateVizForSave(viz);
      return mutate({
        variables: {
          viz: vizRequest,
        },
      });
    },
  }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onlyUpdateForKeys(['show']),
  UpdateVizMutation,
  SaveVizMutation,
  withHandlers({
    saveUnsaved: props => (unsaved, onComplete) => {
      const promises = [];
      unsaved.forEach(d => {
        if (_.isNil(d.viz.id)) {
          promises.push(props.newVisualization(d.viz, d.name));
        } else {
          promises.push(props.updateVisualization(d.viz));
        }
      });
      // wait for all of the mutations to complete
      Promise.all(promises)
        .then(results => {
          console.log('Done updating all unsaved visualizations');
          onComplete(results);
        })
        .catch(e => {
          onComplete(e);
        });
    },
  }),
)(ConfirmLogoutPromptComponent);
