import {
  IWebWorker,
  IWebWorkerArgs,
  IWebWorkerResponse,
} from './web-worker.interfaces';
import { noop, isObject, isNil, isFunction } from 'lodash';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WorkerRefContext } from './web-worker.context';

export function useWebWorker<
  MessageType,
  ResponseType extends IWebWorkerResponse,
>({
  workerId,
  relativeUrlToBase,
  name = 'web worker',
  onMessage = noop,
  onError = noop,
}: IWebWorkerArgs<ResponseType>) {
  const baseUrl = useSelector((state: any) => state?.main?.appUrl);
  const { getWorker, registerWorker } = useContext(WorkerRefContext);

  const { worker, postMessage, terminate } = useMemo(() => {
    let worker: IWebWorker<MessageType> = getWorker(workerId);

    const postMessage = (messageObject: MessageType) => {
      // restarts if worker has been terminated
      if (isNil(worker) || worker.terminated) {
        try {
          worker = new Worker(new URL(relativeUrlToBase, baseUrl), {
            name,
            type: 'module',
            credentials: 'omit',
          });

          // add terminated status, since it is not in Worker spec
          worker.terminated = false;

          registerWorker({ workerId, worker });

          worker.onerror = onError;
          worker.onmessageerror = onError;

          worker.onmessage = ({ data }: MessageEvent<string>) => {
            let responseObject: ResponseType = {} as ResponseType;
            try {
              // enforce JSON message passing
              responseObject = isObject(data) ? data : JSON.parse(data);
            } catch (e) {
              responseObject.errorMessage = e;
            }

            onMessage(responseObject);
          };
        } catch (e) {
          console.error('Could not start web worker', e);
        }

        worker.postMessage(messageObject);
      } else {
        try {
          worker.postMessage(messageObject);
        } catch (e) {
          console.log('error posting message to web worker', e);
        }
      }
    };

    const terminate = () => {
      if (isFunction(worker?.terminate)) {
        worker.terminated = true;
        worker.terminate();
      }
    };

    return {
      worker,
      postMessage,
      terminate,
    };
  }, [
    baseUrl,
    getWorker,
    name,
    onError,
    onMessage,
    registerWorker,
    relativeUrlToBase,
    workerId,
  ]);

  return {
    worker,
    postMessage,
    terminate,
  };
}
