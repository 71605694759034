import {
  FilterSubType,
  FilterType,
  IAnyAttribute,
  IAppliedFilters,
  IExpression,
} from '../../../datasets';
import {
  ISugarFieldDef,
  ISugarFilterCollection,
  ISugarFilterDef,
  ISugarRuntimeFilter,
  ISugarTableList,
  SugarRuntimeDef,
} from './sugar-filter-converter.interfaces';
import { timestampOperators } from '../../../discovery/filter/FilterOperators';
import {
  FilterTypes,
  LogicalOperators,
  StringFilterSubTypes,
  TimestampFilterSubTypes,
} from '../../../discovery/filter/Filter';
import {
  convertDateOperands,
  convertDayOperatorToOperands,
  convertRelativeDatesOperatorToOperands,
  generateAliasModuleMap,
  sugarFilterOperatorsMap,
  timestampFilterOverrideMap,
} from './sugar-filter-utils';
import {
  endsWith,
  find,
  forEach,
  get,
  includes,
  initial,
  isArray,
  isEmpty,
  isEqual,
  isString,
  isUndefined,
  join,
  keys,
  last,
  map,
  omit,
  reduce,
  some,
  split,
  values,
} from 'lodash';
import JSON5 from 'json5';
import { FieldTypes, Types } from '../../Constants';

const AND = 'AND';
const REF_TO = 'REF_TO_FIELD';
export const SOURCE_NAME = 'SOURCE_NAME';
const ENUM_ANNOTATION = 'ENUM_ID_ATTRIB';
export const SUGAR_ENUM_SUFFIX = '_value';

const getOperands = (
  operands: string | string[],
  operandMapper?: (o: string) => string,
): string[] => {
  const ops = isArray(operands) ? operands : [operands];
  return isUndefined(operandMapper) ? ops : map(ops, operandMapper);
};

const isDateField = (fieldType: FieldTypes): boolean => {
  return includes([Types.TIMESTAMP, FilterTypes.DATE], fieldType);
};

const resolveOperands = (filterDef: ISugarFilterDef): string | string[] =>
  get(
    filterDef,
    last(
      keys(omit(filterDef, ['qualifier_name'])).filter(
        key => !isEmpty(filterDef[key]),
      ),
    ),
  );

const generateDiscoverFilter = (
  filterDef: ISugarFilterDef,
  discoverFieldType: FieldTypes,
): {
  expression: IExpression;
  type: FilterType;
  subType?: FilterSubType;
} => {
  const _op = filterDef.qualifier_name;
  const operator = isDateField(discoverFieldType)
    ? (timestampFilterOverrideMap[_op] ?? '')
    : (sugarFilterOperatorsMap[_op] ?? '');
  const type = isDateField(discoverFieldType)
    ? FilterTypes.DATE
    : FilterTypes.STRING;
  switch (_op) {
    case 'empty':
    case 'not_empty':
      return {
        expression: {
          left: {
            operator,
            operands: [],
          },
        },
        type,
      };
    case 'is':
    case 'is_not':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(resolveOperands(filterDef)),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SET_CONDITION,
      };
    case 'contains':
    case 'does_not_contain':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(filterDef.input_name0, o => `%${o}%`),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SET_CONDITION,
      };
    case 'starts_with':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(filterDef.input_name0, o => `${o}%`),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SET_CONDITION,
      };
    case 'ends_with':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(filterDef.input_name0, o => `%${o}`),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SET_CONDITION,
      };
    case 'equals':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(filterDef.input_name0),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SET_CONDITION,
      };
    case 'one_of':
    case 'not_one_of':
      return {
        expression: {
          left: {
            operator,
            operands: getOperands(filterDef.input_name0),
          },
        },
        type: FilterTypes.STRING,
        subType: StringFilterSubTypes.SELECT_ITEMS,
      };
    case 'tp_yesterday':
    case 'tp_today':
    case 'tp_tomorrow':
      return {
        expression: {
          left: {
            operator,
            operands: ['1', 'DAYS', 'false'],
          },
        },
        type: FilterTypes.DATE,
        subType: TimestampFilterSubTypes.RELATIVE_DATES,
      };
    case 'tp_last_7_days':
    case 'tp_last_30_days':
    case 'tp_last_n_days':
    case 'tp_next_7_days':
    case 'tp_next_30_days':
    case 'tp_next_n_days':
      return {
        expression: {
          left: {
            operator,
            operands: convertDayOperatorToOperands(_op, filterDef.input_name0),
          },
        },
        type: FilterTypes.DATE,
        subType: TimestampFilterSubTypes.RELATIVE_DATES,
      };
    case 'tp_last_month':
    case 'tp_last_quarter':
    case 'tp_last_year':
    case 'tp_this_month':
    case 'tp_this_quarter':
    case 'tp_this_year':
    case 'tp_next_month':
    case 'tp_next_quarter':
    case 'tp_next_year':
    case 'tp_previous_fiscal_quarter':
    case 'tp_previous_fiscal_year':
    case 'tp_current_fiscal_quarter':
    case 'tp_current_fiscal_year':
    case 'tp_next_fiscal_quarter':
    case 'tp_next_fiscal_year':
      return {
        expression: {
          left: {
            operator,
            operands: convertRelativeDatesOperatorToOperands(_op),
          },
        },
        type: FilterTypes.DATE,
        subType: TimestampFilterSubTypes.RELATIVE_DATES,
      };
    case 'on':
    case 'before':
    case 'after':
    case 'not_equals_str':
      return isEqual(type, FilterTypes.DATE)
        ? {
            expression: {
              left: {
                operator,
                operands: convertDateOperands(filterDef.input_name0),
              },
            },
            type: FilterTypes.DATE,
            subType: TimestampFilterSubTypes.SET_CONDITION,
          }
        : {
            expression: {
              left: {
                operator,
                operands: getOperands(filterDef.input_name0),
              },
            },
            type,
            subType: StringFilterSubTypes.SET_CONDITION,
          };
    case 'between_dates':
      return {
        expression: {
          left: {
            operator: timestampOperators.gte.key,
            operands: convertDateOperands(filterDef.input_name0),
          },
          right: {
            operator: timestampOperators.lte.key,
            operands: convertDateOperands(filterDef.input_name1),
          },
          operator: LogicalOperators.AND,
        },
        type: FilterTypes.DATE,
        subType: TimestampFilterSubTypes.SET_CONDITION,
      };
    default:
      return { expression: {} as IExpression, type: FilterTypes.STRING };
  }
};

const buildSourceName = ({
  module = '',
  fieldDef,
}: {
  module: string;
  fieldDef: ISugarFieldDef;
}) =>
  `${module}_${fieldDef?.name}${
    isSugarFieldEnum(fieldDef?.type) ? SUGAR_ENUM_SUFFIX : ''
  }`;

const isSugarFieldEnum = (fieldType: ISugarFieldDef['type']) =>
  isEqual(fieldType, 'enum');

export const isDiscoverFieldEnum = (field: IAnyAttribute) => {
  const fieldSourceName = find(field.annotations, { key: SOURCE_NAME })?.value;
  return endsWith(fieldSourceName, SUGAR_ENUM_SUFFIX);
};

export const convertSugarFilter = (
  sugarFilter: ISugarRuntimeFilter,
  vizRuntimeFilters: IAnyAttribute[],
): IAppliedFilters => <IAppliedFilters>reduce(
    values(sugarFilter),
    (result: IAppliedFilters, sugarRuntimeDef: SugarRuntimeDef) => {
      const { fieldDef, targetModule, filterDef } = sugarRuntimeDef;
      const isEnum = isSugarFieldEnum(fieldDef?.type);
      const sourceName = buildSourceName({
        module: targetModule,
        fieldDef,
      });
      const refToName = `${targetModule}:${fieldDef.name}`;
      const field = find(vizRuntimeFilters, ({ annotations }) =>
        some(
          annotations,
          ann =>
            (isEqual(ann.key, REF_TO) && isEqual(ann.value, refToName)) ||
            (isEqual(ann.key, SOURCE_NAME) &&
              isEqual(ann.value.toLowerCase(), sourceName.toLowerCase())),
        ),
      );

      if (!(field && filterDef)) {
        return result;
      }

      const { expression, type, subType } = generateDiscoverFilter(
        filterDef,
        field.attributeType,
      );

      const filterFieldName = isEnum
        ? (find(field.annotations, { key: ENUM_ANNOTATION })?.value ??
          field.name)
        : field.name;

      if (!isEmpty(expression)) {
        result[filterFieldName] = {
          field: filterFieldName,
          expression,
          type,
          subType,
        };
      }

      return result;
    },
    {},
  );

export const convertDiscoverRuntimeFilters = (
  runtimeFilters: string,
  datasetJson: string,
): { filterDefs: ISugarFilterCollection; fullTableList: ISugarTableList } => {
  try {
    const filters = isString(runtimeFilters)
      ? JSON5.parse(runtimeFilters)
      : runtimeFilters;
    const aliasMap = generateAliasModuleMap(datasetJson);
    const filterDefs: ISugarFilterCollection = {};
    const fullTableList: ISugarTableList = {};

    let idx = 0;
    forEach(filters, value => {
      const processAnnotation = (
        annotation: any,
        splitChar: string,
        hasEnum: boolean,
      ) => {
        const [moduleName, ...remaining] = annotation?.value
          ? split(annotation.value, splitChar)
          : [];

        const sugarModule = aliasMap[moduleName] ?? moduleName;
        const sourceName =
          splitChar === ':'
            ? last(remaining)
            : hasEnum
              ? isEqual(last(remaining), 'value')
                ? join(initial(remaining), '_')
                : join(remaining, '_')
              : join(remaining, '_');

        filterDefs[`${idx++}`] = {
          name: sourceName,
          table_key: moduleName,
          runtime: 1,
        };

        if (!fullTableList[moduleName]) {
          fullTableList[moduleName] = {
            value: sugarModule, //displays in tooltip
            module: sugarModule, //needs to be valid Sugar module
            label: sugarModule, //displays on the dashlet options
            dependents: [],
          };
        }
      };

      const refToAnnotation = find(value.annotations, {
        key: REF_TO,
      });
      const sourceNameAnnotation = find(value.annotations, {
        key: SOURCE_NAME,
      });
      const hasEnumAnnotation =
        !isEmpty(
          find(value.annotations, {
            key: ENUM_ANNOTATION,
          }),
        ) ||
        (isEqual(value.attributeType, 'STRING') &&
          !isEmpty(value.ordinalAttribute));

      if (refToAnnotation) {
        processAnnotation(refToAnnotation, ':', hasEnumAnnotation);
      } else if (sourceNameAnnotation) {
        processAnnotation(sourceNameAnnotation, '_', hasEnumAnnotation);
      }
    });

    filterDefs.operator = AND;

    return { filterDefs, fullTableList };
  } catch (e) {
    console.error('Error parsing filters', e);
    return {
      filterDefs: undefined,
      fullTableList: undefined,
    };
  }
};
