import { useIsDarkModeEnabled } from '../../redux/selectors/main-selector-hooks';
import { getAdjustedColors } from './linear-gradient.utils';

export const LinearGradient = ({
  id,
  isChartHorizontal = false,
  isChartNegative = false,
  baseColor,
}) => {
  const isDarkMode = useIsDarkModeEnabled();
  const gradientRotation = isChartHorizontal ? 0 : 90;
  const { adjustedDerivativeColor, adjustedBaseColor } = getAdjustedColors({
    isDarkMode,
    baseColor,
  });

  return (
    <defs>
      <linearGradient id={id} gradientTransform={`rotate(${gradientRotation})`}>
        <stop
          offset='0%'
          stopColor={
            isChartHorizontal || isChartNegative
              ? adjustedDerivativeColor
              : adjustedBaseColor
          }
        />
        <stop
          offset='100%'
          stopColor={
            isChartHorizontal || isChartNegative
              ? adjustedBaseColor
              : adjustedDerivativeColor
          }
        />
      </linearGradient>
    </defs>
  );
};
