import { compose, pure, withPropsOnChange } from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from '../base-cartesian-chart';
import { connect } from 'react-redux';
import LinePlot from './LinePlot';
import { LineChartUtils } from '../LineChartUtils';
import NoDataIfHOC from '../NoDataIfHOC';
import { withDiscoverRouter } from '../../../common/utilities/with-router';

class LineChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [
      {
        shelf: 'VALUES',
        Plot: LinePlot,
        dataId: 'lineData',
        TargetPlot: LinePlot,
        targetDataId: 'targetData',
      },
    ]);
    this.preventVerticalScrolling = true;
  }
}

export default compose(
  pure,
  withDiscoverRouter,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    let lineData = LineChartUtils.transformResult(
      props.queryResults,
      props.viz,
      undefined,
      undefined,
      undefined,
      props.i18nPrefs,
    );

    lineData = LineChartUtils.sortData(
      lineData,
      props.viz,
      props.queryResults.executeQuery.querySort,
    );

    // why are we duplicating data on two different variables?
    const targetData = { ...lineData };

    return { lineData, targetData };
  }),
  NoDataIfHOC(props => props.lineData.LINES.length === 0),
)(LineChart);
