import { Component } from 'react';
import ReactDOM from 'react-dom';
import { area, easeLinear, select } from 'd3';

class Area extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.pathElement && prevProps.transitionDuration > 0) {
      const p = ReactDOM.findDOMNode(this.pathElement);
      const node = select(p);

      const data = [...this.props.data];
      const pathDef = this.area(data, this.props);
      node
        .transition('area-ease')
        .duration(prevProps.transitionDuration)
        .ease(easeLinear)
        .attr('d', () => pathDef)
        .on('end', () => this.setState({ path: pathDef }));
    } else {
      this.setState({ path: this.area(this.props.data, this.props) });
    }
  }

  componentDidMount() {
    this.setState({ path: this.area(this.props.data) });
  }

  area(data, props) {
    let areaProps = this.props;
    if (props) {
      areaProps = props;
    }
    const areaFunc = area()
      .x(d => {
        return areaProps.xScale(areaProps.getX(d));
      })
      .y1(d => {
        return areaProps.yScale(areaProps.getY1(d));
      })
      .y0(d => {
        return areaProps.yScale(areaProps.getY(d));
      });

    return areaFunc(data);
  }

  renderAreaPath(props) {
    return (
      <path
        ref={p => {
          this.pathElement = p;
        }}
        className={props.className}
        d={this.state.path}
        style={{ fill: props.color }}
      />
    );
  }

  render() {
    return <g>{this.renderAreaPath(this.props)}</g>;
  }
}

Area.defaultProps = {
  className: 'valArea',
  transitionDuration: 0,
};

export default Area;
