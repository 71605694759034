import { get, includes, isFunction } from 'lodash';
import { Tabs } from '@mui/material';
import classnames from 'classnames';
import { TableSpec } from '../tableSpec';
import { messages } from '../../../i18n';
import * as UI from '../ui';
import { ToolbarButton } from '../ui/toolbar-button';

export const FilterToolbar = ({
  activeFilters,
  onToggleFilter,
  onClearFilters,
}) => {
  return (
    <UI.FilterToolBar>
      <UI.Label>{messages.editDatasetPanel.filterByLabel}</UI.Label>
      <Tabs
        value={0}
        sx={{
          minHeight: '32px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {TableSpec.filters.map(f => {
          const isActive = includes(activeFilters, f);
          return (
            !f.hidden && (
              <ToolbarButton
                key={f.id}
                className={classnames({ active: isActive })}
                title={get(messages, f.tooltip, f.tooltip)}
                onClick={e => onToggleFilter(f, !isActive, e)}
                label=''
                icon={<>{isFunction(f.icon) ? f.icon({ isActive }) : f.icon}</>}
              />
            )
          );
        })}
      </Tabs>
      <UI.LinkButton onClick={e => onClearFilters(e)}>
        {messages.editDatasetPanel.clearFiltersLabel}
      </UI.LinkButton>
    </UI.FilterToolBar>
  );
};
