import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DatePicker as DatePickerCSS } from './css/DatePicker.styles';
import { DateRangePicker as DateRangePickerCSS } from './css/DateRangePicker.styles';
import { useDiscoverTheme } from '../../../common/emotion';
import { useIsResponsive } from '../../../auth';
import { noop } from 'lodash';
import { useEffect } from 'react';

/**
 * NOTE that these styles are derived from Adobe's react-aria components. CSS
 * styling was provided by Adobe (https://react-spectrum.adobe.com/react-aria/DatePicker.html)
 * and modified for our use
 */
const useDateCssRules = ({ onMount = noop }) => {
  useEffect(() => {
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isResponsive = useIsResponsive();

  const {
    colors: {
      ContentBackground,
      ContentText,
      PrimaryColor,
      PrimaryButtonColor,
      PrimaryButtonTextColor,
      PanelBorder,
      DisabledText,
      PlaceholderText,
      DimText,
      PrimaryButtonColorHover,
      DropdownButtonBorderColor,
      ErrorColor,
    } = {},
  } = useDiscoverTheme();

  return css`
    ${DatePickerCSS}

    user-select: none;

    ${!isResponsive
      ? ''
      : `
      margin-top: 2px;

      .react-aria-Dialog {
        width: calc(var(--viewport-width) - 5px);
        max-width: 100%;
        
        .react-aria-Calendar, .react-aria-RangeCalendar {
          width: 100%;
          
          .react-aria-CalendarCell {
            width: calc((var(--viewport-width) / 7) - 1rem);
          }
        }
      }
    `}

    // non-disabled colors/styling
    &:has(
        :not(
          .react-aria-DatePicker[data-disabled],
          .react-aria-DateRangePicker[data-disabled]
        )
      ) {
      --focus-ring-color: ${PrimaryColor};
      --text-color: ${ContentText};
      --text-color-disabled: ${DisabledText};
      --text-color-placeholder: ${PlaceholderText};
      --border-color: ${PrimaryColor};
      --border-color-hover: ${PanelBorder};
      --border-color-pressed: ${PanelBorder};
      --border-color-disabled: ${PanelBorder};
      --field-background: ${ContentBackground};
      --field-text-color: ${DimText};
      --overlay-background: ${ContentBackground};
      --button-background: ${ContentBackground};
      --button-background-pressed: var(--background-color);
      /* these colors are the same between light and dark themes
       * to ensure contrast with the foreground color */
      --highlight-background: ${PrimaryButtonColor};
      --highlight-background-pressed: ${PrimaryButtonColorHover};
      --highlight-background-invalid: ${ErrorColor};
      --highlight-foreground: ${PrimaryButtonTextColor};
      --highlight-foreground-pressed: ${PrimaryButtonTextColor};
      --invalid-color: ${ErrorColor};
      --invalid-color-pressed: ${ErrorColor};

      .react-aria-Group {
        cursor: pointer;
      }

      .react-aria-Button:hover,
      .react-aria-Button[data-pressed] {
        background: var(--highlight-background-pressed);

        .sicon {
          color: var(--highlight-foreground);
        }
      }
    }

    // disabled colors/styling
    &:has(
        .react-aria-DatePicker[data-disabled],
        .react-aria-DateRangePicker[data-disabled]
      ) {
      cursor: not-allowed;
      --focus-ring-color: ${DisabledText};
      --text-color: ${DisabledText};
      --text-color-disabled: ${DisabledText};
      --text-color-placeholder: ${DisabledText};
      --border-color: ${DropdownButtonBorderColor};
      --border-color-hover: ${DisabledText};
      --border-color-pressed: ${DisabledText};
      --border-color-disabled: ${DisabledText};
      --field-text-color: ${DisabledText};
      --overlay-background: ${DisabledText};
      --button-background-pressed: var(--background-color);
      --highlight-background: transparent;
      --highlight-background-pressed: transparent;
      --highlight-background-invalid: transparent;
      --highlight-foreground: ${DisabledText};
      --highlight-foreground-pressed: ${DisabledText};
      --invalid-color: ${DisabledText};
      --invalid-color-pressed: ${DisabledText};

      .react-aria-Group {
        cursor: not-allowed;
      }
    }

    /* Windows high contrast mode overrides */
    @media (forced-colors: active) {
      --background-color: Canvas;
      --focus-ring-color: Highlight;
      --text-color: ButtonText;
      --text-color-base: ButtonText;
      --text-color-hover: ButtonText;
      --text-color-disabled: GrayText;
      --text-color-placeholder: ButtonText;
      --link-color: LinkText;
      --link-color-secondary: LinkText;
      --link-color-pressed: LinkText;
      --border-color: ButtonBorder;
      --border-color-hover: ButtonBorder;
      --border-color-pressed: ButtonBorder;
      --border-color-disabled: GrayText;
      --field-background: Field;
      --field-text-color: FieldText;
      --overlay-background: Canvas;
      --button-background: ButtonFace;
      --button-background-pressed: ButtonFace;
      --highlight-background: Highlight;
      --highlight-background-pressed: Highlight;
      --highlight-background-invalid: LinkText;
      --highlight-foreground: HighlightText;
      --highlight-foreground-pressed: HighlightText;
      --invalid-color: LinkText;
      --invalid-color-pressed: LinkText;
    }

    .react-aria-DateInput {
      padding: 4px;
    }
    .react-aria-DateInput.with-icon {
      padding-right: 1.7rem;
    }
  `;
};

export const DatePickerStyles = styled.div<{ onMount?: () => void }>(
  ({ onMount = noop }) => {
    const commonOverrides = useDateCssRules({ onMount });

    return css`
      ${DatePickerCSS}

      ${commonOverrides}
    `;
  },
);

export const DateRangePickerStyles = styled.div<{ onMount?: () => void }>(
  ({ onMount = noop }) => {
    const commonOverrides = useDateCssRules({ onMount });

    return css`
      ${DateRangePickerCSS}

      ${commonOverrides}
    `;
  },
);
