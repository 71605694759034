import { useMemo } from 'react';
import { ISugarIconProps } from '../../common';
import _ from 'lodash';
import { SugarIcon } from '../../sugar-icon';
import { CombinedFieldTypeContainer, TextIcon } from './field-type-icon.styles';
import { useDiscoverThemeColors } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';

import { css } from '@emotion/react';
import { ChartSpecIds, ShelfName } from '../../../common/Constants';
import { SugarSecondaryPalette } from '../../../common/emotion/theme/colors.styles';

export type FieldTypeIconProps = Omit<ISugarIconProps, 'icon'> & {
  fieldType: string;
  isCalculation?: boolean;
  shelfId?: ShelfName;
  isFieldDecrease?: boolean;
};

function makeIconTypeContainer(_fieldType) {
  return props => {
    const withoutSizeProps = useMemo(() => {
      return _.omit(props, ['size']);
    }, [props]);

    const size = props.size ?? 24;
    const defaultCursor = css({
      cursor: 'default',
    });
    return (
      <CombinedFieldTypeContainer
        size={size}
        left={_fieldType === 'calendar' ? size / 3 : size / 2}
      >
        {props.isCalculation && (
          <SugarIcon
            className={'left'}
            icon={'equal'}
            size={size / 2}
            css={defaultCursor}
            {...withoutSizeProps}
          />
        )}
        <SugarIcon
          className={'right'}
          size={size / 2}
          icon={_fieldType}
          css={defaultCursor}
          {...props}
        />
      </CombinedFieldTypeContainer>
    );
  };
}

const StringIcon = makeIconTypeContainer('string');
const NumberIcon = makeIconTypeContainer('number');
const CalendarIcon = makeIconTypeContainer('calendar');
const TargetIcon = makeIconTypeContainer('target-lg');
const MissingFieldIcon = makeIconTypeContainer('close');
const ArrowUp = makeIconTypeContainer('arrow-up');
const ArrowDown = makeIconTypeContainer('arrow-down');

export const FieldTypeIcon = ({
  fieldType = '',
  isCalculation,
  shelfId,
  isFieldDecrease,
  ...props
}: FieldTypeIconProps) => {
  const withoutFieldTypeProps = useMemo(() => {
    return _.omit(props, ['fieldType', 'isCalculation']);
  }, [props]);
  const { FieldIconBlue, SuccessColor, LightRed } = useDiscoverThemeColors();
  const { MediumGreen, MediumRed } = SugarSecondaryPalette;
  switch (_.toUpper(fieldType)) {
    case 'TIME_CALC':
    case 'TIMESTAMP':
    case 'PRIOR_PERIOD_CALC':
    case 'HIERARCHY':
      return <CalendarIcon color={FieldIconBlue} {...withoutFieldTypeProps} />;
    case 'NUMBER':
      return (
        <NumberIcon
          isCalculation={isCalculation}
          color={SuccessColor}
          {...withoutFieldTypeProps}
        />
      );
    case 'STRING_CALC':
    case 'CALC':
      return <StringIcon isCalculation color={SuccessColor} />;
    case 'TARGET':
      return <TargetIcon color={FieldIconBlue} {...withoutFieldTypeProps} />;
    case 'STRING':
      return (
        <StringIcon
          isCalculation={isCalculation}
          color={FieldIconBlue}
          {...props}
        />
      );
    case '':
      return <MissingFieldIcon color={LightRed} />;
    default:
      if (shelfId === ChartSpecIds.ENTRANCE) {
        return <ArrowUp color={MediumGreen} />;
      } else if (shelfId === ChartSpecIds.EXIT) {
        return <ArrowDown color={MediumRed} />;
      } else if (shelfId === ChartSpecIds.CHANGE) {
        if (isFieldDecrease) {
          return <ArrowDown color={MediumRed} />;
        } else {
          return <ArrowUp color={MediumGreen} />;
        }
      }
      return <TextIcon>{fieldType.slice(0, 3).toUpperCase()}</TextIcon>;
  }
};
