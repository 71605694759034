import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../../../common/emotion/theme';
import { PopperStyled as PopperUnstyled } from '../../../../components/nested-dropdown/nested-dropdown.styles';
import { useSelector } from 'react-redux';

export const PopperStyled = styled(PopperUnstyled as any)`
  width: ${props => (props.disablePortal ? '100%' : 'auto')};
  max-width: 250px;
  min-width: ${props => (props.disablePortal ? '100%' : 'auto')};
  z-index: 5;
  border: none;
`;
export const SlicerLabel = styled.div<{ height: string; isOpen: boolean }>(
  ({ height, isOpen }) => {
    const {
      mobileMaxWidth,
      isDashletMode,
      colors: { ContentText },
    } = useDiscoverTheme();

    const mobileStyles = css`
      padding-left: 15px;
      transform: ${isOpen && 'translate(-100%, 0)'};
      min-width: 100%;
    `;

    return css`
      width: 100%;
      font-size: 14px;
      color: ${ContentText};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 12px;
      position: relative;
      height: ${height}px;

      ${isDashletMode &&
      css`
        ${mobileStyles}
      `}

      @media (max-width: ${mobileMaxWidth}px) {
        ${mobileStyles}
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
  },
);

interface ISlicerOptionSubMenuWrapper {
  isSubMenuOpen: boolean;
}

export const SlicerOptionSubMenuWrapper =
  styled.div<ISlicerOptionSubMenuWrapper>(({ isSubMenuOpen }) => {
    const {
      mobileMaxWidth,
      isDashletMode,
      isMobile,
      colors: { ContentBackground },
    } = useDiscoverTheme();

    const mobileStyles = css`
      width: 100%;
      display: block;
      transform: translate(${isSubMenuOpen && !isMobile ? '-100%' : 0}, 0);
      transition: transform 300ms ease-in-out;
    `;

    return css`
      display: ${isSubMenuOpen ? 'block' : 'none'};

      .corvana-calendar-picker {
        position: relative;
        left: 0;
      }

      ${isDashletMode &&
      css`
        .corvana-calendar-picker {
          height: calc(
            var(--viewport-height) - var(--calendar-height-adjust, 10rem)
          );
          overflow: hidden auto;
          background-color: ${ContentBackground};
        }
      `}

      @media (max-width: ${mobileMaxWidth}px) {
        ${mobileStyles}
      }
    `;
  });

export const StyledList = styled.div(() => {
  const { isResponsive = false, isDashletMode = false } = useSelector<
    any,
    { isResponsive: boolean; isDashletMode: boolean }
  >(
    ({
      main: { isMobile = false },
      dashlet: { isDashletMode = false },
    }: any) => ({
      isResponsive: isMobile || isDashletMode,
      isDashletMode,
    }),
  );

  return css`
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }

    ${isResponsive &&
    css`
      --calendar-height-adjust: 10rem;

      height: calc(
        var(--viewport-height) -
          ${isDashletMode ? 'var(--calendar-height-adjust, 10rem)' : '15rem'}
      );
      margin-top: 0.2rem;

      .ReactVirtualized__Grid.ReactVirtualized__List {
        overflow: inherit !important;
        width: 100% !important;
      }
      .ReactVirtualized__Grid__innerScrollContainer {
        max-width: 100% !important;
      }
    `}
  `;
});

export const DatePickerWrapper = styled.div(() => {
  const { mobileMaxWidth, isDashletMode } = useDiscoverTheme();

  const mobileStyles = css`
    width: 100%;

    &.since-calendar {
      top: -28px;
    }

    &.between-calendar {
      top: -56px;
    }
  `;

  return css`
    ${isDashletMode &&
    css`
      ${mobileStyles}
    `}

    @media (max-width: ${mobileMaxWidth}px) {
      ${mobileStyles}
    }
  `;
});
