import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocationProtected } from '../../common/utilities/with-router';

export const Segue = ({
  className,
  classNames,
  timeout,
  children,
}: {
  className?;
  classNames?;
  timeout?;
  children?;
}) => {
  const location = useLocationProtected();
  const _timeout = timeout | 250;
  return (
    <TransitionGroup className={className}>
      <CSSTransition
        key={location.key}
        classNames={classNames}
        timeout={_timeout}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};
