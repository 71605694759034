import { IShortid } from './shortid-adapter.interface';
import { customAlphabet } from 'nanoid';

/**
 * This uses the adapter pattern to mimic the shortid library.
 * shortid historically used a URL-safe alphabet [0-9a-zA-Z-_]
 * and returned IDs from 7 to 14 characters (random length).
 */
const SHORTID_ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
const MIN_LENGTH = 7;
const MAX_LENGTH = 14;

export const shortidAdapter: IShortid = {
  generate(): string {
    const randomLength =
      MIN_LENGTH + Math.floor(Math.random() * (MAX_LENGTH - MIN_LENGTH + 1));

    const shortidLikeId = customAlphabet(SHORTID_ALPHABET, randomLength);

    return shortidLikeId();
  },
};
