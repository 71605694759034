import { css } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { SugarIcon } from '../../icons';
import { getMobileMaxWidth } from '../../common/redux/selectors/main-selector-hooks';

const ProfileDefaultIcon = ({ className, ...otherProps }) => {
  const mobileMaxWidth = useSelector(getMobileMaxWidth);

  return (
    <SugarIcon
      css={css`
        padding-top: 4px;
        @media (max-width: ${mobileMaxWidth}px) {
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            font-size: 2rem;
          }
        }
      `}
      icon={'user'}
      className={className}
      {...otherProps}
    />
  );
};

class component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isReady: false,
    };
    this.eventHandlers = [];
    this._handleFailure = () =>
      this.setState({ isLoaded: false, isReady: true });
    this._handleSuccess = () =>
      this.setState({ isLoaded: true, isReady: true });
  }

  componentDidMount() {
    const img = new Image();
    img.src = this.props.src;
    this.eventHandlers.push({
      elem: img,
      event: 'load',
      handler: this._handleSuccess,
    });
    this.eventHandlers.push({
      elem: img,
      event: 'error',
      handler: this._handleFailure,
    });
    img.addEventListener('load', this._handleSuccess, false);
    img.addEventListener('error', this._handleFailure, false);
  }

  componentWillUnmount() {
    this.eventHandlers.forEach(({ elem, event, handler }) => {
      elem.removeEventListener(event, handler);
      elem.remove();
    });
  }

  render() {
    const { className, src, style, ...otherProps } = this.props;
    const styles = {
      backgroundImage: `url(${src})`,
      ...style,
    };
    if (!this.state.isLoaded && this.state.isReady) {
      // icon
      return (
        <ProfileDefaultIcon
          className={classnames('profile-image default', className)}
          {...otherProps}
        />
      );
    }
    if (this.state.isLoaded && this.state.isReady) {
      // pic
      return (
        <div
          style={styles}
          className={classnames('profile-image', className)}
          {...otherProps}
        />
      );
    }
    return (
      <div
        style={style}
        className={classnames('profile-image', className)}
        {...otherProps}
      />
    );
  }
}

component.propTypes = {
  src: PropTypes.string,
};

component.defaultProps = {
  src: undefined,
};

export default component;
