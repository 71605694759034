import { memo } from 'react';
import _ from 'lodash';
import { messages } from '../../../../../i18n';
import {
  ITimePeriod,
  TimePeriods,
  getDisplayText,
} from '../../../../filter/relative-date-condition';
import { useSelectedListItemSxStyles } from './time-frame-dropdown.styles';
import { useSelector } from 'react-redux';
import { VIZ_SELECTORS } from '../../../../../common/redux/selectors/viz-selectors';
import {
  SelectDropdown,
  SelectItem,
} from '../../../../../ui/dropdowns/select-dropdown';

interface ITimeFrameDropdownProps {
  onMenuItemClick: (key: string) => void;
  selectedValue: string;
}

export const TimeFrameDropdown = memo<ITimeFrameDropdownProps>(
  ({ onMenuItemClick, selectedValue }) => {
    const { useFiscalCalendar } = useSelector(state => ({
      useFiscalCalendar:
        VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
        VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
    }));

    const { sx: selectedListItemsSx } = useSelectedListItemSxStyles();

    const menuItems = [{ key: '', moment_val: '', display: '', format: '' }]
      .concat(Object.values(TimePeriods))
      .map((timePeriod: ITimePeriod) => {
        const displayMessage = getDisplayText(timePeriod, useFiscalCalendar);
        const { key } = timePeriod;
        return {
          key,
          title:
            key === ''
              ? `${messages.slicer.timeFrame}...`
              : _.get(messages, displayMessage, displayMessage),
        };
      });

    const toggleButtonTitle = menuItems.find(
      ({ key }) => key === selectedValue,
    ).title;

    const isResponsive = useSelector(
      ({
        main: { isMobile = false },
        dashlet: { isDashletMode = false },
      }: any) => isMobile || isDashletMode,
    );

    return (
      <div className={`dropdown${open ? ' open' : ''}`}>
        <SelectDropdown
          title={toggleButtonTitle}
          className='timeframe-toggle-button'
          headerSx={{
            width: '100%',
            height: '32px',
            ...(isResponsive ? { maxWidth: 'unset' } : {}),
          }}
          listSx={{
            maxHeight: 'min(20rem, calc(var(--viewport-height) - 15rem))',
          }}
        >
          {menuItems.map(({ key, title }) => {
            return (
              <SelectItem
                key={key}
                onClick={() => {
                  onMenuItemClick(key);
                }}
                sx={selectedValue === key && selectedListItemsSx}
                className={key === '' && 'hide'}
              >
                {title}
              </SelectItem>
            );
          })}
        </SelectDropdown>
      </div>
    );
  },
);
