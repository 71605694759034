import { css } from '@emotion/react';
import { calcDialogStyles } from '../../discovery/calc/CalcDialog/calc-dialog.styles';
import { datasetPreviewTableStyles } from '../../views/DatasetPreviewTable/dataset-preview-table.styles';
import { uiTableStyles } from '../../components/UITable/ui-table.styles';
import { iconDropdownStyles } from '../../components/./icon-dropdown/icon-dropdown.styles';
import { multipleEmailInputStyles } from '../../components/MultipleEmailInput/multiple-email-input.styles';
import { accountSettingsStyles } from '../../account/account-settings/account-settings.styles';
import { mobileAccountSettingsStyles } from '../../account/mobile-account-settings/mobile-account-settings.styles';
import { libraryStyles } from '../../views/Library/library.styles';
import { mobileLibraryStyles } from '../../views/MobileLibrary/mobile-library.styles';
import { pivotConditionalFormattingStyles } from '../../components/PivotConditionalFormatting/pivot-conditional-formatting.styles';
import { reportDetailStyles } from '../../views/ReportDetail/report-detail.styles';
import { cellMenuStyles } from '../../components/CellMenu/cell-menu.styles';
import { libraryDashletStyles } from '../../sugar-dashlet/library-dashlet/library-dashlet.styles';
import { headerNavigationStyles } from '../../components/HeaderNavigation/header-navigation.styles';
import { mobileVizFooterStyles } from '../../components/MobileVizFooter/mobile-viz-footer.styles';
import { mobileMenuButtonStyles } from '../../components/MobileMenuButton/mobile-menu-button.styles';
import { slackNotificationConfigStyles } from '../../components/SlackNotificationConfig/slack-notification-config.styles';
import { IDiscoverEmotionTheme } from './theme';

export const componentStyles = ({
  mobileMaxWidth,
  theme,
}: {
  mobileMaxWidth;
  theme: IDiscoverEmotionTheme;
}) => css`
  ${slackNotificationConfigStyles({ theme })}
  ${calcDialogStyles({ theme })}
  ${datasetPreviewTableStyles({ theme })}
  ${uiTableStyles({ theme })}
  ${iconDropdownStyles({ theme })}
  ${multipleEmailInputStyles({ theme })}
  ${accountSettingsStyles({ theme })}
  ${libraryStyles({ theme })}
  ${mobileLibraryStyles({ mobileMaxWidth })}
  ${pivotConditionalFormattingStyles({ theme })}
  ${reportDetailStyles({ theme })}
  ${cellMenuStyles({ theme })}
  ${libraryDashletStyles}
  ${headerNavigationStyles}
  ${mobileVizFooterStyles}
  ${mobileAccountSettingsStyles({ theme })}
  ${mobileMenuButtonStyles({ theme })}
`;
