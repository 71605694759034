import { memo, useState } from 'react';
import _, { get, includes, isBoolean, keys } from 'lodash';
import Popper from '@mui/material/Popper';
import ListItem from '@mui/material/ListItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import { FieldTextWithAdvancedTooltip } from '../../discovery/SimpleFieldPill';
import { CheckIconSmall } from '../Icons';
import { messages } from '../../i18n';
import { FieldMetadata, IAnyAttribute } from '../../datasets';
import {
  useOpenVizChartSpec,
  useOpenVizLayoutSelector,
  useRuntimeFilters,
  useVizOptionSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { IChartShelf, ShelfTypes } from '../../discovery/interfaces';
import AggregationMenuItem, {
  getAggregateList,
} from '../../discovery/AggregationMenuItem';
import {
  FieldMenuActions,
  isEditableCalcField,
  isNotCalc,
} from './field-dropdown.utils';
import {
  ListItemButtonStyled,
  PaperStyled,
  ListStyled,
} from './field-dropdown.styles';
import { TimeHierarchyMenuItem } from '../time-hierarchy-menu-item';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import { useSelector } from 'react-redux';
import { NestedDropdownContextProvider } from '../nested-dropdown';
import { ChartSpecIds, Types, VIZ_OPTION_IDS } from '../../common/Constants';
import { Viz } from '../../discovery/VizUtil';
import { useHasDisabledAggregations } from './field-dropdown.hook';

interface FieldDropdownProps {
  discoveryId: string;
  field: IAnyAttribute;
  shelf?: IChartShelf;
  doMenuAction?: (actionKey: string) => void;
  isScrolling?: boolean;
  isDragging?: boolean;
  isFieldMissing?: boolean;
}

export const FieldDropdown = memo(
  ({
    discoveryId,
    field,
    shelf,
    doMenuAction = _.noop,
    isScrolling = false,
    isDragging = false,
    isFieldMissing = false,
  }: FieldDropdownProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const aggregateInfo = useSelector<any, any>(
      (state: any) => state.main?.aggregateInfo,
    );
    const hasAggregations = !_.isEmpty(getAggregateList(field, aggregateInfo));
    const hasDisabledAggregations = useHasDisabledAggregations({
      discoveryId,
      field,
    });

    const fieldMetaObj = useVizOptionSelector({
      discoveryId,
      option: VIZ_OPTION_IDS.fieldMetadata,
      defaultValue: {},
    });
    const fieldMeta = get(fieldMetaObj, field?.name, {});

    const isDecrease =
      isBoolean(get(fieldMeta, FieldMetadata.IS_DECREASE)) &&
      fieldMeta[FieldMetadata.IS_DECREASE];

    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClickAway = () => {
      setAnchorEl(null);
    };

    const handleMenuAction = action => {
      doMenuAction(action);
      setIsHovering(false);
      handleClickAway();
    };

    const open = Boolean(anchorEl);

    const hasScrollingChanged = useHasValueChanged({ value: isScrolling });
    if (hasScrollingChanged && isScrolling) {
      handleClickAway();
    }

    const popperId = open
      ? `field-dropdown-popper-${_.kebabCase(field?.name)}`
      : undefined;

    const layout = useOpenVizLayoutSelector({ discoveryId });
    const chartSpec = useOpenVizChartSpec({ discoveryId });
    const isInShelf = !!shelf;
    const fieldsInShelf = layout[shelf?.id] ?? [];
    const fieldIndexInShelf = _.findIndex(fieldsInShelf, { name: field?.name });

    const querySort = useVizOptionSelector({
      discoveryId,
      option: VIZ_OPTION_IDS.querySort,
      defaultValue: {},
    });

    const checkSortField = !!querySort[field.name];
    const checkSortAsc =
      checkSortField && querySort[field.name].direction === 'asc';
    const checkSortDesc =
      checkSortField && querySort[field.name].direction === 'desc';

    const { shelves } = chartSpec;
    const moveToMenuItems = _.values(shelves).filter(_shelf => {
      // don't show the option to move it to itself
      const notSameShelf = _shelf?.id !== shelf?.id;

      // don't show the option to move it to a shelf that doesn't support the data type
      const shelfAcceptsFieldType = chartSpec.validateFieldForShelf(
        field,
        _shelf.id,
        layout,
      );
      return notSameShelf && shelfAcceptsFieldType;
    });

    const runtimeFilters = useRuntimeFilters(discoveryId);

    const canDesignateRuntimeFilter =
      !includes(keys(runtimeFilters), field?.name) && isNotCalc(field);

    const isAnyFieldInPlay = !_.isEmpty(Viz.getAllFieldsInPlay(layout));

    return (
      <NestedDropdownContextProvider>
        <div
          className={`dropdown${open ? ' open' : ''}`}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
        >
          <FieldTextWithAdvancedTooltip
            onClick={handleClick}
            discoveryId={discoveryId}
            field={field}
            isDragging={isDragging}
            isScrolling={isScrolling}
            isFieldMissing={isFieldMissing ?? false}
            isHovering={isHovering}
            isFieldDropdownOpen={open}
          />
          <Popper
            id={popperId}
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-start'}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <PaperStyled square>
                <ListStyled
                  sx={{ width: 175 }}
                  isShifted={checkSortDesc || checkSortAsc}
                >
                  <ListItem disablePadding>
                    <ListItemButtonStyled
                      onClick={() => handleMenuAction(FieldMenuActions.FILTER)}
                      disableRipple
                    >
                      {messages.layoutPanel.filter}...
                    </ListItemButtonStyled>
                  </ListItem>
                  {[Types.DATE, Types.TIMESTAMP, Types.STRING].includes(
                    field.attributeType,
                  ) &&
                    isAnyFieldInPlay && (
                      <ListItem disablePadding>
                        <ListItemButtonStyled
                          onClick={() =>
                            handleMenuAction(FieldMenuActions.FILTER_AGGREGATE)
                          }
                          disableRipple
                        >
                          {messages.layoutPanel.filterAggregate}...
                        </ListItemButtonStyled>
                      </ListItem>
                    )}
                  {!isInShelf && field.attributeType === 'TIMESTAMP' && (
                    <TimeHierarchyMenuItem
                      field={field}
                      discoveryId={discoveryId}
                    />
                  )}
                  <Divider sx={{ margin: '8px 0' }} />
                  {shelf?.id === ChartSpecIds.CHANGE && (
                    <ListItem disablePadding key={`shelf-${shelf?.id}`}>
                      <ListItemButtonStyled
                        onClick={() =>
                          handleMenuAction(
                            isDecrease
                              ? FieldMenuActions.INCREASE
                              : FieldMenuActions.DECREASE,
                          )
                        }
                        disableRipple
                      >
                        {isDecrease
                          ? messages.layoutPanel.increaseOption
                          : messages.layoutPanel.decreaseOption}
                      </ListItemButtonStyled>
                    </ListItem>
                  )}
                  {!_.isEmpty(moveToMenuItems) &&
                    moveToMenuItems.map(shelf => (
                      <ListItem disablePadding key={`shelf-${shelf?.id}`}>
                        <ListItemButtonStyled
                          onClick={() => handleMenuAction(shelf?.id)}
                          disableRipple
                        >
                          {messages.formatString(
                            messages.layoutPanel.moveToShelf,
                            _.get(messages, shelf?.name, shelf?.name),
                          )}
                        </ListItemButtonStyled>
                      </ListItem>
                    ))}
                  {canDesignateRuntimeFilter && (
                    <ListItem disablePadding key={`shelf-${shelf?.id}`}>
                      <ListItemButtonStyled
                        onClick={() =>
                          handleMenuAction(FieldMenuActions.DESIGNATE_RUNTIME)
                        }
                        disableRipple
                      >
                        {messages.layoutPanel.makeRuntimeFilter}
                      </ListItemButtonStyled>
                    </ListItem>
                  )}
                  {isInShelf && !_.isEmpty(moveToMenuItems) && (
                    <Divider sx={{ margin: '8px 0' }} />
                  )}
                  {shelf?.shelfType === ShelfTypes.MEASURE && (
                    <>
                      {hasDisabledAggregations ? (
                        <ListItemButtonStyled disabled>
                          {messages.aggregation.menuItemLabel}
                        </ListItemButtonStyled>
                      ) : (
                        <AggregationMenuItem
                          field={field}
                          shelf={shelf}
                          discoveryId={discoveryId}
                          onSelect={() => handleClickAway()}
                        />
                      )}
                      {hasAggregations && <Divider sx={{ margin: '8px 0' }} />}
                    </>
                  )}
                  {isInShelf &&
                    chartSpec.supportsLayoutPanelSort && [
                      <ListItem disablePadding key={'sort-ascending'}>
                        <CheckIconSmall show={checkSortAsc} />
                        <ListItemButtonStyled
                          onClick={() =>
                            handleMenuAction(FieldMenuActions.SORT_ASCENDING)
                          }
                          disableRipple
                        >
                          {messages.layoutPanel.sortAscending}
                        </ListItemButtonStyled>
                      </ListItem>,
                      <ListItem disablePadding key={'sort-descending'}>
                        <CheckIconSmall show={checkSortDesc} />
                        <ListItemButtonStyled
                          onClick={() =>
                            handleMenuAction(FieldMenuActions.SORT_DESCENDING)
                          }
                          disableRipple
                        >
                          {messages.layoutPanel.sortDescending}
                        </ListItemButtonStyled>
                      </ListItem>,
                      <Divider sx={{ margin: '8px 0' }} key={'sort-divider'} />,
                    ]}
                  {isInShelf &&
                    fieldsInShelf.length > 1 && [
                      fieldIndexInShelf !== 0 && (
                        <ListItem disablePadding key={'move-up'}>
                          <ListItemButtonStyled
                            onClick={() =>
                              handleMenuAction(FieldMenuActions.MOVE_UP)
                            }
                            disableRipple
                          >
                            {messages.layoutPanel.moveUp}
                          </ListItemButtonStyled>
                        </ListItem>
                      ),
                      fieldIndexInShelf !== fieldsInShelf.length - 1 && (
                        <ListItem disablePadding key={'move-down'}>
                          <ListItemButtonStyled
                            onClick={() =>
                              handleMenuAction(FieldMenuActions.MOVE_DOWN)
                            }
                            disableRipple
                          >
                            {messages.layoutPanel.moveDown}
                          </ListItemButtonStyled>
                        </ListItem>
                      ),
                      <Divider
                        sx={{ margin: '8px 0' }}
                        key={'move-to-divider'}
                      />,
                    ]}
                  {isInShelf && (
                    <ListItem disablePadding>
                      <ListItemButtonStyled
                        onClick={() =>
                          handleMenuAction(FieldMenuActions.REMOVE)
                        }
                        disableRipple
                      >
                        {messages.layoutPanel.remove}
                      </ListItemButtonStyled>
                    </ListItem>
                  )}
                  {isEditableCalcField(field) && [
                    <Divider sx={{ margin: '8px 0' }} key={'edit-divider'} />,
                    <ListItem disablePadding key={'edit-calc'}>
                      <ListItemButtonStyled
                        onClick={() =>
                          handleMenuAction(FieldMenuActions.EDIT_CALC)
                        }
                        disableRipple
                      >
                        {messages.layoutPanel.edit}...
                      </ListItemButtonStyled>
                    </ListItem>,
                    <ListItem disablePadding key={'delete-calc'}>
                      <ListItemButtonStyled
                        onClick={() =>
                          handleMenuAction(FieldMenuActions.DELETE_CALC)
                        }
                        disableRipple
                      >
                        {messages.layoutPanel.delete}...
                      </ListItemButtonStyled>
                    </ListItem>,
                  ]}
                </ListStyled>
              </PaperStyled>
            </ClickAwayListener>
          </Popper>
        </div>
      </NestedDropdownContextProvider>
    );
  },
);
